import React, { forwardRef, useEffect, useRef } from 'react';
import { Button, MenuItem, Menu } from '@material-ui/core';
import { FaCaretDown } from "react-icons/fa";
import AtlasIcon from "components/Icon/atlasIcon";
import './index.scss';

const CustomMenu = forwardRef((props) => (
  <Menu
    keepMounted
    disableAutoFocusItem={true}
    classes={{ paper: 'at-btn__dropdown--paper' }}
    MenuListProps={{ className: "at-btn__dropdown--list_root" }}
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const Dropdown = ({ 
    label,
    children,
    disabled,
    options,
    selected,
    iconRef,
    iconStyle,
    wrapperStyle,
    onSelect 
  }) => {
  let menuRef = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    if (children) {
      document.addEventListener("mousedown", handleDocumentClick);
      return () => {
        document.removeEventListener("mousedown", handleDocumentClick);
      }
    }
  }, [children]);

  // Toggle dropdown menu
  const toggleDropdown = (event) => setAnchorEl(event?.currentTarget);
  const handleClose = () => setAnchorEl(null);

  // Handle document click
  const handleDocumentClick = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      handleClose();
    }
  };

  // Handle selection
  const handleSelect = (option) => {
    onSelect(option);
    handleClose();
  };

  return (
    <div className="at-participant__assign at-dropdown" 
      style={{...wrapperStyle || {}}}>
      {
        !iconRef && (
          <button
            disabled={disabled}
            aria-controls="customized-menu"
            aria-haspopup="true"
            variant="contained"
            className="btn-new btn-new--primary grid_gap-1 at-dropdown-btn" 
            onClick={toggleDropdown}>
            {label || 'Select an option'}
            <FaCaretDown style={{ width: 14, height: 14 }} />
          </button>
        )
      }
      {
        iconRef && (
          <Button
            disabled={disabled}
            className="at-btn_dropdown-icon"
            aria-controls="customized-menu"
            aria-haspopup="true"
            variant="contained"
            onClick={toggleDropdown}>
            <AtlasIcon
              svgHref={iconRef || ""}
              style={{ width: 14, height: 14, ...iconStyle || {} }}/>
          </Button>
        )
      }
      <CustomMenu
        ref={menuRef} 
        onClose={handleClose}
        id="customized-menu" 
        open={Boolean(anchorEl)} 
        className={`at-btn_dropdown--modal-root`}
        anchorEl={anchorEl}>
        {children && children}
        {options && options.map((option, index) => (
          <MenuItem
            key={`${option.value}-${index}`}
            onClick={() => handleSelect(option)}
            classes={{ root: `at-btn__dropdown--list ${selected?.value === option.value ? 'at-selected' : ''}` }}>
            {option.label}
          </MenuItem>
        ))}
      </CustomMenu>
    </div>
  );
};

export default Dropdown;