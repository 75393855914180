import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { FaCaretDown, FaUserAlt, FaUserTie } from "react-icons/fa";

import CustomButton from "components/Button";
import CustomTableHeader from "./components/header";
import CustomTableContent from "./components/content";

import "../../index.scss";

class SimpleTable extends Component {
  state = {
    rowData: [],
    headerRearrangeData: {},
    showCreateDropDown: false,
    maxActionColumnLength: 0
  };

  constructor(props) {
    super(props);

    if (props.showCreate) {
      this.dropDownRef = React.createRef();
    }
  }

  componentDidMount = () => {
    this.setState({ rowData: this.props.rowData });
    document.addEventListener("click", this.handleOnClose, true);

    let tmpMaxLength = 0
      this.props.rowData?.forEach((rowItem) => {
        let tmpLength = 0

        this.props.actionColumnContent?.forEach((action) => {
          if(
            (action.onShow && typeof action.onShow === "function" && action.onShow(rowItem)) 
            || !action.onShow
          ) {
            tmpLength++
          }
        })
        
        tmpMaxLength = tmpMaxLength < tmpLength
          ? tmpLength
          : tmpMaxLength
      })
      this.setState({
        maxActionColumnLength: tmpMaxLength
      }); 
  };

  handleOnClose = (event) => {
    if (
      this.dropDownRef?.contains &&
      !this.dropDownRef?.contains(event.target)
    ) {
      this.setState({ showCreateDropDown: false });
    }
  };

  componentDidUpdate = (pp) => {
    if(pp.rowData !== this.props.rowData) {
      let tmpMaxLength = 0
      this.props.rowData?.forEach((rowItem) => {
        let tmpLength = 0

        this.props.actionColumnContent?.forEach((action) => {
          if(
            (action.onShow && typeof action.onShow === "function" && action.onShow(rowItem)) 
            || !action.onShow
          ) {
            tmpLength++
          }
        })
        
        tmpMaxLength = tmpMaxLength < tmpLength
          ? tmpLength
          : tmpMaxLength
      })
      this.setState({
        rowData: this.props.rowData,
        maxActionColumnLength: tmpMaxLength
      }); 
    }
  };

  renderTableContent = () => {
    const {
      partyId,
      title,
      rowData,
      headerData,
      actionColumn,
      rowKey,
      actionColumnContent,
      booleanColumn,
      thousandSeparatorColumn,
      sendEmailDocType,
      downloadDocType,
      disabledDelete,
    } = this.props;

    return rowData && rowData.length > 0 ? (
      this.state.rowData.map((rowItem, index) => (
        <CustomTableContent
          key={index}
          title={title}
          partyId={partyId}
          userId={
            window.location.href.includes("/admin-impersonate")
              ? this.props.data.currentSignInProfileReducer.id
              : this.props.data.profileReducer.id
          }
          disabledDelete={disabledDelete}
          headerData={headerData}
          actionColumn={actionColumn}
          actionColumnContent={actionColumnContent}
          rowItem={rowItem}
          editClick={(param) => this.props.editClick(param)}
          detailsClick={(param) => this.props.detailsClick(param)}
          deleteClick={(param) => this.props.deleteClick(param)}
          sendEmail={(param) => this.props.sendDocClick(param)}
          downloadClick={(param) => this.props.downloadClick(param)}
          rowKey={rowKey}
          maxActionColumnLength={this.state.maxActionColumnLength}
          headerRearrangeData={this.state.headerRearrangeData}
          booleanColumn={booleanColumn}
          thousandSeparatorColumn={thousandSeparatorColumn}
          sendEmailDocType={sendEmailDocType}
          downloadDocType={downloadDocType}
        />
      ))
    ) : (
      <div className="at-table-row-cont at-party_table-row-cont">
        <ul className="at-table-row grid-controller">
          <li className="at-table-row__item">No participants</li>
        </ul>
      </div>
    );
  };

  onClickRearrangeData = (val) =>
    this.setState({ headerRearrangeData: val }, () => {
      if (val.columnValue) {
        return this.setState((prevState) => ({
          rowData: _.orderBy(
            prevState.rowData,
            (o) => {
              let columnData = _.get(o, val.columnValue);
              if (columnData) {
                if (!isNaN(columnData)) {
                  return new Number(columnData);
                } else if (columnData.match(/^(\d{2})\-(\d{2})\-(\d{4})$/)) {
                  let dateString = columnData.split("-");
                  return new Date(dateString[2], dateString[1], dateString[0]);
                } else if (columnData.match(/^(\d{2})\s([A-Z]{3})\s(\d{4})$/)) {
                  return new Date(columnData);
                } else {
                  return columnData.toLowerCase();
                }
              }
            },
            val.mode,
          ),
        }));
      }
    });

  renderDropDown = () => {
    const { partyId, form_type } = this.props;
    const { role } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    return (
      <div
        ref={(ref) => (this.dropDownRef = ref)}
        className={"at-party__dropbear--modal-root"}
        style={{display: this.state.showCreateDropDown ? "block" : "none",}}
      >
        <div className={`at-party__dropbear--list`}>
          {form_type === "confirmation" && role === "Agent" && (
            <button onClick={() => this.props.onClickAssignMyself()}>
              Assign Myself
            </button>
          )}
          {form_type === "confirmation" && role === "Personal Assistant" && (
            <button onClick={() => this.props.onClickAssignSuperior()}>
              Assign My Superior
            </button>
          )}
          <button onClick={() => this.props.onClickAssignContact()}>
            Select from My Contact
          </button>
          <button onClick={() => this.props.onClickCreateContact()}>
            Create a New Contact
          </button>
          {(partyId === 2 || partyId === 4) && (
            <button onClick={() => this.props.onClickAssignInternal()}>
              Select from Internal Agent
            </button>
          )}
        </div>
      </div>
    );
  };

  render = () => {
    const {
      mode,
      title,
      partyId,
      headerData,
      actionColumn,
      showCreate,
      actionColumnContent,
    } = this.props;

    return (
      <div key={partyId}>
        <div className={"at-table_heading"} style={{flexWrap: "nowrap"}}>
          <span className="at-icon_svg-table_heading-title">
            {["Vendor", "Purchaser", "Tenant", "Landlord"].indexOf(title) >
            -1 ? (
              <FaUserAlt />
            ) : (
              <FaUserTie />
            )}
          </span>
          <h5 className="fw-600">{title}</h5>
          {showCreate && (
            <div className="at-participant__assign">
              <CustomButton
                className={"btn-new btn-new--primary grid_gap-1"}
                children={
                  <>
                    Assign
                    <FaCaretDown style={{ width: 14, height: 14 }} />
                  </>
                }
                containerStyle={{ display: "inline", position: "relative" }}
                onClick={() => this.setState({ showCreateDropDown: true })}
                tooltip={false}
              />
              {this.renderDropDown()}
            </div>
          )}
        </div>
        <div className="at-table at-table--set">
          <CustomTableHeader
            mode={mode}
            data={headerData}
            actionColumnContent={actionColumnContent}
            maxActionColumnLength={this.state.maxActionColumnLength}
            actionColumn={actionColumn}
            onClickRearrangeData={(val) => this.onClickRearrangeData(val)}
          />
          {this.renderTableContent()}
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({ data: state });
export default connect(mapStateToProps)(SimpleTable);
