export const cardsData = [
  {
    id: 1,
    title: "Negotiator New Tag Application",
    description: "Apply for a new negotiator tag to showcase your expertise",
    icon: "atlas-vuesax-card",
    color: "#F0631D",
    slug: "negotiator-new-tag-application"
  },
  {
    id: 2,
    title: "Negotiator Tag Replacement",
    description: "Request a replacement for your lost or damaged negotiator tag",
    icon: "atlas-vuesax-convert-card",
    color: "#3B82F6",
    slug: "negotiator-tag-replacement"
  },
  {
    id: 3,
    title: "Subsale Banner Request",
    description: "Order a subsale banner to effectively advertise your property listing",
    icon: "atlas-vuesax-slider-vertical",
    color: "#12B76A",
    slug: "subsale-banner-request"
  },
  {
    id: 4,
    title: "Subsale Sticker Request",
    description: "Order subsale stickers to mark your property listings",
    icon: "atlas-vuesax-clipboard",
    color: "#F79009",
    slug: "subsale-sticker-request"
  },
  {
    id: 5,
    title: "General Requests",
    description: "We're here to assist with all your needs",
    icon: "atlas-vuesax-book",
    color: "#14B8A6",
    slug: "general-request-form"
  },
  {
    id: 6,
    title: "REN Tag Renewal 2025",
    description: "Stay compliant and continue your professional practice seamlessly",
    icon: "atlas-vuesax-note",
    color: "#6875F5",
    slug: "ren-tag-renewal",
    disabled: true
  },
  {
    id: 7,
    title: "Business Card Request",
    description: "Order personalized business cards to enhance your professional image",
    icon: "atlas-vuesax-personal-card",
    color: "#D946EF",
    slug: "business-card-request"
  },
];