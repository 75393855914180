import React, { Component } from "react";
import { compose } from "redux";
import { IoMdAdd } from "react-icons/io";
import _ from "lodash";

import CustomCard from "components/Card";
import AtlasDialog from "components/Dialog";
import ModuleHeader from "components/ModuleHeader";
import AtPaginatedTable from "components/NewPaginatedTable";
import GenerateAppointmentLetterForm from "components/generateForm";
import ModalDialog from "components/Modal/ModalDialog";
import EditAppLetter from "./components/editContent";
import LoadingModal from "components/LoadingModal";

import FormManagementHOC from "../actions";
import AppointmentLetterHOC from "./actions";
import VersionCheckHOC from "actions/versionCheck";
import permissionsChecker from "utils/permissionsChecker";
import { getColorBadge } from "dictionary/badgeColor";

import { initGA } from "utils/ga";

const columnData = [
  {
    header: "Form Number",
    accessor: "transaction_number",
    columnStyle: { width: "60%" },
  },
  {
    header: "Agent",
    renderColumn: ({ agent }) => (
      <>
        <p>{agent.full_name || "N/A"}</p>
        <p>{agent.mobile_contact_number || "N/A"}</p>
        <p>{agent.email || "N/A"}</p>
        <p>{agent.team_name || "N/A"}</p>
      </>
    ),
  },
  {
    header: "Appointment Date",
    accessor: "appointment_date",
    columnStyle: { width: "80%" },
  },
  { header: "Property Address", accessor: "property_address" },
  {
    header: "Remark",
    renderColumn: ({ description = "" }) => <p>{description}</p>,
  },
  {
    header: "Submitted",
    renderColumn: ({ is_claimed }) => (
      <div
        className="at-status_badge"
        style={{ ...getColorBadge(is_claimed ? "Green" : "Red") }}
      >
        {is_claimed ? "Yes" : "No"}
      </div>
    ),
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    columnStyle: { width: "60%", textAlign: "center" },
  },
];

const typeOptions = [
  { label: "Sales", value: "1" },
  { label: "Rental", value: "2" },
];

class AppointmentLetter extends Component {
  componentDidMount = () => {
    this.props.getAllDictionaryData();
    this.props.getInvoicingBranches();
    this.props.getAppointmentLetters(1, "");
    initGA("/dashboard/admin/forms/appointment-letter");

    const id = this.props.history?.location?.state?.id;
    if (id) {
      this.props.getAppointmentLetter(id);
    }
  };

  renderEditAppointmentLetter = () => {
    const { e_process, isLocked } = this.props.selected_appointment_letter;
    const { can_update } = permissionsChecker("Forms", this.props.data);
    const { status_id } = e_process ?? {}
    const eProcessDisabledChecking = !( e_process === null || (e_process !== null && status_id === 0))
    
    return (
      <AtlasDialog withFloatPanel={true} open={this.props.showEditApptLetter}>
        <CustomCard
          className="h-100"
          containerStyle={{ background: "#F3F4F6" }}
          cardContent={<EditAppLetter 
            {...this.props}
            closedEditing={ isLocked || !can_update || eProcessDisabledChecking }/>
          }
        />
      </AtlasDialog>
    );
  }

  generateAppointmentLetter = (dataToSubmit) => {
    this.props.onChangeAppointmentHOC(true, "disableSubmitButton");
    this.props.createAppointmentLetter(dataToSubmit);
  };

  renderFormGenerator = () => {
    const {
      agentList,
      showCreationForm,
      getAgentsBySearch,
      onChangeAppointmentHOC,
    } = this.props;

    if (showCreationForm) {
      return (
        <ModalDialog
          title={"New Appointment Letter"}
          children={
            <GenerateAppointmentLetterForm
              title={"New Appointment Letter"}
              agentList={agentList}
              typeOptions={typeOptions}
              getAgentsBySearch={getAgentsBySearch}
              loading={this.props.showLoadingModal}
              onSelectBranch={(val) =>
                onChangeAppointmentHOC(val.id, "createInvoiceId")
              }
              selectSubsaleType={(val) =>
                onChangeAppointmentHOC(val, "createTypeId")
              }
              invoicingBranch={this.props.data.branchReducer.invoicingBranch}
              invoicing_branch_id={this.props.createInvoiceId}
              generateForm={this.generateAppointmentLetter}
              onClickCloseFormGenerator={() =>
                onChangeAppointmentHOC(false, "showCreationForm")
              }
              submitButtonText={"Create Now"}
            />
          }
          onClose={() =>
            this.props.onChangeAppointmentHOC(false, "showCreationForm")
          }
        />
        
      );
    }
  };

  render = () => {
    const { can_create, can_update } = permissionsChecker(
      "Forms",
      this.props.data,
    );

    return (
      <>
        <ModuleHeader
          title={"Appointment Letters"}
          atlasIcon={"atlas-appointment-letter"}
          actionButton={[
            {
              label: "New Form",
              onShow: can_create,
              className: "btn-new btn-new--primary",
              icon: (
                <IoMdAdd style={{ height: 18, width: 18 }} />
              ),
              onClick: () =>
                this.props.onChangeAppointmentHOC(true, "showCreationForm"),
            },
          ]}
        />
        <CustomCard
          className={`h-100`}
          containerStyle={{ background: "#F3F4F6", borderRadius: 0 }}
          cardContent={
            <AtPaginatedTable
              rowData={this.props.appointment_letters.data || []}
              meta={this.props.appointment_letters.meta}
              columns={columnData}
              actionColumnContent={[
                {
                  name: can_update ? "edit" : "view",
                  onClick: (rowData) =>
                    this.props.getAppointmentLetter(rowData.id),
                },
              ]}
              searchParams={this.props.searchParams}
              onChangeSearchParams={(val) =>
                this.props.onChangeAppointmentHOC(val, "searchParams")
              }
              getListAPI={this.props.getAppointmentLetters}
              totalPages={this.props.appointmentLetterPage}
            />
          }
        />
        {this.renderFormGenerator()}
        {this.renderEditAppointmentLetter()}
        {(this.props.onLoadApptLetter || this.props.onLoadForms) && (
          <LoadingModal />
        )}
      </>
    );
  };
}

export default compose(
  AppointmentLetterHOC,
  FormManagementHOC,
  VersionCheckHOC,
)(AppointmentLetter);
