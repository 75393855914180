import React from "react";
import _ from "lodash";

import LoadingModal from "components/LoadingModal";
import AtlasRadioGroup from "components/RadioGroup";

const typeOptions = [
  { label: "Sale", value: "1" },
  { label: "Rental", value: "2" },
];

const GenerateForm = ({
  loading,
  selectSubsaleType,
  invoicingBranch,
  type,
  generateForm,
  disableSubmitButton,
  onClickCloseFormGenerator,
}) => {
  return (
    <>
      <div className="grid-control grid_gap-2">
        <section className="grid-full-col">
          <h2 className="at-form-input__title">Type</h2>
          <AtlasRadioGroup
            horizontal={true}
            checkedValue={type}
            selectedRadioValue={(val) => selectSubsaleType(val)}
            options={typeOptions}
          />
        </section>
        {!_.isEmpty(invoicingBranch) && (
          <section className="grid-full-col">
            <h2 className="at-form-input__title">Branch</h2>
            <p>{invoicingBranch.name}</p>
          </section>
        )}
      </div>
      <div className="at-modal_dialog-container-footer g-3">
        <button
          disabled={disableSubmitButton}
          className={"btn-new btn-new--primary"}
          onClick={generateForm}
        >
          Create Now
        </button>
        <button
          type={"button"}
          className="btn-new btn-new--outline-secondary"
          onClick={onClickCloseFormGenerator}
        >
          Cancel
        </button>
      </div>
      {loading && <LoadingModal />}
    </>
  );
};

export default GenerateForm;
