import React, { useState, useCallback } from "react";
import _ from "lodash";
import Moment from "moment";
import { PulseLoader } from "react-spinners";

import CustomCard from "components/Card";
import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import AtlasRadioGroup from "components/RadioGroup";
import CustomFormInput from "components/Input/formInput";
import CustomTextarea from "components/Input/textarea";
import CustomTypeahead from "components/Typeahead/new";
import DatePicker from "components/Input/datetimepicker";

import AgentSearchHOC from "actions/agentSearch";

import { Status, Type } from "./options";

const AgentRepresentOptions = {
  "1": [
    { value: "3", label: "Both" },
    { value: "2", label: "Tenant", cobroke_represent: "Landlord" },
    { value: "1", label: "Landlord", cobroke_represent: "Tenant" },
  ],
  "2": [
    { value: "3", label: "Both" },
    { value: "2", label: "Purchaser", cobroke_represent: "Vendor" },
    { value: "1", label: "Vendor", cobroke_represent: "Purchaser" },
  ],
}

const Create = ({
  agentList,
  onLoadPaperForms,
  onLoadAgent,

  onChangeAgentHOC,
  createPaperForm,
  getAgentsBySearch,
  onClose,
}) => {
  const [createForm, onChangeCreateForm] = useState({
    user: null,
    region_id: "",
    tracking_number: "",
    type_id: "1",
    status_id: "",
    remark: "",
    date_taken: Moment().format("DD-MM-YYYY"),
    form_type_id: "1",
    representative_id: "3",
    co_broker: null
  });

  const onChangeCreateField = (key, val) => {
    let tmp = _.cloneDeep(createForm);
    tmp[key] = val;

    if (key === "representative_id" && val === "3") {
      tmp.co_broker = null;
    }

    onChangeCreateForm(tmp);
  };

  const onChangeTypeaheadSearch = useCallback(
    _.debounce((val) => {
      val.length > 0
        ? getAgentsBySearch(val)
        : onChangeAgentHOC([], "agentList");
    }, 600),
    [],
  );

  return (
    <>
      <div className="col-12 col-md-4">
        <div className="at-form__content grid-control">
          <section className="grid-full-col">
            <h2 className="at-form-input__title">{"Form Type"}</h2>
            <p>Physical Form</p>
          </section>
          <section className="grid-full-col">
            <h2 className="at-form-input__title">{"Type"}</h2>
            <AtlasRadioGroup
              valueKey={"id"}
              horizontal={true}
              checkedValue={createForm.type_id}
              containerClassName={"w-100"}
              selectedRadioValue={(val) => onChangeCreateField("type_id", val)}
              options={Type}
            />
          </section>

          <section className="grid-full-col">
            <h2 className="at-form-input__title">{"Form Number"}</h2>
            <CustomFormInput
              type={"text"}
              disabled={createForm.form_type_id === "2" || createForm.form_type_id === "3"}
              value={createForm.tracking_number}
              onChangeValue={(val) => onChangeCreateField("tracking_number", val)}
            />
          </section>

          <section className="grid-full-col">
            <h2 className="at-form-input__title">{"Status"}</h2>
            <AtlasRadioGroup
              valueKey={"id"}
              horizontal={true}
              checkedValue={createForm.status_id}
              containerClassName={"w-100"}
              selectedRadioValue={(val) => onChangeCreateField("status_id", val)}
              options={Status}
            />
          </section>
        </div>
      </div>
      <div className="col-12 col-md-8">
        <div className="grid-control">
          <section className="grid-full-col">
            <div className="d-flex">
              <h2 className="at-form-input__title">Select an Agent</h2>
              <PulseLoader
                sizeunit={"px"}
                size={10}
                color={"#16232c"}
                loading={onLoadAgent}
              />
            </div>
            <CustomTypeahead
              disabled={onLoadAgent}
              typeaheadId={"internal_agent"}
              options={agentList ? agentList : []}
              selectedValue={createForm.user ? [createForm.user] : []}
              filterBy={["full_name", "email", "mobile_contact_number"]}
              labelKey={"full_name"}
              onSearch={(val) => {
                onChangeCreateField("user", null);
                onChangeTypeaheadSearch(val);
              }}
              onSelect={(val) => {
                if (val && val.length > 0) {
                  onChangeCreateField("user", val[0])
                  onChangeTypeaheadSearch("")
                }
              }}
              helpText={"e.g. Full Name, Email, Mobile Number"}
              childrenHead={(rowItem) => <p>{rowItem.full_name}</p>}
              highlighterData={[
                (option) => `Team: ${option.team_name}`,
                <br />,
                (option) => `Email: ${option.email} | Contact: ${option.mobile_contact_number}`,
              ]}
            />
          </section>
          <section className="grid-full-col">
            <h2 className="at-form-input__title">{"Agent Representing"}</h2>
            <AtlasRadioGroup
              horizontal={true}
              checkedValue={createForm.representative_id}
              containerClassName={"w-100"}
              selectedRadioValue={(val) => onChangeCreateField("representative_id", val)}
              options={AgentRepresentOptions[createForm.type_id]}
            />
          </section>
          {createForm.representative_id !== "3" && (
            <>
              <section className="grid-full-col">
                <div className="d-flex">
                  <h2 className="at-form-input__title">Select Co-broke Agent For Separate Claim Submission</h2>
                  <PulseLoader
                    sizeunit={"px"}
                    size={10}
                    color={"#16232c"}
                    loading={onLoadAgent}
                  />
                </div>
                <CustomTypeahead
                  disabled={onLoadAgent}
                  typeaheadId={"internal_agent"}
                  options={agentList || []}
                  selectedValue={createForm.co_broker ? [createForm.co_broker] : []}
                  filterBy={["full_name", "email", "mobile_contact_number"]}
                  labelKey={"full_name"}
                  onSearch={(val) => {
                    onChangeCreateField("co_broker", null);
                    onChangeTypeaheadSearch(val);
                  }}
                  onSelect={(val) => {
                    if (val && val.length > 0) {
                      onChangeCreateField("co_broker", val[0])
                      onChangeTypeaheadSearch("")
                    }
                  }}
                  helpText={"e.g. Full Name, Email, Mobile Number"}
                  childrenHead={(rowItem) => <p>{rowItem.full_name}</p>}
                  highlighterData={[
                    (option) => `Team: ${option.team_name}`,
                    <br />,
                    (option) =>
                      `Email: ${option.email} | Contact: ${option.mobile_contact_number}`,
                  ]}
                />
              </section>
              <section className="grid-full-col">
                <h2 className="at-form-input__title">{"Co-broke Agent Representing"}</h2>
                <p>{_.find(AgentRepresentOptions[createForm.type_id], {value: createForm.representative_id})?.cobroke_represent}</p>
              </section>
            </>
          )}
          <section className="grid-full-col">
            <h2 className="at-form-input__title">{"Remark"}</h2>
            <CustomTextarea
              rows={5}
              value={createForm.remark}
              onChangeValue={(val) => onChangeCreateField("remark", val)}
              placeholder={"Remark"}
            />
          </section>
        </div>
      </div>

      <div className="at-modal_dialog-container-footer">
        <button
          type={"button"}
          className={"btn-new btn-new--primary"}
          onClick={() =>
            createPaperForm({
              ...createForm,
              user_id: createForm.user ? createForm.user.id : null,
              co_broker_id: createForm.co_broker ? createForm.co_broker.id : null,
            })
          }
        >
          Create Now
        </button>
        <button
          type={"button"}
          className="btn-new btn-new--outline-secondary ml-2"
          onClick={() => onClose()}
        >
          Cancel
        </button>
      </div>
      {onLoadPaperForms && <LoadingModal />}
    </>
  );
};

export default AgentSearchHOC(Create);
