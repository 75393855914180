import React from "react";
import { AppointmentBasisOptions, Stakeholders } from "../assets";
import Moment from "moment";

import CustomFormInput from "components/Input/formInput";
import AtlasFormGroup from "components/FormGroup/input";
import CustomCheckbox from "components/Checkbox";

const subsaleForms = [
  {
    label: "Property address",
    value: "property_address",
    type: "autosuggest",
    col: "full",
    placeholder: "Type the property address.",
    disabled: true,
    required: true,
  },
  {
    label: "Appointment date",
    value: "appointment_date",
    type: "date",
    required: true,
  },
  {
    label: "Total consideration price for the property (RM)",
    value: "consideration_price",
    type: "currency",
    required: true,
  },
  {
    label: "Earnest deposit upon acceptance (%)",
    value: "earnest_deposit_percentage",
    type: "number",
    automated: true,
    required: true,
    iconLabelTooltip: `If the downpayment is not equivalent to 10%, toggle to turn off the auto-calculation for Earnest deposit upon acceptance (%) and Upon signing of S&P (%) fields.`,
    renderIconLabel: ({ states, closedEditing, onChange }) => {
      const { autoCalculated } = states;

      return (
        <span
          style={{ display: "inline-flex" }}
          className={`at-form-switch_button  ${closedEditing ? "disabled" : ""} ${(!closedEditing && autoCalculated) ? "checked" : ""}`}>
          <button style={{ width: "initial" }}>
            <input type="checkbox" id="toggle" />
            <label
              htmlFor="toggle"
              onClick={() => {
                if(!closedEditing){
                  onChange("TriggerIcon", !autoCalculated)}
                }
              }
            ></label>
          </button>
        </span>
      );
    },
  },
  {
    label: "Upon signing of S&P (%)",
    value: "balance_deposit_percentage",
    type: "number",
    required: true,
    automated: true,
    style: { marginTop: "auto" },
  },
  {
    label: "Balance of consideration price to be paid within (days)",
    value: "balance_pay_within_days",
    type: "number",
    required: true,
  },
  {
    label:
      "Another period of the balance of the consideration price to be paid (days)",
    value: "balance_pay_within_another_days",
    type: "number",
    required: true,
  },
  {
    label: "Chargeable interest (%)",
    value: "chargeable_interest_percentage",
    type: "number",
    required: true,
  },
  {
    label: "Condition",
    value: "conditions",
    type: "text",
    required: false,
  },
  {
    label: "Land and Buildings (Commercial or Residential) Fee (%)",
    value: "land_and_buildings_fee_percentage",
    type: "number",
    required: true,
  },
  {
    label: "Professional fee payable to IQI Realty Sdn Bhd (RM)",
    value: "fee_payable_amount",
    type: "currency",
    required: true,
  },
  {
    label: "Basis of appointment",
    value: "basis_id",
    type: "radio",
    required: true,
    options: AppointmentBasisOptions,
    contents: [
      {
        id: 1,
        show: ({ basis_id }) => basis_id === 1,
        renderContent: ({
          data,

          can_update,
          onChange,
        }) => {
          const { valid_from = null, valid_to = null } = data;
          return (
            <ul className="at-form_mngmt-collapsed_cont">
              <li className="at-form_mngmt-form_collapsed">
                <h2 className="at-form-input__title">Valid from</h2>
                <div style={{ display: "inline-block" }}>
                  <AtlasFormGroup
                    type={"calendar"}
                    placeholder={"Select date"}
                    value={valid_from}
                    onSelectDate={(val) => {
                      onChange(
                        "valid_from",
                        val && val !== null
                          ? Moment(val).format("YYYY-MM-DD")
                          : null,
                      );
                    }}
                    disabledDate={!can_update}
                  />
                </div>
              </li>
              <li className="at-form_mngmt-form_collapsed">
                <h2 className="at-form-input__title">Valid to</h2>
                <div style={{ display: "inline-block" }}>
                  <AtlasFormGroup
                    type={"calendar"}
                    placeholder={"Select date"}
                    value={valid_to}
                    onSelectDate={(val) => {
                      onChange(
                        "valid_to",
                        val && val !== null
                          ? Moment(val).format("YYYY-MM-DD")
                          : null,
                      );
                    }}
                    disabledDate={!can_update}
                  />
                </div>
              </li>
            </ul>
          );
        },
      },
    ],
  },
  {
    label: "Party to be authorized as a stakeholder",
    value: "stake_holder_id",
    type: "radio",
    required: true,
    options: Stakeholders,
    contents: [
      {
        id: 1,
        show: ({ stake_holder_id }) => stake_holder_id === 2,
        renderContent: ({ data, can_update, onChange }) => {
          const {
            stake_holder_company = "",
            stake_holder_registration_number = "",
          } = data;

          return (
            <ul className="at-form_mngmt-collapsed_cont">
              <li className="at-form_mngmt-form_collapsed">
                <h5 className="at-form-input__title" required>Lawyer Company Name:</h5>
                <CustomFormInput
                  required
                  type={"text"}
                  value={stake_holder_company || ""}
                  onChangeValue={(val) => onChange("stake_holder_company", val)}
                  disabled={!can_update}
                />
              </li>
              <li className="at-form_mngmt-form_collapsed">
                <h5 className="at-form-input__title" required>
                  Lawyer Company Registration Number:
                </h5>
                <CustomFormInput
                  required
                  type={"text"}
                  value={stake_holder_registration_number || ""}
                  onChangeValue={(val) =>
                    onChange("stake_holder_registration_number", val)
                  }
                  disabled={!can_update}
                />
              </li>
            </ul>
          );
        },
      },
    ],
  },
  {
    type: "custom",
    renderCustomForm: ({ data, can_update, onChange }) => {
      const { remind_me, day_to_remind_after_appointed } = data;

      return (
        <li className="at-form_mngmt-form_list grid-full-col">
          <CustomCheckbox
            labelStyle={{ alignItems: "center" }}
            checked={remind_me}
            disabled={!can_update}
            content={
              <span>
                Remind me about this instruction after
                <input
                  type={"text"}
                  disabled={!can_update}
                  className="subsales-reminder-days-input"
                  value={day_to_remind_after_appointed}
                  onChange={(e) =>
                    onChange("day_to_remind_after_appointed", e.target.value)
                  }
                />
                days.
              </span>
            }
            onChangeCheckboxValue={(e) =>
              onChange("remind_me", e.target.checked)
            }
          />
        </li>
      );
    },
  },
];

export default subsaleForms;
