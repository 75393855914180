import React, { useState } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import CustomForm from "components/Form";
import AtlasIcon from "components/Icon/atlasIcon";
import SvgIcon from "components/Icon/new";
import SectionBookingDetails from "../ClaimFormSections/bookingDetails";
import PropertyInfo from "../ClaimFormSections/propertyInfo";
import SectionAgencyFee from "../ClaimFormSections/agencyFee";
import SectionStampingFee from "../ClaimFormSections/stampingFee";

import permissionsChecker from "utils/permissionsChecker";

const SubsaleClaimForm = ({
  data,
  menuExpand,
  onClickNav,
  autoSuggestLoading,
  autoSuggestOptions,
  agencies,
  selected_claim,
  original_claim,
  townshipsList,
  unsavedChanges,
  storedAddresses,
  edgeAutoSuggest,
  setShowExternalAgent,
  onChangeUpdateSelectedClaim,
  onChangeSubsaleClaimsHOC,
  updateSelectedClaim,
  onCloseForm,
  getSelectedTownShip,
}) => {
  const { can_update } = permissionsChecker("Subsales Claims", data);

  const { is_main_info_locked } = selected_claim;

  return (
    <>
      <CustomForm
        formSection={[
          {
            formIcon: <AtlasIcon svgHref={"atlas-calendar-ranged"} />,
            formTitle: "Booking Details",
            formContent: (
              <>
                <div className="mb-3">
                  <h2 className="at-form-input__title">{"Transaction Type"}</h2>
                  <i>
                    {selected_claim.sub_sale_type_id === 1
                      ? "Sub-Sale"
                      : "Sub-Rent"}
                  </i>
                </div>
                <SectionBookingDetails
                  can_update={can_update}
                  storedAddresses={storedAddresses}
                  selected_claim={selected_claim}
                  onChangeUpdateSelectedClaim={onChangeUpdateSelectedClaim}
                  onChangeSubsaleClaimsHOC={onChangeSubsaleClaimsHOC}
                />
              </>
            ),
          },
          {
            formIcon: <AtlasIcon svgHref="atlas-landed-building" />,
            formTitle: "Property Info",
            formContent: (
              <PropertyInfo
                data={data}
                autoSuggestLoading={autoSuggestLoading}
                disableSubmitButton={!can_update || is_main_info_locked}
                loading={false}
                onChange={onChangeUpdateSelectedClaim}
                formData={selected_claim}
                originalPropertyData={original_claim}
                autoSuggestOptions={autoSuggestOptions}
                townshipsList={townshipsList}
                getSelectedTownShip={getSelectedTownShip}
                edgeAutoSuggest={edgeAutoSuggest}
                onClickCloseForm={() => onChangeSubsaleClaimsHOC(false, "showUpdateClaimFormModal")}
              />
            ),
          },
          {
            formIcon: <AtlasIcon svgHref={"atlas-percentage-square"} />,
            formTitle: "Agency Fee",
            formContent: (
              <SectionAgencyFee
                can_update={can_update}
                selected_claim={selected_claim}
                setShowExternalAgent={setShowExternalAgent}
                onChangeUpdateSelectedClaim={onChangeUpdateSelectedClaim}
              />
            ),
          },
          {
            formIcon: <AtlasIcon svgHref={"svg-react-clipboard"} />,
            formTitle: "Tenancy Agreement & Stamping Fee",
            formShow: () => selected_claim.sub_sale_type_id === 2,
            formContent: (
              <SectionStampingFee
                can_update={can_update}
                selected_claim={selected_claim}
                onChangeUpdateSelectedClaim={onChangeUpdateSelectedClaim}
              />
            ),
          }
        ]}
        alertMassage={
          <>
            {can_update && unsavedChanges && (
              <h2 className="at-form-input__title text-danger">
                You have unsaved changes. Please click save button to save the
                changes.
              </h2>
            )}
            {!can_update && (
              <h2 className="at-form-input__title" style={{ color: "red" }}>
                You do not have the access right to edit this claim
              </h2>
            )}
          </>
        }
      />
      <div className={`at-floating_panel${menuExpand ? " menu-expand" : ""}`}>
        {can_update &&
          (selected_claim.claim_status === "Draft" ||
            selected_claim.claim_status === "Rejected") && (
            <button
              className="btn-new btn-new--success"
              onClick={() =>
                updateSelectedClaim(selected_claim.id, selected_claim, true)
              }
            >
              Save Information
            </button>
          )}
        <button
          className="btn-new btn-new--secondary at-btn_next subsaleclaim-claimform-navuploaddocs"
          onClick={() => onClickNav("Participants")}
        >
          <p>Participants</p>
        </button>
        <button
          className="btn-new btn-new--outline-secondary subsaleclaim-claimform-navuploaddocs"
          onClick={() => onCloseForm()}
        >
          Close
        </button>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({ data: state });
export default connect(mapStateToProps)(SubsaleClaimForm);
