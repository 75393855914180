export const occupationOptions = [
  { value: "Accounting", label: "Accounting" },
  {
    value: "Administration & Office Support",
    label: "Administration & Office Support",
  },
  { value: "Advertising, Arts & Media", label: "Advertising, Arts & Media" },
  {
    value: "Banking & Financial Services",
    label: "Banking & Financial Services",
  },
  {
    value: "Call Centre & Customer Service",
    label: "Call Centre & Customer Service",
  },
  {
    value: "Community Services & Development",
    label: "Community Services & Development",
  },
  { value: "Construction", label: "Construction" },
  { value: "Consulting & Strategy", label: "Consulting & Strategy" },
  { value: "Design & Architechture", label: "Design & Architechture" },
  { value: "Education & Training", label: "Education & Training" },
  { value: "Engineering", label: "Engineering" },
  {
    value: "Farming, Animals & Conservation",
    label: "Farming, Animals & Conservation",
  },
  { value: "Food and Beverage", label: "Food and Beverage" },
  { value: "Government & Defence", label: "Government & Defence" },
  { value: "Healthcare & Medical", label: "Healthcare & Medical" },
  { value: "Hospitality & Tourism", label: "Hospitality & Tourism" },
  {
    value: "Human Resources & Recruitment",
    label: "Human Resources & Recruitment",
  },
  {
    value: "Information & Communication Technology",
    label: "Information & Communication Technology",
  },
  { value: "Insurance & Superannuation", label: "Insurance & Superannuation" },
  { value: "Legal", label: "Legal" },
  {
    value: "Manufacturing, Transport & Logistics",
    label: "Manufacturing, Transport & Logistics",
  },
  { value: "Marketing & Communications", label: "Marketing & Communications" },
  { value: "Mining, Resources & Energy", label: "Mining, Resources & Energy" },
  { value: "Real Estate & Property", label: "Real Estate & Property" },
  { value: "Retail & Consumer Products", label: "Retail & Consumer Products" },
  { value: "Retired", label: "Retired" },
  { value: "Sales", label: "Sales" },
  { value: "Science & Technology", label: "Science & Technology" },
  { value: "Sport & Recreation", label: "Sport & Recreation" },
  { value: "Trades & Services", label: "Trades & Services" },
  { value: "Unemployed", label: "Unemployed" },
];

export const personalFieldData = [
  {
    value: "full_name",
    title: "Full Name",
    placeholder: "Enter Full Name",
    required: true,
    type: "text",
  },
  {
    value: "identification_number",
    title: "NRIC/Passport No.",
    placeholder: "Enter NRIC. e.g. 990101042222",
    required: true,
    type: "alphanumeric",
  },
  {
    value: "mobile_contact_number",
    title: "Mobile Number",
    placeholder: "e.g. 60123456789",
    onRequired: data => data?.party_id % 2 === 0,
    type: "number",
    instruction: "Phone number must include country code, for example, a Malaysian phone number will be like 6012345678"
  },
  { value: "email", title: "Email", placeholder: "Enter Email", type: "text", onRequired: data => data?.party_id % 2 === 0, },
  { value: "gender", title: "Gender", type: "radio", required: true },
  { value: "date_of_birth", title: "Date of Birth", placeholder: "e.g. 06-08-2019", type: "calendar", required: true },
  {
    value: "occupation",
    title: "Occupation",
    required: false,
    placeholder: "Enter Occupation",
    type: "autosuggest",
    options: occupationOptions,
  },
  {
    value: "nationality",
    title: "Nationality",
    required: true,
    placeholder: "Enter Nationality",
    type: "autosuggest",
  },
  {
    value: "current_address",
    title: "Current Residence Address",
    required: true,
    fullCol: true,
    placeholder: "Enter Current Residence Address",
    type: "textarea",
  },
  {
    value: "country",
    title: "Current Residence Country",
  },
  {
    value: "state",
    title: "Current Residence State",
  },
  {
    value: "town",
    title: "Current Residence City",
  },
  {
    value: "postcode",
    title: "Current Residence Postcode",
  }
];

export const companyFieldData = [
  {
    value: "company_name",
    title: `Company Name`,
    required: true,
    placeholder: "Enter Company Name",
    type: "text",
  },
  {
    value: "company_registration_no",
    title: `Company Reg. No. (12 Digits)`,
    required: true,
    placeholder: "e.g. 202005123456",
    type: "text",
  },
  {
    value: "job_title",
    title: "Representative's Designation",
    required: true,
    placeholder: "e.g. Director / Manager / PIC",
    type: "text",
  },
  {
    value: "full_name",
    required: true,
    title: "Representative's Name",
    placeholder: "Enter Representative's Name",
    type: "text",
  },
  {
    value: "identification_number",
    required: true,
    title: "Representative's NRIC/Passport No.",
    placeholder: "Enter NRIC. e.g. 990101042222",
    type: "alphanumeric",
  },
  {
    value: "mobile_contact_number",
    title: "Representative's Mobile Number",
    placeholder: "e.g. 60123456789",
    type: "number",
    instruction: "Phone number must include country code, for example, a Malaysian phone number will be like 6012345678",
    required: false
  },
  {
    value: "email",
    title: "Company/Representative's Email",
    placeholder: "Enter Company/Representative's Email",
    type: "text",
    required: false
  },
  {
    value: "current_address",
    required: true,
    fullCol: true,
    title: "Company Address",
    placeholder: "Enter Company Address",
    type: "textarea",
  },
  {
    value: "country",
    title: "Country",
  },
  {
    value: "state",
    title: "State",
  },
  {
    value: "town",
    title: "Area / City",
  },
  {
    value: "postcode",
    title: "Postcode",
  },
];

export const personalDDFieldData = [
  {
    value: "financing_method_id",
    title: "Method of financing property sale/rental",
    placeholder: "Enter Method of financing property sale/rental",
    className: "grid-half-col",
    type: "select",
    options: [
      { value: "", label: "Choose a method", disabled: true },
      { value: "1", label: "Cash" },
      { value: "2", label: "Loan" },
      { value: "3", label: "Cash & Loan" },
    ],
  },
  {
    value: "employer_name",
    title: "Name of Employer or if self-employed, Nature of Business",
    placeholder: "Enter Name of Employer or if self-employed, Nature of Business",
    className: "grid-half-col",
    type: "text",
  },
  {
    value: "salary_range_id",
    title: "Monthly Income",
    placeholder: "Enter Monthly Income",
    className: "grid-half-col",
    type: "select",
    options: [
      { value: "", label: "Select a range", disabled: true },
      { value: "8", label: "None" },
      { value: "1", label: "Below RM5000" },
      { value: "2", label: "RM5,001 - RM10,000" },
      { value: "3", label: "RM10,001 - RM20,000" },
      { value: "4", label: "RM20,001 - RM30,000" },
      { value: "5", label: "RM30,001 - RM40,000" },
      { value: "6", label: "RM40,001 - RM50,000" },
      { value: "7", label: "Above RM50,000" },
    ],
  },
  {
    value: "identity_copy_url",
    title: "IC / Passport",
    placeholder: "Enter NRIC. e.g. 990101042222",
    className: "grid-half-col",
    type: "document",
  },
  {
    value: "transaction_purpose_id",
    title: "Purpose of Transaction",
    placeholder: "Enter Purpose of Transaction",
    className: "grid-half-col",
    type: "select",
    options: [
      { value: "", label: "Choose one", disabled: true },
      { value: "1", label: "Own Stay/Use" },
      { value: "2", label: "Investment" },
    ],
  },
  {
    value: "declared_bankrupt",
    title: "Has the client ever been declared bankrupt in any country or jurisdiction?",
    placeholder: "",
    className: "grid-full-col",
    type: "radio",
    options: [
      { value: true, label: "Yes" },
      { value: false, label: "No" },
    ],
  },
];

export const companyDDFieldData = [
  {
    value: "financing_method_id",
    title: "Method of financing property sale/rental",
    placeholder: "Enter Method of financing property sale/rental",
    className: "grid-half-col",
    type: "select",
    options: [
      { value: "", label: "Choose a method", disabled: true },
      { value: "1", label: "Cash" },
      { value: "2", label: "Loan" },
      { value: "3", label: "Cash & Loan" },
    ],
  },
  {
    value: "nature_of_business",
    title: "Nature of Company",
    placeholder: "Enter Nature of Company",
    className: "grid-half-col",
    type: "select",
    options: [
      { value: "", label: "Select an occupation", disabled: true },
      { value: "Accounting", label: "Accounting" },
      {
        value: "Administration & Office Support",
        label: "Administration & Office Support",
      },
      {
        value: "Advertising, Arts & Media",
        label: "Advertising, Arts & Media",
      },
      {
        value: "Banking & Financial Services",
        label: "Banking & Financial Services",
      },
      {
        value: "Call Centre & Customer Service",
        label: "Call Centre & Customer Service",
      },
      {
        value: "Community Services & Development",
        label: "Community Services & Development",
      },
      { value: "Construction", label: "Construction" },
      { value: "Consulting & Strategy", label: "Consulting & Strategy" },
      { value: "Design & Architechture", label: "Design & Architechture" },
      { value: "Education & Training", label: "Education & Training" },
      { value: "Engineering", label: "Engineering" },
      {
        value: "Farming, Animals & Conservation",
        label: "Farming, Animals & Conservation",
      },
      { value: "Food and Beverage", label: "Food and Beverage" },
      { value: "Government & Defence", label: "Government & Defence" },
      { value: "Healthcare & Medical", label: "Healthcare & Medical" },
      { value: "Hospitality & Tourism", label: "Hospitality & Tourism" },
      {
        value: "Human Resources & Recruitment",
        label: "Human Resources & Recruitment",
      },
      {
        value: "Information & Communication Technology",
        label: "Information & Communication Technology",
      },
      {
        value: "Insurance & Superannuation",
        label: "Insurance & Superannuation",
      },
      { value: "Legal", label: "Legal" },
      {
        value: "Manufacturing, Transport & Logistics",
        label: "Manufacturing, Transport & Logistics",
      },
      {
        value: "Marketing & Communications",
        label: "Marketing & Communications",
      },
      {
        value: "Mining, Resources & Energy",
        label: "Mining, Resources & Energy",
      },
      { value: "Real Estate & Property", label: "Real Estate & Property" },
      {
        value: "Retail & Consumer Products",
        label: "Retail & Consumer Products",
      },
      { value: "Sales", label: "Sales" },
      { value: "Science & Technology", label: "Science & Technology" },
      { value: "Sport & Recreation", label: "Sport & Recreation" },
      { value: "Trades & Services", label: "Trades & Services" },
    ],
  },
  {
    value: "transaction_purpose_id",
    title: "Purpose of Transaction",
    placeholder: "Enter Purpose of Transaction",
    className: "grid-half-col",
    type: "select",
    options: [
      { value: "", label: "Choose one", disabled: true },
      { value: "1", label: "Own Stay/Use" },
      { value: "2", label: "Investment" },
    ],
  },
  {
    value: "declared_bankrupt",
    title: "Has the client ever been declared bankrupt in any country or jurisdiction?",
    placeholder: "",
    className: "grid-full-col",
    type: "radio",
    options: [
      { value: true, label: "Yes" },
      { value: false, label: "No" },
    ],
  },
];

export const personalDDFormData = {
  due_diligence_type_id: "",
  financing_method_id: "",
  signing_link: "",
  salary_range_id: "",
  employer_name: "",
  copies_info: {},
  transaction_purpose_id: "",
  declared_bankrupt: false,
};

export const companyDDFormData = {
  due_diligence_type_id: "",
  financing_method_id: "",
  nature_of_business: "",
  transaction_purpose_id: "",
  declared_bankrupt: false,
};

export const defaultFormData = {
  id: null,
  form_id: null,
  party_id: null,
  signee_type_id: "0",
  full_name: "",
  email: "",
  mobile_contact_number: "",
  identification_number: "",
  occupation: "",
  current_address: "",
  town: "",
  state: "",
  country: "",
  postcode: "",
};

export const unit_types = [
  {
    id: 1,
    name: "Corner",
    value: 1,
    label: "Corner",
  },
  {
    id: 2,
    name: "Duplex",
    value: 2,
    label: "Duplex",
  },
  {
    id: 3,
    name: "Endlot",
    value: 3,
    label: "Endlot",
  },
  {
    id: 4,
    name: "Intermediate",
    value: 4,
    label: "Intermediate",
  },
  {
    id: 5,
    name: "Penthouse",
    value: 5,
    label: "Penthouse",
  },
  {
    id: 6,
    name: "Studio",
    value: 6,
    label: "Studio",
  },
  {
    id: 7,
    name: "Triplex",
    value: 7,
    label: "Triplex",
  },
  {
    id: 8,
    name: "Unknown",
    value: 8,
    label: "Unknown",
  },
  {
    id: 9,
    name: "SOHO",
    value: 9,
    label: "SOHO",
  },
];

export const declineFieldData = [
  {
    value: "supporting_document_url",
    title: "Supporting Document",
    placeholder: "",
    helpText:
      "i.e. screenshot of WhatsApp/Email conversation where client declined to share information",
    type: "document",
  },
];