import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import { Card, Grid, Dialog } from "@material-ui/core";
import { IoMdAdd } from "react-icons/io";

import CustomCard from "components/Card";
import AlertBox from "components/AlertBox";
import AtlasDialog from "components/Dialog";
import CustomButton from "components/Button";
import ModuleHeader from "components/ModuleHeader";
import ModalDialog from "components/Modal/ModalDialog";
import AtPaginatedTable from "components/NewPaginatedTable";
import GenerateConfirmationForm from "./components/generateForm";
import ConfirmationModal from "components/Modal/confirmation";
import EditFormModalContent from "./components/editConfirmationFormModalContent";
import RequestConfirmationForm from "../ConfirmationRequest/components/requestConfirmationForm";
import LoadingModal from "components/LoadingModal";

import FormHOC from "../../actions";
import ConfirmationFormHOC from "./actions";
import ListingHOC from "./actions/listing.js";
import VersionCheckHOC from "actions/versionCheck";
import SubsalesStatusHOC from "actions/subsalesStatus";
import CreateConfirmationFormHOC from "./actions/create";
import InvoicingBranchesHOC from "actions/invoicingBranches";
import ConfirmationRequestHOC from "../ConfirmationRequest/actions";
import BranchesHOC from "../ConfirmationRequest/actions/branches.js";

import permissionsChecker from "utils/permissionsChecker";
import { getColorBadge } from "dictionary/badgeColor";
import { initGA } from "utils/ga";
import "./index.scss";

const ColumnData = [
  {
    header: "Form Number",
    columnStyle: { width: "80%" },
    accessor: "transaction_number",
  },
  {
    header: "Type",
    accessor: "type",
    columnStyle: { width: "60%" },
  },
  {
    header: "Confirmation Date",
    accessor: "confirmation_date",
    columnStyle: { width: "80%" },
  },
  {
    header: "Property Address",
    accessor: "property_address",
    columnStyle: { width: "180%" },
  },
  {
    header: "Status",
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    columnStyle: { width: "50%" },
    renderColumn: ({ claim_status }) => {
      let { colorName = "", value = "" } = _.find(legendsArray, {
        value: claim_status,
      });

      return (
        <div
          className="at-status_badge"
          style={{ ...getColorBadge(colorName) }}
        >
          {value}
        </div>
      );
    },
  },
];

const legendsArray = [
  {
    colorName: "Blue",
    colorCode: "#3498db",
    value: "Pending",
  },
  {
    colorName: "Yellow",
    colorCode: "#fad25a",
    value: "Submitted",
  },
  {
    colorName: "Grey",
    colorCode: "#d3d3d3",
    value: "Cancelled",
  },
];

class ConfirmationForm extends Component {
  componentDidMount = () => {
    this.props.getCommRate();
    this.props.getBranches();
    this.props.getInvoicingBranch();
    this.props.getAllDictionaryData();
    this.props.getInvoicingBranches();
    this.props.getConfirmationForms(1, "");

    const id = this.props.history?.location?.state?.id;

    if (id) {
      this.props.getConfirmationForm(id);
    }

    initGA("/dashboard/personal/forms/confirmation-form");
  };

  componentDidUpdate = (pp) => {
    const tmpProfileReducer = window.location.href.includes(
      "/admin-impersonate",
    )
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    if (
      this.props.showCreationForm !== pp.showCreationForm &&
      !this.props.showCreationForm
    ) {
      const temp = _.find(this.props.invoicing_branches, {
        branch_id: tmpProfileReducer.branch_id,
      });
      if (temp) {
        this.props.onChangeCreateConfirmationHOC(
          temp.id,
          "invoicing_branch_id",
        );
      } else {
        this.props.onChangeCreateConfirmationHOC(null, "invoicing_branch_id");
      }
      this.props.onChangeCreateConfirmationHOC(null, "type_id");
    }
  };

  selectSubsaleType = (params) => {
    this.props.onChangeCreateConfirmationHOC(
      params === "Sale" ? "1" : "2",
      "type_id",
    );
  };

  generateConfirmationForm = (dataToSubmit) => this.props.createConfirmationForm(dataToSubmit);

  renderEditConfirmationForm = () => {
    const { is_buyer_claimed, is_claimed, is_owner_claimed, e_process } = this.props.selected_confirmation_form;
    const { can_update } = permissionsChecker("Forms", this.props.data);

    const { status_id } = e_process ?? {}
    const eProcessDisabledChecking = !( e_process === null || (e_process !== null && status_id === 0))

    const tmpDisabled = !can_update || is_buyer_claimed || is_claimed || is_owner_claimed || eProcessDisabledChecking ;
    return (
      <AtlasDialog withFloatPanel={true} open={this.props.showConfirmationForm}>
        <CustomCard
          containerStyle={{ background: "#F3F4F6" }}
          className="h-100 at-form__mngmt-card-padding"
          cardContent={
            <EditFormModalContent
              {...this.props}
              closedEditing={tmpDisabled}
              downloadAckReceipt={() =>
                this.props.downloadAckReceipt(
                  this.props.selected_confirmation_form.id,
                  `${this.props.selected_confirmation_form.transaction_number}_Acknowledgement_Receipt.pdf`,
                )
              }
            />
          }
        />
      </AtlasDialog>
    );
  };

  renderCloneConfirmationModal = () => {
    const {
      cloneConfirmationForm,
      selectedCloneId,
      onChangeConfirmationHOC,
      showCloneConfirmationModal,
    } = this.props;
    return (
      <ConfirmationModal
        open={showCloneConfirmationModal}
        title={"Duplicate Form"}
        message={
          "Are you sure you want to clone this form data into a new form ?"
        }
        positiveAction={() => cloneConfirmationForm(selectedCloneId)}
        negativeAction={() =>
          onChangeConfirmationHOC(false, "showCloneConfirmationModal")
        }
        positiveLabel={"Duplicate"}
        negativeLabel={"Cancel"}
        loading={this.props.onLoadConfirmationForm}
      />
    );
  };

  renderGenerateForm = () => {
    return (
      <ModalDialog
        title={"New Confirmation Form"}
        children={
          <GenerateConfirmationForm
            data={this.props.data}
            disableSubmitButton={
              !this.props.invoicing_branch_id ||
              !this.props.type_id ||
              this.props.disableSubmitButton
            }
            type={this.props.type_id}
            loading={
              this.props.onLoadConfirmationForm ||
              this.props.onLoadCreateConfirmation
            }
            onChange={this.props.onChangeCreateConfirmationHOC}
            invoicingBranch={this.props.invoicingBranch}
            autoSuggestOptions={this.props.autoSuggestOptions}
            generateForm={this.generateConfirmationForm}
            edgeAutoSuggest={this.props.edgeAutoSuggest}
            onClickCloseFormGenerator={() =>
              this.props.onChangeConfirmationHOC(false, "showCreationForm")
            }
          />
        }
        onClose={() =>
          this.props.onChangeConfirmationHOC(false, "showCreationForm")
        }
      />
    );
  };

  renderAlertContent = () => {
    const { data, commRate, completed_trainings } = this.props;

    const { status_id } = window.location.href.includes("/admin-impersonate")
      ? data.currentSignInProfileReducer
      : data.profileReducer;
    if (
      (status_id !== 2 && !completed_trainings) ||
      (status_id !== 2 && !commRate) ||
      status_id === 2
    ) {
      return (
        <>
          {status_id !== 2 && !completed_trainings && (
            <AlertBox
              mode={"danger"}
              containerClassName={ ((status_id !== 2 && !commRate) || status_id === 2) ? 'mb-3' : '' }
              title={"Training not completed."}
              description={
                "You have not completed your training. You are required to finish training before accessing Appointment Letter, Confirmation Form, Cobroke Letter & Authorisation Forms To Secure Property. Please contact support if you have any questions."
              }
            />
          )}
          {status_id !== 2 && !commRate && (
            <AlertBox
              title={"No commision rate set."}
              containerClassName={ status_id === 2 ? 'mb-3' : '' }
              description={
                "You don’t have a secondary market commission rate set in Atlas. Please speak with your leader or request that they contact Helpdesk with your commission rate information."
              }
            />
          )}
          {status_id === 2 && (
            <AlertBox
              mode={"danger"}
              description={
                "Your account is inactive and temporarily unable to access the module. Please contact support if you have any questions."
              }
            />
          )}
        </>
      );
    }
    return "";
  };

  render = () => {
    const { role, status_id, confirmation_forms_balance } =
      window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

    const tmpPath =
      window.location.href.indexOf("/admin-impersonate") > -1
        ? this.props.data.adminSignInReducer.prefix_path
        : "";
    const { can_create } = permissionsChecker("Forms", this.props.data);
    const status_permissions =
      (status_id !== 2 && this.props.completed_trainings) ||
      this.props.whitelisted;
  
    return (
      <>
        <ModuleHeader
          title={"Confirmation Forms"}
          atlasIcon={"atlas-confirmation-form"}
          showPABadge={role === "Personal Assistant"}
          backButton={{
            onClick: () =>
              this.props.history.push(
                `${tmpPath}/dashboard/personal/forms/menu`,
              ),
          }}
          actionButton={[
            {
              label: "New Form",
              onShow: can_create && status_permissions,
              className: "btn-new btn-new--primary",
              icon: <IoMdAdd style={{ height: 18, width: 18 }} />,
              onClick: () =>
                this.props.onChangeConfirmationHOC(true, "showCreationForm"),
            },
          ]}
          renderAlertContent={this.renderAlertContent()}
        />
        <CustomCard
          className={`h-100`}
          containerStyle={{ background: "#F3F4F6", borderRadius: 0 }}
          cardContent={
            <>
              <Card
                className={"at-form_mngmt__request-card"}
                style={{
                  padding: "15px 10px 10px 15px",
                  maxWidth: 400,
                  alignItems: "flex-end",
                }}
              >
                <Grid container spaing={16}>
                  <Grid item md={4} xs={12} className={"mb-10"}>
                    <p style={{ fontSize: 14, fontWeight: 500 }}>Balances</p>
                    <p style={{ fontSize: 32, fontWeight: 600 }}>
                      {confirmation_forms_balance}
                    </p>
                  </Grid>
                  <Grid item md={8} xs={12} className={"d-flex g-3"}>
                    <CustomButton
                      containerStyle={{ marginTop: "auto", marginLeft: "auto" }}
                      className="btn-new"
                      style={{ color: "#2563EB" }}
                      onClick={() =>
                        this.props.history.push(
                          `${tmpPath}/dashboard/personal/forms/confirmation-form-request`,
                        )
                      }
                    >
                      {"History"}
                    </CustomButton>
                    <CustomButton
                      containerStyle={{ marginTop: "auto" }}
                      disabled={!status_permissions}
                      className="btn-new btn-new--secondary"
                      onClick={() =>
                        this.props.onChangeHOC(true, "showCreateRequestForm")
                      }
                    >
                      {"Request More"}
                    </CustomButton>
                  </Grid>
                </Grid>
              </Card>
              <AtPaginatedTable
                columns={ColumnData}
                headerClass={"at-form_mngmt__table-header"}
                actionColumnContent={
                  status_permissions
                    ? [
                        {
                          name: "edit",
                          onClick: (rowData) =>
                            this.props.getConfirmationForm(rowData.id),
                        },
                        {
                          name: "duplicate",
                          onClick: (rowData) =>
                            Promise.all([
                              this.props.onChangeConfirmationHOC(
                                rowData.id,
                                "selectedCloneId",
                              ),
                            ]).then(() => {
                              this.props.onChangeConfirmationHOC(
                                true,
                                "showCloneConfirmationModal",
                              );
                            }),
                        },
                      ]
                    : []
                }
                rowData={
                  this.props.confirmation_forms.data
                    ? this.props.confirmation_forms.data
                    : []
                }
                meta={
                  this.props.confirmation_forms.meta &&
                  this.props.confirmation_forms.meta
                }
                onChangeSearchParams={(val) =>
                  this.props.onChangeConfirmationHOC(val, "searchParams")
                }
                searchParams={this.props.searchParams}
                getListAPI={this.props.getConfirmationForms}
                totalPages={this.props.confirmationFormPage}
              />
            </>
          }
        />

        {this.props.showCreationForm && this.renderGenerateForm()}
        {this.renderEditConfirmationForm()}
        {this.renderCloneConfirmationModal()}
        {this.props.showCreateRequestForm && (
          <Dialog open={true} maxWidth={"lg"} classes={{ paper: "m-0" }}>
            <RequestConfirmationForm
              branches={this.props.branches}
              onChange={this.props.onChangeHOC}
              branch_id={this.props.branch_id}
              reason={this.props.reason}
              quantity={this.props.quantity}
              closeFormDialog={() =>
                this.props.onChangeHOC(false, "showCreateRequestForm")
              }
              postConfirmationRequest={this.props.postConfirmationRequest}
            />
          </Dialog>
        )}
        {(this.props.onLoadConfirmationForm ||
          this.props.onLoadCreateConfirmation ||
          this.props.onLoadForms ||
          this.props.onLoadConfirmationFormRequest) && <LoadingModal />}
      </>
    );
  };
}

export default compose(
  BranchesHOC,
  SubsalesStatusHOC,
  ConfirmationFormHOC,
  FormHOC,
  InvoicingBranchesHOC,
  ConfirmationRequestHOC,
  CreateConfirmationFormHOC,
  ListingHOC,
  VersionCheckHOC,
)(ConfirmationForm);
