import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { requestError, requestSuccess } from "utils/requestHandler";

import { Post } from "utils/axios";

const HOC = (WrappedComponent) => {
  class CreateClaimHOC extends Component {
    state = {
      disableCreateButton: false,
      createClaim: {
        form_type_id: "1",
        confirmation_form_number: "",
        confirmation_form_id: "",
        agency_id: null,
        sub_sale_type_id: null,
        form_method_id: "1",
      },
      loading: false,
      showSubmitConfirmation: false
    };

    load = (param) => this.setState({ loading: param });

    onChangeCreateClaimHOC = (key, val) => {
      if (key === "createClaim") {
        if (val["form_type_id"] !== this.state.createClaim.form_type_id) {
          let tmp = {
            confirmation_form_number: "",
            confirmation_form_id: "",
            ...val,
            form_type_id: val["form_type_id"],
          };
          val = tmp;
        }
      }
      this.setState({ [key]: val });
    };

    postClaim = (dataToSubmit) => {
      let temp = _.cloneDeep(dataToSubmit);
      if (["1", "2"].indexOf(temp.form_type_id) > -1) {
        delete temp.form_method_id;
        delete temp.agency_id;
        delete temp.sub_sale_type_id;
      }
      if (temp.form_method_id === "1" || temp.form_type_id === "4") {
        delete temp.agency_id;
        delete temp.sub_sale_type_id;
      }

      if (temp.form_method_id === "2") {
        delete temp.confirmation_form_id;
        delete temp.confirmation_form_number;
      }

      if(temp.form_type_id !== "3") {
        delete temp.representative_id;
      }

      Post(
        `/sub_sale_claims`,
        temp,
        this.postClaimSuccess,
        this.postClaimError,
        this.load,
      );
    };
    postClaimSuccess = (payload) => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      requestSuccess("Subsale Claim is created successfully.");
      this.setState(
        {
          disableCreateButton: false,
        },
        () => {
          this.props.onClose();
          this.props.getClaimsList(currentPage, searchParams);
          this.props.getSelectedClaim(payload.id);
        },
      );
    };
    postClaimError = (error) => {
      this.setState({ disableCreateButton: false });
      requestError(error);
    };

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            showSubmitConfirmation={this.state.showSubmitConfirmation}
            disableCreateButton={this.state.disableCreateButton}
            onLoadCreateClaim={this.state.loading}
            createClaim={this.state.createClaim}
            onChangeCreateClaimHOC={this.onChangeCreateClaimHOC}
            postClaim={this.postClaim}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(CreateClaimHOC);
};

export default HOC;
