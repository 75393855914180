import React, { useState, useEffect, useCallback } from "react";
import _, { update } from "lodash";
import { PulseLoader } from "react-spinners";
import Moment from "moment";

import ModalDialog from "components/Modal/ModalDialog";
import AtlasCloseButton from "components/Button/prev";
import LoadingModal from "components/LoadingModal";
import AtlasRadioGroup from "components/RadioGroup";
import CustomFormInput from "components/Input/formInput";
import CustomTextarea from "components/Input/textarea";
import CustomTypeahead from "components/Typeahead/new";
import FormChart from "components/Chart/form";
import ConfirmationModal from "components/Modal/confirmation";
import AtlasIcon from "components/Icon/atlasIcon";
import DatePicker from "components/Input/datetimepicker";

import AgentSearchHOC from "actions/agentSearch";
import isEmptyValue from "utils/isEmpty";

import { Status, Type } from "./options";

const RepresentativeInfo = type_id => [
  {
    id: 1,
    label: `Represent ${ type_id === "1" ? 'Landlord' : 'Vendor' }`,
    value: 1,
  },
  {
    id: 2,
    label: `Represent ${ type_id === "1" ? 'Tenant' : 'Purchaser' }`,
    value: 2,
  },
];

const AgentRepresentOptions = {
  "1": [
    { value: "3", label: "Both" },
    { value: "2", label: "Tenant", cobroke_represent: "Landlord" },
    { value: "1", label: "Landlord", cobroke_represent: "Tenant" },
  ],
  "2": [
    { value: "3", label: "Both" },
    { value: "2", label: "Purchaser", cobroke_represent: "Vendor" },
    { value: "1", label: "Vendor", cobroke_represent: "Purchaser" },
  ],
}

const Update = ({
  history,
  data,
  showSelectForm,
  showSubmitConfirmation,
  showDetachConfirmation,
  agentList,
  permissionsChecker,
  selectedPaperForm,
  onLoadPaperForms,
  onLoadAgent,
  onLoadForms,

  onChangeAgentHOC,
  onChangePaperFormHOC,
  updatePaperForm,
  detachForm,
  getAgentsBySearch,
  submitForm,
  onClose,
}) => {
  const [toSubmitData, onChangeSubmitData] = useState({});

  const [updateForm, onChangeUpdateForm] = useState({
    user: null,
    region_id: "",
    tracking_number: "",
    type_id: "",
    status_id: "1",
    remark: "",
    date_taken: "",
    form_type_id: "1",
    agency_id: "",
    forms_chart: [],
    representative_id: "1",
    co_broker: null
  });
  const { can_update } = permissionsChecker;

  const onChangeUpdateField = (key, val) => {
    let tmp = _.cloneDeep(updateForm);
    tmp[key] = val;

    if (key === "representative_id" && val === "3") {
      tmp.co_broker = null;
    }
    
    onChangeUpdateForm(tmp);
  };

  useEffect(() => {
    onChangeUpdateForm({
      ...selectedPaperForm,
      representative_id: `${selectedPaperForm.representative_id}`,
    });
  }, []);

  const onChangeTypeaheadSearch = useCallback(
    _.debounce((val) => {
      val.length > 0
        ? getAgentsBySearch(val)
        : onChangeAgentHOC([], "agentList");
    }, 600),
    [],
  );

  const renderSubmitFormModal = () => {
    if (showSelectForm) {
      return (
        <ModalDialog
          title={"Submit Physical Form"}
          fullHeight={false}
          onClose={() => onChangePaperFormHOC("showSelectForm", false)}
          children={
            <>
              <section className="grid-full-col">
                <h2 className="at-form-input__title">{"Representative"}</h2>
                <AtlasRadioGroup
                  horizontal={true}
                  checkedValue={toSubmitData.representative_id}
                  containerClassName={"w-100"}
                  selectedRadioValue={(val) => {
                    onChangeSubmitData({
                      ...toSubmitData,
                      representative_id: val,
                    });
                  }}
                  options={RepresentativeInfo(selectedPaperForm.type_id)}
                />
              </section>
              <button
                disabled={!toSubmitData.representative_id}
                className="btn-new btn-new--primary mt-3"
                onClick={() => {
                  onChangePaperFormHOC("showSubmitConfirmation", true);
                }}
              >
                Submit
              </button>
            </>
          }
        />
      );
    }
  };

  const { associatedForms = [], associatedTransNumber = [] } = toSubmitData;

  const { id, user_id } = selectedPaperForm;

  return (
    <>
      {updateForm.forms_chart !== null && (
        <div className="col-12">
          <FormChart
            history={history}
            infoData={{id: id}}
            currentFormType={"Physical Form"}
            forms_chart={updateForm.forms_chart}
            onClickButton={(data) => {
              if (updateForm.forms_chart[0].form_no === null) {
                onChangePaperFormHOC("showSelectForm", true);
              } else {
                onChangeSubmitData({
                  ...toSubmitData,
                  representative_id: updateForm.forms_chart[1][0].form_no.includes("Physical Form")
                    ? 2
                    : 1
                });
                onChangePaperFormHOC("showSubmitConfirmation", true);
              }
              onChangeSubmitData({
                ...data,
                user_id: user_id,
              });
            }}
            onClickDetach={(data) => {
              onChangeSubmitData(data);
              onChangePaperFormHOC("showDetachConfirmation", true);
            }}
          />
        </div>
      )}

      <div className="col-12 col-md-4">
        <div className="at-form__content grid-control">
          <section className="grid-full-col">
            <h2 className="at-form-input__title">{"Form Type"}</h2>
            <p>{updateForm.form_type}</p>
          </section>
          <section className="grid-full-col">
            <h2 className="at-form-input__title">{"Type"}</h2>
            <AtlasRadioGroup
              valueKey={"id"}
              horizontal={true}
              disabled={!can_update || !updateForm.editable_columns_status?.type_id}
              checkedValue={updateForm.type_id}
              containerClassName={"w-100"}
              selectedRadioValue={(val) => onChangeUpdateField("type_id", val)}
              options={Type}
            />
          </section>
          <section className="grid-full-col">
            <h2 className="at-form-input__title">{"Form Number"}</h2>
            <CustomFormInput
              type={"text"}
              disabled={true}
              value={updateForm.tracking_number}
              onChangeValue={(val) => onChangeUpdateField("tracking_number", val)}
            />
          </section>

          <section className="grid-full-col">
            <h2 className="at-form-input__title">{"Date Taken"}</h2>
            <p>{updateForm.date_taken}</p>
          </section>
          
          <section className="grid-full-col">
            <h2 className="at-form-input__title">{"Status"}</h2>
            <AtlasRadioGroup
              valueKey={"id"}
              horizontal={true}
              disabled={!can_update}
              checkedValue={updateForm.status_id}
              containerClassName={"w-100"}
              selectedRadioValue={(val) => onChangeUpdateField("status_id", val)}
              options={Status}
            />
          </section>
        </div>
      </div>
      
      <div className="col-12 col-md-8">
        <div className="at-form__content grid-control">
          <section className="grid-full-col">
            <div className="d-flex">
              <h2 className="at-form-input__title">Select an Agent</h2>
              <PulseLoader
                sizeunit={"px"}
                size={10}
                color={"#16232c"}
                loading={onLoadAgent}
              />
            </div>
            <CustomTypeahead
              disabled={onLoadAgent || !can_update || !updateForm.editable_columns_status?.user_id}
              typeaheadId={"internal_agent"}
              options={agentList || []}
              selectedValue={updateForm.user ? [updateForm.user] : []}
              filterBy={["full_name", "email", "mobile_contact_number"]}
              labelKey={"full_name"}
              onSearch={(val) => {
                onChangeUpdateField("user", null);
                onChangeTypeaheadSearch(val);
              }}
              onSelect={(val) =>
                val && val.length > 0 && onChangeUpdateField("user", val[0])
              }
              helpText={"e.g. Full Name, Email, Mobile Number"}
              childrenHead={(rowItem) => <p>{rowItem.full_name}</p>}
              highlighterData={[
                (option) => `Team: ${option.team_name}`,
                <br />,
                (option) =>
                  `Email: ${option.email} | Contact: ${option.mobile_contact_number}`,
              ]}
            />
          </section>

          <section className="grid-full-col">
            <h2 className="at-form-input__title">{"Agent Representing"}</h2>
            <AtlasRadioGroup
              horizontal={true}
              checkedValue={updateForm.representative_id}
              containerClassName={"w-100"}
              disabled={!can_update || !updateForm.editable_columns_status?.representative_id}
              selectedRadioValue={(val) => onChangeUpdateField("representative_id", val)}
              options={AgentRepresentOptions[updateForm.type_id || "1"]}
            />
          </section>
          
          
          {updateForm.representative_id !== "3" && (
            <>
              <section className="grid-full-col">
                <div className="d-flex">
                  <h2 className="at-form-input__title">Select Co-broke Agent For Separate Claim Submission</h2>
                  <PulseLoader
                    sizeunit={"px"}
                    size={10}
                    color={"#16232c"}
                    loading={onLoadAgent}
                  />
                </div>
                <CustomTypeahead
                  disabled={onLoadAgent || !can_update || !updateForm.editable_columns_status?.co_broker_id}
                  typeaheadId={"internal_agent"}
                  options={agentList || []}
                  selectedValue={updateForm.co_broker ? [updateForm.co_broker] : []}
                  filterBy={["full_name", "email", "mobile_contact_number"]}
                  labelKey={"full_name"}
                  onSearch={(val) => {
                    onChangeUpdateField("co_broker", null);
                    onChangeTypeaheadSearch(val);
                  }}
                  onSelect={(val) =>
                    val && val.length > 0 && onChangeUpdateField("co_broker", val[0])
                  }
                  helpText={"e.g. Full Name, Email, Mobile Number"}
                  childrenHead={(rowItem) => <p>{rowItem.full_name}</p>}
                  highlighterData={[
                    (option) => `Team: ${option.team_name}`,
                    <br />,
                    (option) =>
                      `Email: ${option.email} | Contact: ${option.mobile_contact_number}`,
                  ]}
                />
              </section>
              <section className="grid-full-col">
                <h2 className="at-form-input__title">{"Co-broke Agent Representing"}</h2>
                <p>{_.find(AgentRepresentOptions[updateForm.type_id], {value: updateForm.representative_id})?.cobroke_represent}</p>
              </section>
            </>
          )}
          <section className="grid-full-col">
            <h2 className="at-form-input__title">{"Remark"}</h2>
            <CustomTextarea
              rows={5}
              value={updateForm.remark}
              disabled={!can_update}
              onChangeValue={(val) => onChangeUpdateField("remark", val)}
              placeholder={"Remark"}
            />
          </section>
        </div>
      </div>
      <div className="at-modal_dialog-container-footer">
        {can_update && (
          <button
            className={"btn-new btn-new--success"}
            type={"button"}
            onClick={() => updatePaperForm({
              ...updateForm,
              user_id: updateForm.user ? updateForm.user.id : null,
              co_broker_id: updateForm.co_broker ? updateForm.co_broker.id : null,
            })} >
            Update
          </button>
        )}
        <button
          type={"button"}
          className="btn-new btn-new--outline-secondary ml-2"
          onClick={() => onClose()} >
          Close
        </button>
      </div>

      {renderSubmitFormModal()}
      <ConfirmationModal
        mode={"info"}
        open={showSubmitConfirmation}
        title={"Closing Deal"}
        message={
          "Are you sure you want to submit Physical Form to Claim Form? This will mark this form as submitted and no longer be edited."
        }
        loading={onLoadPaperForms || onLoadForms}
        positiveText={"Submit"}
        icon={<AtlasIcon svgHref={"svg-document-home"} />}
        positiveAction={() =>
          submitForm(toSubmitData.id, "PhysicalForm", {
            representative_id: toSubmitData.representative_id,
          })
        }
        negativeText={"Cancel"}
        negativeAction={() => onChangePaperFormHOC("showSubmitConfirmation", false)}
      />
      <ConfirmationModal
        mode={"info"}
        open={showDetachConfirmation}
        title={`Detach ${associatedForms[1]}`}
        message={`Are you sure you want to detach ${associatedTransNumber[1]} from ${associatedTransNumber[0]}?`}
        loading={onLoadPaperForms || onLoadForms}
        positiveText={"Detach"}
        icon={<AtlasIcon svgHref={"svg-document-home"} />}
        positiveAction={() => {
          let tmpData = _.cloneDeep(toSubmitData);
          tmpData.type_id = selectedPaperForm.type_id;
          detachForm(tmpData);
        }}
        negativeText={"Cancel"}
        negativeAction={() => onChangePaperFormHOC("showDetachConfirmation", false)}
      />
      {onLoadPaperForms && <LoadingModal />}
    </>
  );
};

export default AgentSearchHOC(Update);
