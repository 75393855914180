import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Moment from "moment";

import { requestError, requestSuccess } from "utils/requestHandler";

import { Get, Post, Put, Delete } from "utils/axios";
import { document_types } from "dictionary/subsales";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      documents: [],
      onLoadOCR: false,
      selectedSubsaleClaimID: 0,
      showLoadingModal: false,
      OCRContent: {},

      callbackDocUploadSuccess: () => {},
    };

    load = (param) => this.setState({ showLoadingModal: param });

    getDocuments = (id) =>
      Get(
        `/admin/sub_sale_claims/${id}/documents`,
        this.getDocumentsSuccess,
        this.getDocumentsError,
        this.load,
      );
    getDocumentsSuccess = (payload) => {
      let tmp = [];
      payload.map((item) => {
        let doc = _.find(document_types, { id: item.type_id });
        let data = {
          ...item,
          doc_type: doc ? doc.name : "",
          created_at: item.created_at
            ? Moment(item.created_at).format("DD MMM YYYY HH:mm")
            : "N/A",
        };
        tmp.push(data);
      });
      return this.setState({ documents: tmp });
    };
    getDocumentsError = (error) => requestError(error);

    uploadDocument = (id, dataToSubmit) =>
      Post(
        `/admin/sub_sale_claims/${id}/documents`,
        dataToSubmit,
        this.uploadDocumentSuccess,
        this.uploadDocumentError,
        this.load,
      );
    uploadDocumentSuccess = (payload) => {
      this.getDocuments(payload.sub_sale_claim_id);
      requestSuccess("Document uploaded successfully!");
    };
    uploadDocumentError = (error) => requestError(error);

    bankInSlipOCR = (dataToSubmit, onSuccess) =>
      Post(
        `/ocr/bank_slip`,
        dataToSubmit,
        (payload) => this.bankInSlipOCRSuccess(payload, onSuccess),
        this.bankInSlipOCRError,
        (param) => this.setState({ onLoadOCR: param }),
      );
    bankInSlipOCRSuccess = (payload, onSuccess) => {
      let temp = _.cloneDeep(payload.data);
      if (temp.payment_method.length > 0) {
        temp.remark = temp.payment_method.concat(temp.reference);
      } else {
        temp.remark = temp.reference;
      }
      this.setState({ OCRContent: temp }, () => onSuccess());
    };
    bankInSlipOCRError = (error) => requestError(error);

    updateBankInSlip = (sub_sale_claim_id, document_id, dataToSubmit) =>
      Put(
        `/admin/sub_sale_claims/${sub_sale_claim_id}/documents/${document_id}`,
        {
          bank_id: dataToSubmit.bank_id,
          payment_method_id: dataToSubmit.payment_method_id,
          payer_type_id: dataToSubmit.payer_type_id,
          payer_type_other: dataToSubmit.payer_type_other,
          reference_no: dataToSubmit.reference_no,
          transacted_at: Moment(
            dataToSubmit.date + " " + dataToSubmit.time,
            "DD-MM-YYYY HH:mm",
          ).toString(),
          amount: dataToSubmit.amount,
          ...(dataToSubmit.snapshot && {
            snapshot: dataToSubmit.snapshot,
            snapshot_file_name: dataToSubmit.snapshot_file_name,
          }),
        },
        () => this.updateBankInSlipSuccss(sub_sale_claim_id),
        this.updateBankInSlipError,
        this.load,
      );
    updateBankInSlipSuccss = (sub_sale_claim_id) => {
      requestSuccess("Bank in slip updated.");
      this.getDocuments(sub_sale_claim_id);
    };
    updateBankInSlipError = (error) => requestError(error);

    deleteDocument = (id, doc_id) => {
      Delete(
        `/admin/sub_sale_claims/${id}/documents/${doc_id}`,
        this.deleteDocumentSuccess,
        this.deleteDocumentError,
        this.load,
      );
      return this.setState({ selectedSubsaleClaimID: id });
    };
    deleteDocumentSuccess = (payload) =>
      this.getDocuments(this.state.selectedSubsaleClaimID);
    deleteDocumentError = (error) => requestError(error);

    combineDocuments = (claim_id, res) => {
      this.setState({ callbackDocUploadSuccess: res });
      Post(
        `/admin/sub_sale_claims/${claim_id}/combine_documents`,
        {},
        this.combineDocumentsSuccess,
        this.combineDocumentsError,
        this.load,
      );
    };
    combineDocumentsSuccess = (payload) => {
      this.state.callbackDocUploadSuccess();
      requestSuccess("Documents combined successfully!");
    };
    combineDocumentsError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadOCR={this.state.onLoadOCR}
            documents={this.state.documents}
            OCRContent={this.state.OCRContent}
            getDocuments={this.getDocuments}
            bankInSlipOCR={this.bankInSlipOCR}
            uploadDocument={this.uploadDocument}
            updateBankInSlip={this.updateBankInSlip}
            deleteDocument={this.deleteDocument}
            combineDocuments={this.combineDocuments}
            onLoadDocuments={this.state.showLoadingModal}
            onClickStatusModalButton={this.state.onClickStatusModalButton}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
