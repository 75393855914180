import React, { Component } from "react";

class TableHeader extends Component {
  render() {
    const { mode, data, actionColumn, maxActionColumnLength } = this.props;
    const tmpMinWidth = maxActionColumnLength * 55 >= 120
      ? maxActionColumnLength * 55
      : 120

    return (
      <ul className={`at-table-row at-table-head`}>
        {data.map((item, index) => {
          return (
            <li
              style={{
                cursor: "auto",
                width: item.width ? item.width : "100%",
                ...item.containerStyle,
              }}
              key={item.label}
              className={`at-table-row__item at-table__${index}`}
            >
              <span className="at-table-head__title">{item.label}</span>
            </li>
          );
        })}
        {actionColumn ? (
          <li
            style={{
              cursor: "auto",
              width: `${tmpMinWidth}px`,
              minWidth: `${tmpMinWidth}px`,
            }}
            className="at-table-row__item at-table__actions"
          >
            <span className="at-table-head__title">Actions</span>
          </li>
        ) : (
          ""
        )}
      </ul>
    );
  }
}

export default TableHeader;
