import React, { Component } from "react";
import { FaUserTie } from "react-icons/fa";
import { Tooltip } from "@material-ui/core";
import { MoonLoader } from "react-spinners";
import { FaShareAltSquare, FaCheckSquare } from "react-icons/fa";
import { AiOutlineExport } from "react-icons/ai";
import { BsFillImageFill, BsInfoCircleFill } from "react-icons/bs";
import { GiDominoMask } from "react-icons/gi";
import { IoMdDocument, IoMdCheckmarkCircleOutline } from "react-icons/io";

import SVGIcon from "components/Icon";
import AtlasIcon from "components/Icon/atlasIcon";
import CustomButton from "components/Button";

import IQILogo from "assets/images/iqi_logo_only.png";

const processIconType = (param) => {
  switch (param) {
    case "details":
      return (
        <BsInfoCircleFill style={{ width: 20, height: 20, color: "#007BFF" }} />
      );
    case "duplicate":
      return (
        <AtlasIcon
          svgHref={"svg-iconsax-document-copy"}
          style={{ width: 20, height: 20, color: "#007BFF" }}
        />
      );
    case "edit":
      return (
        <AtlasIcon
          svgHref={"svg-react-icons-edit-image"}
          style={{ width: 20, height: 20, fill: "#007BFF", color: "#007BFF" }}
        />
      );
    case "authorise":
    case "authorised":
      return (
        <AtlasIcon
          svgHref={"atlas-security-user"}
          style={{ width: 20, height: 20, fill: "transparent", color: "transparent" }}
        />
      );
    case "unauthorise":
      return (
        <AtlasIcon
          svgHref={"atlas-shield-slash"}
          style={{ width: 20, height: 20, fill: "transparent", color: "transparent" }}
        />
      );
    case "download":
      return (
        <SVGIcon className="svg-icon-info" svgHref={"icon-download-doc"} />
      );
    case "release":
      return <SVGIcon className="svg-icon-info" svgHref={"icon-unlock"} />;
    case "reserve":
      return (
        <IoMdDocument style={{ width: 20, height: 20, color: "#007BFF" }} />
      );
    case "approve":
      return (
        <FaCheckSquare style={{ width: 20, height: 20, color: "#007BFF" }} />
      );
    case "delete":
      return (
        <AtlasIcon
          svgHref="svg-react-icons-trash"
          style={{ width: 20, height: 20, color: "#F04438" }}
        />
      );
    case "share":
      return (
        <FaShareAltSquare style={{ width: 20, height: 20, color: "#007BFF" }} />
      );
    case "preview":
      return (
        <BsFillImageFill style={{ width: 20, height: 20, color: "#007BFF" }} />
      );
    case "select":
      return (
        <IoMdCheckmarkCircleOutline
          style={{ width: 20, height: 20, color: "#007BFF" }}
        />
      );
    case "check":
      return (
        <MoonLoader
          sizeunit={"px"}
          size={30}
          color={"#16232C"}
          loading={true}
        />
      );
    case "export to ERP":
      return (
        <AiOutlineExport style={{ width: 20, height: 20, color: "#007BFF" }} />
      );
    case "impersonate":
      return (
        <GiDominoMask style={{ width: 20, height: 20, color: "#007BFF" }} />
      );
    default:
      return (
        <BsInfoCircleFill style={{ width: 20, height: 20, color: "#007BFF" }} />
      );
  }
};

class Content extends Component {
  renderActionContent = (content) => {
    const { rowKey, actionColumnContent } = this.props;
    return actionColumnContent?.map((action, index) => {
      if (
        (action.onShow &&
          typeof action.onShow === "function" &&
          action.onShow(content)) ||
        !action.onShow
      ) {
        return (
          <CustomButton
            disabled={ typeof action?.disabled === "function"
              ? action?.disabled(content)
              : action?.disabled
            }
            key={index}
            className={"at-table-icon-button"}
            children={processIconType(action.name)}
            tooltip={true}
            tooltipChildren={
              <span className="text-capitalize">{action.name}</span>
            }
            tooltipPosition={"top"}
            tooltipID={`action-${content[rowKey]}-${action.name}`}
            onClick={() => action.onClick(content)}
          />
        );
      }
      return ""
    });
  };

  renderMobileAction = (content) => {
    const { rowKey, actionColumnContent } = this.props;

    return actionColumnContent?.map((action, index) => {
      if (
        (action.onShow &&
          typeof action.onShow === "function" &&
          action.onShow(content)) ||
        !action.onShow
      ) {
        return (
          <CustomButton
            containerClass={"d-flex justify-content-center w-100"}
            key={index}
            disabled={typeof action?.disabled === "function"
              ? action?.disabled(content)
              : action?.disabled}
            style={{ color: "#007BFF" }}
            children={
              <>
                {processIconType(action.name)}
                <span className="text-capitalize ml-2">{action.name}</span>
              </>
            }
            tooltip={true}
            tooltipChildren={
              <span className="text-capitalize">{action.name}</span>
            }
            tooltipPosition={"top"}
            tooltipID={`${content[rowKey]}-${action.name}`}
            onClick={() => action.onClick(content)}
          />
        );
      }
      return ""
    });
  };

  renderTableContentGrid(
    index,
    columnValue,
    content,
    columnLabel,
    columnWidth,
    styling,
  ) {
    const { rowItem, maxActionColumnLength } = this.props;
    const tmpMinWidth = maxActionColumnLength * 55 >= 120
      ? maxActionColumnLength * 55
      : 120

    const renderBadgeIcon = () => {
      const { party_id, signee_type_id, contactable_type_id } = rowItem;
      let tempBadgeName = "";
      let tempIcon = null;

      if (contactable_type_id === 2) {
        tempIcon = <img src={IQILogo} alt="" />;
        tempBadgeName = "Internal Agent";
      } else if (contactable_type_id === 1 && party_id % 2 === 0) {
        tempBadgeName = "External Agent";
        tempIcon = <FaUserTie />;
      } else if (contactable_type_id === 1 && party_id % 2 > 0) {
        if (signee_type_id === 1) {
          tempBadgeName = "Company";
          tempIcon = <AtlasIcon svgHref={"atlas-building-company"}/>;
        } else {
          tempIcon = <AtlasIcon svgHref={"atlas-icon-user"}/>;
          tempBadgeName = "Personal";
        }
      }

      return (
        <Tooltip placement={"top"} title={tempBadgeName}>
          <div className="party-card__badge-title-icon at-subsales-contact_form_type-title-icon">{tempIcon}</div>
        </Tooltip>
      );
    };

    const contentProcess = (param) => {
      if (typeof param === "object") {
        return param?.map((item) => <p>{item}</p>);
      }
      return param;
    };
    const renderHighlightColumn = (param) => {
      if (param) {
        if (param?.columnValue === columnValue) {
          return "grid-top-object";
        }
      }
      return "";
    };
    return (
      <>
        {index !== "actions" && (
          <li
            key={index}
            className={`at-table-row__item at-table__${index} ${renderHighlightColumn(this.props.headerRearrangeData)}`}
            style={{ width: columnWidth || "100%", ...styling }}
          >
            <span className="at-table-row__item-label">{columnLabel}</span>
            <div className={"d-flex align-items-center"}>
              {columnValue === "participants_name" && renderBadgeIcon()}
              <div>{contentProcess(content)}</div>
            </div>
          </li>
        )}
        {index === "actions" && (
          <li
            key={index}
            className={`at-table-row__item at-table__${index} ${renderHighlightColumn(this.props.headerRearrangeData)}`}
            style={{
              width: `${tmpMinWidth}px`,
              minWidth: `${tmpMinWidth}px`,
            }}
          >
            <span className="at-table-row__item-label">{columnLabel}</span>
            {this.renderActionContent(content)}
          </li>
        )}
      </>
    );
  }

  render = () => {
    const { 
      key, 
      headerData, 
      actionColumn, 
      rowItem, 
      actionColumnContent,
      maxActionColumnLength
    } = this.props;

    let tmpOnShowReducer = actionColumnContent.some(
      (action) =>
        (action.onShow &&
          typeof action.onShow === "function" &&
          action.onShow(rowItem)) ||
        !action.onShow,
    );

    return (
      <div key={key} className="at-table-row-cont">
        <div className="at-table__height-control" style={{ height: "initial" }}>
          <ul className="at-table-row grid-controller">
            {headerData.map((columnItem, index) => {
              return this.renderTableContentGrid(
                index,
                columnItem.value,
                rowItem[columnItem.value],
                columnItem.label,
                columnItem.width,
                columnItem.containerStyle,
              );
            })}
            {actionColumn
              ? this.renderTableContentGrid("actions", "Actions", rowItem)
              : ""}
          </ul>
          {actionColumn &&
            tmpOnShowReducer &&
            actionColumnContent &&
            maxActionColumnLength > 0 && (
              <div className="at-table-row__mobile-action">
                {this.renderMobileAction(rowItem)}
              </div>
            )}
        </div>
      </div>
    );
  };
}

export default Content;
