import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactHtmlParser from "react-html-parser";
import { BsXLg, BsFillExclamationTriangleFill, BsInfoCircleFill, BsCheckCircleFill} from "react-icons/bs";

const CustomCloseButton = ({ closeToast }) => (
  <BsXLg
    style={{ height: "16px", width: "16px", flexShrink: "0", cursor: "pointer", color: "#374151" }}
    onClick={closeToast}
  />
);

const renderMessages = (message, showMoreFunction) => {
  if (Array.isArray(message)) {
    const messagesToShow = message.slice(0, 5).map((msg, index) => (
      <div key={index} className="at-toast-body__message_details">
        {ReactHtmlParser(msg.errors || msg)}
      </div>
    ));
    return (
      <>
        {messagesToShow}
        {message.length > 5 && <p onClick={showMoreFunction} style={{ cursor: 'pointer', color: 'blue' }}>Show More</p>}
      </>
    );
  } else {
    return <div className="at-toast-body__message_details">{ReactHtmlParser(message) || message}</div>;
  }
};

export const requestInformation = (input) => {
  const { 
    message, 
    header = "", 
    toastId = null, 
    position = "top-right", 
    pauseOnFocusLoss = false, 
    closeOnClick = true,
    autoClose = true
  } = typeof input === 'string' ? { message: input } : input;

  toast.info(
    <div className="d-flex flex-column">
      <div className="at-toast-body__message_type"> 
        {ReactHtmlParser(header)}
      </div>
      <p>
        <div className="at-toast-body__message_details">{ReactHtmlParser(message)}</div>
      </p>
    </div>,
    {
      position: position,
      pauseOnFocusLoss: pauseOnFocusLoss,
      closeOnClick: closeOnClick,
      closeButton: <CustomCloseButton/>,
      toastId: toastId,
      autoClose: autoClose,
      icon: <BsInfoCircleFill style={{ color: "#3B82F6" }} />,
  });
};  

export const requestSuccess = (input) => {
  const { 
    message, 
    header = "", 
    toastId = null, 
    position = "bottom-right", 
    pauseOnFocusLoss = false, 
    closeOnClick = true,
    autoClose = true
  } = typeof input === 'string' ? { message: input } : input;

  toast.success(
    <div className="d-flex flex-column">
      <div className="at-toast-body__message_type"> 
        {ReactHtmlParser(header)}
      </div>
      <p>
        <div className="at-toast-body__message_details">{ReactHtmlParser(message)}</div>
      </p>
    </div>,
    {
      position: position,
      pauseOnFocusLoss: pauseOnFocusLoss,
      closeOnClick: closeOnClick,
      closeButton: <CustomCloseButton />,
      toastId: toastId,
      autoClose: autoClose,
      icon: <BsCheckCircleFill style={{ color: "#12B76A" }} />,
    }
  );
};

export const requestWarning = (input) => {
  const { 
    message, 
    header = "", 
    toastId = null, 
    position = "bottom-right", 
    pauseOnFocusLoss = false, 
    closeOnClick = true,
    autoClose = true
  } = typeof input === 'string' ? { message: input } : input;
  toast.warn(
    <div className="d-flex flex-column">
      {header && (
        <div className="at-toast-body__message_type"> 
          {ReactHtmlParser(header)}
        </div>
      )}
      
      <div className="at-toast-body__message_details">
        {ReactHtmlParser(message)}
      </div>
    </div>,
    {
      position: position,
      pauseOnFocusLoss: pauseOnFocusLoss,
      closeOnClick: closeOnClick,
      closeButton: <CustomCloseButton/>,
      toastId: toastId,
      autoClose: autoClose,
      icon: <BsInfoCircleFill style={{ color: "#F79009" }} />,
  });
};

export const requestError = (input) => {
  const { 
    message = "", 
    header = "", 
    toastId = null, 
    position = "bottom-right", 
    pauseOnFocusLoss = false, 
    closeOnClick = true,
    showMoreFunction,
    autoClose = true
  } = typeof input === 'string' ? { message: input } : input;
  const defaultMessage = "You might be disconnected from the Internet, please reconnect and refresh the page to use Atlas again.";
  toast.error(
    <div className="d-flex flex-column">
      <div className="at-toast-body__message_type"> 
        {ReactHtmlParser(header)}
      </div>
      <p>
        <div className="at-toast-body__message_details">{(renderMessages(message, showMoreFunction) || defaultMessage)}</div>
      </p>
    </div>,
    {
      position: position,
      pauseOnFocusLoss: pauseOnFocusLoss,
      closeOnClick: closeOnClick,
      closeButton: <CustomCloseButton/>,
      toastId: toastId,
      autoClose: autoClose,
      icon: <BsFillExclamationTriangleFill style={{ color: "#F04438" }} />,
  });
};