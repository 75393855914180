import React, { Component } from "react";
import { compose } from "redux";
import { MdFilterList } from "react-icons/md";
import _ from "lodash";

import Tab from "components/Tab";
import AlertBox from "components/AlertBox";
import ModuleHeader from "components/ModuleHeader";
import CustomCard from "components/Card";
import TableSearchParams from "./components/CustomSearchParam";
import LoadingModal from "components/LoadingModal";
import CustomTable from "./components/NewTable";
import ModalDialog from "components/Modal/ModalDialog";
import ConfirmationBuyerDetail from "./components/ConfirmationBuyerDetail";
import ConfirmationCompanyDetail from "./components/ConfirmationCompanyDetail";

import CddHOC from "./actions";
import { columnData } from "./assets";
import "./index.scss";

class CustomerDueDillegence extends Component {
  componentDidMount = () => {
    this.props.getCddReport(this.props.selectedForm)
  }

  renderConfirmationFormDetail = () => {
    return (
      <>
        { this.props.type === 'Personal' && (
          <ConfirmationBuyerDetail 
            dataInfo={this.props.seletedData}
            selectedForm={this.props.selectedForm} 
          /> 
        )}
        { this.props.type === 'Company' && 
          <ConfirmationCompanyDetail 
            dataInfo={this.props.seletedData}
            selectedForm={this.props.selectedForm} 
          /> 
        }
      </>
    )
  }

  renderTabCard = () => {
    return (
      <>
        <button
          className="btn-new btn-new--outline-secondary at-mobile-view__controller"
          style={{ width: "100%" }}
          onClick={() => this.props.onChangeCddHOC(true, "showSearchContent")}>
          <MdFilterList style={{ width: 20 }} />
          <h5 style={{ fontSize: 15, fontWeight: 500, marginLeft: 5 }}>
            Filter & Search
          </h5> 
        </button>
        <div className="at-table__search-cont at-desktop-view__controller ">
          <TableSearchParams
            mode={"desktop"}
            showResetButton={true}
            searchParams={this.props.searchParams}
            onChangeSearchParams={(val) => this.props.onChangeCddHOC(val, "searchParams")}
            onToggleSearchModal={() => this.props.onChangeCddHOC(!this.props.showSearchContent, "showSearchContent")}
            resetListAPI={() => this.props.resetCddReport(this.props.selectedForm)}
            getListAPI={(page, search) => this.props.getCddReport(this.props.selectedForm)}
          />
        </div>
        { this.props.showSearchContent && (
          <TableSearchParams
            mode={"mobile"}
            showResetButton={true}
            searchParams={this.props.searchParams}
            onChangeSearchParams={(val) => this.props.onChangeCddHOC(val, "searchParams")}
            onToggleSearchModal={(val) => this.props.onChangeCddHOC(val, "showSearchContent")}
            resetListAPI={() => this.props.resetCddReport(this.props.selectedForm)}
            getListAPI={(page, search) => this.props.getCddReport(this.props.selectedForm)}
          />
        )}
        <Tab
          sections={['Confirmation Forms','Subsales Claims']}
          containerStyle={{marginTop: 16, paddingTop: 16}}
          selectedSection={this.props.selectedForm}
          onSelectSection={(val) => {
            this.props.onChangeCddHOC(val,"selectedForm")
            this.props.getCddReport(val)
          }}
        />
        <div className="at-card-container">
          <CustomTable
            columns={columnData[this.props.type]}
            type={this.props.type}
            onChangeForm={this.props.onChangeCddHOC}
            pagination={true}
            rowData={this.props.rowData[this.props.type]}
            hideSearch={true}
            estimatedCommProps={""}
            agencyCommProps={""}
            updateSimpleTable={ () => {} }
            downloadReport={this.props.downloadCddReport}
            actionColumnContent={[
              {
                name: "details",
                onClick: (rowData) => {
                  this.props.onChangeCddHOC(rowData,"seletedData")
                  this.props.onChangeCddHOC(true,"showDialog")
                }
              }
            ]}
          />
        </div>
      </>
    )
  }

  renderCardContent = () => {
    return (
      <>
        <CustomCard
          containerStyle={{ background: "#F3F4F6", overflowX: "none" }}
          cardContent={this.renderTabCard()}
        />
      </>
    )
  }

  render() {
    return (
      <>
        <ModuleHeader 
          title={"Customer Due Diligence"} 
          moduleIcon={"icon-user-search"}
          renderAlertContent={
            <AlertBox
            mode={"info"}
            description={"Customer due diligence table defaults to displaying results for the past 3 months."}
            />
          }
        />
        {this.renderCardContent()}
        {
          this.props.showDialog && (
            <ModalDialog 
              title={`Buyer Details`}
              fullWidth={true}
              fullHeight={false}
              removeFooter={true}
              onClose={() => this.props.onChangeCddHOC(false,"showDialog")}
              children={
                this.renderConfirmationFormDetail()
              }
            />
          )
        }
        { this.props.loading && <LoadingModal /> }
      </>
    );
  }
}

export default compose(CddHOC)(CustomerDueDillegence);