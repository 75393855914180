import React, { Component } from "react";

import IconSprite from "assets/stylesheets/atlasIcon.svg";

const defaultGradient = ({
  svgHref,
  gradientId,
  gradient
}) => {
  if(svgHref === "atlas-pilot") { // safari browser compatibility
    return (
      <defs>
        <linearGradient id="paint0_linear_10801_126109" x1="11.0699" y1="0.997854" x2="11.0699" y2="18.8586" gradientUnits="userSpaceOnUse">
          <stop stop-color="#EFE446"/>
          <stop offset="0.03" stop-color="#EFDB3B"/>
          <stop offset="0.11" stop-color="#F0CB29"/>
          <stop offset="0.19" stop-color="#F0C21D"/>
          <stop offset="0.28" stop-color="#F1BF1A"/>
          <stop offset="0.67" stop-color="#EA8A42"/>
          <stop offset="0.89" stop-color="#E96633"/>
          <stop offset="1" stop-color="#E64039"/>
        </linearGradient>
        <linearGradient id="paint1_linear_10801_126109" x1="7.73208" y1="9.97218" x2="17.1755" y2="10.4506" gradientUnits="userSpaceOnUse">
          <stop stop-color="#F0A422"/>
          <stop offset="1" stop-color="#F15A2E"/>
        </linearGradient>
        <linearGradient id="paint2_linear_10801_126109" x1="0.0787025" y1="6.7886" x2="-39.2283" y2="4.79739" gradientUnits="userSpaceOnUse">
          <stop stop-color="#F0A422"/>
          <stop offset="1" stop-color="#F15A2E"/>
        </linearGradient>
      </defs>
    )
  }
  return (
    <defs>
      <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="100%" gradientTransform={`rotate(${gradient.deg})`}>
        {
          gradient.stops.map((stop, index) => (
            <stop key={index} offset={stop.offset} stopColor={stop.stopColor} />
          ))
        }
      </linearGradient>
    </defs>
    
  )
}
class SideBarIcon extends Component {
  render() {
    const { id, className, svgHref, style, onClick, gradient } = this.props;
    const gradientId = Math.random().toString().slice(-6);
    const grandElement = gradient
      ? defaultGradient({ ...this.props, gradientId })
      : (
        <defs>
          <linearGradient id={gradientId}>
            <stop offset="100%" stopColor="#8d8d8d" />
          </linearGradient>
        </defs>
      )

    return (
      <svg id={id} className={className} style={style} onClick={onClick}>
        { grandElement }
        <use
          id={id}
          href={`${IconSprite}#${svgHref}`}
          xlinkHref={`${IconSprite}#${svgHref}`}
          fill={`url(#${gradientId})`}
        />
      </svg>
    );
  }
}

export default SideBarIcon;
