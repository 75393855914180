import React from "react";
import ReactHtmlParser from "react-html-parser";
import _ from "lodash";
import Moment from "moment";
import CurrencyInput from "react-currency-input-field";

import CustomCheckbox from "components/Checkbox";
import FormDialog from "../../components/formDialog";
import DatePicker from "components/Input/datetimepicker";

import { validationCheck } from "utils/checkNull";
import { replaceAll } from "utils/replaceAll";
import subsaleForm from "../assets/subsaleLetter";
import "react-datepicker/dist/react-datepicker.css";

const branchInfo = (id, branches) => {
  let tmp = _.find(branches, { id: id });
  return {
    name: tmp.name,
    contact_details: tmp.contact_details ? tmp.contact_details : "",
    registration_number: tmp.registration_number,
  };
};

const TempSubsalesLetter = ({
  FormTab,
  onChange,
  conditions,
  data,
  switchMode,
  autoCalculatedList,
  onAutoCalculateEarnestDepositPercentage,
  storedAddresses,
  invoicing_branches,

  closedEditing,
}) => {
  const {
    invoicing_branch_id,
    transaction_number,
    appointment_date,
    remind_me,
    day_to_remind_after_appointed,
    property_address,
    consideration_price,
    earnest_deposit_percentage,
    balance_deposit_percentage,
    balance_pay_within_days,
    balance_pay_within_another_days,
    chargeable_interest_percentage,
    land_and_buildings_fee_percentage,
    fee_payable_amount,
    basis_id,
    valid_from,
    valid_to,
    stake_holder_id,
    stake_holder_company,
    stake_holder_registration_number,
    sales_tax_percentage,
  } = data;

  const tmpAddress = validationCheck(property_address) ? property_address : "";


  const renderContractLayout = () => (
    <>
      <h5 className="fw-600">Branch Information</h5>
      <hr />
      <div className="at-project-details__label">
        {
          branchInfo(invoicing_branch_id, invoicing_branches)
            .registration_number
        }
      </div>
      <div className="at-project-details__label">
        {ReactHtmlParser(
          replaceAll(
            branchInfo(invoicing_branch_id, invoicing_branches)
              .contact_details || "",
            "|",
            "<br/>",
          ),
        )}
      </div>
      <hr />
      <div className="d-flex flex-wrap justify-content-between align-items-end grid_gap-10">
        <h5 className="fw-600">
          {" "}
          Appointment to Act as an Estate Agent (To Secure A Purchaser){" "}
        </h5>
        <div className="d-flex justify-content-between w-100">
          <p style={{ marginBottom: 10, fontSize: 16 }}>{`${transaction_number}`}</p>
          <div>
            {`Date: `}
            <div 
              className={`at-form-input ${closedEditing ? "claim_form-disabled" : ""}`}
              style={{ display: "inline-block", height: "auto" }}>
              <DatePicker
                value={appointment_date ? Moment(appointment_date, "YYYY-MM-DD") : null}
                onChange={(val) => onChange("appointment_date", Moment(val).format("YYYY-MM-DD"))}
                disabled={closedEditing}
              />
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="fs-2">
        I/We,
        <span className={"text-danger"}>
          {` (Info will be displayed according to the contract participants section) `}
        </span>
        NRIC No/Passport No:
        <span className={"text-danger"}>
          {` (Info will be displayed according to the contract participants section) `}
        </span>
        the registered/beneficial owner of Address:
        <span className={"text-danger"}>
          {` (Info will be displayed according to the contract participants section) `}
        </span>
        hereby appoint IQI REALTY SDN BHD to secure a purchaser for the said
        Property known as:
        <p className="my-2">
          <b>{tmpAddress || "N/A"}</b>
        </p>
        whereby IQI REALTY SDN BHD are permitted to display signboard, record
        360-degree video, conduct virtual tour, advertise in any media or in any
        form to promote the sale of the above mentioned property, subject to the
        following terms and conditions:
      </div>
      <ol className="fs-2">
        <li>
          The total consideration price for the said Property shall be RM
          <CurrencyInput
            className="subsales-letter-input"
            style={{ paddingLeft: "0.2rem" }}
            name={"consideration_price"}
            defaultValue={1000}
            disabled={closedEditing}
            value={consideration_price}
            onValueChange={(value) => onChange("consideration_price", value)}
          />
          or the nearest offer <strong>(“the Consideration Price”)</strong>.
        </li>
        <li>
          The payments of the Consideration Price are as follows:
          <ol type={"a"}>
            <li>
              Earnest Deposit upon acceptance of the offer:
              <input
                type={"number"}
                className="subsales-letter-input"
                value={earnest_deposit_percentage}
                onChange={(e) =>
                  onChange("earnest_deposit_percentage", e.target.value)
                }
                disabled={closedEditing}
              />
              % of consideration price.
            </li>
            <li>
              Upon signing the Sale & Purchase Agreement:
              <input
                type={"number"}
                className="subsales-letter-input"
                value={balance_deposit_percentage}
                onChange={(e) =>
                  onChange("balance_deposit_percentage", e.target.value)
                }
                disabled={closedEditing}
              />
              % of consideration price.
            </li>
            <li>
              The balance of the Consideration Price is to be paid within a
              period of
              <input
                type={"number"}
                className="subsales-letter-input"
                value={balance_pay_within_days}
                onChange={(e) =>
                  onChange("balance_pay_within_days", e.target.value)
                }
                disabled={closedEditing}
              />{" "}
              days [within a period of another
              <input
                type={"number"}
                className="subsales-letter-input"
                value={balance_pay_within_another_days}
                onChange={(e) =>
                  onChange("balance_pay_within_another_days", e.target.value)
                }
                disabled={closedEditing}
              />{" "}
              days at a chargeable interest of
              <input
                type={"number"}
                className="subsales-letter-input"
                value={chargeable_interest_percentage}
                onChange={(e) =>
                  onChange("chargeable_interest_percentage", e.target.value)
                }
                disabled={closedEditing}
              />
              % per annum at daily rests].
            </li>
          </ol>
        </li>
        <li>
          Condition:
          <input
            type={"text"}
            style={{ width: "100%", maxWidth: "100%" }}
            className="subsales-letter-input"
            value={conditions}
            onChange={(e) => onChange("conditions", e.target.value)}
            disabled={closedEditing}
          />
        </li>
        <li>
          I/We hereby agree to pay IQI Realty Sdn Bhd a Professional Fee as
          follows:
          <ol type={"a"}>
            <li>
              In accordance with Schedule 7(C)(1) under Rule 48(a) of the
              Valuers, Appraisers and Estate Agents Rules 1986, the scale of fee
              to be charged by registered agents for their professional services
              shall be:
              <ol type={"i"}>
                <li>
                  Land and Buildings - Fee of
                  <input
                    type={"number"}
                    className="subsales-letter-input"
                    value={land_and_buildings_fee_percentage}
                    onChange={(e) =>
                      onChange(
                        "land_and_buildings_fee_percentage",
                        e.target.value,
                      )
                    }
                    disabled={closedEditing}
                  />
                  {`%  (Commercial or Residential).`}
                </li>
                <li>
                  <input
                    type={"number"}
                    className="subsales-letter-input"
                    value={sales_tax_percentage}
                    onChange={(e) =>
                      onChange("sales_tax_percentage", e.target.value)
                    }
                    disabled={closedEditing}
                  />
                  % Sales & Service Tax;
                </li>
              </ol>
            </li>
            <li>
              Professional Fee payable to IQI Realty Sdn Bhd as calculated above
              is RM
              <CurrencyInput
                className="subsales-letter-input"
                style={{ paddingLeft: "0.2rem" }}
                name={"fee_payable_amount"}
                defaultValue={1000}
                disabled={closedEditing}
                value={fee_payable_amount}
                onValueChange={(value) => onChange("fee_payable_amount", value)}
              />
              only.
            </li>
          </ol>
        </li>
        <li>
          The Professional Fee shall be paid in full to IQI Realty Sdn Bhd upon
          the signing of the Sale and Purchase Agreement.
        </li>
        <li>
          In the event that the Earnest Deposit is paid but the transaction is
          aborted before the Sale and Purchase Agreement is signed, I/We
          nevertheless agree to pay IQI Realty Sdn Bhd a fee of 50% of the
          Earnest Deposit or forfeitable deposit, whichever is lesser.
        </li>
        <li>
          In the event that the Sale and Purchase Agreement is signed, but the
          transaction has subsequently aborted of any reasons, I/We nevertheless
          agree to pay IQI Realty Sdn Bhd the full Professional Fee.
        </li>
        <li>
          The appointment is on an{" "}
          <input
            type={"radio"}
            name={"appt-basis"}
            checked={basis_id === 1}
            value={1}
            onChange={(e) => onChange("basis_id", +e.target.value)}
            disabled={closedEditing}
          />{" "}
          {`Exclusive basis `}
          <input
            type={"radio"}
            name={"appt-basis"}
            checked={basis_id === 2}
            value={2}
            onChange={(e) => onChange("basis_id", +e.target.value)}
            disabled={closedEditing}
          />
          Ad-hoc basis.
          {basis_id === 1 && (
            <ol type={"i"}>
              <li>
                In the event where this appointment is on the basis of Exclusive
                agency and that I/We have sold the property myself/ourselves or
                by a third party, I/We agree to pay IQI REALTY SDN BHD the full
                Professional fees as compensation.
              </li>
              <li>
                This appointment shall be valid{" "}
                {basis_id === 1 && (
                  <>
                    from{" "}
                    <div 
                      className="at-form-input" 
                      style={{ display: "inline-block", height: "auto", padding: 0 }}>
                      <DatePicker
                        value={valid_from ? Moment(valid_from, "YYYY-MM-DD") : null}
                        onChange={(val) => onChange("valid_from", Moment(val).format("YYYY-MM-DD"))}
                        disabled={closedEditing}
                      />
                    </div>
                    {` to `}
                    <div 
                    className="at-form-input" 
                    style={{ display: "inline-block", height: "auto", padding: 0 }}>
                    <DatePicker
                        value={valid_to ? Moment(valid_to, "YYYY-MM-DD") : null}
                        onChange={(val) => onChange("valid_to", Moment(val).format("YYYY-MM-DD"))}
                        disabled={closedEditing}
                      />
                    </div>
                  </>
                )}
                ("the Original Term") and shall automatically renewed for a period equivalent to the Original Term 
                unless either party gives notice of termination in writing at the end of the Original Term.
              </li>
              <li>
                I/We hereby agree that I/We shall not be entitled to terminate
                this appointment during its period of validity and in the event
                where I/We shall purport to terminate, whether in writing or
                otherwise, this appointment before its due expiry. I/We shall be
                liable to pay IQI Realty Sdn Bhd the full Professional Fee as
                compensation.
              </li>
            </ol>
          )}
        </li>
        <li>
          I/We hereby authorize (
          <input
            type={"radio"}
            name={"party-selection"}
            checked={stake_holder_id === 1}
            value={1}
            onChange={(e) => onChange("stake_holder_id", +e.target.value)}
            disabled={closedEditing}
          />
          {`IQI REALTY SDN BHD  `}
          <input
            type={"radio"}
            name={"party-selection"}
            value={2}
            checked={stake_holder_id === 2}
            onChange={(e) => onChange("stake_holder_id", +e.target.value)}
            disabled={closedEditing}
          />
          {`Lawyer)   `}
          {stake_holder_id === 2 && (
            <div style={{ border: "1px solid #000", padding: 8 }}>
              <div>
                <span> Lawyer Company Name: </span>
                <input
                  type={"text"}
                  className="subsales-letter-input"
                  style={{ width: "1000px" }}
                  value={stake_holder_company}
                  onChange={(e) =>
                    onChange("stake_holder_company", e.target.value)
                  }
                  disabled={closedEditing}
                />
              </div>
              <div>
                <span> Lawyer Company Registration Number: </span>
                <input
                  type={"text"}
                  className="subsales-letter-input"
                  style={{ width: "1000px" }}
                  value={stake_holder_registration_number}
                  onChange={(e) =>
                    onChange("stake_holder_registration_number", e.target.value)
                  }
                  disabled={closedEditing}
                />
              </div>
            </div>
          )}
          to act as stakeholder and to accept on *my/our behalf the Earnest
          Deposit and I/We irrevocably instruct IQI Realty Sdn Bhd to deduct the
          agreed Professional Fee from the Earnest Deposit and release the
          remaining Earnest Deposit (if any) to us and/or our appointed lawyer
          once the Sale and Purchase Agreement is signed and stamped.
        </li>
        <li>
          I/We fully indemnify IQI Realty Sdn Bhd against all claims if
          Purchaser/I/We, for any reason whatsoever, do not proceed to execute
          the Sale and Purchase Agreement after Earnest Deposit has been
          collected.{" "}
        </li>
        <li>
          All signatories to this document hereby acknowledge and agree to comply with the Malaysia Anti-Corruption Commission Act 2009 and Amendment Act - Section 17A of the MACC Act 2009 (MACC 2009), Anti-Money Laundering, Anti-Terrorism Financing and Proceeds of Unlawful Activities Act 2001 (AML/CFT), 
          Personal Data Protection Act 2010 and further authorise IQI Realty Sdn Bhd and/or its group companies to disclose their information to third parties as necessary, inter alia, 
          at the request of regulatory authorities. For reference, parties may refer to our website at <a target="_blank" href="https://www.iqiglobal.com/amla">https://www.iqiglobal.com/amla</a> and <a target="_blank" href="https://www.iqiglobal.com/privacy-policy">https://www.iqiglobal.com/privacy-policy</a>.
        </li>
      </ol>
      <hr />
      <CustomCheckbox
        checked={remind_me}
        disabled={closedEditing}
        content={
          <span className="fs-2">
            Remind me about this instruction after
            <input
              type={"text"}
              disabled={closedEditing}
              className="subsales-reminder-days-input"
              value={day_to_remind_after_appointed}
              onChange={(e) =>
                onChange("day_to_remind_after_appointed", e.target.value)
              }
            />
            days.
          </span>
        }
        onChangeCheckboxValue={(e) => onChange("remind_me", e.target.checked)}
      />
    </>
  );

  const renderFormListLayout = () => {
    return (
      <FormDialog
        formData={subsaleForm}
        states={{ autoCalculated: onAutoCalculateEarnestDepositPercentage }}
        data={data}
        autoCalculatedList={autoCalculatedList}
        closedEditing={closedEditing}
        onChange={onChange}
      />
    );
  };

  return (
    <>
      {switchMode === "Contract View" && renderContractLayout()}
      {switchMode === "Form View" && renderFormListLayout()}
    </>
  );
};

export default TempSubsalesLetter;
