import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import firebase from "firebase/app";

import Cookies from "js-cookie";
import { ErrorBoundary } from "@appsignal/react";

import { getItem } from "utils/tokenStore";
import { appsignal } from "../../appSignal";

import DashboardTemplate from "./template";

import Profile from "./Profile";
import ProfileUpdate from "./ProfileUpdate";
import ChangePassword from "./ProfileUpdate/ChangePassword";
import Settings from "./Settings";
import Statistic from "./Statistic";
import GlobalNetwork from "./GlobalNetwork";
import ProjectListing from "./ProjectListing";
import MyTeam from "./Team/MyTeam";
import TeamMembers from "./Team/MyTeam/TeamMembers";
import HierarchySales from "./Team/MyTeam/HierarchySales";
import TeamSales from "./Team/TeamSales";
import ProjectSales from "./Team/ProjectSales";
import TeamPerformance from "./Team/TeamPerformance";
import RecruitmentGrowthTarget from "./Team/RecruitmentGrowthTarget";
import PersonalSales from "./Personal/MySales";
import CompanyEventsBeta from "./CompanyEventsBeta";
import Announcement from "./Announcement";
import NewAnnouncement from "./Announcement/New";
import AnnouncementDetails from "./Announcement/Details";
import AnnouncementEdit from "./Announcement/Edit";
import Notification from "./Notification";
import NotificationDetails from "./Notification/Details";
import IQIDrive from "./IQIDrive";
import Tutorial from "./Tutorial";
import PendingApproval from "./PendingApproval";
import VideoCreator from "./VideoCreator";
import Reports from "./Reports";
import MarketHub from "./MarketHub";
import MeetingHub from "./Team/MeetingHub";
import PresentationCentre from "./PresentationCentre";
import AnalyticCentre from "./AnalyticCentre";
import JIQICafe from "./JIQICafe";
import Template from "components/ModuleTemplate";
import ChaskiqChat from "components/ChaskiqChat";
import GraphicStudio from "./GraphicStudio";
import EmailCampaigns from "./EmailCampaigns";

import AdminRouter from "./Administrator/router";

import PersonalRouter from "./Personal/router";

import TrainingRouter from "./TrainingCentre";

import ValuationRouter from "./Valuations";

import LoadingModal from "components/LoadingModal";

import { refreshToken } from "actions/login";
import { getPermissions } from "actions/permissions";
import { getCurrentUserProfile } from "actions/profile";
import { getCurrentSignInProfile } from "actions/currentSignInProfile";
import {
  askForPermissionToReceiveNotifications,
  getFirebaseMessage,
} from "utils/firebase";
import { storeFirebaseUserToken } from "actions/firebase";
import AxiosInterceptors from "Sec/AxiosInterceptors"; // please do not remove this
import adminLogOut from "actions/adminLogOut";

import "firebase/messaging";

import "react-toastify/dist/ReactToastify.css";
import "./index.scss";
import "assets/stylesheets/new_atlas/index.scss";

class Dashboard extends Component {
  componentDidMount = () => {
    const tmpProfileReducer = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    this.onClickAllowNotification();
    if (getItem("IQI_ATLAS_JWT_TOKEN") === null) {
      this.props.history.push("/login");
    } else {
      this.props.getCurrentUserProfile();
    }
    if (
      [
        "Associated Agent",
        "Personal Assistant",
        "Admin",
        "Project Manager",
      ].includes(tmpProfileReducer.role)
    ) {
      this.props.getPermissions(tmpProfileReducer.role);
    }

    document.title = "IQI Atlas - Dashboard";

    if (window.location.href.includes("/admin-impersonate")) {
      window.addEventListener("unload", (event) => {
        this.props.adminLogOut();
      });
    }
  };

  componentWillUnmount = () => clearInterval(this.ticker);

  componentDidCatch = (error, errorInfo) => {
    if (window.location.host.indexOf("atlas.iqiglobal.com") > -1) {
      // this.props.onChangeHOC( error, 'error' )
      // Sentry.withScope( scope => {
      //   Object.keys( errorInfo ).forEach( key => {
      //     scope.setExtra( key, errorInfo[ key ] );
      //   });
      //   Sentry.captureException( error );
      // });
      // span.setError( temp )
      // span.setTags({
      //   profile: this.props.data.profileReducer.profile,
      //   location: ''
      // })
      const span = appsignal.createSpan((span) => {
        return span.setError(
          `${JSON.stringify(error)} ${JSON.stringify(errorInfo)}`
        );
      });
      appsignal.send(span);
    }
  };

  onClickAllowNotification = () => {
    const impersonate = window.location.href.includes("/admin-impersonate");
    if (firebase.messaging.isSupported() && !impersonate) {
      askForPermissionToReceiveNotifications().then((result) => {
        if (result) {
          this.props.storeFirebaseUserToken(result);
        }
      });
      getFirebaseMessage(() => {});
    }
  };

  handleAppSignalError = (error) => {
    const tmpProfileReducer = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;

    if (window.location.host.indexOf("atlas.iqiglobal.com") > -1) {
      const span = appsignal.createSpan();
      let temp = error;
      temp.name = error.message;
      span.setError(temp);
      span.setTags({
        profile: tmpProfileReducer,
        location: window.location.href,
      });
      appsignal.send(span);
    }
  };

  renderChaskiqChat = () => {
    const {
      id,
      email,
      role,
      full_name,
      mobile_contact_number,
      atlas_bot_identifier,
      avatar_url
    } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;
    const token = window.location.href.indexOf("/admin-impersonate")
      ? getItem("IQI_ATLAS_JWT_TOKEN")
      : getItem("IQI_ATLAS_JWT_AGENT_TOKEN");

    if(id !== 6) return null;
    
    return (
      <ChaskiqChat
      email={email}
      name={full_name}
      role={role}
      mobile_number={mobile_contact_number}
      identifierKey={atlas_bot_identifier}
      avatarUrl={avatar_url}
      token={token}/>
    )      
  };  

  renderContent = (propRef) => {
    return (
      <>
        {this.props.data.ajaxStatusReducer.ajaxCallProgressToken === 0 && (
          <ErrorBoundary
            instance={appsignal}
            fallback={(error) => this.handleAppSignalError(error)}
          >
            <Switch>
              <Route exact path={"/dashboard/profile"} component={Profile} />
              <Route exact path={"/dashboard/settings"} component={Settings} />

              <Route
                exact
                path={"/dashboard/statistic"}
                component={Statistic}
              />
              <Route
                exact
                path={"/dashboard/global-network"}
                component={GlobalNetwork}
              />
              <Route
                exact
                path={"/dashboard/project-listing"}
                component={ProjectListing}
              />
              <Route
                exact
                path={"/dashboard/presentation-centre"}
                component={PresentationCentre}
              />
              <Route
                exact
                path={"/dashboard/analytic-centre"}
                component={AnalyticCentre}
              />
              <Route
                exact
                path={"/dashboard/admin-activities"}
                component={AnalyticCentre}
              />
              <Route path={"/marketing-centre"} component={MarketHub} />

              <Route
                exact
                path={"/dashboard/team/my-team"}
                component={MyTeam}
              />
              <Route
                exact
                path={"/dashboard/team/team-members"}
                component={TeamMembers}
              />
              <Route
                exact
                path={"/dashboard/team/team-sales"}
                component={TeamSales}
              />
              <Route
                exact
                path={"/dashboard/team/hierarchy-sales"}
                render={() => (
                  <HierarchySales {...this.props} scrollTop={propRef} />
                )}
              />
              <Route
                exact
                path={"/dashboard/team/project-sales"}
                component={ProjectSales}
              />
              <Route
                exact
                path={"/dashboard/team/team-performance"}
                component={TeamPerformance}
              />
              <Route
                exact
                path={"/dashboard/team/recruitment-growth-targets"}
                component={RecruitmentGrowthTarget}
              />
              <Route
                path={"/dashboard/team/personal-sales/:mobile"}
                component={PersonalSales}
              />
              <Route
                path={"/dashboard/video-creator"}
                component={VideoCreator}
              />
              <Route
                path={"/dashboard/graphic-studio"}
                component={GraphicStudio}
              />
              <Route
                path={"/dashboard/email-campaigns"}
                component={EmailCampaigns}
              />
              <Route path={"/dashboard/jiqi-cafe"} component={JIQICafe} />
              <Route
                path={"/dashboard/team/pending-approval"}
                component={PendingApproval}
              />
              <Route
                path={"/dashboard/team/meeting-hub"}
                component={MeetingHub}
              />

              <Route path={"/dashboard/iqi-drive/:type"} component={IQIDrive} />
              <Route path={"/dashboard/reports"} component={Reports} />
              <Route
                exact
                path={"/dashboard/announcement"}
                component={Announcement}
              />
              <Route
                exact
                path={"/dashboard/announcement/new"}
                component={NewAnnouncement}
              />
              <Route
                path={"/dashboard/announcement/details/:id"}
                component={AnnouncementDetails}
              />
              <Route
                exact
                path={"/dashboard/announcement/edit"}
                component={AnnouncementEdit}
              />
              <Route
                exact
                path={"/dashboard/notification"}
                component={Notification}
              />
              <Route
                path={"/dashboard/notification/details/:id"}
                component={NotificationDetails}
              />
              <Route
                path={"/dashboard/iqi-calendar"}
                component={CompanyEventsBeta}
              />
              <Route exact path={"/dashboard/tutorials"} component={Tutorial} />
              <Route
                path={"/admin-impersonate/:profileId/marketing-centre"}
                component={MarketHub}
              />

              <Route
                exact
                path={"/admin-impersonate/:profileId/dashboard/profile"}
                component={Profile}
              />
              <Route
                exact
                path={"/admin-impersonate/:profileId/dashboard/settings"}
                component={Settings}
              />
              <Route
                exact
                path={"/admin-impersonate/:profileId/dashboard/statistic"}
                component={Statistic}
              />
              <Route
                path={"/admin-impersonate/:profileId/dashboard/jiqi-cafe"}
                component={JIQICafe}
              />
              <Route
                exact
                path={"/admin-impersonate/:profileId/dashboard/global-network"}
                component={GlobalNetwork}
              />
              <Route
                exact
                path={"/admin-impersonate/:profileId/dashboard/project-listing"}
                component={ProjectListing}
              />
              <Route
                exact
                path={
                  "/admin-impersonate/:profileId/dashboard/presentation-centre"
                }
                component={PresentationCentre}
              />
              <Route
                exact
                path={"/admin-impersonate/:profileId/dashboard/analytic-centre"}
                component={AnalyticCentre}
              />
              <Route
                exact
                path={
                  "/admin-impersonate/:profileId/dashboard/team/team-members"
                }
                component={TeamMembers}
              />
              <Route
                exact
                path={"/admin-impersonate/:profileId/dashboard/team/my-team"}
                component={MyTeam}
              />
              <Route
                exact
                path={"/admin-impersonate/:profileId/dashboard/team/team-sales"}
                component={TeamSales}
              />
              <Route
                exact
                path={
                  "/admin-impersonate/:profileId/dashboard/team/hierarchy-sales"
                }
                render={() => (
                  <HierarchySales {...this.props} scrollTop={propRef} />
                )}
              />
              <Route
                exact
                path={
                  "/admin-impersonate/:profileId/dashboard/team/project-sales"
                }
                component={ProjectSales}
              />
              <Route
                exact
                path={
                  "/admin-impersonate/:profileId/dashboard/team/team-performance"
                }
                component={TeamPerformance}
              />
              <Route
                exact
                path={
                  "/admin-impersonate/:profileId/dashboard/team/recruitment-growth-targets"
                }
                component={RecruitmentGrowthTarget}
              />
              <Route
                path={
                  "/admin-impersonate/:profileId/dashboard/team/personal-sales/:mobile"
                }
                component={PersonalSales}
              />
              <Route
                path={"/admin-impersonate/:profileId/dashboard/video-creator"}
                component={VideoCreator}
              />
              <Route
                path={"/admin-impersonate/:profileId/dashboard/graphic-studio"}
                component={GraphicStudio}
              />
              <Route
                path={"/admin-impersonate/:profileId/dashboard/email-campaigns"}
                component={EmailCampaigns}
              />
              <Route
                path={
                  "/admin-impersonate/:profileId/dashboard/team/pending-approval"
                }
                component={PendingApproval}
              />
              <Route
                path={"/admin-impersonate/:profileId/dashboard/iqi-drive/:type"}
                component={IQIDrive}
              />
              <Route
                path={"/admin-impersonate/:profileId/dashboard/reports"}
                component={Reports}
              />
              <Route
                exact
                path={"/admin-impersonate/:profileId/dashboard/announcement"}
                component={Announcement}
              />
              <Route
                exact
                path={
                  "/admin-impersonate/:profileId/dashboard/announcement/new"
                }
                component={NewAnnouncement}
              />
              <Route
                path={
                  "/admin-impersonate/:profileId/dashboard/announcement/details/:id"
                }
                component={AnnouncementDetails}
              />
              <Route
                exact
                path={
                  "/admin-impersonate/:profileId/dashboard/announcement/edit"
                }
                component={AnnouncementEdit}
              />
              <Route
                exact
                path={"/admin-impersonate/:profileId/dashboard/notification"}
                component={Notification}
              />
              <Route
                path={
                  "/admin-impersonate/:profileId/dashboard/notification/details/:id"
                }
                component={NotificationDetails}
              />
              <Route
                path={"/admin-impersonate/:profileId/dashboard/iqi-calendar"}
                component={CompanyEventsBeta}
              />
              <Route
                exact
                path={"/admin-impersonate/:profileId/dashboard/tutorials"}
                component={Tutorial}
              />
              <Route
                path={
                  "/admin-impersonate/:profileId/dashboard/team/meeting-hub"
                }
                component={MeetingHub}
              />
            </Switch>

            <PersonalRouter />

            <TrainingRouter />
            <ValuationRouter />
            <AdminRouter />
          </ErrorBoundary>
        )}
      </>
    );
  };

  render = () => {
    const { history, match } = this.props;
    return (
      <>
        {this.props.data.ajaxStatusReducer.ajaxCallProgressToken > 0 ? (
          <LoadingModal />
        ) : (
          <>
            <DashboardTemplate
              history={history}
              content={(props) => this.renderContent(props)}
            />
            <ToastContainer autoClose={10000} style={{ minWidth: 350, zIndex: 9999 }} />
          </>
        )}
        {this.renderChaskiqChat()}
      </>
    );
  };
}

const mapStateToProps = (state) => ({ data: state });

export default connect(mapStateToProps, {
  refreshToken,
  getPermissions,
  adminLogOut,
  getCurrentUserProfile,
  getCurrentSignInProfile,
  storeFirebaseUserToken,
})(Dashboard);
