import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";
import { connect } from "react-redux";

import { requestError, requestSuccess } from "utils/requestHandler";

import { Get, Put, Post } from "utils/axios";
import { getBranches, getInvoicingBranches } from "actions/branch";
import { getAgencies } from "actions/agency";
import { getAllDictionaryData, getCountryDictionary } from "actions/dictionary";
import { storeLastView } from "actions/lastView";
import { refreshToken } from "actions/login";
import {
  types,
  categories,
  tenure_types,
  title_types,
  unit_types,
  furnishing_statuses,
  directions,
  occupancies,
  measurements,
  statuses,
} from "dictionary/subsales";

const searchParams = (countries) => [
  {
    label: "Property Name",
    value: "property_name_cont",
    key: "label.details.address.property_name", 
    type: "input",
    param: "",
  },
  {
    label: "Listing Category",
    value: "category_id_eq",
    key: "label.details.details.listing_category",
    type: "select",
    param: "",
    options: categories,
  },
  {
    label: "Property Type",
    value: "property_type_id_eq",
    key: "label.details.details.property_type",
    type: "multi",
    param: "",
    options: [],
  },
  {
    label: "Land Area", 
    value: "land_area_gteq",
    key: "label.details.details.land_area",
    type: "input",
    param: "",
  },
  {
    label: "Built up size (Min.)",
    value: "built_up_gteq",
    key: "label.details.details.build_up_min",
    type: "input",
    param: "",
  },
  {
    label: "Built up size (Max.)",
    value: "built_up_lteq",
    key: "label.details.details.build_up_max",
    type: "input",
    param: "",
  },
  {
    label: "Asking Price (Min.)",
    value: "asking_price_cents_gteq",
    key: "label.details.details.asking_price_min",
    type: "input",
    param: "",
  },
  {
    label: "Asking Price (Max.)",
    value: "asking_price_cents_lteq",
    key: "label.details.details.asking_price_max",
    type: "input",
    param: "",
  },
  {
    label: "Listing Code",
    value: "code_cont",
    key: "label.details.details.code",
    type: "input",
    param: "",
  },
  {
    label: "Country",
    value: "country_id_eq",
    key: "label.details.address.country",
    type: "select",
    param: "",
    options: countries,
  },
  {
    label: "State",
    value: "state_id_eq",
    key: "label.details.address.state",
    type: "select",
    param: "",
    options: [],
  },
  {
    label: "Township",
    value: "township_cont",
    key: "label.details.address.township",
    type: "typeahead",
    labelKey: "name",
    filterBy: ["name"],
    param: "",
    options: [],
    containerStyle: {
      padding: "1px 10px",
    },
    typeaheadChildren: (rowItem) => <p>{rowItem?.name}</p>,
  },
  {
    label: "Status",
    value: "status_id_eq",
    key: "label.details.status.status",
    type: "radio",
    param: "",
    options: [
      { value: "0", label: "Draft", key: "option.statuses.draft" },
      { value: "1", label: "Active", key: "option.statuses.active" },
      { value: "2", label: "Inactive/Expired", key: "option.statuses.inactive_expired" },
    ],
  },
  {
    label: "Type",
    value: "type_id_eq",
    key: "label.details.details.type",
    type: "radio",
    param: "",
    options: [
      { value: "1", label: "Sale", key: "option.details.details.type_1"},
      { value: "2", label: "Rental", key: "option.details.details.type_2" },
    ],
  },
  {
    label: "Favourite",
    value: "is_favourite_true",
    key: "action.favourite",
    type: "radio",
    param: "",
    options: [
      { value: "", label: "All", key: "action.all" },
      { value: "1", label: "Yes", key: "action.yes" },
      { value: "0", label: "No", key: "action.no" },
    ],
  },
];

const SubsalesHOC = (WrappedComponent) => {
  class SubsalesWrappedComponent extends Component {
    state = {
      requests: [],
      townshipArray: [],
      subAreaArray: [],
      
      showNewModal: false,
      showViewModal: false,
      showEditModal: false,
      showDeleteModal: false,
      showLoadingModal: false,
      showLoadingSubarea: false,
      showFavouriteLoading: false,

      disableStatus: false,

      descriptionTab: "Eng", // 1. Eng 2. Zh
      showImportModal: false,

      showSubsalesListingPreviewModal: false,
      showPublishIPropertyConfirmation: false,
      selectedSection: "Details",

      tmpSubsalesCurrentUser: {},
      subsalesEntryCreationProps: {},
      showSubsaleInfoLoadingModal: false,
      searchParams: searchParams(this.props.data.dictionaryReducer.countries),
      subsalesPages: null,
      listingPreviewType: "IQI",
      propertyOptions: [],

      initialRecommendation: [],
      fullRecommendationList: [],
    };

    load = (param) => this.setState({ showLoadingModal: param });

    onChangeSubsalesHOC = (val, context) => this.setState({ [context]: val });

    getStateLocation = (id) =>
      Get(
        `/sub_sales/get_buildings_list?state_id=${id}?locale=${this.props.data.languageReducer.language}`,
        this.getStateLocationSuccess,
        this.getStateLocationError,
        this.load,
      );
    getStateLocationSuccess = (payload) => {
      let temp = [];
      payload &&
        payload.length > 0 &&
        payload.map((item) => {
          let tempObj = { value: item.level5_name, id: item.level5_id };
          temp.push(tempObj);
        });
      this.setState({ propertyOptions: temp });
    };
    getStateLocationError = (error) => requestError(error);

    // get subsales list
    getSubsalesCurrentUser = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);
      
      Get(
        `/sub_sales?${search || ""}page=${page}&locale=${this.props.data.languageReducer.language}`,
        this.getSubsalesListingSuccess,
        this.getSubsalesListingError,
        this.load,
      );
    };

    getSubsalesListingSuccess = (payload) => {
      let tmpTotalPages = [];
      let tmpSubsalesCurrentUser = [];

      for (let i = 0; i < payload.meta.pages; i++) {
        tmpTotalPages.push(i);
      }
      payload.data &&
        payload.data.length > 0 &&
        payload.data.map((item) => {
          let tmpType = _.find(types, { id: item.type_id });
          let tmpCategory = _.find(categories, { id: item.category_id });
          let tempStatus = _.find(statuses, { id: item.status_id });
          let tmpCatGroupPropertyType = {};
          if (tmpCategory) {
            tmpCategory.group_types.map((child) => {
              return child.children.map((childItem) => {
                if (childItem.id === item.property_type_id) {
                  tmpCatGroupPropertyType = childItem;
                }
              });
            });
          }
          let tempTitleType = _.find(title_types, { id: item.title_type_id });
          let tempDirection = _.find(directions, { id: item.direction_id });
          let tempFurnishing = _.find(furnishing_statuses, {
            id: item.furnishing_status_id,
          });
          let tempMeasurement = _.find(measurements, {
            id: item.measurement_id,
          });
          let tempOccupancy = _.find(occupancies, { id: item.occupancy_id });
          let tempTenure = _.find(tenure_types, { id: item.tenure_id });
          let tempUnitType = _.find(unit_types, { id: item.unit_type_id });

          let featureArray = [
            {
              label: "Direction",
              value:
                tempDirection &&
                tempDirection.name &&
                tempDirection.name.toLowerCase() !== "unknown"
                  ? tempDirection.name
                  : "N/A",
            },
            {
              label: "Occupancy",
              value:
                tempOccupancy &&
                tempOccupancy.name &&
                tempOccupancy.name.toLowerCase() !== "unknown"
                  ? tempOccupancy.name
                  : "N/A",
            },
            {
              label: "Furnishing",
              value:
                tempFurnishing &&
                tempFurnishing.name &&
                tempFurnishing.name.toLowerCase() !== "unknown"
                  ? tempFurnishing.name
                  : "N/A",
            },
            {
              label: "Tenure",
              value:
                tempTenure &&
                tempTenure.name &&
                tempTenure.name.toLowerCase() !== "unknown"
                  ? tempTenure.name
                  : "N/A",
            },
            {
              label: "Unit Type",
              value:
                tempUnitType &&
                tempUnitType.name &&
                tempUnitType.name.toLowerCase() !== "unknown"
                  ? tempUnitType.name
                  : "N/A",
            },
            {
              label: "Title Type",
              value:
                tempTitleType &&
                tempTitleType.name &&
                tempTitleType.name.toLowerCase() !== "unknown"
                  ? tempTitleType.name
                  : "N/A",
            },
          ];

          let data = {
            ...item,
            updated_on: item.updated_on
              ? Moment(item.updated_on, "DD-MM-YYYY").format("DD MMM YYYY")
              : "N/A",
            featureArray: featureArray,
            property_name: item.property_name || "N/A",
            address: item.address || "N/A",
            type: tmpType ? tmpType.name : "N/A",
            property:
              _.values(tmpCatGroupPropertyType).length > 0
                ? tmpCatGroupPropertyType.name
                : "N/A",
            measurement:
              tempMeasurement && tempMeasurement.name
                ? tempMeasurement.name
                : "N/A",
            status: tempStatus && tempStatus.name ? tempStatus.name : "N/A",
            category:
              tmpCategory && tmpCategory.name ? tmpCategory.name : "N/A",
          };
          return tmpSubsalesCurrentUser.push(data);
        });
      payload.data = tmpSubsalesCurrentUser;
      this.setState({
        tmpSubsalesCurrentUser: payload,
        subsalesPages: tmpTotalPages,
      });
    };
    getSubsalesListingError = (error) => requestError(error);

    //get selected subsales listing
    getSelectedSubsales = (val) =>
      Get(
        `/sub_sales/${val}?locale=${this.props.data.languageReducer.language}`,
        this.getSelectedSubsalesSuccess,
        this.getSelectedSubsalesError,
        this.load,
      );
    getSelectedSubsalesSuccess = (payload) => {
      let tmp = _.cloneDeep(payload);
      this.getSelectedTownShip(payload.state_id);
      this.getSelectedSubArea(payload.township);

      let tmpType = _.find(types, { id: tmp.type_id });
      let tmpCategory = _.find(categories, { id: tmp.category_id });
      let tempStatus = _.find(statuses, { id: tmp.status_id });
      let tmpCatGroupPropertyType = {};
      if (tmpCategory) {
        tmpCategory.group_types.map((child) => {
          return child.children.map((childItem) => {
            if (childItem.id === tmp.property_type_id) {
              tmpCatGroupPropertyType = childItem;
            }
          });
        });
      }
      let tempFurnishing = _.find(furnishing_statuses, {
        id: tmp.furnishing_status_id,
      });
      let tempMeasurement = _.find(measurements, { id: tmp.measurement_id });
      let tempOccupancy = _.find(occupancies, { id: tmp.occupancy_id });
      let tempTenure = _.find(tenure_types, { id: tmp.tenure_id });

      let featureArray = [
        {
          label: "Occupancy",
          value:
            tempOccupancy && tempOccupancy.name ? tempOccupancy.name : "N/A",
        },
        {
          label: "Furnishing",
          value:
            tempFurnishing && tempFurnishing.name ? tempFurnishing.name : "N/A",
        },
        {
          label: "Tenure",
          value: tempTenure && tempTenure.name ? tempTenure.name : "N/A",
        },
      ];

      let facilitiesArray = [
        { label: "All Day Security", value: tmp.all_day_security },
        { label: "Business Centre", value: tmp.business_centre },
        { label: "Parking", value: tmp.parking },
        { label: "Gymnasium", value: tmp.gymnasium },
        { label: "Jogging Track", value: tmp.jogging_track },
        { label: "Playground", value: tmp.playground },
        { label: "Cafeteria", value: tmp.cafetria },
        { label: "Club House", value: tmp.club_house },
        { label: "Jacuzzi", value: tmp.jacuzzi },
        { label: "Mini Market", value: tmp.mini_market },
        { label: "Nursery", value: tmp.nursery },
        { label: "Salon", value: tmp.salon },
        { label: "Sauna", value: tmp.sauna },
        { label: "Squash Court", value: tmp.squash_court },
        { label: "Tennis Court", value: tmp.tennis_court },
        { label: "Swimming Pool", value: tmp.swimming_pool },
        { label: "Wading Pool", value: tmp.wading_pool },
        { label: "BBQ", value: tmp.bbq },
      ];

      let tempRcc = _.map(payload.recommended_agents_info, (item) => ({
        ...item,
        cobrokeRequestId: item.co_broke_requests.id,
        status: item.co_broke_requests.status,
        status_id: item.co_broke_requests.status_id,
        last_transacted: Moment(item.last_transacted).format("DD MMM YYYY"),
        last_transacted_unix: item.last_transacted
          ? Moment(item.last_transacted).unix()
          : 0,
      }));

      this.setState({
        initialRecommendation: tempRcc.slice(0, 10),
        fullRecommendationList: tempRcc,
        selectedSection: "Details",
        disableStatus: payload.status_id === 4,
        subsalesEntryCreationProps: {
          ...tmp,
          car_parks: tmp.car_parks || "N/A",
          bedrooms: tmp.bedrooms || "N/A",
          bathrooms: tmp.bathrooms || "N/A",
          built_up: tmp.built_up,
          land_area: tmp.land_area,
          facilities: facilitiesArray,
          featureArray: featureArray,
          type: tmpType ? tmpType.name : "N/A",
          property:
            _.values(tmpCatGroupPropertyType).length > 0
              ? tmpCatGroupPropertyType.name
              : "N/A",
          measurement:
            tempMeasurement && tempMeasurement.name
              ? tempMeasurement.name
              : "N/A",
          status: tempStatus && tempStatus.name ? tempStatus.name : "N/A",
          available_date:
            tmp && tmp.available_date ? tmp.available_date : "N/A",
          updated_on: tmp.updated_on
            ? Moment(tmp.updated_on, "DD-MM-YYYY").format("DD MMM YYYY")
            : "N/A",
          category: tmpCategory && tmpCategory.name ? tmpCategory.name : "N/A",

          township: payload.township ? payload.township : "",
          sub_area: payload.sub_area ? payload.sub_area : "",
        },
      });
    };
    getSelectedSubsalesError = (error) => requestError(error);

    // get township based on state
    getSelectedTownShip = (val) =>
      Get(
        `/townships?state_id=${val}?locale=${this.props.data.languageReducer.language}`,
        this.getSelectedTownShipSuccess,
        this.getSelectedTownShipError,
        this.load,
      );
    getSelectedTownShipSuccess = (payload) => {
      let temp = [];
      let tempSearch = _.cloneDeep(this.state.searchParams);
      let townShipIndex = _.findIndex(tempSearch, { value: "township_cont" });
      payload &&
        payload.length > 0 &&
        payload.map((item) => {
          let tempObj = {
            id: item.id,
            value: item.name,
            name: item.name,
            label: item.name,
          };
          temp.push(tempObj);
        });
      tempSearch[townShipIndex].options = temp;
      this.setState({ searchParams: tempSearch, townshipArray: temp });
    };
    getSelectedTownShipError = (error) => requestError(error && error);

    //get sub area based on township
    getSelectedSubArea = (val) =>
      Get(
        `/sub_areas?township_name=${val}?locale=${this.props.data.languageReducer.language}`,
        this.getSelectedSubAreaSuccess,
        this.getSelectedSubAreaError,
        (param) => this.setState({ showLoadingSubarea: param }),
      );
    getSelectedSubAreaSuccess = (payload) => {
      let temp = [];
      payload &&
        payload.length > 0 &&
        payload.map((item) => {
          let tempObj = { value: item.name, id: item.id };
          temp.push(tempObj);
        });
      this.setState({ subAreaArray: temp });
    };
    getSelectedSubAreaError = (error) => requestError(error);

    toggleFavourite = (id) =>
      Put(
        `/sub_sales/${id}/favourite?locale=${this.props.data.languageReducer.language}`,
        "",
        () => this.toggleFavouriteSuccess(id),
        this.toggleFavouriteError,
        (param) =>
          this.setState({
            showFavouriteLoading: param,
          }),
      );
    toggleFavouriteSuccess = (id) => {
      let tmpSubsalesArrays = _.cloneDeep(
        this.state.tmpSubsalesCurrentUser.data,
      );
      let tmpIndex = _.findIndex(tmpSubsalesArrays, { id: id });

      this.state.showViewModal && this.getSelectedSubsales(id);
      requestSuccess(
        !tmpSubsalesArrays[tmpIndex].is_favourite
          ? this.props.getLocalised("success.add_favourite", "Favourite has been added successfully.")
          : this.props.getLocalised("success.remove_favourite", "Favourite has been removed successfully.")
      );

      tmpSubsalesArrays[tmpIndex] = {
        ...tmpSubsalesArrays[tmpIndex],
        is_favourite: !tmpSubsalesArrays[tmpIndex].is_favourite,
      };
      this.setState({
        tmpSubsalesCurrentUser: {
          ...this.state.tmpSubsalesCurrentUser,
          data: tmpSubsalesArrays,
        },
      });
    };

    toggleFavouriteError = (error) => requestError(error);

    publishIProperty = (id) =>
      Post(
        `/sub_sales/${id}/publish_to_iproperty?locale=${this.props.data.languageReducer.language}`,
        {},
        this.publishIPropertySuccess,
        this.publishIPropertyError,
        this.load,
      );
    publishIPropertySuccess = (payload) => {
      const { currentPage, searchParams } = this.props.data.lastViewReducer.lastView;

      this.getSubsalesCurrentUser(currentPage, searchParams);
      this.setState({ showPublishIPropertyConfirmation: false });
      requestSuccess(this.props.getLocalised("success.publish_iproperty", "Listing published on iProperty website successfully"));
      this.getSelectedSubsales(payload.id);
    };
    publishIPropertyError = (error) => requestError(error);

    updateRCCCobrokeStatus = (id, status_id) =>
      Put(
        `/co_broke_requests/${id}?locale=${this.props.data.languageReducer.language}`,
        {
          status_id,
          request_from: "RecommendationEngine",
        },
        this.updateRCCCobrokeStatusSuccess,
        this.updateRCCCobrokeStatusError,
        this.load,
      );
    updateRCCCobrokeStatusSuccess = (payload) =>
      this.getSelectedSubsales(payload.listing_id);
    updateRCCCobrokeStatusError = (error) => requestError(error);

    duplicateSubsales = (id) =>
      Post(
        `/sub_sales/${id}/duplicate`,
        {},
        this.duplicateSubsalesSuccess,
        this.duplicateSubsalesError,
        this.load,
      );
    duplicateSubsalesSuccess = (payload) => {
      const { currentPage, searchParams } = this.props.data.lastViewReducer.lastView;
      this.getSubsalesCurrentUser(currentPage, searchParams);
      this.getSelectedSubsales(payload.id);
      requestSuccess(this.props.getLocalised("success.duplicate_listing", "Listing duplicate successful."));
    };
    duplicateSubsalesError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            disableStatus={this.state.disableStatus}
            propertyOptions={this.state.propertyOptions}
            onLoadSubsales={
              this.state.showLoadingModal || this.state.showFavouriteLoading
            }
            onLoadSubarea={this.state.showLoadingSubarea}
            subAreaArray={this.state.subAreaArray}
            selectedSection={this.state.selectedSection}
            townshipArray={this.state.townshipArray}
            isAssociatedAgent={this.state.isAssociatedAgent}
            listingPreviewType={this.state.listingPreviewType}
            descriptionTab={this.state.descriptionTab}
            subsalesEntryCreationProps={this.state.subsalesEntryCreationProps}
            searchParams={this.state.searchParams}
            subsalesPages={this.state.subsalesPages}
            showNewModal={this.state.showNewModal}
            showImportModal={this.state.showImportModal}
            showDeleteModal={this.state.showDeleteModal}
            showEditModal={this.state.showEditModal}
            showViewModal={this.state.showViewModal}
            showCobrokeRequest={this.state.showCobrokeRequest}
            showPublishIPropertyConfirmation={
              this.state.showPublishIPropertyConfirmation
            }
            showSubsalesListingPreviewModal={
              this.state.showSubsalesListingPreviewModal
            }
            tmpSubsalesCurrentUser={this.state.tmpSubsalesCurrentUser}
            showSubsaleInfoLoadingModal={this.state.showSubsaleInfoLoadingModal}
            openSnackBar={this.state.openSnackBar}
            snackBarMessage={this.state.snackBarMessage}
            initialRecommendation={this.state.initialRecommendation}
            fullRecommendationList={this.state.fullRecommendationList}
            updateRCCCobrokeStatus={this.updateRCCCobrokeStatus}
            toggleFavourite={this.toggleFavourite}
            publishIProperty={this.publishIProperty}
            onChangeSubsalesHOC={this.onChangeSubsalesHOC}
            getSelectedSubsales={this.getSelectedSubsales}
            getSelectedSubArea={this.getSelectedSubArea}
            getSelectedTownShip={this.getSelectedTownShip}
            getStateLocation={this.getStateLocation}
            duplicateSubsales={this.duplicateSubsales}
            getSubsalesCurrentUser={this.getSubsalesCurrentUser}
          />
        </>
      );
    };
  }

  const mapStateToProps = (state) => ({ data: state });

  return connect(mapStateToProps, {
    storeLastView,
    getBranches,
    getInvoicingBranches,
    getCountryDictionary,
    getAllDictionaryData,
    getAgencies,
    refreshToken,
  })(SubsalesWrappedComponent);
};

export default SubsalesHOC;
