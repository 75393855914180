import React from "react";
import { MenuItem, Collapse } from "@material-ui/core";
import Moment from "moment";
import _ from "lodash";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { BiCalculator } from "react-icons/bi";

import AtlasSelect from "components/Select/new";
import AtlasIcon from "components/Icon/atlasIcon";
import AtCurrecyInput from "components/Input/currencyInput";
import AtlasFormInput from "components/Input/formInput";
import AtlasFormGroup from "components/FormGroup/input";
import AtlasRadioGroup from "components/RadioGroup";
import AtlasAutosuggest from "components/Autosuggest";
import CustomSelect from "components/Select";

import { validationCheck } from "utils/checkNull";

const AtTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#000000BB",
    fontSize: 15,
  },
}))(Tooltip);

const renderPeriodLabel = (period) => {
  switch (period) {
    case "0": return "Year(s)";
    case "1": return "Month(s)";
    default: return "";
  }
}

const FormDialog = ({
  formData = [],
  data = {},
  states = {},
  autoCalculatedList = [],
  onChangeFocus,
  onChangeBlur,
  closedEditing,
  onChange,
  onChangeHOC
}) => {
  return (
    <div className="d-flex flex-wrap grid_gap-2">
      {formData &&
        formData.map((formItem, index) => {
          const {
            col,
            type,
            mode,
            label,
            value,
            required = false,
            options = [],
            select_options = [],
            placeholder = "",
            horizontal = true,
            className = "",
            style = {},
            contents = [],
            renderCustomForm = () => {},
            dateFormat,
            labelClass = "",
            disabled = false,
            automated = false,
            iconLabelTooltip,
            renderIconLabel,
          } = formItem;

          if (type === "text") {
            return (
              <li
                key={index}
                className={`at-form_mngmt-form_list grid-${col || "half"}-col`}>
                <h2 className={`at-form-input__title ${labelClass}`} required={required}>
                  {label}
                </h2>
                <div>
                  <AtlasFormInput
                    type="text"
                    className={className}
                    required={required}
                    disabled={closedEditing || disabled}
                    value={`${data[value] || ""}`}
                    onChangeValue={(val) => onChange(value, val)}
                  />
                </div>
              </li>
            );
          }

          if(type === "select_number") {
            return (
              <li key={index} className="at-form_mngmt-form_list" style={style}>
                <h2 className={`at-form-input__title ${labelClass}`} required={required}>
                  {label}
                </h2>
                <div className="at-select__search-cont mb-0">
                  <CustomSelect
                    className="at-select_new-interface"
                    disabled={closedEditing || disabled}
                    selectItems={select_options}
                    labelKey={"label"}
                    valueKey={"value"}
                    currentlySelected={{ value: `${data[value?.select_input]}` }}
                    updateSelect={(item) => onChange(value?.select_input, item.value)}
                  />
                  <input
                    type="search"
                    key={"searchInput"}
                    disabled={closedEditing || disabled}
                    value={data[value?.text_input]}
                    className={"at-table__search-input"}
                    placeholder={`Search with ${renderPeriodLabel(`${data[value?.select_input]}`)}`}
                    onChange={(event) => onChange(value?.text_input, event.target.value)}
                  />
                </div>
              </li>
            )
          }

          if (type === "number") {
            return (
              <li
                key={index}
                className={`at-form_mngmt-form_list grid-${col || "half"}-col`}
                style={style}>
                <h2 className={`at-form-input__title ${labelClass}`} required={required}>
                  {label}
                  {automated && (
                    <AtTooltip
                      classes={{ tooltip: "tooltip-arrow right" }}
                      placement={"right"}
                      enterTouchDelay={0}
                      title={"Auto calculated"}>
                      <span>
                        {autoCalculatedList.indexOf(value) > -1 && (
                          <BiCalculator
                            className={`at-form_mngmt-label_icon at-form_mngmt-icon_automated_signal`}
                          />
                        )}
                        {autoCalculatedList.indexOf(value) < 0 && (
                          <BiCalculator
                            className={`at-form_mngmt-label_icon`}
                          />
                        )}
                      </span>
                    </AtTooltip>
                  )}
                  {(renderIconLabel && typeof renderIconLabel === "function") && (
                    <span className="at-label_icon-cont">
                      {renderIconLabel({
                        states,
                        data,
                        closedEditing,
                        onChange,
                        onChangeHOC
                      })}
                      {iconLabelTooltip && (
                        <AtTooltip
                          classes={{ tooltip: "tooltip-arrow top" }}
                          placement={"top"}
                          enterTouchDelay={0}
                          title={iconLabelTooltip || "Tooltip"}>
                          <span>
                            <AtlasIcon 
                              svgHref={"atlas-question-circle"}
                              style={{
                                height: 20,
                                width: 20,
                                color: "#838383",
                                marginLeft: 0
                              }}/>
                          </span>
                        </AtTooltip>
                      )}
                    </span>
                  )}
                </h2>
                <div>
                  <AtlasFormInput
                    type="number"
                    step={0.1}
                    className={className}
                    required={required}
                    disabled={closedEditing || disabled}
                    value={`${data[value]}`}
                    onChangeFocus={() => onChangeFocus(value)}
                    onChangeBlur={() => onChangeBlur(value)}
                    onChangeValue={(val) =>
                      onChange(value, validationCheck(+val, "number"))
                    }
                  />
                </div>
              </li>
            );
          }
          if (type === "currency") {
            return (
              <li
                key={index}
                className={`at-form_mngmt-form_list grid-${col || "half"}-col`}
                style={style}>
                <h2 className={`at-form-input__title ${labelClass}`} required={required}>
                  {label}
                  {automated && (
                    <AtTooltip
                      classes={{ tooltip: "tooltip-arrow right" }}
                      placement={"right"}
                      enterTouchDelay={0}
                      title={"Auto calculated"}>
                      <span>
                        {autoCalculatedList.indexOf(value) > -1 && (
                          <BiCalculator
                            className={`at-form_mngmt-label_icon at-form_mngmt-icon_automated_signal`}
                          />
                        )}
                        {autoCalculatedList.indexOf(value) < 0 && (
                          <BiCalculator
                            className={`at-form_mngmt-label_icon`}
                          />
                        )}
                      </span>
                    </AtTooltip>
                  )}
                </h2>
                <div>
                  <AtCurrecyInput
                    style={{ paddingLeft: "0.2rem" }}
                    context={value}
                    required={required}
                    disabled={closedEditing || disabled}
                    value={`${data[value]}`}
                    onChangeFocus={() => onChangeFocus(value)}
                    onChangeBlur={() => onChangeBlur(value)}
                    onChange={(val, cont) => {
                      onChange(value, validationCheck(val, "number"));
                    }}/>
                </div>
              </li>
            );
          }

          if (type === "select") {
            return (
              <li
                key={index}
                className={`at-form_mngmt-form_list grid-${col || "half"}-col`}>
                <h2 className={`at-form-input__title ${labelClass}`} required={required}>
                  {label}
                </h2>
                <div>
                  <AtlasSelect
                    required={required}
                    disabled={closedEditing || disabled}
                    value={data[value] || ""}
                    onChange={(e) => onChange(value, e.target.value)}>
                    {options &&
                      options.length > 0 &&
                      options.map((item) => (
                        <MenuItem
                          classes={{ root: `at-select__dropdown-item` }}
                          key={item.id}
                          value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </AtlasSelect>
                  {contents &&
                    contents.map((contentItem) => (
                      <Collapse
                        key={contentItem.id}
                        unmountOnExit
                        timeout="auto"
                        in={contentItem.show && contentItem.show(data)}
                      >
                        {contentItem.renderContent &&
                          contentItem.renderContent({
                            data,
                            onChange,
                            can_update: !closedEditing,
                          })}
                      </Collapse>
                    ))}
                </div>
              </li>
            );
          }

          if (type === "date") {
            return (
              <li
                key={index}
                className={`at-form_mngmt-form_list grid-${col || "half"}-col`}>
                <h2 className={`at-form-input__title ${labelClass}`} required={required}>
                  {label}
                </h2>
                {!closedEditing && (
                  <AtlasFormGroup
                    type={"calendar"}
                    required={required}
                    disabled={disabled}
                    placeholder={placeholder || "Select date"}
                    value={data[value] || null}
                    onSelectDate={(val) => {
                      onChange(
                        value,
                        (val && val !== null)
                          ? Moment(val).format("YYYY-MM-DD")
                          : '',
                      );
                    }}
                  />
                )}
                {closedEditing && (
                  <p>
                    {  
                      Moment(data[value]).isValid()
                        ? Moment(data[value]).format("DD MMM YYYY")
                        : (data[value] ?? "N/A")
                    }
                  </p>)
                }
              </li>
            );
          }

          if (type === "radio") {
            return (
              <li
                key={index}
                className={`at-form_mngmt-form_list grid-${col || "half"}-col`}>
                <h2 className={`at-form-input__title ${labelClass}`} required={required}>
                  {label}
                </h2>
                <div>
                  <AtlasRadioGroup
                    required={required}
                    horizontal={horizontal}
                    mode={value === "disbursement_type_id" ? "old" : "new"}
                    disabled={closedEditing || disabled}
                    options={options}
                    checkedValue={data[value]}
                    selectedRadioValue={(val) => onChange(value, +val)}
                  />
                  {contents &&
                    contents.map((contentItem, index) => (
                      <Collapse
                        key={index}
                        unmountOnExit
                        timeout="auto"
                        in={contentItem.show && contentItem.show(data)}
                      >
                        {contentItem.renderContent &&
                          contentItem.renderContent({
                            data,
                            onChange,
                            onChangeFocus,
                            onChangeBlur,
                            can_update: !closedEditing,
                          })}
                      </Collapse>
                    ))}
                </div>
              </li>
            );
          }

          if (type === "autosuggest") {
            return (
              <li
                key={index}
                className={`at-form_mngmt-form_list grid-${col || "half"}-col`}
                style={{ width: "100%" }}>
                <h2 className={`at-form-input__title ${labelClass}`} required={required}>
                  {label}
                </h2>
                <div>
                  <AtlasAutosuggest
                    required={required}
                    placeholder={placeholder}
                    value={data[value] || ""}
                    updateValue={(val) => onChange(value, val)}
                    options={options}
                    disabled={closedEditing || disabled}
                  />
                </div>
              </li>
            );
          }

          if (type === "custom") {
            return renderCustomForm({
              index,
              data,
              autoCalculatedList,
              can_update: !closedEditing,
              onChangeFocus,
              onChangeBlur,
              onChange,
            });
          }
        })}
    </div>
  );
};

export default FormDialog;
