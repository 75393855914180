import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";

import SubsaleClaimForm from "./components/form";
import LoadingModal from "components/LoadingModal";

import AgenciesHOC from "../../actions/agency";
import SubsaleAdminHOC from "../../actions/admin";
import ListingHOC from "../../actions/listing";
import NationalityHOC from "../../actions/nationality";

class ClaimForm extends Component {
  state = {
    toRemovePaymentID: 0,
    showPreviewClaimModal: false,
    showConfirmationModal: false,
    unsavedChanges: false,
  };

  componentDidMount = () => {
    this.props.getBanks();
    this.props.getAgencies();
  };

  onClickPreviewClaimForm = () =>
    this.setState({ showPreviewClaimModal: true });

  onCloseClaimPreviewModal = () =>
    this.setState({ showPreviewClaimModal: false });

  render = () => {
    const {
      banks,
      agencies,
      selected_claim,
      original_claim,
      showTransferModal,

      onLoadSubsaleClaims,
      onLoadAgencies,
      onLoadNationalities,

      updateSelectedClaim,
      onChangeClaimsHOC,
      transferConfirmation,
      onChangeUpdateSelectedClaim,
    } = this.props;
    return (
      <>
        <SubsaleClaimForm
          {...this.props}
          showTransferModal={showTransferModal}
          agencies={agencies}
          banks={banks}
          storedAddresses={this.props.data.addressReducer.address}
          selected_claim={selected_claim}
          original_claim={original_claim}
          menuExpand={this.props.data.sideMenuReducer.sideMenuExpand}
          transferConfirmation={transferConfirmation}
          unsavedChanges={this.state.unsavedChanges}
          onLoadSubsaleClaims={onLoadSubsaleClaims}
          onCloseForm={this.props.onCloseForm}
          onChangeUpdateSelectedClaim={(key, val) => {
            onChangeUpdateSelectedClaim(key, val);
            this.setState({ unsavedChanges: true });
          }}
          onClickPreviewClaimForm={this.onClickPreviewClaimForm}
          onClickSubmitClaim={this.onClickSubmitClaim}
          onClickNav={this.props.onSelectSection}
          updateSelectedClaim={(id, dataToSubmit, enableExternalAgentBankValidation) => {
            updateSelectedClaim(id, dataToSubmit, enableExternalAgentBankValidation);
            this.setState({ unsavedChanges: false });
          }}
          onChangeClaimsHOC={onChangeClaimsHOC}
        />
        {(onLoadSubsaleClaims || onLoadAgencies || onLoadNationalities) && (
          <LoadingModal />
        )}
      </>
    );
  };
}

export default compose(
  AgenciesHOC,
  SubsaleAdminHOC,
  NationalityHOC,
  ListingHOC,
)(ClaimForm);
