import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { initGA } from "utils/ga";
import { verify_otp } from "actions/login";

class LoginVerifyOTP extends Component {
  state = {
    errorMessage: "",
    ajaxCallProgress: 0,
    ajaxErrorMessage: "",
    from: "",
  };

  componentDidMount = () => {
    if (window.location.host.indexOf("atlas.iqiglobal.com") > -1) {
      initGA("/login-otp");
    }
    this.checkLoginToken();
  };

  componentDidUpdate = (prevProps) => {
    let { ajaxCallProgress, ajaxSuccess, ajaxError } =
      this.props.data.ajaxStatusReducer;
    if (
      ajaxCallProgress !== prevProps.data.ajaxStatusReducer.ajaxCallProgress
    ) {
      if (ajaxCallProgress === 0) {
        if (ajaxError) {
          alert("Your OTP session has expired, please login again.");
          this.props.history.push("/login");
        }
        if (ajaxSuccess) {
          this.props.history.push("/dashboard/statistic");
        }
      }
    }
  };

  ajaxError = (message) => this.setState({ errorMessage: message });

  checkLoginToken = () => {
    const hashParams = new URLSearchParams(window.location.hash.split("?")[1]);
    const temp_token = hashParams.get("temp_token");
    const source = hashParams.get("source");
    const otp = hashParams.get("otp");

    if (source === 'app') {
      const a = document.createElement('a');
      a.href = `atlasmobile://app/login/${otp}/${temp_token}`;
      a.click();
    } else {
      this.props.verify_otp({ temp_token, otp });
    }
  };

  render = () => {
    return <div></div>;
  };
}

const mapStateToProps = (state) => ({ data: state });

export default compose(connect(mapStateToProps, { verify_otp }))(
  LoginVerifyOTP
);
