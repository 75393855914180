import FormInputs from "../formInputs";

import { getTranslation } from "../../assets";

const PersonalInfoForm = ({
  tmpProfileReducer,
  branch_option,
  branch_name,
  onChangeField,
  updateNewAgent,
}) => {
  const language = tmpProfileReducer?.country_id === 14 ? "jp" : "en";

  const inputConfig = [
    {
      label: getTranslation("display_name", language),
      id: "display_name",
      placeholder: getTranslation("display_name_placeholder", language),
      pattern: /^[a-zA-Z\s]*$/,
    },
    {
      type: "select",
      label: getTranslation("gender", language),
      id: "gender_id",
      options: [
        { id: 1, value: getTranslation('male', language) },
        { id: 2, value: getTranslation('female', language) },
      ],
    },
    {
      type: "date",
      label: getTranslation("date_of_birth", language),
      id: "date_of_birth",
    },
    {
      type: "select",
      label: "Race",
      id: "race_id",
      hidden: tmpProfileReducer?.country_id === 14,
      options: [
        { id: 1, value: "Malay" },
        { id: 2, value: "Chinese" },
        { id: 3, value: "Indian" },
        { id: 4, value: "Others" },
      ],
    },
    {
      type: "select",
      label: getTranslation("marital_status", language),
      id: "marital_status_id",
      options: [
        { id: 1, value: getTranslation('single', language) },
        { id: 2, value: getTranslation('married', language) },
        { id: 3, value: getTranslation('divorced', language) },
      ],
    },
    branch_name
      ? {
          type: "custom-output",
          label: getTranslation("branch_name", language),
          id: "branch_name",
          content: () => <p>{branch_name}</p>,
          disabled: true,
        }
      : {
          type: "select",
          label: getTranslation("branch_name", language),
          id: "branch_id",
          options: branch_option.map(option => ({ ...option, value: option.name })),
        },
  ];

  return (
    <>
      <p className={"mb-4"}>{getTranslation("agent.profile.please_complete", language)}</p>
      <div className={"container-fluid p-0"}>
        <FormInputs
          payload={updateNewAgent}
          inputConfig={inputConfig}
          onChange={onChangeField}
        />
      </div>
    </>
  );
};

export default PersonalInfoForm;
