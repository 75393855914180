import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import { IoMdAdd } from "react-icons/io";

import Tab from "components/Tab";
import CustomCard from "components/Card";
import AtlasDialog from "components/Dialog";
import LoadingModal from "components/LoadingModal";
import ModuleHeader from "components/ModuleHeader";
import ModalDialog from "components/Modal/ModalDialog";
import AtPaginatedTable from "components/NewPaginatedTable";
import SalesForm from "./component/saleForms";
import ViewSale from "./component/viewSale";
import EditContent from "./component/Edit";

import CobrokeSalesHOC from "./actions/cobroke";
import ReportNewSalesHOC from "./actions";
import { numberWithCommas } from "utils/thousandSeparator";
import { StatusColor } from "./assets";
import { getColorBadge } from "dictionary/badgeColor";

const ColumnData = [
  {
    header: "Date",
    accessor: "booking_date",
    columnStyle: { width: "70%" },
  },
  {
    header: "Project",
    accessor: "project_name",
    columnStyle: { width: "140%" },
  },
  { header: "Unit Number", accessor: "unit_number" },
  { header: "Buyer", accessor: "buyer_name" },
  {
    header: "Net Price",
    contentClass: "at-column-responsive_right",
    columnClass: "at-column-responsive_right",
    renderColumn: (rowData) => (
      <p className="text-lg-right">{numberWithCommas(rowData.nett_price)}</p>
    ),
  },
  {
    header: "Status",
    contentClass: "at-column-responsive_center",
    customClass: "at-column-responsive_center",
    renderColumn: (rowData) => {
      const { colorName = "" } =
        _.find(StatusColor, { value: rowData.status }) || {};

      return (
        <div
          className="at-status_badge"
          style={{ ...getColorBadge(colorName) }}
        >
          {rowData.status}
        </div>
      );
    },
    columnStyle: { width: "150%" },
  },
];

class ReportNewSales extends Component {
  componentDidMount = () => {
    this.props.getCountryDictionary();
    this.props.getReportSales(1, "");
    this.props.getCobrokeSale(1, "");
  };

  componentDidUpdate = (pp) => {
    const { showEditDialog, showCreateDialog } = this.props;

    if (pp.showEditDialog !== showEditDialog && !showEditDialog) {
      this.props.onChangeSalesHOC(false, "formFileChanges");
      this.props.onChangeSalesHOC(false, "unsavedChanges");
    }
    if (pp.showCreateDialog !== showCreateDialog && !showCreateDialog) {
      this.props.onChangeSalesHOC(false, "formFileChanges");
      this.props.onChangeSalesHOC(false, "unsavedChanges");
    }
  };

  renderTable = () => {
    if (this.props.tabsContent === "My Project Sale") {
      return (
        <AtPaginatedTable
          columns={ColumnData}
          actionColumnContent={[
            {
              name: "edit",
              onClick: (rowData) => this.props.getSelectedSales(rowData.id),
            },
          ]}
          rowData={this.props.reportSales.data}
          meta={this.props.reportSales.meta}
          searchParams={this.props.searchParams}
          onChangeSearchParams={(val) =>
            this.props.onChangeSalesHOC(val, "searchParams")
          }
          getListAPI={this.props.getReportSales}
          totalPages={this.props.reportPage}
        />
      );
    } else {
      return (
        <AtPaginatedTable
          columns={ColumnData}
          actionColumnContent={[
            {
              name: "view",
              onClick: (rowData) =>
                this.props.getSelectedCobrokeSale(rowData.id),
            },
          ]}
          rowData={this.props.cobrokeSale.data}
          meta={this.props.cobrokeSale.meta}
          searchParams={this.props.cobrokeSearchParams}
          onChangeSearchParams={(val) =>
            this.props.onChangeCobrokeSaleHOC(val, "cobrokeSearchParams")
          }
          getListAPI={this.props.getCobrokeSale}
          totalPages={this.props.cobrokeSalePage}
        />
      );
    }
  };

  renderCardContent = () => {
    return (
      <>
        <Tab
          sections={["My Project Sale", "My Co-broked Sale"]}
          selectedSection={this.props.tabsContent}
          onSelectSection={(val) =>
            this.props.onChangeSalesHOC(val, "tabsContent")
          }
        />
        {this.renderTable()}
      </>
    );
  };

  render = () => {
    const {
      onLoadSales,
      onLoadRegion,
      onCobrokeLoad,

      showCreateDialog,
      showEditDialog,
      showViewDialog,
    } = this.props;
    const { project_name, unit_number } = (this.props.tabsContent === "My Project Sale" 
      ? this.props.selectedProjectSale 
      : this.props.selectedCobroke
    ) || {};

    return (
      <>
        <ModuleHeader
          title={"New Project Sales"}
          atlasIcon={"atlas-project-sales"}
          description={
            "Add and manage your new project sales here. When you’ve done a new project sale,you should report it as quickly as possible by clicking the “new” icon above. Enter all the details for the unit, upload a copy of the booking form andsubmit for the admin team to approve."
          }
          actionButton={[
            {
              label: "New Project Sale",
              onShow: true,
              className: "btn-new btn-new--primary",
              icon: <IoMdAdd style={{ height: 18, width: 18 }} />,
              onClick: () =>
                this.props.onChangeSalesHOC(true, "showCreateDialog"),
            },
          ]}
        />
        <CustomCard cardContent={this.renderCardContent()} />
        {showCreateDialog && (
          <ModalDialog
            title={"New Project Sale"}
            children={
              <SalesForm
                {...this.props}
                formMode={"create"}
                onClickSubmit={this.props.createReportSale}
                onClose={() =>
                  this.props.onChangeSalesHOC(false, "showCreateDialog")
                }
              />
            }
            onClose={() =>
              this.props.onChangeSalesHOC(false, "showCreateDialog")
            }
          />
        )}
        {showEditDialog && (
          <ModalDialog
            removeFooter
            title={`Project Sale - ${project_name || "N/A"} - ${unit_number || "N/A"}`}
            fullWidth={true}
            fullHeight={true}
            children={
              <EditContent 
                {...this.props} 
                selectedSale={this.props.selectedProjectSale}/>}
            onClose={() => this.props.onChangeSalesHOC(false, "showEditDialog")}
          />
        )}
        {showViewDialog && (
          <ModalDialog
            removeFooter
            title={`Project Sale - ${project_name || "N/A"} - ${unit_number || "N/A"}`}
            fullWidth={true}
            fullHeight={true}
            children={
              <ViewSale 
                {...this.props} 
                selectedSale={this.props.selectedCobroke} />
            }
            onClose={() =>
              this.props.onChangeCobrokeSaleHOC(false, "showViewDialog")
            }
          />
        )}
        {(onLoadSales || onLoadRegion || onCobrokeLoad) && <LoadingModal />}
      </>
    );
  };
}

export default compose(ReportNewSalesHOC, CobrokeSalesHOC)(ReportNewSales);
