import React, { Component } from "react";
import { Dialog, MenuItem } from "@material-ui/core";
import { FaUserTie } from "react-icons/fa";
import _ from "lodash";

import AtlasTable from "components/NewPaginatedTable";
import ContactFormDialog from "../components/formDialog";
import LoadingModal from "components/LoadingModal";
import DeleteConfirmationModal from "components/Modal/delete";
import CustomDropdown from "components/Button/dropdown";
import AtlasIcon from "components/Icon/atlasIcon";

import permissionsChecker from "utils/permissionsChecker";
import { getColorBadge } from "dictionary/badgeColor";
import {
  subsaleRepresentativeBadge,
  subrentRepresentativeBadge,
} from "containers/Dashboard/Administrator/SubsalesClaimsBeta/assets";
import "stylesheets/containers/subsales-claims/index.scss";

const representativeEntity = (representative_id, sub_sale_type_id) => {
  if (representative_id !== null) {
    const tmpRepresentative =
      sub_sale_type_id === 1
        ? _.find(subsaleRepresentativeBadge, { value: representative_id })
        : _.find(subrentRepresentativeBadge, { value: representative_id });
    return tmpRepresentative || {};
  }

  return {};
}

const columnData = [
  {
    header: "Full Name",
    renderColumn: (rowData) => (
      <div className={"d-flex align-items-center party-card__badge-title-icon at-subsales-contact_form_type-title-icon"}>
        {rowData.contact_type_id === "2" ? (
          <>
            <AtlasIcon svgHref={"atlas-building-company"}/>
            <div className={"ml-1"}>
              {rowData.full_name}
              <br />({rowData.nric})
            </div>
          </>
        ) : (
          <>
            {rowData.contact_type_id === "1" ? <AtlasIcon svgHref={"atlas-icon-user"}/> : <FaUserTie />}
            {rowData.full_name}
          </>
        )}
      </div>
    ),
  },
  { header: "Email", accessor: "email" },
  { header: "NRIC/Passport No.",
    renderColumn: ({contact_type_id, nric}) => (contact_type_id === "2" ? "N/A" : nric),
  },
  { header: "Mobile/Office Contact No.", accessor: "contact_number" },
];

class Referral extends Component {
  state = {
    showConfirmationModal: false,
    toRemoveItem: {},
  };

  componentDidMount = () => {
    const { id: opposite_claim_id } =
      this.props.selected_claim.opposite_claim ?? {};

    this.props.getAssignedContactType({
      claim_id: this.props.selected_claim.id,
      type_id: 4,
      isPrimaryClaim: true,
    });

    if (opposite_claim_id) {
      this.props.getAssignedContactType({
        claim_id: opposite_claim_id,
        type_id: 4,
        isPrimaryClaim: false,
      });
    }
  };

  componentDidUpdate = (pp) => {
    const { id: opposite_claim_id } =
      this.props.selected_claim.opposite_claim ?? {};

    if (this.props.internalAgentCount !== pp.internalAgentCount) {
      if (this.props.referrals.length > 0) {
        this.props.getAssignedContactType({
          claim_id: this.props.selected_claim.id,
          type_id: 4,
          isPrimaryClaim: true,
        });
        if (opposite_claim_id) {
          this.props.getAssignedContactType({
            claim_id: opposite_claim_id,
            type_id: 4,
            isPrimaryClaim: false,
          });
        }
      }
    }
  };

  onSubmitData = (newContact) => {
    let tmp = {
      referral: newContact,
    };
    this.props.createEntity(tmp, this.props.selected_claim.id, 4);
  };

  onSubmitUpdatedData = (data) =>
    this.props.submitUpdatedData(this.props.selected_claim.id, data);

  onClickEditProfile = (id, data, isPrimaryParty) =>
    this.props.getSelectedEntity(id, 4, data.id, isPrimaryParty);

  onClickSaveReferralCard = (data) =>
    this.props.updateSelectedEntity(
      data,
      this.props.selected_claim.id,
      4,
      data.referral.id,
    );

  onClickRemoveReferralCard = (data) =>
    this.setState({ showConfirmationModal: true, toRemoveItem: data });

  deleteSelectedItem = () =>
    this.setState({ showConfirmationModal: false }, () => {
      this.props.removeSelectedEntity(
        this.props.selected_claim.id,
        this.state.toRemoveItem.type_id,
        this.state.toRemoveItem.id,
      );
    });

  closeConfirmationModal = () =>
    this.setState({ showConfirmationModal: false });

  renderEditDialog = () => {
    if (this.props.showEditEntityDialog) {
      return (
        <Dialog open={true} maxWidth={"lg"} classes={{ paper: "m-0" }}>
          <ContactFormDialog
            title={"Edit"}
            titleContext={"Referral"}
            formTypes={this.props.formTypes([
              {
                label: "Internal Agent",
                value: "3",
              },
            ])}
            nationalities={this.props.nationalities}
            banks={this.props.banks}
            personalForms={this.props.personalForms}
            companyForms={this.props.companyForms}
            loading={this.props.onLoadContactType}
            onToggleDialog={() =>
              this.props.onChangeContactTypeHOC(false, `showEditEntityDialog`)
            }
            onChangeValue={this.props.onChangeEditContact}
            submitData={this.onSubmitUpdatedData}
            storedAddresses={this.props.data.addressReducer.address}
            selectedClaim={this.props.selected_claim}
            selectedContact={this.props.selectedContact}
            onChangeContactTypeHOC={this.props.onChangeContactTypeHOC}
          />
        </Dialog>
      );
    }
  };

  renderNewDialog = () => {
    if (this.props.showNewEntityDialog) {
      return (
        <Dialog open={true} maxWidth={"lg"} classes={{ paper: "m-0" }}>
          <ContactFormDialog
            title={"Add"}
            titleContext={"Referral"}
            formTypes={this.props.formTypes([
              {
                label: "Internal Agent",
                value: "3",
              },
            ])}
            nationalities={this.props.nationalities}
            banks={this.props.banks}
            allFormItems={this.props.referrals}
            onLoadAgent={this.props.onLoadAgent}
            agentList={this.props.agentList}
            personalForms={this.props.personalForms}
            companyForms={this.props.companyForms}
            onToggleDialog={() => {
              this.props.onChangeContactTypeHOC(false, `showNewEntityDialog`)
              this.props.onChangeAgentHOC([], "agentList");
            }}
            submitData={this.onSubmitData}
            getAgentsBySearch={this.props.getAgentsBySearch}
            onChangeAgentHOC={this.props.onChangeAgentHOC}
            storedAddresses={this.props.data.addressReducer.address}
            onChangeContactTypeHOC={this.props.onChangeContactTypeHOC}
          />
        </Dialog>
      );
    }
  };

  renderTableHeading = (claim_identity, can_create) => {
    const { representative_id } = this.props.selected_claim ?? {};
    const { representative_id: opposite_representative_id } = this.props.selected_claim.opposite_claim ?? {};

    const representativeId = claim_identity === "primary" ? representative_id : opposite_representative_id;

    const { sub_sale_type_id } = this.props.selected_claim;
    const { label: representativeLabel, badgeColor: claimBadgeColor } = representativeEntity(representativeId, sub_sale_type_id);

    let labelChecking = false
    if(claim_identity === "primary") {
      labelChecking = (representative_id && representative_id !== null) && (opposite_representative_id && opposite_representative_id !== null)
    } else {
      labelChecking = (opposite_representative_id && opposite_representative_id !== null) 
    }
    if (can_create) {
      return (
        <div className={`at-table_heading`}>
          {
            labelChecking && (
              <>
                <div
                  className="at-status_badge text-center"
                  style={{ ...getColorBadge(claimBadgeColor) }}>
                  {representativeLabel}
                </div>
              </>
            )
          }
          {can_create && this.renderDropDown("Referral")}
        </div>
      );
    }
  };

  onClickSelectFromContacts = () => {
    this.props.updateSelectedParticipantTypeId(4)
    this.props.updateSelectedTitle("Referral")
    this.props.onChangeClaimsHOC("showAssignContactModal", true)
  }

  renderDropDown = () => {
    return (
      <CustomDropdown label={"Add Referral"}>
        <MenuItem
          onClick={() => this.onClickSelectFromContacts()}
          classes={{ root: `at-btn__dropdown--list` }}>
          Select from My Contact
        </MenuItem>
        <MenuItem
          onClick={() => this.props.onChangeContactTypeHOC(true, "showNewEntityDialog")}
          classes={{ root: `at-btn__dropdown--list` }}>
          Create a New Contact
        </MenuItem>
      </CustomDropdown>
    );
  }

  render = () => {
    const { referrals, opposite_claim_referrals } = this.props;
    const { can_create, can_update, can_destroy } = permissionsChecker(
      "Subsales Claims",
      this.props.data,
    );

    const { representative_id } = this.props.selected_claim ?? {};
    const { representative_id: opposite_representative_id } = this.props.selected_claim.opposite_claim ?? {};

    return (
      <>
        <AtlasTable
          columns={columnData}
          meta={[]}
          searchParams={[]}
          totalPages={["1"]}
          rowData={referrals || []}
          actionColumnContent={
            can_update || can_destroy
              ? [
                  {
                    name: "edit",
                    onShow: () => can_update,
                    onClick: (rowData) =>
                      this.onClickEditProfile(
                        this.props.selected_claim.id,
                        rowData,
                        true
                      )
                  },
                  {
                    name: "delete",
                    color: "#F04438",
                    onShow: () => can_destroy,
                    onClick: (rowData) => this.onClickRemoveReferralCard(rowData),
                  }
                ]
              : []
          }
          renderExtraContent={() => this.renderTableHeading("primary", can_create)}
          customEmptyState={{
            title: "No referrals available",
            description: (
              <>Click<b>Add Referral</b> to create referrals</>
            ),
            icon: "icon-user",
          }}
        />
        {
          opposite_representative_id && (
            <AtlasTable
              wrapperClassName="mt-3"
              columns={columnData}
              meta={[]}
              searchParams={[]}
              totalPages={["1"]}
              rowData={opposite_claim_referrals || []}
              actionColumnContent={[]}
              renderExtraContent={() => this.renderTableHeading("opposite", false)}
              customEmptyState={{
                title: "No referrals available",
                icon: "icon-user",
              }}
            />
          )
        }
        {this.renderNewDialog()}
        {this.renderEditDialog()}
        {this.props.onLoadContactType && <LoadingModal />}
        <DeleteConfirmationModal
          open={this.state.showConfirmationModal}
          loading={this.props.onLoadContactType}
          positiveAction={this.deleteSelectedItem}
          negativeAction={this.closeConfirmationModal}
          message={`You will not able to recover the data`}
        />
      </>
    );
  };
}    

export default (Referral);
