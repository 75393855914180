import React, { useEffect, useRef, useState } from 'react'
import { FiEdit } from "react-icons/fi";
import _ from 'lodash'

import CustomCard from "components/Card";
import AtlasIcon from "components/Icon/atlasIcon";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import AtPaginatedTable from "components/NewPaginatedTable";
import DeleteConfirmation from "components/Modal/confirmation";
import ModifyTranslationList from './components/modifyTranslationList';
import ImportTranslationList from "./components/importTranslationList";
import BulkUpdate from './components/BulkUpdate';
import Settings from "./components/Settings";
import CreateTranslation from "./Create";
import UpdateTranslation from './Update';

import WithHOC from "./actions";
import "./index.scss";

const columnData = [
  {
    header: "Module",
    accessor: "module_name",
    label: "Module"
  },
  {
    header: "Key",
    accessor: "key",
    label: "Key"
  },
  {
    header: "Default Translation",
    accessor: "default_translation",
    label: "Default Translation"
  },
  {
    header: "Localised Language",
    renderColumn: (rowData) => {
      if (rowData.localised_language) {
        return Object.keys(rowData.localised_language).map((key, index) => key?.toUpperCase()).join(", ");
      } else {
        return "-"
      }
    },
    label: "Localised Language"
  },
]

const LocalisedCentre = props => {
  const parentRef = useRef();
  const dropDownRef = useRef();
  const [showDropDown, setShowDropDown] = useState(false);
  const { role } = window.location.href.includes("/admin-impersonate")
    ? props.data.currentSignInProfileReducer
    : props.data.profileReducer;

  useEffect(() => {
    props.getModules();
    props.getLocalisedCentre(1, "");
    props.getAllLocalisedSetting();

    const parent = parentRef.current;
    parent.addEventListener("click", (handleOnClose), true);
    return () => {
      parent.removeEventListener("click", handleOnClose);
    };

  }, []);

  useEffect(() => {
    if (props.modules?.length > 0) {
      let temp = _.cloneDeep(props.searchParams)
      temp[0].options = props.modules
      props.onChangeLocalisedHOC(temp, "searchParams")
    }
  }, [props.modules])

  const handleOnClose = (event) => {
    if (event.target?.id !== 'bulk-update-btn') {
      setShowDropDown(false)
    }
  };

  const onClickBulkUpdate = (param) => Promise.all([
    props.onChangeLocalisedHOC(param, "selectedTranslation")
  ]).then(() => {
    setShowDropDown(false)
    props.onChangeLocalisedHOC(true, "showBulkUpdate")
  })

  const renderDropDown = () => (
    <div
      ref={dropDownRef}
      className={"at-party__dropbear--modal-root"}
      style={{display: showDropDown ? "block" : "none"}}>
      <div className={`at-party__dropbear--list`}>
        {props.settings?.map((setting, index) => (
          <button key={index} onClick={() => onClickBulkUpdate(setting)}>
            {`${setting.language_name}(${setting.language_code})`}
          </button>
        ))}
      </div>
    </div>
  )

  const renderCheckBoxActions = (rowData) => {
    return (
      <div className="at-localised-center_table-title">
        <div className='d-flex align-items-center g-2'>
          <div
            className="at-renewal-details__icon-badge"
            style={{ backgroundColor: "#FFEDD5" }}
          >
            <AtlasIcon
              svgHref={"atlas-translate-linear"}
              style={{ fill: "#EA580C" }}
            />
          </div>
          <p style={{ fontSize: 16, fontWeight: 500, color: "#111827" }}>
            Translation List
          </p>
        </div>
        <div className='d-flex align-items-center g-2'>
          <button
            id={'bulk-update-btn'}
            className='btn-new btn-new--secondary position-relative'
            onClick={() => setShowDropDown(!showDropDown)}>
            <FiEdit style={{ height: 18, width: 18 }} />
            Bulk Update
            {renderDropDown()}
          </button>
          <button
            className='btn-new btn-new--outline-secondary'
            onClick={() => props.onChangeLocalisedHOC(true, "showModifyTranslation")}>
            <AtlasIcon svgHref={'atlas-export-linear'} style={{ height: 18, width: 18 }} />
            Import Translation
          </button>
        </div>
      </div>
    )
  }

  return (
    <>
      <ModuleHeader
        title={"Localised Centre"}
        atlasIcon={"atlas-translate"}
        actionButton={[
          {
            label: "New Translation",
            onShow: true,
            className: "btn-new btn-new--primary",
            icon: <AtlasIcon svgHref={'atlas-add-linear'} style={{ height: 18, width: 18, fill: 'white' }} />,
            onClick: () => props.onChangeLocalisedHOC(true, "showCreateModal"),
          },
          {
            label: "Import List",
            onShow: true,
            className: "btn-new btn-new--secondary",
            icon: <AtlasIcon svgHref={'atlas-export-2-linear'} style={{ height: 18, width: 18, fill: 'white' }} />,
            onClick: () => props.onChangeLocalisedHOC(true, "showImportTranslation"),
          },
          {
            label: "Settings",
            onShow: true,
            className: "btn-new btn-new--outline-secondary",
            icon: <AtlasIcon svgHref={'atlas-setting-2'} style={{ height: 18, width: 18 }} />,
            onClick: () => props.getAllLocalisedSetting(true),
          },
        ]}
         />
      <CustomCard ref={parentRef} cardContent={
        <AtPaginatedTable
          columns={columnData}
          actionColumnContent={[
            {
              name: "edit",
              onClick: (rowData) => props.getSelectedLocalised(rowData.id),
            },
            ...(role === "Super Admin"
              ? [
                  {
                    name: "delete",
                    color: "#F04438",
                    onClick: (rowData) =>
                      Promise.all([
                        props.onChangeLocalisedHOC(rowData, "selectedLocalised"),
                      ]).then(() => {
                        props.onChangeLocalisedHOC(true, "showDeleteConfirmation");
                      }),
                  },
                ]
              : []),
          ]}
          rowData={props.localisedData?.data ?? []}
          meta={props.localisedData?.meta ?? {}}
          onChangeSearchParams={(val) => props.onChangeLocalisedHOC(val, "searchParams")}
          searchParams={props.searchParams || []}
          getListAPI={props.getLocalisedCentre}
          totalPages={props.localisedPages}
          renderCheckBoxActions={renderCheckBoxActions}
        />
      } />

      {props.showSettingsModal && <Settings {...props} /> }
      {props.showCreateModal && <CreateTranslation {...props} /> }
      {props.showUpdateModal && <UpdateTranslation {...props} /> }
      {props.showBulkUpdate && <BulkUpdate {...props} /> }
      {props.showImportTranslation && <ImportTranslationList {...props} /> }
      {props.showModifyTranslation && <ModifyTranslationList {...props} /> }
      <DeleteConfirmation
        open={props.showDeleteConfirmation}
        title={"Delete Translation"}
        message={"Are you sure you want to delete this translation?"}
        negativeAction={() =>
          props.onChangeLocalisedHOC(false, "showDeleteConfirmation")
        }
        positiveAction={() =>
          props.deleteTranslation()
        }
      />

      {props.onLoadLocalised && <LoadingModal />}
    </>
  )
}

export default WithHOC(LocalisedCentre);