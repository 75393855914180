export const OccupationOptions = [
  { value: "Accounting", label: "Accounting" },
  {
    value: "Administration & Office Support",
    label: "Administration & Office Support",
  },
  { value: "Advertising, Arts & Media", label: "Advertising, Arts & Media" },
  {
    value: "Banking & Financial Services",
    label: "Banking & Financial Services",
  },
  {
    value: "Call Centre & Customer Service",
    label: "Call Centre & Customer Service",
  },
  {
    value: "Community Services & Development",
    label: "Community Services & Development",
  },
  { value: "Construction", label: "Construction" },
  { value: "Consulting & Strategy", label: "Consulting & Strategy" },
  { value: "Design & Architechture", label: "Design & Architechture" },
  { value: "Education & Training", label: "Education & Training" },
  { value: "Engineering", label: "Engineering" },
  {
    value: "Farming, Animals & Conservation",
    label: "Farming, Animals & Conservation",
  },
  { value: "Food and Beverage", label: "Food and Beverage" },
  { value: "Government & Defence", label: "Government & Defence" },
  { value: "Healthcare & Medical", label: "Healthcare & Medical" },
  { value: "Hospitality & Tourism", label: "Hospitality & Tourism" },
  {
    value: "Human Resources & Recruitment",
    label: "Human Resources & Recruitment",
  },
  {
    value: "Information & Communication Technology",
    label: "Information & Communication Technology",
  },
  { value: "Insurance & Superannuation", label: "Insurance & Superannuation" },
  { value: "Legal", label: "Legal" },
  {
    value: "Manufacturing, Transport & Logistics",
    label: "Manufacturing, Transport & Logistics",
  },
  { value: "Marketing & Communications", label: "Marketing & Communications" },
  { value: "Mining, Resources & Energy", label: "Mining, Resources & Energy" },
  { value: "Real Estate & Property", label: "Real Estate & Property" },
  { value: "Retail & Consumer Products", label: "Retail & Consumer Products" },
  { value: "Retired", label: "Retired" },
  { value: "Sales", label: "Sales" },
  { value: "Science & Technology", label: "Science & Technology" },
  { value: "Sport & Recreation", label: "Sport & Recreation" },
  { value: "Trades & Services", label: "Trades & Services" },
  { value: "Unemployed", label: "Unemployed" },
];

export const FormData = ({
  banks,
  genders,
  company,
  nationalities,
}) => [
  ...(company
    ? [
      {
        value: "company",
        label: "Company Name",
        placeholder: "Enter Company Name",
        required: true,
        className: "mycontacts-radiogrp-companyfield",
        section: 1
      },
      {
        value: "company_registration_number",
        label: "Company Reg. No. (12 Digits)",
        placeholder: "e.g. 202005123456",
        required: true,
        className: "mycontacts-radiogrp-companyregnumberfield",
        section: 1
      },
      { value: "job_title", required: false, label: "Representative's Designation", placeholder: "Enter Representative's Designation", section: 1 },
      { value: "full_name", label: "Representative's Name", placeholder: "Enter Representative's Name", required: true, section: 1 },
      { value: "identification_number", label: "Representative's NRIC/Passport No.", placeholder: "Enter NRIC. e.g. 990101042222", required: false, section: 1 },
      { value: "current_address", label: "Company Address", placeholder: "Enter Company Address", type: "textarea", grid: "full", required: false, section: 1 },
      { value: "country", label: "Country", section: 1 },
      { value: "state", label: "State", section: 1 },
      { value: "town", label: "Town", section: 1 },
      { value: "postcode", label: "Postcode", section: 1 },
    ]
    : [
      { value: "full_name", label: "Full Name", placeholder: "Enter Full Name", required: true, section: 1 },
      { value: "identification_number", label: "NRIC/Passport No.", placeholder: "Enter NRIC. e.g. 990101042222", required: false, section: 1 },
      { value: "gender", label: "Gender", placeholder: "Enter Gender", required: false, type: "radio", options: genders, section: 1 },
      { value: "date_of_birth", label: "Date of Birth", placeholder: "e.g. 06-08-2019", type: "calendar", required: false, section: 1 },
      { value: "occupation", label: "Occupation", placeholder: "Enter Occupation", required: false, type: "autosuggest", options: OccupationOptions, section: 1 },
      { value: "nationality", label: "Nationality", placeholder: "Enter Nationality", required: false, type: "autosuggest", options: nationalities, section: 1 },
      { value: "current_address", label: "Current Residence Address", placeholder: "Enter Current Residence Address", type: "textarea", required: false, grid: "full", section: 1 },
      { value: "country", label: "Country", section: 1 },
      { value: "state", label: "State", section: 1 },
      { value: "town", label: "Town", section: 1 },
      { value: "postcode", label: "Postcode", section: 1 },
    ]),
  { value: "email", label: "Email", required: false, placeholder: "Enter Email", section: 2},
  { value: "mobile_contact_number", label: "Mobile Number", placeholder: "e.g. 60123456789", required: false, section: 2},
  { value: "home_telephone_number", label: "Home Tel Number", placeholder: "Enter Home Tel Number", section: 2 },
  { value: "office_telephone_number", label: "Office Tel Number", placeholder: "Enter Office Tel Number", section: 2 },
  { value: "bank_id", label: "Bank", type: "select", options: banks, placeholder: "Select Bank Name", section: 3 },
  { value: "bank_account_number", label: "Bank Account Number", placeholder: "Enter Bank Account Number", section: 3  },
  { value: "payable_name", label: "Bank Payable Name", placeholder: "Enter Bank Payable Name", section: 3  },
  { value: "remark", label: "Remark", placeholder: "Enter Remark", grid: "full", section: 4 },
];

export const RepresentativeFormData = [
  { value: "representative_name", label: "Representative Name" },
  {
    value: "representative_contact_number",
    label: "Representative Contact Number",
  },
];

export const DefaultFormValue = {
  type_id: 1,
  full_name: "",
  nric: "",
  address: "",
  company: "",
  occupation: "",
  nationality: "",
  email: "",
  mobile_number: "",
  home_telephone_number: "",
  office_telephone_number: "",
  has_representative: false,
  representative_name: "",
  representative_contact_number: "",
  ren_tag: "",
  bank_id: null,
  bank_account_number: "",
  payable_name: "",
  remark: "",
  tags: [],
};

export const contactInformation = [
  { label: "Gender", value: "gender" },
  { label: "NRIC / Passport No", value: "identification_number" },
  { label: "Occupation", value: "occupation" },
  { label: "Date of Birth", value: "date_of_birth" },
  { label: "Home Tel Number", value: "home_telephone_number" },
  { label: "Office Tel Number", value: "office_telephone_number" }
]

export const personalInformation = [
  { label: "Nationality", value: "nationality" },
  { label: "Address", value: "current_address" },
  { label: "Country", value: "country" },
  { label: "State", value: "state" },
  { label: "Town", value: "town" },
  { label: "Postcode", value: "postcode" }
]

export const bankInformation = [
  { label: "Bank", value: "bank_id" },
  { label: "Bank Account Number", value: "bank_account_number" },
  { label: "Bank Payable Name", value: "payable_name" }
]

export const representativeInformation = [
  { label: "Representative Name", value: "representative_name" },
  { label: "Representative Contact Number", value: "representative_contact_number"}
]