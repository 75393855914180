import React from "react";
import CurrencyInput from "react-currency-input-field";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { BiCalculator } from "react-icons/bi";

import CustomToggle from "components/Button/toggle";
import AtCurrecyInput from "components/Input/currencyInput";
import CustomFormInput from "components/Input/formInput";

import { validationCheck } from "utils/checkNull";
import { PaymentTypes, DisbursementCost } from "./index";

const AtTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#000000BB",
    fontSize: 15,
  },
}))(Tooltip);

const subrentForm = ({ address }) => [
  {
    label: "Confirmation date",
    value: "confirmation_date",
    type: "date",
    required: true
  },
  {
    label: "Commencement date",
    value: "commencement_date",
    type: "date",
    required: true
  },
  {
    label: "Property address",
    value: "property_address",
    type: "autosuggest",
    col: "full",
    options: address,
    disabled: true,
    required: true
  },
  {
    label: "Amount of monthly rental (RM)",
    value: "monthly_rental_amount",
    type: "currency",
    required: true
  },
  {
    label: "Earnest deposit for one month (RM)",
    value: "one_month_earnest_deposit",
    type: "currency",
    automated: true,
    required: true,
  },
  {
    type: "custom",
    renderCustomForm: ({ 
      index, 
      data, 
      can_update, 
      autoCalculatedList,
      onChange,
      onChangeFocus,
      onChangeBlur
    }) => {
      return (
        <div 
          key={`advanced_rental-${index}`}
          className="at-form_mngmt-form_list at-form_list-full_col at-form__content mt-4 mb-0 grid_gap-2" 
          style={{ backgroundColor: !data.include_advanced_rental ? "#F9FAFB" : "inherit" }}>
          <li className={`at-form_mngmt-form_list at-form_list-full_col align-items-baseline`}
            style={{flexWrap: "nowrap"}}>
            <CustomToggle
              className={"at-toggle__secondary"}
              disabled={!can_update}
              currentState={!data.include_advanced_rental}
              onToggleButton={() => onChange("include_advanced_rental", !data.include_advanced_rental)}/>
            <h2 className={`at-form-input__title fs-2`}>Include Advanced Rental</h2>
          </li>
          <li className={`at-form_mngmt-form_list grid-half-col`}>
            <h2 className={`at-form-input__title`}>
              Advanced Rental (months)</h2>
            <div>
              <AtCurrecyInput
                style={{ paddingLeft: "0.2rem" }}
                context={"advanced_rental_months"}
                required={false}
                disabled={!can_update || !data.include_advanced_rental}
                value={`${data["advanced_rental_months"]}`}
                onChangeFocus={() => onChangeFocus("advanced_rental_months")}
                onChangeBlur={() => onChangeBlur("advanced_rental_months")}
                onChange={(val, cont) => {
                  onChange("advanced_rental_months", validationCheck(val, "number"));
                }}/>
            </div>
          </li>
          <li className={`at-form_mngmt-form_list grid-half-col`}>
            <h2 className={`at-form-input__title`}>
              Amount of Advanced Rental (RM)
              <AtTooltip
                classes={{ tooltip: "tooltip-arrow right" }}
                placement={"right"}
                enterTouchDelay={0}
                title={"Auto calculated"}>
                <span>
                  {autoCalculatedList.indexOf("advanced_rental_amount") > -1 && (
                    <BiCalculator
                      className={`at-form_mngmt-label_icon at-form_mngmt-icon_automated_signal`}
                    />
                  )}
                  {autoCalculatedList.indexOf("advanced_rental_amount") < 0 && (
                    <BiCalculator
                      className={`at-form_mngmt-label_icon`}
                    />
                  )}
                </span>
              </AtTooltip>
            </h2>
            <div>
              <AtCurrecyInput
                style={{ paddingLeft: "0.2rem" }}
                context={"advanced_rental_amount"}
                required={false}
                disabled={!can_update || !data.include_advanced_rental}
                value={`${data["advanced_rental_amount"]}`}
                onChangeFocus={() => onChangeFocus("advanced_rental_amount")}
                onChangeBlur={() => onChangeBlur("advanced_rental_amount")}
                onChange={(val, cont) => {
                  onChange("advanced_rental_amount", validationCheck(val, "number"));
                }}/>
            </div>
          </li>
        </div>
      )
    }
  },
  {
    label: "Security deposit (months)",
    value: "security_deposit_months",
    type: "number",
    required: false
  },
  {
    label: "Amount of security deposit (RM)",
    value: "security_deposit_amount",
    type: "currency",
    automated: true,
    required: false
  },
  {
    label: "Utility deposit (Electricity, Water and Sewerage) (months)",
    value: "utility_deposit_months",
    type: "number",
    required: false
  },
  {
    label: "Amount of utility deposit (RM)",
    value: "utility_deposit_amount",
    type: "currency",
    automated: true,
    required: false
  },
  {
    label: "Period of tenancy",
    value: { select_input: "intended_tenure_unit_id", text_input: "intended_tenure_period"},
    select_options: [
      {
        label: "Year(s)",
        value: "0",
        id: "0"
      },
      {
        label: "Month(s)",
        value: "1",
        id: "1"
      }
    ],
    type: "select_number",
    required: true,
  },
  {
    label: "Renewal of tenure",
    select_options: [
      {
        label: "Year(s)",
        value: "0",
        id: "0"
      },
      {
        label: "Month(s)",
        value: "1",
        id: "1"
      }
    ],
    value: { select_input: "renewal_tenure_unit_id", text_input: "renewal_tenure_period"},
    type: "select_number",
    required: false,
  },
  {
    label: "Stamping fee of tenancy agreement (RM)",
    value: "stamping_fee",
    type: "currency",
    automated: true,
    required: false
  },
  {
    label: "Disbursement of tenancy agreement (inclusive SST) (RM)",
    value: "disbursement_amount",
    type: "currency",
    required: false
  },
  {
    label: "Access card & car park deposit (RM)",
    value: "access_key_and_car_park_deposit_amount",
    type: "currency",
    required: false
  },
  {
    label: "Total outstanding to be paid before handover of keys (RM)",
    value: "before_handover_amount",
    type: "currency",
    required: false
  },
  {
    label: "Disbursement cost",
    value: "disbursement_type_id",
    type: "radio",
    mode: "old",
    required: true,
    horizontal: false,
    options: DisbursementCost,
    contents: [
      {
        id: 1,
        show: ({ disbursement_type_id = "" }) => disbursement_type_id === 1,
        renderContent: ({
          data,
          can_update,
          onChangeBlur,
          onChangeFocus,
          onChange,
        }) => {
          const {
            tenant_disbursement_amount = "",
            landlord_disbursement_amount = "",
          } = data;
          return (
            <div
              style={{
                border: "0.18rem solid #C6C6C6",
                borderRadius: "0.2rem",
                padding: "0.5rem 1rem",
                margin: "1rem 0",
              }}
            >
              <div>
                <h2 className="at-form-input__title">
                  {" "}
                  Amount of tenant disbursement (RM):{" "}
                </h2>
                <CurrencyInput
                  className="subsales-letter-input"
                  style={{ paddingLeft: "0.2rem" }}
                  name={"tenant_disbursement_amount"}
                  defaultValue={1000}
                  disabled={!can_update}
                  onBlur={() => onChangeBlur("tenant_disbursement_amount")}
                  onFocus={() => onChangeFocus("tenant_disbursement_amount")}
                  value={tenant_disbursement_amount || ""}
                  onValueChange={(value) =>
                    onChange("tenant_disbursement_amount", value)
                  }
                />
              </div>
              <div className="mt-3">
                <h2 className="at-form-input__title">
                  {" "}
                  Amount of landlord disbursement (RM):{" "}
                </h2>
                <CurrencyInput
                  className="subsales-letter-input"
                  style={{ paddingLeft: "0.2rem" }}
                  name={"landlord_disbursement_amount"}
                  defaultValue={1000}
                  disabled={!can_update}
                  onBlur={() => onChangeBlur("landlord_disbursement_amount")}
                  onFocus={() => onChangeFocus("landlord_disbursement_amount")}
                  value={landlord_disbursement_amount || ""}
                  onValueChange={(value) =>
                    onChange("landlord_disbursement_amount", value)
                  }
                />
              </div>
            </div>
          );
        },
      },
    ],
  },
  {
    label: "Execution of tenancy agreement within (days)",
    value: "execution_days",
    type: "number",
    required: true
  },
  {
    label: "Interest rate per annum (%)",
    value: "annum_interest_rate",
    type: "number",
    required: false
  },
  {
    label: "Earnest deposit paid by Tenant (RM)",
    value: "earnest_deposit_amount",
    type: "currency",
    required: true
  },
  {
    label: "Other conditions",
    value: "other_conditions",
    required: false,
    type: "custom",
    renderCustomForm: ({ index, data, can_update, onChange }) => {
      const { has_extra_conditions, other_conditions } = data;

      return (
        <li
          key={index}
          className="at-form_mngmt-form_list grid-full-col"
          style={{ width: "100%" }}>
          <h2 className="at-form-input__title"> Other Conditions </h2>
          <CustomFormInput
            type="text"
            placeholder={
              "If the condition is short enough to put in one line, write it here"
            }
            value={other_conditions}
            onChangeValue={(val) =>
              !has_extra_conditions && onChange("other_conditions", val)
            }
            disabled={has_extra_conditions || !can_update}/>
        </li>
      );
    },
  },
  {
    label: "Deposit Payment Method",
    value: "deposit_payment_method_id",
    type: "select",
    required: true,
    options: PaymentTypes,
    contents: [
      {
        id: 1,
        show: ({ deposit_payment_method_id = "" }) =>
          deposit_payment_method_id === 2,
        renderContent: ({ data, can_update, onChange }) => {
          const { deposit_cheque_number = "" } = data;
          return (
            <div className="at-form__content at-new_container-16 mt-4 mb-0">
              <div>
                <h2 className="at-form-input__title">Cheque Number</h2>
                <CustomFormInput
                  value={deposit_cheque_number || ""}
                  type={"text"}
                  onChangeValue={(val) => onChange("deposit_cheque_number", val)}
                  disabled={!can_update}/>
              </div>
            </div>
          );
        },
      },
    ],
  },
  {
    label: "Stakeholder",
    value: "stake_holder_id",
    type: "radio",
    required: true,
    options: [
      {
        id: 1,
        label: "IQI REALTY SDN BHD",
        value: 1,
      },
      {
        id: 2,
        label: "Lawyer",
        value: 2,
      },
    ],
    contents: [
      {
        id: 1,
        show: ({ stake_holder_id = "" }) => stake_holder_id === 2,
        renderContent: ({ data, can_update, onChange }) => {
          const {
            stake_holder_company = "",
            stake_holder_registration_number = "",
          } = data;
          return (
            <div
              className="at-form__content at-new_container-16"
              style={{
                margin: "0.5rem 0 0 0",
              }}>
              <div className="at-form_mngmt-form_collapsed">
                <h2 className="at-form-input__title">Lawyer Company Name:</h2>
                <CustomFormInput
                  type={"text"}
                  value={stake_holder_company || ""}
                  onChangeValue={(val) => onChange("stake_holder_company", val)}
                  disabled={!can_update}
                />
              </div>
              <div className="at-form_mngmt-form_collapsed">
                <h2 className="at-form-input__title">Lawyer Company Registration Number:</h2>
                <CustomFormInput
                  type={"text"}
                  value={stake_holder_registration_number || ""}
                  onChangeValue={(val) =>
                    onChange("stake_holder_registration_number", val)
                  }
                  disabled={!can_update}/>
              </div>
            </div>
          );
        },
      },
    ],
  },
  {
    label: "Total agency fee (months)",
    value: "agency_fee_months",
    type: "text",
    required: false
  },
  {
    label: "Total agency fee (RM)",
    value: "agency_fee_amount",
    type: "currency",
    required: false
  },
];

export default subrentForm;
