import React, { Component } from "react";
import _ from "lodash";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";

import packageJSON from "../../package.json";
import "stylesheets/components/snackbar/index.scss";

global.appVersion = packageJSON.version;

const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

const VersionCheckHOC = (WrappedComponent) => {
  class VersionCheckWrappedComponent extends Component {
    state = {
      loading: false,
      isLatestVersion: true,
      latestVersion: "",
      refreshCacheAndReload: () => {
        console.log("Clearing cache and hard reloading...");
        if (caches) {
          // Service worker cache should be cleared with caches.delete()
          caches.keys().then(function (names) {
            for (let name of names) caches.delete(name);
          });
        }
        // delete browser cache and hard reload
        window.location.reload(true);
      },
    };

    componentDidMount = () => {
      this.setState({ loading: true });
      fetch("/meta.json")
        .then((response) => response.json())
        .then((meta) => {
          console.log(meta, "meta");
          const latestVersion = meta.version;
          const currentVersion = global.appVersion;
          const shouldForceRefresh = semverGreaterThan(
            latestVersion,
            currentVersion,
          );
          if (shouldForceRefresh) {
            console.log(
              `We have a new version - ${latestVersion}. Should force refresh`,
            );
            this.setState({ loading: false }, () => {
              this.setState({ isLatestVersion: false });
            });
          } else {
            console.log(
              `You already have the latest version - ${latestVersion}. No cache refresh needed.`,
            );
            this.setState({ loading: false, isLatestVersion: true });
          }
          this.setState({ latestVersion });
        });
    };

    render = () => {
      const atlasBot = document.getElementById('chaskiq-prime')
      let snackBarStyle = atlasBot ? { bottom:  80 } : {}
      
      return (
        <>
          <WrappedComponent
            {...this.props}
            onLoadVersionCheck={this.state.loading}
            isLatestVersion={this.state.isLatestVersion}
            refreshCacheAndReload={this.state.refreshCacheAndReload}
          />
          <Snackbar
            classes={{ root: "at-version-check_snackbar" }}
            style={snackBarStyle}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            ContentProps={{
              classes: {
                root: "at-version-check_snackbar-content",
                action: "at-version-check_snackbar-content-actions",
                message: "at-version-check_snackbar-content-message",
              },
            }}
            open={!this.state.isLatestVersion}
            autoHideDuration={6000}
            onClose={() => {}}
            message={
              <>
                <h5 style={{ color: "white" }}>
                  Atlas has new updates !
                  <span className="badge badge-primary ml-2">
                    {this.state.latestVersion}
                  </span>
                </h5>
                <p>Click the update button to get the latest version.</p>
              </>
            }
            action={
              <Button
                color="primary"
                size="small"
                onClick={() => this.state.refreshCacheAndReload()}
              >
                Update
              </Button>
            }
          />
        </>
      );
    };
  }
  return VersionCheckWrappedComponent;
};

export default VersionCheckHOC;
