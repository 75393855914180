import { occupationOptions } from 'containers/Dashboard/Administrator/SubsalesClaimsBeta/containers/ClaimForm/asset/dueDiligence'

export const personalForms = [
  {
    label: "Full Name",
    value: "full_name",
    type: "form",
    placeholder: "Enter Full Name",
    show: () => true,
    required: true
  },
  {
    label: "NRIC/Passport No.",
    value: "nric",
    type: "form",
    placeholder: "Enter NRIC. e.g. 990101042222",
    show: () => true,
    required: true
  },
  {
    label: "Mobile/Office Contact No.",
    value: "contact_number",
    type: "form",
    placeholder: "Enter Mobile/Office Contact No.",
    show: () => true,
    required: true,
    checkBoxValue: "sensitive_info_by_opposite_claim",
    checkBoxDescription: "Unable to retrieve contact details",
    showCheckBox: false,
    onDisable: data => data.sensitive_info_by_opposite_claim
  },
  {
    label: "Nationality",
    value: "nationality",
    type: "autosuggest",
    placeholder: "Enter Nationality",
    show: ({ titleContext = "" }) => titleContext !== "Law Firm",
    required: ({ titleContext = "" }) => titleContext !== "Law Firm",
  },
  {
    label: "Gender",
    value: "gender",
    type: "radio",
    show: ({ titleContext = "" }) => titleContext !== "Law Firm",
    required: ({ titleContext = "" }) => titleContext !== "Law Firm",
  },
  {
    label: "Date of Birth",
    value: "date_of_birth",
    type: "calendar",
    placeholder: "e.g. 06-08-2019",
    show: () => true,
    required: true
  },
  {
    label: "Tax Identification No.",
    value: "tax_identification_no",
    placeholder: "Enter Tax Identification No.",
    type: "form",
    show: () => true,
    required: false,
  },
  {
    label: "Current Residence Address",
    value: "address",
    type: "textarea",
    placeholder: "Enter Current Residence Address",
    fullCol: true,
    show: () => true,
    required: true
  },
  {
    label: "Current Residence Country",
    value: "country",
    placeholder: "Select Current Residence Country",
    show: ({ titleContext = "" }) => titleContext !== "Law Firm",
    required: ({ titleContext = "" }) => titleContext !== "Law Firm",
  },
  {
    label: "Current Residence State",
    value: "state",
    placeholder: "Select Current Residence State",
    show: ({ titleContext = "" }) => titleContext !== "Law Firm",
    required: ({ titleContext = "" }) => titleContext !== "Law Firm",
  },
  {
    label: "Current Residence City",
    value: "town",
    placeholder: "Enter Current Residence City",
    show: ({ titleContext = "" }) => titleContext !== "Law Firm",
    required: ({ titleContext = "" }) => titleContext !== "Law Firm",
  },
  {
    label: "Current Residence Postcode",
    value: "postcode",
    placeholder: "Enter Current Residence Postcode",
    show: ({ titleContext = "" }) => titleContext !== "Law Firm",
    required: ({ titleContext = "" }) => titleContext !== "Law Firm",
  },
  {
    label: "",
    value: "",
    type: "expandable",
    show: () => true,
    required: false
  },
];

export const companyForms = [
  {
    label: "Company Name",
    value: "company_name",
    placeholder: "Enter Company Name",
    type: "form",
    show: () => true,
    required: true
  },
  {
    label: "Company Reg. No. (12 Digits)",
    placeholder: "e.g. 202005123456",
    value: "company_registration_no",
    type: "form",
    show: () => true,
    required: true
  },
  {
    label: "Representative's Designation",
    placeholder: "e.g. Director / Manager / PIC",
    value: "job_title",
    type: "form",
    show: ({ titleContext = "" }) => titleContext !== "Law Firm",
    required: ({ titleContext = "" }) => titleContext !== "Law Firm",
  },
  {
    label: "Representative's Name",
    value: "full_name",
    placeholder: "Enter Representative's Name",
    type: "form",
    show: ({ titleContext = "" }) => titleContext !== "Law Firm",
    required: ({ titleContext = "" }) => titleContext !== "Law Firm",
  },
  {
    label: "Representative's NRIC/Passport No.",
    placeholder: "Enter NRIC. e.g. 990101042222",
    value: "nric",
    type: "form",
    show: ({ titleContext = "" }) => titleContext !== "Law Firm",
    required: ({ titleContext = "" }) => titleContext !== "Law Firm",
  },
  {
    label: "Representative's Mobile Number",
    value: "contact_number",
    placeholder: "e.g. 60123456789",
    type: "form",
    show: () => true,
    required: true
  },
  {
    label: "Tax Identification No.",
    value: "tax_identification_no",
    placeholder: "Enter Tax Identification No.",
    type: "form",
    show: () => true,
    required: false,
  },
  {
    label: "Company Address",
    value: "address",
    placeholder: "Enter Company Address",
    fullCol: true,
    type: "textarea",
    show: () => true,
    required: true
  },
  {
    label: "Country",
    value: "country",
    placeholder: "Select Country",
    show: ({ titleContext = "" }) => titleContext !== "Law Firm",
    required: ({ titleContext = "" }) => titleContext !== "Law Firm",
  },
  {
    label: "State",
    value: "state",
    placeholder: "Enter State",
    show: ({ titleContext = "" }) => titleContext !== "Law Firm",
    required: ({ titleContext = "" }) => titleContext !== "Law Firm",
  },
  {
    label: "Area / City",
    value: "town",
    placeholder: "Enter Area / City",
    show: ({ titleContext = "" }) => titleContext !== "Law Firm",
    required: ({ titleContext = "" }) => titleContext !== "Law Firm",
  },
  {
    label: "Postcode",
    value: "postcode",
    placeholder: "Enter Postcode",
    show: ({ titleContext = "" }) => titleContext !== "Law Firm",
    required: ({ titleContext = "" }) => titleContext !== "Law Firm",
  },
  {
    label: "",
    value: "",
    type: "expandable",
    show: () => true,
    required: false
  },
];

export const referralPersonalForms = [
  {
    label: "Full Name",
    value: "full_name",
    placeholder: "Enter Full Name",
    type: "form",
    show: () => true,
    required: true
  },
  {
    label: "Mobile/Office Contact No.",
    value: "contact_number",
    placeholder: "Enter Mobile/Office Contact No.",
    type: "form",
    show: () => true,
    required: false
  },
  {
    label: "Email",
    value: "email",
    placeholder: "Enter Email",
    type: "form",
    show: () => true,
    required: false
  },
  {
    label: "NRIC/Passport No.",
    value: "nric",
    placeholder: "Enter NRIC. e.g. 990101042222",
    type: "form",
    show: () => true,
    required: true
  },
  {
    label: "Nationality",
    search_value: "nationality",
    value: "nationality_id",    
    type: "autosuggest",
    placeholder: "Enter Nationality",
    show: ({ titleContext = "" }) => titleContext !== "Law Firm",
    required: ({ titleContext = "" }) => titleContext !== "Law Firm",
  },
  {
    label: "Primary Address",
    value: "address",
    type: "autosuggest",
    placeholder: "Enter Primary Address",
    show: () => true,
    required: false
  },
  {
    label: "Tax Identification No.",
    value: "tax_identification_no",
    type: "form",
    placeholder: "Enter Tax Identification No.",
    show: () => true,
    required: false,
  },
  {
    label: "Referral Fee Amount (RM)",
    value: "commission_amount",
    type: "expandable",
    placeholder: "Enter Referral Fee Amount",
    show: () => true,
    required: true
  },
];

export const referralCompanyForms = [
  {
    label: "Company Name",
    value: "full_name",
    type: "form",
    placeholder: "Enter Company Name",
    show: () => true,
    required: true
  },
  {
    label: "Mobile/Office Contact No.",
    value: "contact_number",
    type: "form",
    placeholder: "Enter Mobile/Office Contact No.",
    show: () => true,
    required: true
  },
  {
    label: "Email",
    value: "email",
    type: "form",
    placeholder: "Enter Email",
    show: () => true,
    required: true
  },
  {
    label: "Company Reg. No. (12 Digits)",
    placeholder: "e.g. 202005123456",
    value: "nric",
    type: "form",
    show: () => true,
    required: true
  },
  {
    label: "Company Address",
    value: "address",
    type: "autosuggest",
    placeholder: "Enter Company Address",
    show: () => true,
    required: true
  },
  {
    label: "Tax Identification No.",
    value: "tax_identification_no",
    type: "form",
    placeholder: "Enter Tax Identification No.",
    show: () => true,
    required: false,
  },
  {
    label: "Referral Fee Amount (RM)",
    value: "commission_amount",
    placeholder: "Enter Referral Fee Amount",
    type: "expandable",
    show: () => true,
    required: true
  },
];

export const personalFormData = {
  id: "",
  full_name: "",
  nric: "",
  address: "",
  contact_number: "",
  bank_id: 0,
  bank_account_number: "",
  type_id: "",
  form_contact_id: "",
  payable_name: "",
  commission_amount: 0,
  date_of_birth: null,
  tax_identification_no: "",
  state: "",
  postcode: "",
  country: "",
  nationality: "",
  gender: "",
  town: "",
  tax_identification_no: "",
  sensitive_info_by_opposite_claim: false,
  due_diligence_information: {
    occupation: "",
    due_diligence_type_id: "",
    financing_method_id: "",
    signing_link: "",
    salary_range_id: "",
    employer_name: "",
    copies_info: {},
    transaction_purpose_id: "",
    declared_bankrupt: false,
    remark: "",
  },
};

export const companyFormData = {
  id: "",
  company_name: "",
  company_registration_no: "",
  tax_identification_no: "",
  job_title: "",
  full_name: "",
  nric: "",
  address: "",
  form_contact_id: "",
  contact_number: "",
  bank_id: 0,
  bank_account_number: "",
  type_id: "",
  payable_name: "",
  commission_amount: 0,
  state: "",
  postcode: "",
  town: "",
  country: "",
  gender: "",
  due_diligence_information: {
    due_diligence_type_id: "",
    financing_method_id: "",
    signing_link: "",
    nature_of_business: "",
    beneficial_owners: "",
    business_type_id: "",
    copies_info: {},
    directors: [],
    shareholders: [],
    senior_management: "",
    nationality: "",
    ownership_type: "",
    transaction_purpose_id: "",
    declared_bankrupt: false,
  },
};