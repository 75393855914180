import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import Dialog from "@material-ui/core/Dialog";

import CustomCard from "components/Card";
import AtlasDialog from "components/Dialog";
import AtPaginatedTable from "./components/Table";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import AtlasSnackbar from "components/Snackbar/v2";
import LocalisedBulkUpdate from 'components/Modal/localisedBulkUpdate';
import LanguageModal from "components/Select/language";

import withLocalization from "actions/localisation";
import SubsalesHOC from "./actions";
import VersionCheckHOC from "actions/versionCheck";
import FloorPlanHOC from "./actions/floorplan";
import MediaLinkHOC from "./actions/medaLink";
import permissionsChecker from "utils/permissionsChecker";

import EditModal from "./Edit";
import ViewModal from "./View";
import { categories } from "dictionary/subsales";

class Subsales extends Component {
  componentDidMount = () => {
    this.props.getCountryDictionary();
    this.props.getSubsalesCurrentUser(1, "");
    this.props.getModuleKeys(14, this.props.data.languageReducer.language);
  };
  
  componentDidUpdate = (pp) => {
    let temp = _.cloneDeep(this.props.searchParams);

    let listingCategoryIndex = _.findIndex(this.props.searchParams, {
      value: "category_id_eq",
    });
    let propertyTypeIndex = _.findIndex(this.props.searchParams, {
      value: "property_type_id_eq",
    });

    if (listingCategoryIndex !== -1) {
      let selectedCategoryParam = this.props.searchParams[listingCategoryIndex].param;
      if (pp.searchParams[listingCategoryIndex].param !== selectedCategoryParam) {
        selectedCategoryParam = _.find(categories, { id: selectedCategoryParam });
        let tmpPropertyTypes = selectedCategoryParam
          ? selectedCategoryParam.group_types.map((item) => {
            let children = item.children.map((child) => ({
              id: child.id,
              name: child.name,
              label: child.name,
              value: child.id,
              key: child.key || null,
              groupName: item.name,
              })
            );
            let tmpParent = {
              name: item.name,
              key: item.key || null,
              children: children,
            };
            return tmpParent;
          })
        : [];
        temp[propertyTypeIndex].param = "";
        temp[propertyTypeIndex].options = tmpPropertyTypes;
        this.props.onChangeSubsalesHOC(temp, "searchParams");
      }
    }

    let countryIndex = _.findIndex(this.props.searchParams, {
      value: "country_id_eq",
    });
    let stateIndex = _.findIndex(this.props.searchParams, {
      value: "state_id_eq",
    });
    let townIndex = _.findIndex(this.props.searchParams, {
      value: "township_cont",
    });

    if (pp.searchParams[countryIndex].param !== temp[countryIndex].param) {
      let selectedCountry = _.find(
        this.props.data.dictionaryReducer.countries,
        { id: this.props.searchParams[countryIndex].param },
      );
      temp[stateIndex].options = selectedCountry?.states ?? [];
      temp[stateIndex].param = "";
      temp[townIndex].param = "";
      temp[townIndex].options = [];
      pp.searchParams[stateIndex].param = "";

      this.props.onChangeSubsalesHOC(temp, "searchParams");
    }

    if (
      pp.searchParams[stateIndex].param !== temp[stateIndex].param &&
      temp[stateIndex].param !== ""
    ) {
      this.props.getSelectedTownShip(temp[stateIndex].param);
    }
  };
  
  renderCardContent = () => {
    const { can_update } = permissionsChecker(
      "Listing Centre",
      this.props.data,
    );

    return (
      <>
        <AtPaginatedTable
          headerData={this.props.subsalesTableHeader}
          rowData={
            this.props.tmpSubsalesCurrentUser.data
              ? this.props.tmpSubsalesCurrentUser.data
              : []
          }
          meta={
            this.props.tmpSubsalesCurrentUser.meta &&
            this.props.tmpSubsalesCurrentUser.meta
          }
          onClickCard={(val) => {
            Promise.all([
              this.props.getSelectedSubsales(val.id),
              this.props.getFloorPlans(val.id),
            ]).then(() => {
              this.props.onChangeSubsalesHOC(true, "showViewModal");
            });
          }}
          onClickFavourite={(id) => {
            this.props.toggleFavourite(id);
          }}
          onClickEdit={(val) => {
            Promise.all([
              this.props.getSelectedSubsales(val.id),
              this.props.getFloorPlans(val.id),
            ]).then(() => {
              this.props.onChangeSubsalesHOC(true, "showEditModal");
            });
          }}
          searchParams={this.props.searchParams}
          onChangeSearchParams={(val) =>
            this.props.onChangeSubsalesHOC(val, "searchParams")
          }
          getListAPI={this.props.getSubsalesCurrentUser}
          totalPages={this.props.subsalesPages}
          can_update={can_update}
          sideMenuExpand={this.props.data.sideMenuReducer.sideMenuExpand}
          getLocalised={this.props.getLocalised}
          getFilterKey={this.props.getFilterKey}
          renderSpecialMessage={this.props.renderSpecialMessage}
        />
        <Dialog
          open={this.props.showSubsaleInfoLoadingModal}
          onClose={() => {}}
        >
          <CustomCard cardContent={this.renderCardContentModal()} />
        </Dialog>
      </>
    );
  };

  renderCardContentModal = () => {
    return (
      <>
        <h4>Notification</h4>
        <hr />
        <p>
          Please wait awhile while we load the necessarily information required
          from the server for you.
        </p>
      </>
    );
  };

  render = () => {
    const {
      data,
      getLocalised,
      onLoadSubsales,
      onLoadFavourite,
      onLoadLocalisation,
      showEditModal,
      showViewModal,
    } = this.props;
    
    return (
      <>
        <ModuleHeader
          title={getLocalised("title.listing_centre", "Listing Centre")}
          moduleIcon={"icon-listing-centre"}
          renderExtraButton={() => (
            <LanguageModal
              data={this.props.data}
              hideLanguageButton={true}
              getAllLocalisedSetting={this.props.getAllLocalisedSetting}
              getAgentLocalisedSetting={this.props.getAgentLocalisedSetting}
              getModuleKeys={this.props.getModuleKeys}
              getAgentModuleKeys={this.props.getAgentModuleKeys}
              settings={this.props.settings}
              permittedSettings={this.props.permittedSettings}
              role={this.props.data.profileReducer.role}
              onChangeHOC={this.props.onChangeSubsalesHOC}
            />
          )}
        />
        <CustomCard cardContent={this.renderCardContent()} />
        <AtlasDialog
          open={showEditModal}
          className={`${data.sideMenuReducer.sideMenuExpand ? "at-subsales_listing-menu_expand" : ""}`}
          paperClassName={"paper"}
        >
          <EditModal {...this.props} />
        </AtlasDialog>
        <AtlasDialog
          className={`at-subsales_listing__view-dialog ${data.sideMenuReducer.sideMenuExpand ? "at-subsales_listing-menu_expand" : ""}`}
          open={showViewModal}
          paperClassName={"paper"}
        >
          <ViewModal {...this.props} />
        </AtlasDialog>
        <AtlasSnackbar
          open={this.props.openSnackBar}
          handleClose={() =>
            this.props.onChangeSubsalesHOC(false, "openSnackBar")
          }
          message={this.props.snackBarMessage}
        />
        {this.props.showBulkUpdate && (
          <LocalisedBulkUpdate
            {...this.props}
            onLoad={this.props.onLoadLocalisation || this.props.onLoadSubsales}
            moduleName={'Listing Centre'}
            onClose={() => this.props.onChangeSubsalesHOC(false, 'showBulkUpdate')} 
          /> 
        )}
        {(onLoadSubsales || onLoadFavourite || onLoadLocalisation) && <LoadingModal />}
      </>
    );
  };
}

export default compose(
  withLocalization,
  SubsalesHOC,
  FloorPlanHOC,
  MediaLinkHOC,
  VersionCheckHOC
)(Subsales);
