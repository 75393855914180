import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { Post } from "utils/axios";
import { requestError } from "utils/requestHandler";

const TemplateHOC = (WrappedComponent) => {
  class TemplateWrappedComponent extends Component {
    state = {
      requests: [],
      loading: false,
    };

    load = (param) => this.setState({ loading: param });

    getSSOToken = (origin) =>
      Post(
        `/tokens/single_sign_on`,
        { origin: origin },
        (payload) => this.getSSOTokenSuccess(payload, origin),
        this.getSSOTokenError,
        this.load,
      );
    getSSOTokenSuccess = (payload, origin) => {
      window.open(
        `${origin}/atlas-academy-login?token=${payload.auth_token}&refresh-token=${payload.refresh_token}`,
        "_blank",
      );
    };
    getSSOTokenError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent {...this.props} getSSOToken={this.getSSOToken} />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(TemplateWrappedComponent);
};

export default TemplateHOC;
