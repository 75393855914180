import React, { Component } from "react";
import { connect } from "react-redux";
import { Buffer } from "buffer";

import { Get } from "utils/axios";
import { storeLastView } from "actions/lastView";

const CaseFileHOC = (WrappedComponent) => {
  class CaseFileWrappedComponent extends Component {
    state = {
      openSnackBar: false,
      statusMessage: "",
      requestStatus: "",
      addressInfo: {},
      showInvalidLinkModal: false
    }

    onChangeAccessCaseFileHOC = (value, key) => this.setState({ [key]: value });

    getAddressInfo = address => {
      const base64Address = encodeURIComponent(address)
      Get(
        `/townships/get_address_info?address=${base64Address}`,
        this.getAddressInfoSuccess,
        this.getAddressInfoError,
        () => {}
      )
    }
    getAddressInfoSuccess = (payload) => this.setState({ addressInfo: payload });
    getAddressInfoError = () => this.setState({ addressInfo: {} });

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}

          getAddressInfo={this.getAddressInfo}
          onChangeAccessCaseFileHOC={this.onChangeAccessCaseFileHOC}
        />
      )
    }
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    storeLastView,
  })(CaseFileWrappedComponent);
}

export default CaseFileHOC;