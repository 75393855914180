import React from "react";

import "./index.scss";

const IOSButton = ({ className, currentState, onToggleButton, disabled }) => {
  return (
    <label className={`at-toggle ${disabled ? "disabled" : ""}  ${className || ""}`}>
      <input
        className="at-toggle__input"
        type="checkbox"
        disabled={disabled}
        checked={!currentState}
        onClick={() => onToggleButton()}
      />
      <span className="at-toggle-slider at-toggle__round"></span>
    </label>
  );
};

export default IOSButton;
