import React, { useEffect, useState, useCallback, useMemo } from "react";
import Moment from "moment";
import _ from "lodash";
import { Tooltip, Collapse, IconButton, MenuItem, Dialog } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { IoMdHelpCircle } from "react-icons/io";
import { FaExclamationCircle } from "react-icons/fa";

import AtSelectNew from "components/Select/new";
import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import AtlasRadioGroup from "components/RadioGroup";
import AtlasAutosuggest from "components/Autosuggest";
import ModalDialog from "components/Modal/ModalDialog";
import ConfirmationModal from "components/Modal/confirmation";
import CustomFormInput from "components/Input/formInput";
import CountryCodeContent from "components/country_code";
import DueDiligenceContent from "./dueDeligenceContent";
import CustomCheckbox from "components/Checkbox";
import DatePicker from "components/Input/datetimepicker";

import { requestError } from "utils/requestHandler";
import {
  personalDDFormData,
  personalFieldData,
  companyDDFormData,
  companyFieldData,
  occupationOptions,
} from "./assets";

const DarkTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#000000",
    fontSize: 16,
  },
}))(Tooltip);

const GenderOptions = [
  { id: "M", label: "Male", value: "M" },
  { id: "F", label: "Female", value: "F" },
];

const appendYearPrefix = (DOB) => {
  let checkAbove00Gen = new RegExp(/[0-3]/);
  let checkBelow90Gen = new RegExp(/[4-9]/);
  let firstDigit = DOB[0];

  if (checkAbove00Gen.test(firstDigit)) {
    return `20${DOB}`;
  } else if (checkBelow90Gen.test(firstDigit)) {
    return `19${DOB}`;
  }
};

const defaultFormData = {
  id: null,
  form_id: null,
  party_id: null,
  signee_type_id: "0",
  full_name: "",
  email: "",
  mobile_contact_number: "",
  identification_number: "",
  current_address: "",
  town: "",
  state: "",
  country: "",
  postcode: "",
  occupation: ""
};

const UpdateAssignContact = ({
  title,
  form_type,
  addressInfo,
  onLoadParty,
  nationalities,
  selected_form,
  selectedContact,
  suggested_ic,

  onClose,
  getAddressInfo,
  onChangePartyHOC,
  updatePartyContact,
  downloadCompletedForm,
  initiateIndemnityLetter,
  getSelectedFormContact,
  getSelectedTownShip,
  extactIcDetails,
}) => {
  const showDueDiligence = form_type !== "authorisation" && [1, 3].includes(selectedContact.party_id);

  const [tempFieldData, onChangeFieldData] = useState(personalFieldData);
  const [updateObj, onChangeUpdateObj] = useState(defaultFormData);
  const [doc, setDoc] = useState({
    identity_copy: {},
    supporting_document: {},
    ssm_copy: {},
  });
  const [showCountryCodeModal, onToggleCountryCode] = useState(false);
  const [showScanIcConfirmationModal, onToggleShowScanIc] = useState(false);
  const [expandDueDiligence, onToggleexpandDueDiligence] = useState(true);
  const [errorList, setErrorList] = useState([]);

  const checkRequiredValue = useMemo(() => {
    return tempFieldData.some(field => {
      if(field.type !== "radio" && (field.required || (typeof field.onRequired === "function" && field.onRequired({...updateObj, form_type: form_type, party_id: selectedContact.party_id})))) {
        return _.isEmpty(updateObj[field.value])
      }
      return false
    })
  }, [updateObj, selectedContact, form_type, tempFieldData])

  useEffect(() => {
    let tempErrorList = []
    let tmpObject = _.cloneDeep(updateObj);
    const checkOccupation = updateObj?.occupation !== '' 
      ?  _.find(occupationOptions, {
        value: updateObj?.occupation ?? "",
      }) 
      : true;
    const checkNationality = _.find(nationalities, { value: tmpObject.nationality }) && tmpObject.signee_type_id === "0";
    
    tempFieldData.forEach(field => {
      let tmpOptions = field?.options || renderOptions(field.value) 
      let tmpRequired = field.type !== "radio" && (field.required || (typeof field.onRequired === "function" && field.onRequired({...updateObj, form_type: form_type, party_id: selectedContact.party_id})))

      if(field.value === "date_of_birth" && tmpObject.identification_number) {
        _.isEmpty(tmpObject?.[field.value]) && tempErrorList.push(field.value)
      } else if ((field.value === "nationality" && !checkNationality) || (field.value === "occupation" && !checkOccupation)) { 
        tempErrorList.push(field.value)
      } else if(["select", "typeahead", "autosuggest"].indexOf(field.type) > -1) {
        const selectedObj = (tmpRequired && !tmpObject?.[field.value]) 
          ? _.find(tmpOptions, item => item.value === tmpObject?.[field.value])
          : true;
          !selectedObj && tempErrorList.push(field.value);
      } else if(tmpRequired) {
        _.isEmpty(tmpObject?.[field.value]) && tempErrorList.push(field.value)
      }
      setErrorList(tempErrorList)
    })
  }, [updateObj])
    
  useEffect(() => {
    let tempField = {
      ...defaultFormData,
      ...(showDueDiligence && {
        due_diligence_information: personalDDFormData,
      }),
      gender: "",
      date_of_birth: "",
      nationality: "",
    };
    let tempData = {};

    if (selectedContact.signee_type_id.toString() === "1") {
      tempField = {
        ...defaultFormData,
        ...(showDueDiligence && {
          due_diligence_information: companyDDFormData,
        }),
        job_title: "",
        company_name: "",
        company_registration_no: "",
      };
      onChangeFieldData(companyFieldData);
    }

    Object.keys(tempField).map((key) => {
      if (key === "due_diligence_information") {
        tempData["due_diligence_information"] = {};
        Object.keys(tempField["due_diligence_information"]).map((item) => {
          if (item === "declared_bankrupt") {
            tempData["due_diligence_information"][item] = selectedContact[item];
          } else if (item === "due_diligence_type_id") {
            tempData["due_diligence_information"][item] =
              selectedContact[item] || 1;
          } else {
            tempData["due_diligence_information"][item] =
              selectedContact[item] || "";
          }
        });
      } else if (key === "date_of_birth") {
        tempData[key] = selectedContact[key] || null;
      } else {
        tempData[key] = selectedContact[key] || "";
      }
    });

    onChangeUpdateObj({
      ...tempData,
      signee_type_id: `${selectedContact.signee_type_id}`,
    });
  }, []);

  useEffect(() => {
    setDoc({ file_name: "", file: {} });
  }, [selectedContact]);

  useEffect(() => {
    if (!suggested_ic) return;
    const { identification_number } = updateObj;
    if (identification_number) {
      if (suggested_ic !== identification_number) {
        onToggleShowScanIc(true);
      }
    } else {
      onChangeUpdateValue(suggested_ic, 'identification_number')
      onChangePartyHOC(null, "suggested_ic")
    }
  }, [suggested_ic]);
  
  const renderOptions = (value) => {
    switch (value) {
      case "nationality":
        return nationalities || [];
      default:
        return [];
    }
  };

  useEffect(() => {
    if (!_.isEmpty(addressInfo)) {
      let temp = _.cloneDeep(updateObj);
      temp.country = addressInfo?.country || "";
      temp.state = addressInfo?.state || "";
      temp.town = addressInfo?.city || "";
      temp.postcode = addressInfo?.postcode || "";
      onChangeUpdateObj(temp);
    }
  }, [addressInfo])

  const onChangeUpdateValue = (val, context) => {
    let tempUpdateObj = _.cloneDeep(updateObj);
    tempUpdateObj[context] = val;

    if (context === "identification_number") {
      tempUpdateObj["date_of_birth"] = dobGenerator(val)?.dob || "";
      tempUpdateObj["gender"] = dobGenerator(val)?.gender || "";
      tempUpdateObj["nationality"] = dobGenerator(val)?.nationality || "";
    }

    onChangeUpdateObj(tempUpdateObj);
  };

  const dobGenerator = (val) => {
    let tmpVal = val.toString();
    let digitLength = tmpVal.length;
    let nricChecking = /^\d{6}-\d{2}-\d{4}$/;
    let tmpBirthday = tmpVal.substr(0, 6);
    tmpBirthday = appendYearPrefix(tmpBirthday);
    let birthday = Moment(tmpBirthday, "YYYYMMDD");
    let isValid = birthday.isValid();

    if ((!isNaN(tmpVal) && digitLength >= 12) || nricChecking.test(tmpVal)) {
      return {
        dob: isValid ? birthday.format("DD-MM-YYYY") : "",
        gender: tmpVal % 2 === 0 ? "F" : "M",
        nationality: "Malaysia",
      }
    }

    if ((!isNaN(tmpVal) && digitLength >= 6) || nricChecking.test(tmpVal)) {
      return {
        dob: isValid ? birthday.format("DD-MM-YYYY") : "",
        nationality: "Malaysia",
        gender: ""
      }
    }
  };
  
  const debounceAddress = useCallback(
    _.debounce((val) => {
      return getAddressInfo(val);
    }, 500),
    [],
  );

  const onToggleUpdate = () => {
    let temp = _.cloneDeep(updateObj);
    delete temp.due_diligence_information;

    const checkOccupation = updateObj?.occupation !== '' 
      ?  _.find(occupationOptions, {
        value: updateObj?.occupation ?? "",
      }) 
      : true;
    const checkNationality = _.find(nationalities, { value: temp.nationality });

    if (!checkNationality && updateObj.signee_type_id === "0") {
      requestError("Please select nationality from the list");
    } else if (
      !checkOccupation &&
      updateObj.signee_type_id === "0"
    ) {
      requestError("Please select occupation from the list.");
    } else if (showDueDiligence) {
      let tmpDocData = {}

      if(updateObj.signee_type_id === "0") {
        if(updateObj?.due_diligence_information?.due_diligence_type_id === 1) {
          if( doc.identity_copy?.file && doc.identity_copy?.file_name ) {
            tmpDocData = {
              identity_copy: doc.identity_copy?.file,
              identity_copy_file_name: doc.identity_copy?.file_name,
            }
          } 
        } else {
          if(doc.supporting_document?.file && doc.supporting_document?.file_name) {
            tmpDocData = {
              supporting_document: doc.supporting_document?.file,
              supporting_document_file_name: doc.supporting_document?.file_name,
            }
          }
        }
      } else {
        if(doc.ssm_copy?.file && doc.ssm_copy?.file_name) {
          tmpDocData = {
            ssm_copy: doc.ssm_copy?.file,
            ssm_copy_file_name: doc.ssm_copy?.file_name,
          }
        }
      }
      updatePartyContact({
        ...updateObj,
        ...updateObj.due_diligence_information,
        ...tmpDocData
      });
    } else {
      updatePartyContact(updateObj);
    }
    onChangePartyHOC(null, "suggested_ic");
  };

  return (
    <>
      <ModalDialog
        title={`Edit ${title}`}
        contentClassName={"overflow-auto"}
        cardClassName={"overflow-visible"}
        containerClasses={{paperScrollPaper: "overflow-visible"}}
        maxHeight={true}
        onClose={() => onClose()}
        children={
          <form
            className="grid-control grid_gap-y-2"
            onSubmit={(e) => e.preventDefault()}
          >
            {updateObj.party_id % 2 !== 0 && (
              <section className="grid-full-col mb-3">
                <AtlasRadioGroup
                  horizontal={true}
                  disabled={true}
                  checkedValue={updateObj.signee_type_id}
                  containerClassName={"w-100"}
                  selectedRadioValue={(val) =>
                    onChangeUpdateValue(val, "signee_type_id")
                  }
                  options={[
                    { value: "0", label: "Personal" },
                    { value: "1", label: "Company" },
                  ]}
                />
              </section>
            )}
            {tempFieldData.map((item) => {
              return (
                <section key={item.value} className={item.fullCol ? "grid-full-col" : "grid-half-col"}>
                  <div className="d-flex grid_gap-2">
                    <h2 className="at-form-input__title" required={item.required || (typeof item?.onRequired === "function" && item?.onRequired({...updateObj, form_type: form_type, party_id: selectedContact.party_id}))}>
                      {item.title}
                    </h2>
                    {item.value === "mobile_contact_number" && (
                      <DarkTooltip
                        title={
                          "You may refer the country code provided by clicking this button."
                        }
                      >
                        <div
                          className="d-flex align-items-center"
                          style={{ gridGap: 4 }}
                        >
                          <IoMdHelpCircle
                            style={{
                              cursor: "pointer",
                              marginLeft: 8,
                              width: "auto",
                              height: "18px",
                              color: "#3C82F6",
                              marginBottom: "6px",
                            }}
                            onClick={() => onToggleCountryCode(true)}
                          />
                          <p
                            className="at-form-input__title"
                            style={{ color: "#3C82F6", cursor: "pointer" }}
                            onClick={() => onToggleCountryCode(true)}
                          >
                            Country Code
                          </p>
                        </div>
                      </DarkTooltip>
                    )}
                  </div>
                  {item.type === "autosuggest" && (
                    <AtlasAutosuggest
                      containerClass="mb-3"
                      className={`${errorList.some( error_value => error_value === item.value) ? "at-form-input--error" : ""}`}
                      disabled={!selected_form.participants_crud?.can_update}
                      required={item.required || (typeof item?.onRequired === "function" && item?.onRequired({...updateObj, form_type: form_type, party_id: selectedContact.party_id}))}
                      placeholder={item.placeholder}
                      value={updateObj[item.value] || ""}
                      updateValue={(val) => onChangeUpdateValue(val, item.value)}
                      options={item?.options || renderOptions(item.value)}
                    />
                  )}
                  {item.type === "radio" && (
                    <AtlasRadioGroup
                      wrapperClass="mb-3"
                      disabled={!selected_form.participants_crud?.can_update}
                      required={item.required || (typeof item?.onRequired === "function" && item?.onRequired({...updateObj, form_type: form_type, party_id: selectedContact.party_id}))}
                      options={GenderOptions}
                      checkedValue={updateObj[item.value] || ""}
                      selectedRadioValue={(val) =>
                        onChangeUpdateValue(val, item.value)
                      }
                    />
                  )}
                  {item.type === "calendar" && (
                    <div className={`at-form-input mb-3 ${!selected_form.participants_crud?.can_update ? "at-form-input__disabled" : ""} ${errorList.some( error_value => error_value === item.value) ? "at-form-input--error" : ""}`}>
                      <AtlasIcon
                        className={"at-form-input__calendar"}
                        svgHref={"atlas-calendar-input"}
                      />
                      <DatePicker
                        disabled={!selected_form.participants_crud?.can_update}
                        disableFuture={true}
                        value={updateObj[item.value] ? Moment(updateObj[item.value], "DD-MM-YYYY") : null}
                        onChange={(val) =>
                          onChangeUpdateValue(
                            Moment(val).format("DD-MM-YYYY"),
                            item.value,
                          )
                        }
                      />
                      { (item.required || (typeof item?.onRequired === "function" && item?.onRequired({...updateObj, form_type: form_type, party_id: selectedContact.party_id}))) && <div className="at-form-input__required">required *</div>}
                    </div>
                  )}
                  {item.type === "select" && (
                    <AtSelectNew
                      rootClass={`${errorList.some( error_value => error_value === item.value) ? "at-form-input--error" : ""}`}
                      placeholder={item.placeholder}
                      disabled={!selected_form.participants_crud?.can_update}
                      required={item.required || (typeof item?.onRequired === "function" && item?.onRequired({...updateObj, form_type: form_type, party_id: selectedContact.party_id}))}
                      value={updateObj[item.value]}
                      onChange={(e) =>
                        onChangeUpdateValue(e.target.value, item.value)
                      }
                    >
                      {item?.options?.map((option) => (
                        <MenuItem
                          classes={{ root: `at-select__dropdown-item` }}
                          key={option.value}
                          value={option.value}
                          disabled={option.disabled}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </AtSelectNew>
                  )}
                  {["text", "alphanumeric", "number", "textarea"].includes(item.type) && (
                    <CustomFormInput
                      type={item.type === "textarea" ? "textarea" : "text"}
                      containerClass="mb-3"
                      className={`${errorList.some( error_value => error_value === item.value) ? "at-form-input--error" : ""}`}
                      disabled={!selected_form.participants_crud?.can_update || (item.checkBoxValue && updateObj[item.checkBoxValue])}
                      required={item.required || (typeof item?.onRequired === "function" && item?.onRequired({...updateObj, form_type: form_type, party_id: selectedContact.party_id}))}
                      value={updateObj[item.value]}
                      onChangeValue={(val) => {
                        let temp = "";

                        switch (item.type) {
                          case "alphanumeric":
                            temp = val.replace(/[^$0-9a-zA-Z]/g, "");
                            break;

                          case "number":
                            temp = val.replace(/[^$0-9]/g, "");
                            break;

                          default:
                            temp = val;
                            break;
                        }
                        onChangeUpdateValue(temp, item.value);

                        if (item.value === "current_address") {
                          debounceAddress(val);
                        }
                      }}
                      inputError={""}
                      placeholder={item.placeholder}
                    />
                  )}
                  {!item.type && (
                    <p style={{ height: 40 }}>{updateObj[item.value]
                      ? updateObj[item.value]
                      : (!updateObj.current_address?.length > 0 ||
                        (item.value === "postcode" && updateObj.country !== "Malaysia"))
                        ? "-"
                        : <span style={{ color: "red" }}>
                            Missing {item.title?.toLowerCase()}, please add it to address
                          </span>
                    }</p>
                  )}
                  {item.instruction && (
                    <p
                      style={{
                        fontSize: "0.8rem",
                        color: "red",
                        maxWidth: "calc(100% - 60px)",
                        fontStyle: "italic",
                        transform: "translate(0px, -14px)",
                        height: "fit-content",
                      }}
                    >
                      {item.instruction}
                    </p>
                  )}
                  { item.checkBoxValue && [1, 3].indexOf(updateObj.party_id) > -1 && (
                    <CustomCheckbox
                      disabled={!selected_form.participants_crud?.can_update}
                      labelClassname={"mb-2"}
                      labelStyle={{transform: 'translate(0px, -0.5rem)'}}
                      checked={updateObj[item.checkBoxValue]}
                      content={
                        <span className="fw-500">
                          {item.checkBoxDescription}
                        </span>
                      }
                      onChangeCheckboxValue={() => onChangeUpdateValue(!updateObj[item.checkBoxValue], item.checkBoxValue)}
                    />
                  ) }
                </section>
              )
            })}
            {showDueDiligence && (
              <div className="at-form__content at-new_container-16 mt-2 grid-full-col mb-0">
                <div className="d-flex align-items-center w-100">
                  <button
                    className={"btn w-100 p-0 text-left"}
                    onClick={() =>
                      onToggleexpandDueDiligence(!expandDueDiligence)
                    }
                  >
                    <h5 className="at-card_content-title">
                      {selectedContact.due_diligence_type_id === 0 && (
                        <FaExclamationCircle
                          style={{
                            width: 20,
                            height: 20,
                            color: "red",
                            marginRight: 10,
                          }}
                        />
                      )}
                      Anti-Money Laundering Act Compliance
                      <IconButton
                        style={{ color: "#F18E06", padding: 5, marginLeft: 10 }}
                      >
                        {expandDueDiligence ? (
                          <AtlasIcon
                            style={{ width: 20, height: 20, color: "#3C82F6" }}
                            svgHref={"atlas-vuesax-arrow-circle-up"}
                          />
                        ) : (
                          <AtlasIcon
                            style={{ width: 20, height: 20, color: "#3C82F6" }}
                            svgHref={"atlas-vuesax-arrow-circle-down"}
                          />
                        )}
                      </IconButton>
                    </h5>
                  </button>
                </div>
                <Collapse in={expandDueDiligence} timeout="auto" unmountOnExit>
                  <DueDiligenceContent
                    doc={doc}
                    form_type={form_type}
                    isView={!selected_form.participants_crud?.can_update}
                    updateObj={updateObj}
                    onLoadParty={onLoadParty}
                    selected_form={selected_form}
                    selectedContact={selectedContact}
                    setDoc={setDoc}
                    onClose={onClose}
                    onChangePartyHOC={onChangePartyHOC}
                    onChangeUpdateObj={onChangeUpdateObj}
                    downloadCompletedForm={downloadCompletedForm}
                    getSelectedFormContact={getSelectedFormContact}
                    initiateIndemnityLetter={initiateIndemnityLetter}
                    extactIcDetails={extactIcDetails}
                    suggested_ic={suggested_ic}
                  />
                </Collapse>
              </div>
            )}
          </form>
        }
        footer={
          <div className="d-flex g-3">
            <button
              type={"button"}
              disabled={checkRequiredValue || !selected_form.participants_crud?.can_update}
              className="btn-new btn-new--success"
              onClick={() => onToggleUpdate()}
            >
              Update
            </button>
            <button
              type={"button"}
              className="btn-new btn-new--outline-secondary"
              onClick={() => onClose()}
            >
              Close
            </button>
          </div>
        }
      />
      {showCountryCodeModal && (
        <CountryCodeContent onClose={() => onToggleCountryCode(false)} />
      )}
      {showScanIcConfirmationModal && (
        <Dialog open={true} classes={{ paper: "m-0" }} maxWidth={"lg"}>
          <ConfirmationModal
            title={"Different IC/Passport Number Detected"}
            open={true} 
            message={
              <>
                <p>The uploaded file IC/Passport number is <b>{suggested_ic}</b> is</p>
                <p>different from the existing number <b>{updateObj.identification_number}</b>.</p>
                <p>Which one would you like to use?</p>
              </>
            }
            negativeText={"Keep Existing"}
            positiveText={"Use Scanned"}
            positiveAction={() => {
              onChangeUpdateValue(suggested_ic, 'identification_number');
              onChangePartyHOC(null, "suggested_ic");
              dobGenerator(suggested_ic)
              onToggleShowScanIc(false);
            }}
            negativeAction={() => {
              onChangePartyHOC(null, "suggested_ic");
              onToggleShowScanIc(false);
            }}
          />
        </Dialog>
      )}
      {onLoadParty && <LoadingModal />}
    </>
  );
};

export default UpdateAssignContact;
