import React, { useState, useEffect, useCallback } from "react";
import { MenuItem, TextField } from "@material-ui/core";
import _ from "lodash";
import Moment from "moment";

import CustomSelect from "components/Select";
import AtlasSelect from "components/Select/new";
import CustomCheckbox from "components/Checkbox";
import AtlasRadioGroup from "components/RadioGroup";
import AtlasFormInput from "components/Input/formInput";
import CustomTypeahead from "components/Typeahead/new";
import AtlasAutosuggest from "components/Autosuggest";
import DatePicker from "components/Input/datetimepicker";

const GenderOptions = [
  { id: "M", label: "Male", value: "M" },
  { id: "F", label: "Female", value: "F" },
];

const appendYearPrefix = (DOB) => {
  let checkAbove00Gen = new RegExp(/[0-3]/);
  let checkBelow90Gen = new RegExp(/[4-9]/);
  let firstDigit = DOB[0];

  if (checkAbove00Gen.test(firstDigit)) {
    return `20${DOB}`;
  } else if (checkBelow90Gen.test(firstDigit)) {
    return `19${DOB}`;
  }
};

const FormInput = ({
  regions,
  formMeta,
  formData,
  setFormData,
  addressInfo,
  addressError,
  getAddressInfo,
  processNationality
}) => {
  const { label, value, type, show, placeholder = "", options, required, search_value, fullCol } = formMeta;
  const {
    titleContext,
    banks,
    title,
    disabledEdit,
    bank_id,
    bank_account_number,
    payable_name,
    referral_sources,
    storedAddresses,
    nationalities,
    onChangeValue,
  } = formData;

  const tmpRequired = typeof required === "function" 
    ? required({titleContext: titleContext}) 
    : required


  const [isError, setError] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    const checkAddEdit = (title === "Add" && !isInitialLoad) || title === "Edit";
    if(checkAddEdit) {
      if(value === "date_of_birth" && formData.nric) {
        setError(_.isEmpty(formData[value]))
      } else if(["select", "typeahead", "autosuggest"].indexOf(type) > -1 && (value !== "address")) {
       if(value === "nationality_id") {
          const tmpOptions = renderOptions(value)
          const selectedObj = _.find(tmpOptions, item => item.id === formData[value]);  
          setError(!selectedObj || (tmpRequired && !formData[value]))
        } else {
          const tmpOptions = options || renderOptions(value)
          const selectedObj = _.find(tmpOptions, item => item.value === formData[value]);  
          setError(!selectedObj || (tmpRequired && !formData[value]))
        }
      } else if(tmpRequired) {
        setError(!formData[value])
      }
    }
  }, [formData])

  useEffect(() => {
    if (!_.isEmpty(addressInfo)) {
      let temp = _.cloneDeep(formData);
      temp.country = addressInfo?.country || "";
      temp.state = addressInfo?.state || "";
      temp.town = addressInfo?.city || "";
      temp.postcode = addressInfo?.postcode || "";
      setFormData(temp);
    }
  }, [addressInfo])

  const onChangeIsSelected = (index) => {
    let temp = _.cloneDeep(formData).referral_sources;
    temp[index].is_selected = !temp[index].is_selected;
    onChangeValue("referral_sources", temp);
  };

  const onChangeReferralSources = (index, val) => {
    let temp = _.cloneDeep(formData).referral_sources;
    temp[index].amount = val;
    onChangeValue("referral_sources", temp);
  };

  const dobGenerator = (val) => {
    let tmpVal = val.toString();
    let digitLength = tmpVal.length;
    let nricChecking = /^\d{6}-\d{2}-\d{4}$/;
    let tmpBirthday = tmpVal.substr(0, 6);
    tmpBirthday = appendYearPrefix(tmpBirthday);
    let birthday = Moment(tmpBirthday, "YYYYMMDD");
    let isValid = birthday.isValid();

    if ((!isNaN(tmpVal) && digitLength >= 6) || nricChecking.test(tmpVal)) {
      onChangeValue("date_of_birth", isValid ? birthday.format("DD-MM-YYYY") : "");
      onChangeValue("nationality", "Malaysia");
      onChangeValue("gender", "");
    } else {
      onChangeValue("date_of_birth", "");
      onChangeValue("nationality", "");
    }

    if ((!isNaN(tmpVal) && digitLength >= 12) || nricChecking.test(tmpVal)) {
      onChangeValue("date_of_birth", isValid ? birthday.format("DD-MM-YYYY") : "");
      onChangeValue("gender", tmpVal % 2 === 0 ? "F" : "M");
      onChangeValue("nationality", "Malaysia");
    }
  };

  const debounceAddress = useCallback(
    _.debounce((val) => {
      return getAddressInfo(val);
    }, 500),
    [],
  );

  const renderAmount = () => {
    return (
      show(formData) && (
        <>
          {value && (
            <div className={"at-form_field-col-6"}>
              <h2 className="at-form-input__title" 
                required={tmpRequired}>
                {label}
              </h2>
              <AtlasFormInput
                type="number"
                placeholder={placeholder}
                required={tmpRequired}
                disabled={disabledEdit}
                value={`${formData[value]}`}
                onChangeValue={(val) => onChangeValue(value, val)}
              />
            </div>
          )}
          <div className="at-form__content at-form_fields_cont at-form_field-col-12 p-3 mt-3 mb-0">
            <div className="at-form_field-col-6">
              <h2 className="at-form-input__title">{"Bank Name"}</h2>
              <AtlasSelect
                value={bank_id || ""}
                disabled={disabledEdit}
                onChange={(e) => onChangeValue("bank_id", e.target.value || 0)}>
                <MenuItem
                  classes={{ root: `at-select__dropdown-item at-select__dropdown-placeholder` }}
                  key={"placeholder"}
                  value={""}
                >
                  Select Bank Name
                </MenuItem>
                {banks?.map((item) => (
                  <MenuItem
                    classes={{ root: `at-select__dropdown-item` }}
                    key={item.id}
                    value={item.id}>
                    {item.name} - {item.country?.name ?? "N/A"}
                  </MenuItem>
                ))}
              </AtlasSelect>
            </div>
            <div className="at-form_field-col-6">
              <h2 className="at-form-input__title">
                {"Bank Account Number"}
              </h2>
              <AtlasFormInput
                type="text"
                disabled={disabledEdit}
                placeholder="Enter Bank Account Number"
                value={`${bank_account_number ? bank_account_number : ""}`}
                onChangeValue={(val) =>
                  onChangeValue("bank_account_number", val)
                }
              />
            </div>
            <div className="at-form_field-col-6">
              <h2 className="at-form-input__title">{"Bank Payable Name"}</h2>
              <AtlasFormInput
                type="text"
                placeholder="Enter Bank Payable Name"
                disabled={disabledEdit}
                value={`${payable_name ? payable_name : ""}`}
                onChangeValue={(val) => onChangeValue("payable_name", val)}
              />
            </div>
            {title !== "Add" && value === "commission_amount" && (
              <section className="at-subsales_claims-commission_deduct-cont at-form_field-col-6">
                <h2 className="at-form-input__title">
                  {"Deduct referral fee from (RM)"}
                </h2>
                {referral_sources &&
                  referral_sources.length > 0 &&
                  referral_sources.map((item, index) => (
                    <div
                      key={index}
                      className="w-auto at-subsales_claims-commission_deduct-list p-0 d-inline-flex">
                      <CustomCheckbox
                        disabled={disabledEdit}
                        labelClassname={"mr-10"}
                        content={item.internal_agent_display_name}
                        checked={item.is_selected}
                        onChangeCheckboxValue={() =>
                          onChangeIsSelected(index)
                        }
                      />
                      <TextField
                        disabled={disabledEdit}
                        value={item.amount}
                        onChange={(e) =>
                          onChangeReferralSources(index, e.target.value)
                        }
                      />
                    </div>
                  ))}
                {!(referral_sources && referral_sources.length > 0) && (
                  <div>No referrals found.</div>
                )}
                <p>
                  Note: Referral commission should not be more than 20% of
                  commission received.
                </p>
              </section>
            )}
          </div>
        </>
      )
    );
  };

  const renderOptions = (value) => {
    switch (value) {
      case "address":
        return storedAddresses || [];
      case "nationality":
        return nationalities || [];
      default:
        return [];
    }
  };

  if (type === "radio") {
    return (
      show(formData) && (
        <div className="at-form_field-col-6">
          <h2 className="at-form-input__title" required={tmpRequired}>
            {label}
          </h2>
          <AtlasRadioGroup
            required={tmpRequired}
            disabled={disabledEdit}
            options={value === "gender" ? GenderOptions : []}
            checkedValue={formData[value]}
            selectedRadioValue={(val) => onChangeValue(value, val)}
          />
        </div>
      )
    );
  }

  if (['form', 'textarea'].includes(type)) {
    return (
      show(formData) && (
        <div className={fullCol ? "at-form_field-col-12" : "at-form_field-col-6"}>
          <h2 className="at-form-input__title" 
            required={tmpRequired}>
            {label}
          </h2>
          <AtlasFormInput
            type={type === "textarea" ? "textarea" : "text"}
            className={`${isError ? "at-form-input--error" : ""}`}
            required={tmpRequired}
            placeholder={placeholder}
            disabled={disabledEdit || (typeof formMeta.onDisable === "function" && formMeta.onDisable(formData))}
            value={`${formData[value] || ""}`}
            showClearButton={typeof formMeta?.showClearButton === "function" ? formMeta.showClearButton(formData) : formMeta.showClearButton}
            clearButtonMode="New"
            onChangeValue={(val) => {
              setIsInitialLoad(false)
              Promise.all([
                onChangeValue(
                  value,
                  ![ "company_registration_no", "company_name", "full_name", "job_title", "address" ].includes(value)
                    ? val.replace(" ", "")
                    : val,
                ),
              ]).then(() => {
                if (value === "nric") {
                  dobGenerator(val);
                }

                if (value === "address") {
                  debounceAddress(val);
                }
              });
            }}
          />
          {(value === "address" && addressError && title === "Add") && (
            <p style={{color: "#ff0000", marginTop: 2}}>{addressError}</p>
          )}
        </div>
      )
    );
  }

  if (type === "calendar") {
    return (
      show(formData) && (
        <div className="at-form_field-col-6">
          <h2 className="at-form-input__title" required={tmpRequired}>
            {label}
          </h2>
          <div
            className={`at-form-input ${disabledEdit ? " at-form-input__disabled" : " "} ${isError ? "at-form-input--error" : ""}`}
          >
            <DatePicker
              disabled={disabledEdit}
              disableFuture={true}
              value={formData[value] ? Moment(formData[value], "DD-MM-YYYY") : null}
              onChange={(val) => {
                setIsInitialLoad(false)
                onChangeValue(value, Moment(val).format("DD-MM-YYYY"))
              }}
            />
            { tmpRequired && <div className="at-form-input__required">required *</div> }
          </div>
        </div>
      )
    );
  }

  if (type === "select") {
    return (
      show(formData) && (
        <div className="at-form_field-col-6">
          <h2 className="at-form-input__title" required={tmpRequired}>
            {label}
          </h2>
          <CustomSelect
            selectClassName={isError ? "at-form-input--error" : ""}
            placeholder={placeholder}
            required={tmpRequired}
            disabled={disabledEdit}
            className="w-100"
            selectItems={renderOptions(value)}
            valueKey="value"
            currentlySelected={
              renderOptions(value)?.find((item) => item?.name === formData?.[value]) ?? {}
            }
            updateSelect={(selectedItem) => {
              setIsInitialLoad(false)
              onChangeValue(value, selectedItem?.name || "")
            }}
          />
        </div>
      )
    )
  }

  if (type === "typeahead" && value === "nationality") {
    return (
      show(formData) && (
        <div className="at-form_field-col-6">
          <h2 className="at-form-input__title" required={tmpRequired}>
            {label}
          </h2>
          <CustomTypeahead
            required={tmpRequired}
            placeholder={placeholder}
            disabled={disabledEdit}
            selectedValue={processNationality(formData[value], nationalities)}
            typeaheadId={"nationality"}
            options={nationalities?.map((item) => ({...item, label: item.country_name, value: item.id}))}
            labelKey={"label"}
            filterBy={["label"]}
            onSelect={(val) => {
              if (val && val.length > 0) {
                setIsInitialLoad(false)
                onChangeValue("nationality", val[0].name);
              }
            }}
            childrenHead={(rowItem) => <p>{rowItem.label}</p>}
          />
        </div>
      )
    );
  }

  if (type === "typeahead" && value === "region") {
    return (
      show(formData) && (
        <div className="at-form_field-col-6">
          <h2 className="at-form-input__title" required={tmpRequired}>
            {"Region"}
          </h2>
          <CustomTypeahead
            required={tmpRequired}
            placeholder={placeholder}
            disabled={disabledEdit}
            typeaheadId={"region"}
            options={regions}
            labelKey={"name"}
            filterBy={["name"]}
            onSelect={(val) => {
              if (val && val.length > 0) {
                setIsInitialLoad(false)
                onChangeValue("region", val[0].name);
              }
            }}
            selectedValue={
              _.find(regions, { name: formData[value] })
                ? [_.find(regions, { name: formData[value] })]
                : []
            }
            childrenHead={(rowItem) => <p>{rowItem.name}</p>}
            highlighterData={[(option) => option.reference_id]}
          />
        </div>
      )
    );
  }

  if (type === "autosuggest") {
    const tmpOptions = options || renderOptions(value)

    return (
      show(formData) && (
        <div className="at-form_field-col-6">
          <h2 className="at-form-input__title" 
            required={tmpRequired}>
            {label}
          </h2>
          <AtlasAutosuggest
            className={ `${isError ? "at-form-input--error" : ""}`}
            required={tmpRequired}
            placeholder={placeholder}
            disabled={disabledEdit}
            value={formData[value] || ""}
            updateValue={async (val) => {
              if(search_value) {
                let { label, value: option_value } = await _.find(tmpOptions, option => option?.label?.toLowerCase() === val?.toLowerCase()) || {};
                if(label){
                  await onChangeValue(option_value, value)
                }
                await onChangeValue(search_value, val)
              } else {
                await onChangeValue(value, val)
              }
              setIsInitialLoad(false)
            }}
            onSelect={ obj => onChangeValue(value, obj.value)}
            options={tmpOptions}
          />
        </div>
      )
    );
  }

  if (type === "expandable") {
    return renderAmount();
  }

  if (!type && ['state', 'town', 'postcode', 'country'].includes(value) && title === "Edit") {
    return (
      <div className="at-form_field-col-6">
        <h2 className="at-form-input__title">
          {label}
        </h2>
        <p>{formData[value]
          ? formData[value]
          : <span style={{ color: "red" }}>Missing {label?.toLowerCase()}, please add it to address</span>
        }</p>
      </div>
    )
  }

  return <></>;
};

export default FormInput;
