import React, { Component } from "react";
import { compose } from "redux";
import { FaUserTie } from "react-icons/fa";
import { MenuItem } from "@material-ui/core";
import _ from "lodash"

import AtlasTable from "components/NewPaginatedTable";
import AtlasIcon from "components/Icon/atlasIcon";
import ModalDialog from "components/Modal/ModalDialog";
import CustomDropdown from "components/Button/dropdown";
import CustomButton from "components/Button";
import AtlasFormInput from "components/Input/formInput";
import ContactFormDialog from "../components/formDialog";
import LoadingModal from "components/LoadingModal";
import DeleteConfirmationModal from "components/Modal/delete";
import CustomCheckbox from "components/Checkbox";
import ShareLinkToRepresentative from "containers/Dashboard/SubsalesClaims/containers/ClaimForm/components/shareLinkToRepresentative"
import { oppositeInfoStatusBadges } from "containers/Dashboard/SubsalesClaims/assets"
import ConfirmationModal from "components/Modal/confirmation"

import { getColorBadge } from "dictionary/badgeColor";
import permissionsChecker from "utils/permissionsChecker";
import OppositeParticipant from "../../../actions/oppositeParticipant";
import "stylesheets/containers/subsales-claims/index.scss";

const columnData = [
  {
    header: "Full Name",
    renderColumn: (rowData) => (
      <div className={"d-flex align-items-center party-card__badge-title-icon at-subsales-contact_form_type-title-icon"}>
        {["3", "4"].indexOf(rowData.contact_type_id) === -1 && (
          <>
            {rowData.is_company && (
              <>
                <AtlasIcon svgHref={"atlas-building-company"}/>
                <div className={"ml-1"}>
                  {rowData.company_name}
                  <br />({rowData.company_registration_no})
                  <br />
                  {rowData.full_name}
                </div>
              </>
            )}
            {!rowData.is_company && (
              <>
                <AtlasIcon svgHref={"atlas-icon-user"}/>
                {rowData.full_name}
              </>
            )}
          </>
        )}
        {["3", "4"].indexOf(rowData.contact_type_id) > -1 && (
          <>
            <FaUserTie />
            {rowData.company_name}
            <br />({rowData.company_registration_no})
          </>
        )}
      </div>
    ),
  },
  { header: "NRIC/Passport No.", accessor: "nric" },
  { header: "Mobile/Office Contact No.", accessor: "contact_number" },
];
class Client extends Component {
  state = {
    newTypeId: "",
    toRemoveItem: {},
    showConfirmationModal: false,
    info_provided_by_opposite_agent: false
  };

  componentDidMount = () => {
    Promise.all([
      this.props.onChangeOppositePaticipantHOC(this.props.selected_claim.opposite_info_password,"infoPassword")
    ]).then( () => {
      this.props.onChangeOppositePaticipantHOC( 2, "currentTypeId")
    })
    this.props.getAssignedContactType(this.props.selected_claim.id, 2);
    this.setState({ info_provided_by_opposite_agent: this.props.selected_claim.info_provided_by_opposite_agent })
  };

  componentDidUpdate = prevProps => {
    if(prevProps.selected_claim.info_provided_by_opposite_agent !== this.props.selected_claim.info_provided_by_opposite_agent) {
      this.setState({
        info_provided_by_opposite_agent: this.props.selected_claim.info_provided_by_opposite_agent
      })
    }
  }

  onCloseSendEmail = () => Promise.all([
    this.props.onChangeOppositePaticipantHOC(false, "showSendEmailLink")
  ]).then(() => {
    this.props.onChangeOppositePaticipantHOC("", "recipientEmail")
  })

  updateSelectedEntity = (data) => {
    this.props.updateSelectedEntity( data, this.props.selected_claim.id, 2, data.id );
  };

  onClickEditProfile = (data) => {
    this.props.onChangeAgencyHOC( "addressInfo", {} );
    this.props.getSelectedEntity( this.props.selected_claim.id, data.type_id, data.id );
  }

  onClickRemoveData = (data) =>
    this.setState({ showConfirmationModal: true, toRemoveItem: data });

  deleteSelectedItem = () =>
    this.setState({ showConfirmationModal: false }, () => {
      this.props.removeSelectedEntity(
        this.props.selected_claim.id,
        2,
        this.state.toRemoveItem.id,
      );
    });

  renderEditDialog = () => {
    // If claim owner is purchaser/tenant and contact type is not bupurchaseryer/tenant, email and mobile number are optional to be filled in 
    const tmpIsPrimaryClaimRepresentative = this.props.selected_claim.representative_id === 1 && this.props.selectedContact.contact_type_id === 2;
    if (this.props.showEditEntityDialog) {
      return (
        <ContactFormDialog
          title={"Edit"}
          formTypes={this.props.selectedContact.contact_type_id === 2 ? this.props.formTypes : []}
          allowToHideContact={tmpIsPrimaryClaimRepresentative}
          personalForms={this.props.personalForms}
          companyForms={this.props.companyForms}
          loading={this.props.onLoadContactType}
          onChangeContactTypeHOC={this.props.onChangeContactTypeHOC}
          type={
            this.props.selectedContact.contact_type_id === 2
              ? this.props.selected_claim.sub_sale_type_id === 2
                ? "Tenant"
                : "Purchaser"
              : "Law Firm"
          }
          onToggleDialog={() =>
            this.props.onChangeContactTypeHOC(false, "showEditEntityDialog")
          }
          onChangeValue={this.props.onChangeEditContact}
          submitData={(data) =>
            this.props.submitUpdatedData(this.props.selected_claim.id, data)
          }
          banks={this.props.banks}
          nationalities={this.props.nationalities}
          storedAddresses={this.props.data.addressReducer.address}
          selectedClaim={this.props.selected_claim}
          selectedContact={this.props.selectedContact}
          suggested_ic={this.props.suggested_ic}
          extactIcDetails={this.props.extactIcDetails}
          addressInfo={this.props.addressInfo}
          getAddressInfo={this.props.getAddressInfo}
          onChangeAgencyHOC={this.props.onChangeAgencyHOC}
          requestContactFromOppositeAgent={this.props.requestContactFromOppositeAgent}
        />
      );
    }
  };

  renderNewDialog = () => {
    // If claim owner is purchaser/tenant and contact type is not purchaser/tenant, email and mobile number are optional to be filled in 
    let tmpIsPrimaryClaimRepresentative = this.props.selected_claim.representative_id === 1 && this.state.newTypeId === "2";
    if (this.props.showNewEntityDialog) {
      return (
        <ContactFormDialog
          title={"Add"}
          type={this.state.newTypeId === "2" 
            ? this.props.selected_claim.sub_sale_type_id === 2
              ? "Tenant"
              : "Purchaser" 
            : "Law Firm"
          }
          formTypes={this.state.newTypeId === "2" ? this.props.formTypes : []}
          allowToHideContact={tmpIsPrimaryClaimRepresentative}
          personalForms={this.props.personalForms}
          companyForms={this.props.companyForms}
          loading={this.props.onLoadContactType}
          banks={this.props.banks}
          onChangeContactTypeHOC={this.props.onChangeContactTypeHOC}
          onToggleDialog={() =>
            this.props.onChangeContactTypeHOC(false, "showNewEntityDialog")
          }
          submitData={(newContact) => {
            let tmpNewContact = {
              ...newContact,
              contact_type_id: this.state.newTypeId,
              type_id: 2,
            };
            this.props.createEntity(
              tmpNewContact,
              this.props.selected_claim.id,
              2,
            );
          }}
          addressInfo={this.props.addressInfo}
          nationalities={this.props.nationalities}
          selectedClaim={this.props.selected_claim}
          getAddressInfo={this.props.getAddressInfo}
          onChangeAgencyHOC={this.props.onChangeAgencyHOC}
          storedAddresses={this.props.data.addressReducer.address}
          requestContactFromOppositeAgent={this.props.requestContactFromOppositeAgent}
        />
      );
    }
  };

  onClickSelectFromContacts = (party_title) => {
    this.props.updateSelectedParticipantTypeId(2)
    this.props.updateSelectedTitle(party_title)
    this.props.onChangeSubsaleClaimsHOC(true, "showAssignContactModal")
  }

  renderDropDown = (party_title, oppositeClaimChecking) => {
    return (
      <CustomDropdown 
        label={`Add ${party_title}`} 
        disabled={oppositeClaimChecking}>
        <MenuItem
          onClick={() => this.onClickSelectFromContacts(party_title)}
          classes={{ root: `at-btn__dropdown--list` }}>
          Select from My Contact
        </MenuItem>
        <MenuItem
          onClick={() => {
            if(party_title !== "Law Firm") {
              this.props.onChangeContactTypeHOC(
                true,
                "showNewEntityDialog",
              );
              this.setState({ newTypeId: "2" });
            } else {
              this.props.onChangeAgencyHOC( "addressInfo", {} );
              this.props.onChangeContactTypeHOC(
                true,
                "showNewEntityDialog",
              );
              this.setState({ newTypeId: "4" });
            }
          }}
          classes={{ root: `at-btn__dropdown--list` }}>
          Create a New Contact
        </MenuItem>
      </CustomDropdown>
    );
  }

  render = () => {
    const { can_create, can_update, can_destroy } = permissionsChecker(
      "Subsales Claims",
      this.props.data,
    );
    const { clients } = this.props;
    const { 
      form_type_id,
      opposite_info_status, 
      opposite_info_link,
      representative_id,
      opposite_representative_party
    } = this.props.selected_claim
    const tmpOppositeClaimChecking = representative_id === 1 && this.state.info_provided_by_opposite_agent // For opposite claim only, disabled when info_provided_by_opposite_agent is true

    let tmpOppositeRepresentativeParty = opposite_representative_party?.toLowerCase() ?? ""

    const { colorName = "" } =
      _.find(
        oppositeInfoStatusBadges,
        item => item.value === opposite_info_status,
      ) || {};
    return (
      <>
        <AtlasTable
          columns={columnData}
          meta={[]}
          searchParams={[]}
          totalPages={["1"]}
          rowData={clients || []}
          headerClass={"at-form_mngmt__table-header"}
          actionColumnContent={ [
            {
              name: "info",
              onShow: () => !can_update || tmpOppositeClaimChecking,
              onClick: (rowData) => this.onClickEditProfile(rowData),
            },
            {
              name: "edit",
              onShow: () => can_update && !tmpOppositeClaimChecking,
              iconClass: "at-table-new-icon-button ",
              iconStyle: { width: 20, height: 20, color: "#007bff" },
              onClick: (rowData) => this.onClickEditProfile(rowData),
            },
            {
              name: "delete",
              onShow: () => can_destroy && !tmpOppositeClaimChecking,
              iconClass: "at-table-new-icon-button ",
              iconStyle: { width: 20, height: 20, fill: "#EB3134" },
              onClick: (rowData) => this.onClickRemoveData(rowData),
            },
          ]}
          renderExtraContent={
            can_create
              ? () => (
                <>
                  {
                    (representative_id === 1 && form_type_id !== 4) && (
                      <div className={`at-table_heading flex-column align-items-start grid_gap-0 mb-lg-0 mb-3`}>
                        <CustomCheckbox
                          labelClassname={"at-party-checkbox"}
                          content={ <p className="at-form-input__title fw-500 mb-0">Let {tmpOppositeRepresentativeParty} or representative to add & update {tmpOppositeRepresentativeParty} details</p> }
                          checked={this.state.info_provided_by_opposite_agent}
                          onChangeCheckboxValue={() => {
                            if(!this.state.info_provided_by_opposite_agent) {
                              this.props.onChangeOppositePaticipantHOC(true, "showShareLink")
                            } else {
                              this.props.onChangeOppositePaticipantHOC(true, "showRemoveShareLinkConfirmation")
                            }
                          }}
                        />
                        {(this.state.info_provided_by_opposite_agent && (opposite_info_link && opposite_info_link !== null)) && (
                          <a 
                            className="at-form-input__title fw-500 ml-4 pl-1" 
                            style={{color: "#2563EB"}}
                            onClick={() => {
                              this.props.onChangeOppositePaticipantHOC(true, "showShareLink")
                            }}>Share Link</a>
                        )}
                        {
                          opposite_info_status !== null && (
                            <div className="ml-4 pl-1">
                              <p className="at-form-input__title fw-500 mb-1">Representative Updated Status</p>
                              <div>
                                <span
                                  style={{ ...getColorBadge(colorName) }}
                                  className={`at-status_badge`}>
                                  {opposite_info_status}
                                </span>
                              </div>
                            </div>
                          )
                        }
                      </div>
                    )
                  }
                  <div className="at-table_heading g-2">
                    {this.renderDropDown(this.props.selected_claim.sub_sale_type_id === 2 ? "Tenant" : "Purchaser", tmpOppositeClaimChecking)}
                    <CustomButton
                      className="btn-new btn-new--primary"
                      disabled={tmpOppositeClaimChecking}
                      onClick={() => {
                        this.props.onChangeContactTypeHOC(
                          true,
                          "showNewEntityDialog",
                        );
                        this.setState({ newTypeId: "4" });
                      }}
                      children="Add Law Firm"
                      tooltip={ false }
                      tooltipChildren={ "Details cannot be added until representative updated status is Complete or uncheck the Share Link" }/>
                  </div>
                </>
              )
              : ""
          }
        />
        {
          this.props.showShareLink && (
            <ShareLinkToRepresentative
              representative_title={this.props.selected_claim.opposite_representative_party}
              infoPassword={this.props.infoPassword}
              onChangeInfoPassword={val => this.props.onChangeOppositePaticipantHOC(val, "infoPassword")}
              disabledShareButtons={this.props.disabledShareButtons}
              selected_claim={this.props.selected_claim}
              opposite_info_status={opposite_info_status}
              opposite_info_link={opposite_info_link}
              info_provided_by_opposite_agent={this.state.info_provided_by_opposite_agent}
              updateOppositeInfoProvided={this.props.updateOppositeInfoProvided}
              sendOppositeInfoRequestEmail={this.props.sendOppositeInfoRequestEmail}
              onClickShareEmail={ () => this.props.onChangeOppositePaticipantHOC(true, "showSendEmailLink") }
              onToggleDialog={val => {
                if(opposite_info_link === null) {
                  this.setState({ info_provided_by_opposite_agent: false })
                }
                this.props.onChangeOppositePaticipantHOC(val, "showShareLink")
              }}/>
          )
        }
        {this.renderNewDialog()}
        {this.renderEditDialog()}
        {this.props.onLoadContactType && <LoadingModal />}
        {
          this.props.showSendEmailLink && (
            <ModalDialog
              title={`Send Link Via Email`}
              onClose={() => this.onCloseSendEmail()}
              children={
                <div>
                  <p className="fs-2">Please include a , between email addresses if multiple emails need to be included as recipients.</p>
                  <p className="fs-2">For example: test@gmail.com,john@gmail.com</p>
                  <div className="mt-3">
                    <h2 className={`at-form-input__title`} required>Recipient's Email</h2>
                    <AtlasFormInput
                      type="text"
                      required={true}
                      value={`${this.props.recipientEmail || ""}`}
                      onChangeValue={(val) => this.props.onChangeOppositePaticipantHOC(val, "recipientEmail")}
                    />
                  </div>
                </div>
              }
              footer={
                <div className="d-flex grid_gap-1">
                  <button className="btn-new btn-new--primary" onClick={ () => {
                    this.props.sendOppositeInfoRequestEmail( this.props.selected_claim.id,{
                      recipients: this.props.recipientEmail,
                    })
                  }}>Send</button>
                  <button
                    className="btn-new btn-new--outline-secondary"
                    onClick={ () => this.onCloseSendEmail() }>
                    Cancel
                  </button>
                </div>
              }/>
          )
        }
        <ConfirmationModal
          mode={"info"}
          open={this.props.showRemoveShareLinkConfirmation}
          loading={this.props.onLoadOppositeParticipant}
          message={"The representative will not be able to access or update the details of the case participants."}
          title={"Remove Share Link"}
          positiveAction={ () => this.props.updateOppositeInfoProvided(this.props.selected_claim.id, { info_provided_by_opposite_agent: false }) }
          positiveText={"Confirm"}
          negativeAction={ () => this.props.onChangeOppositePaticipantHOC(false, "showRemoveShareLinkConfirmation") }
          negativeText={"Cancel"}
        />
        <DeleteConfirmationModal
          open={this.state.showConfirmationModal}
          loading={this.props.onLoadContactType}
          positiveAction={this.deleteSelectedItem}
          negativeAction={() => this.setState({ showConfirmationModal: false })}
          message={`You will not able to recover the data`}
        />
        
      </>
    );
  };
}

export default compose(OppositeParticipant)(Client);
