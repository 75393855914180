import React, { Component } from "react";
import _ from "lodash";
import Moment from "moment";
import { connect } from "react-redux";
import { Post, Put, Get, Delete, GetFile } from "utils/axios";
import { storeLastView } from "actions/lastView";

import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      modules: [],
      searchParams: [
        {
          label: "Module",
          value: "module_id_eq",
          type: "select",
          param: "",
          options: [],
        },
        {
          label: "Key",
          value: "key_cont",
          type: "input",
          param: "",
        },
        {
          label: "Default Translation",
          value: "default_translation_cont",
          type: "input",
          param: "",
        },
      ],

      settings: [],
      moduleKeys: [],
      localisedData: {
        data: [],
        meta: {}
      },
      localisedPages: [],
      selectedLocalised: null,
      selectedTranslation: null,

      showCreateModal: false,
      showUpdateModal: false,
      showBulkUpdate: false,
      showSettingsModal: false,
      showImportTranslation: false,
      showModifyTranslation: false,
      showDeleteConfirmation: false,
    }

    load = (param) => this.setState({ loading: param });
    onChangeLocalisedHOC = (val, context) => this.setState({ [context]: val });

    getLocalisedCentre = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);
      Get(
        `/admin/translations?${search}page=${page}`,
        this.getLocalisedCentreSuccess,
        this.getLocalisedCentreError,
        this.load
      );
    }
    getLocalisedCentreSuccess = (payload) => {
      let tempPages = [];
      let tempData = [];
      for (let index = 0; index < payload.meta.pages; index++) {
        tempPages.push(index);
      }

      this.setState({
        localisedData: payload,
        localisedPages: tempPages,
      });
    }
    getLocalisedCentreError = (error) => requestError(error);

    getSelectedLocalised = (id) => Get(
      `/admin/translations/${id}`,
      this.getSelectedLocalisedSuccess,
      this.getSelectedLocalisedError,
      this.load
    );
    getSelectedLocalisedSuccess = (payload) => this.setState({
      selectedLocalised: payload,
      showUpdateModal: true,
    });
    getSelectedLocalisedError = (error) => requestError(error);

    getModules = () => Get(
      `/module_countries/all_modules`,
      this.getModulesSuccess,
      this.getModulesError,
      this.load
    );
    getModulesSuccess = (payload) => {
      let temp = []
      if (!_.isEmpty(payload)) {
        Object.keys(payload).map((key) => {
          temp.push({ label: payload[key], value: key });
        });
      }
      temp = _.sortBy(temp, ['label']);
      this.setState({ modules: temp });
    }
    getModulesError = (error) => requestError(error);

    createTranslation = dataToSubmit => Post(
      `/admin/translations`,
      dataToSubmit,
      this.createTranslationSuccess,
      this.createTranslationError,
      this.load
    )
    createTranslationSuccess = (payload) => {
      const { currentPage, searchParams } = this.props.data.lastViewReducer.lastView;
      requestSuccess('Translation created successfully');
      this.setState({ showCreateModal: false });
      this.getLocalisedCentre(currentPage, searchParams);
    }
    createTranslationError = (error) => requestError(error);

    updateTranslation = dataToSubmit => Put(
      `/admin/translations/${this.state.selectedLocalised.id}`,
      dataToSubmit,
      this.updateTranslationSuccess,
      this.updateTranslationError,
      this.load
    )
    updateTranslationSuccess = (payload) => {
      const { currentPage, searchParams } = this.props.data.lastViewReducer.lastView;
      requestSuccess('Translation updated successfully');
      this.setState({ showUpdateModal: false });
      this.getLocalisedCentre(currentPage, searchParams);
    }
    updateTranslationError = (error) => requestError(error);

    importTranslationList = dataToSubmit => Post(
      `/admin/translations/import_list`,
      dataToSubmit,
      this.importTranslationListSuccess,
      this.importTranslationListError,
      this.load
    )
    importTranslationListSuccess = () => {
      const { currentPage, searchParams } = this.props.data.lastViewReducer.lastView;
      requestSuccess('Translation imported successfully');
      this.setState({ showImportTranslation: false });
      this.getLocalisedCentre(currentPage, searchParams);
    }
    importTranslationListError = (error) => requestError(error);

    modifyTranslationList = dataToSubmit => Post(
      `/admin/translations/import_translations`,
      dataToSubmit,
      this.modifyTranslationListSuccess,
      this.modifyTranslationListError,
      this.load
    )
    modifyTranslationListSuccess = () => {
      const { currentPage, searchParams } = this.props.data.lastViewReducer.lastView;
      requestSuccess('Translation modified successfully');
      this.setState({ showModifyTranslation: false });
      this.getLocalisedCentre(currentPage, searchParams);
    }
    modifyTranslationListError = (error) => requestError(error);

    getModuleKeys = (module_id, language_code) => Get(
      `/admin/translations/module_keys?module_id=${module_id}&language=${language_code}`,
      this.getModuleKeysSuccess,
      this.getModuleKeysError,
      this.load
    )
    getModuleKeysSuccess = (payload) => this.setState({ moduleKeys: payload });
    getModuleKeysError = (error) => requestError(error);

    bulkUpdateTranslation = dataToSubmit => Put(
      `/admin/translations/bulk_update`,
      { updates: dataToSubmit },
      this.bulkUpdateTranslationSuccess,
      this.bulkUpdateTranslationError,
      this.load
    )
    bulkUpdateTranslationSuccess = (payload) => {
      const { currentPage, searchParams } = this.props.data.lastViewReducer.lastView;
      requestSuccess('Translation updated successfully');
      this.setState({ showBulkUpdate: false });
      this.getLocalisedCentre(currentPage, searchParams);
    }
    bulkUpdateTranslationError = (error) => requestError(error);

    deleteTranslation = () => Delete(
      `/admin/translations/${this.state.selectedLocalised.id}`,
      this.deleteTranslationSuccess,
      this.deleteTranslationError,
      this.load
    )
    deleteTranslationSuccess = (payload) => {
      const { currentPage, searchParams } = this.props.data.lastViewReducer.lastView;
      requestSuccess('Translation deleted successfully');
      this.setState({ showDeleteConfirmation: false });
      this.getLocalisedCentre(currentPage, searchParams);
    }
    deleteTranslationError = (error) => requestError(error);

    getAllLocalisedSetting = (isSettings) => Get(
      `/admin/localization_settings`,
      payload => this.getAllLocalisedSettingSuccess(payload, isSettings),
      this.getAllLocalisedSettingError,
      this.load
    )
    getAllLocalisedSettingSuccess = (payload, isSettings) => {
      const [english, otherLanguages] = _.partition(payload.data, setting => setting.language_name === 'English');
      const sortedOtherLanguages = _.sortBy(otherLanguages, ['language_name']);
      const sortedSettings = [...english, ...sortedOtherLanguages];
      this.setState({
        settings: sortedSettings,
        settingsMeta: payload.meta,
        ...(isSettings ? {showSettingsModal: true} : {})
      });
    }
    getAllLocalisedSettingError = (error) => requestError(error);

    downloadTranslationList = (module, language) => GetFile(
      `/admin/translations/download?module_id=${module.value}${language ? `&language=${language.language_code}` : ''}`,
      `translation_list_${Moment().toISOString()}.xls`,
      this.downloadTranslationListSuccess,
      this.downloadTranslationListError,
      this.load
    )
    downloadTranslationListSuccess = () => requestSuccess('Translation list downloaded successfully');
    downloadTranslationListError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            {...this.state}
            onLoadLocalised={this.state.loading}

            getModules={this.getModules}
            getModuleKeys={this.getModuleKeys}
            createTranslation={this.createTranslation}
            updateTranslation={this.updateTranslation}
            bulkUpdateTranslation={this.bulkUpdateTranslation}
            deleteTranslation={this.deleteTranslation}
            getAllLocalisedSetting={this.getAllLocalisedSetting}
            getLocalisedCentre={this.getLocalisedCentre}
            modifyTranslationList={this.modifyTranslationList}
            downloadTranslationList={this.downloadTranslationList}
            importTranslationList={this.importTranslationList}
            getSelectedLocalised={this.getSelectedLocalised}
            onChangeLocalisedHOC={this.onChangeLocalisedHOC}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, { storeLastView })(WithHOC);
};

export default HOC;
