import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import Moment from "moment";
import { requestError, requestSuccess } from "utils/requestHandler";
import { Get, Put, Delete, Post } from "utils/axios";
import { storeSubsalesAddress } from "actions/subsales/address";

const ContactTypeHOC = (WrappedComponent) => {
  class ContactTypeWrappedComponent extends Component {
    state = {
      type_id: 0,
      owners: [],
      lawyers: [],
      clients: [],
      internal_cobrokers: [],
      external_cobrokers: [],
      referrals: [],
      loading: false,

      showNewEntityDialog: false,
      showEditEntityDialog: false,
      selectedContact: {},
      suggested_ic: null,
      confidentialContactDetails: {}
    };

    load = (param) => this.setState({ loading: param });

    onChangeContactTypeHOC = (val, context) =>
      this.setState({ [context]: val });

    onChangeEditData = (arrayContext, context, val, index) => {
      let tmp = this.state[arrayContext];
      tmp[index][context] = val;
      return this.setState({ [arrayContext]: tmp });
    };

    submitUpdatedData = (claim_id, dataToSubmit) => {
      let tmp = {};
      if (dataToSubmit.type_id === 3) {
        tmp = { co_broker: dataToSubmit };
      } else if (dataToSubmit.type_id === 4) {
        tmp = { referral: dataToSubmit };
      } else {
        tmp = dataToSubmit;
      }
      this.updateSelectedEntity(
        tmp,
        claim_id,
        dataToSubmit.type_id,
        dataToSubmit.id,
      );
    };

    processContactWithBank = (params) => {
      let data = [];
      params &&
        params.length > 0 &&
        params.map((item) => {
          let tmpBank = _.find(this.props.banks, { id: item.bank_id });
          let tmp = { ...item };
          if (tmpBank) {
            tmp.bank_name = tmpBank.name;
          }
          tmp.contact_type_id = tmp.contact_type_id
            ? `${tmp.contact_type_id}`
            : "";
          data.push(tmp);
        });
      return data;
    };

    getAssignedContactType = (claim_id, type_id) =>
      this.setState({ type_id: type_id }, () =>
        Get(
          `/sub_sale_claims/${claim_id}/contacts/type/${type_id}`,
          this.getAssignedContactTypeSuccess,
          this.getAssignedContactTypeError,
          this.load,
        ),
      );
    getAssignedContactTypeSuccess = (payload) => {
      if (payload) {
        if (this.state.type_id === 1) {
          this.setState({ owners: this.processContactWithBank(payload) });
        } else if (this.state.type_id === 2) {
          this.setState({ clients: this.processContactWithBank(payload) });
        } else if (this.state.type_id === 3) {
          this.setState({
            internal_cobrokers: payload.filter((obj) => obj.is_internal && obj),
            external_cobrokers: this.processContactWithBank(payload).filter(
              (obj) => !obj.is_internal && obj,
            ),
          });
        } else if (this.state.type_id === 4) {
          this.setState({ referrals: this.processContactWithBank(payload) });
        }
      }
    };
    getAssignedContactTypeError = (error) => requestError(error);

    getAssignedLawyer = (claim_id) =>
      Get(
        `/sub_sale_claims/${claim_id}/contacts/type/5`,
        this.getAssignedLawyerSuccess,
        this.getAssignedLawyerError,
        this.load,
      );
    getAssignedLawyerSuccess = (payload) =>
      this.setState({ lawyers: this.processContactWithBank(payload, 5) });
    getAssignedLawyerError = (error) => requestError(error);

    createEntity = (data, claim_id, type_id) => {
      const amountChecking = (amount) => {
        return amount <= 0;
      };

      if (data.address && data.address !== "") {
        this.props.storeSubsalesAddress(data.address);
      }
      let tmp = { ...data, date_of_birth: data.date_of_birth };
      this.setState({ type_id: type_id });

      if (tmp.is_company) {
        tmp.gender = "";
        tmp.date_of_birth = "";
      }

      if (tmp.referral && tmp.referral.is_company) {
        tmp.referral.date_of_birth = "";
      }

      if (tmp.referral && amountChecking(tmp.referral.commission_amount)) {
        tmp.referral.bank_account_number = "";
        tmp.referral.bank_id = 0;
        tmp.referral.payable_name = "";
      }

      Post(
        `/sub_sale_claims/${claim_id}/contacts/type/${type_id}`,
        tmp,
        this.createEntitySuccess,
        this.createEntityError,
        this.load,
      );
    };
    createEntitySuccess = (payload) => {
      this.props.getCurrentUserContactList(this.props.selected_claim.id);

      const party = () => {
        if (payload.type_id === 3) {
          return "Co-broker";
        } else if (payload.type_id === 4) {
          return "Referral";
        } else {
          switch (payload.contact_type_id) {
            case 1:
              return this.props.selected_claim.form_type_id === 1
                ? "Vendor"
                : "Landlord";
            case 2:
              return this.props.selected_claim.form_type_id === 1
                ? "Purchaser"
                : "Tenant";
            case 3:
              return "Lawyer";
            case 4:
              return "Lawyer";
          }
        }
      };

      if(payload.type_id === 4 && payload.contact_type_id === 3) {
        this.props.onChangeAgentHOC({}, 'selectedAgent')
      } 

      if (this.state.type_id === 5) {
        this.getAssignedLawyer(this.props.selected_claim.id);
      } else {
        this.getAssignedContactType(
          this.props.selected_claim.id,
          this.state.type_id,
        );
      }
      this.setState({
        selectedContact: payload,
        showEditEntityDialog: true,
        showNewEntityDialog: false,
      });
      requestSuccess(`${party()} has been added successfully.`);
    };
    createEntityError = (error) => requestError(error);

    getSelectedEntity = (claim_id, type_id, id) =>
      Get(
        `/sub_sale_claims/${claim_id}/contacts/${id}/type/${type_id}`,
        this.getSelectedEntitySuccess,
        this.getSelectedEntityError,
        this.load,
      );
    getSelectedEntitySuccess = (payload) =>
      this.setState({
        selectedContact: {
          ...payload,
          date_of_birth: Moment(payload.date_of_birth, "YYYY-MM-DD").isValid()
            ? Moment(payload.date_of_birth, "YYYY-MM-DD").format("DD-MM-YYYY")
            : payload.date_of_birth
        },
        showEditEntityDialog: true,
      });
    getSelectedEntityError = (error) => requestError(error);

    updateSelectedEntity = (data, claim_id, type_id, id) => {
      const amountChecking = (amount) => {
        return amount <= 0;
      };
      if (data.address && data.address !== "") {
        this.props.storeSubsalesAddress(data.address);
      }
      let tmp = { ...data, date_of_birth: data.date_of_birth };

      if (tmp.is_company) {
        tmp.gender = "";
        tmp.date_of_birth = "";
      }

      if (tmp.referral && tmp.referral.is_company) {
        tmp.referral.date_of_birth = "";
      }

      if (tmp.referral && amountChecking(tmp.referral.commission_amount)) {
        tmp.referral.bank_account_number = "";
        tmp.referral.bank_id = 0;
        tmp.referral.payable_name = "";
      }

      this.setState({ type_id: type_id });
      Put(
        `/sub_sale_claims/${claim_id}/contacts/${id}/type/${type_id}`,
        tmp,
        this.updateSelectedEntitySuccess,
        this.updateSelectedEntityError,
        this.load,
      );
    };
    updateSelectedEntitySuccess = (payload) => {
      payload.type_id === 5
        ? this.getAssignedLawyer(this.props.selected_claim.id)
        : this.getAssignedContactType(
            this.props.selected_claim.id,
            this.state.type_id,
          );
      this.setState({ selectedContact: {
        ...payload,
        date_of_birth: Moment(payload.date_of_birth, "YYYY-MM-DD").isValid()
          ? Moment(payload.date_of_birth, "YYYY-MM-DD").format("DD-MM-YYYY")
          : payload.date_of_birth
      } });
      requestSuccess("Data is updated successfully.");
    };
    updateSelectedEntityError = (error) => requestError(error);

    removeSelectedEntity = (claim_id, type_id, id) => {
      this.setState({ type_id: type_id });
      Delete(
        `/sub_sale_claims/${claim_id}/contacts/${id}/type/${type_id}`,
        (payload) => this.removeSelectedEntitySuccess(payload, claim_id),
        this.removeSelectedEntityError,
        this.load,
      );
    };
    removeSelectedEntitySuccess = (payload, claim_id) => {
      requestSuccess(payload.message);
      this.state.type_id === 5
        ? this.getAssignedLawyer(claim_id)
        : this.getAssignedContactType(claim_id, this.state.type_id);
    };
    removeSelectedEntityError = (error) => requestError(error);

    requestContactFromOppositeAgent = (id = 1, type_id = 1, notify) => {
      Post(
        `/sub_sale_claims/${this.props.selected_claim.id}/contacts/${id}/type/${type_id}/request`,
        { notify: notify },
        payload => this.requestContactFromOppositeAgentSuccess(payload, notify),
        this.requestContactFromOppositeAgentError,
        this.load,
      );
    }
    requestContactFromOppositeAgentSuccess = (payload, notify) => {
      if(notify) {
        requestSuccess(payload.message);
      } else {
        requestSuccess("Request has been sent successfully.");
      }
    };
    requestContactFromOppositeAgentError = (error) => requestError(error);

    assignContact = (data, type_id) => {
      Post(
        `/sub_sale_claims/${this.props?.selected_claim?.id}/contacts/assign_contact/type/${type_id}`,
        { 
          contact_id: data.contact_id,
          contact_type_id: data.contact_type_id 
        },
        payload => this.assignContactSuccess(payload, type_id),
        this.assignContactError,
        this.load,
      );
    }
    assignContactSuccess = (payload, type_id) => {
      this.getAssignedContactType(
        this.props?.selected_claim?.id,
        type_id
      );

      this.setState({
        selectedContact: payload,
        showEditEntityDialog: true
      });
      this.props.onChangeSubsaleClaimsHOC(false, "showAssignContactModal")
      requestSuccess("Contact assigned successfully.");
    };
    assignContactError = (error) => requestError(error);

    extactIcDetails = (file) =>
      Post(
        `/forms/confirmation_forms/analyze_mycard`,
        file,
        this.extactIcDetailsSuccess,
        this.extactIcDetailsError,
        this.load,
      );
    extactIcDetailsSuccess = (payload) => this.setState({ suggested_ic: payload.ic_numbers });
    extactIcDetailsError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            owners={this.state.owners}
            lawyers={this.state.lawyers}
            clients={this.state.clients}
            internal_cobrokers={this.state.internal_cobrokers}
            external_cobrokers={this.state.external_cobrokers}
            referrals={this.state.referrals}
            onLoadContactType={this.state.loading}
            selectedContact={this.state.selectedContact}
            showNewEntityDialog={this.state.showNewEntityDialog}
            showEditEntityDialog={this.state.showEditEntityDialog}
            suggested_ic={this.state.suggested_ic}
            extactIcDetails={this.extactIcDetails}
            assignContact={this.assignContact}
            onChangeContactTypeHOC={this.onChangeContactTypeHOC}
            getAssignedContactType={this.getAssignedContactType}
            getAssignedLawyer={this.getAssignedLawyer}
            createEntity={this.createEntity}
            getSelectedEntity={this.getSelectedEntity}
            updateSelectedEntity={this.updateSelectedEntity}
            removeSelectedEntity={this.removeSelectedEntity}
            submitUpdatedData={this.submitUpdatedData}
            onChangeEditData={this.onChangeEditData}
            requestContactFromOppositeAgent={this.requestContactFromOppositeAgent}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, {
    storeSubsalesAddress,
  })(ContactTypeWrappedComponent);
};

export default ContactTypeHOC;
