import React, { Component } from "react";
import { FormHelperText } from "@material-ui/core";
import { compose } from "redux";
import _ from "lodash";
import { Highlighter } from "react-bootstrap-typeahead";

import CustomButton from "components/Button";
import LoadingModal from "components/LoadingModal";
import AtlasRadioGroup from "components/RadioGroup";
import CustomTypeahead from "components/Typeahead/new";
import ConfirmationModal from "components/Modal/confirmation";

import ConfirmationFormHOC from "../../actions/confirmationForm";
import ListingHOC from "../../actions/listing";
import PaperFormHOC from "../../actions/paperForm";
import CreateClaimHOC from "../../actions/create";
import { RepresentativeInfo } from "../../assets";

const FormType = [
  { label: "Digital Form", value: "1", id: "formtype-digital-form" },
  { label: "Physical Form", value: "2", id: "formtype-physical-form" },
  { label: "Co-Agency Form", value: "3", id: "formtype-co-agency-form" },
  { label: "Renewal Form", value: "4", id: "formtype-renewal-form" },
];

const ClaimTypes = [
  { label: "Joint Claim", value: "joint", id: "joint-claim-type" },
  { label: "Separate Claim", value: "separate", id: "separate-claim-type" }
] 

const Type = [
  { value: "1", label: "Sale" },
  { value: "2", label: "Rental" },
];

const AgentRepresentOptions = {
  2: [
    { value: 3, label: "Both" },
    { value: 2, label: "Tenant", cobroke_represent: "Landlord" },
    { value: 1, label: "Landlord", cobroke_represent: "Tenant" },
  ],
  1: [
    { value: 3, label: "Both" },
    { value: 2, label: "Purchaser", cobroke_represent: "Vendor" },
    { value: 1, label: "Vendor", cobroke_represent: "Purchaser" },
  ],
}

class CreateClaimForm extends Component {
  state = {
    disabledCreate: true,
    selectedClaimType: "",
    subsales_type_id: ""
  };

  componentDidMount = () => this.props.getUnclaimConfirmationForms()
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.createClaim !== prevProps.createClaim) {
      this.setState({ disabledCreate: this.onDisableCreate() });
    }

    const { representative_id, form_type_id } = this.props.createClaim;
    if (
      ((representative_id !== prevProps.createClaim.representative_id) || (form_type_id !== prevProps.createClaim.form_type_id))
      && form_type_id === "1"
    ) {
      // API for Digital Form
      this.props.getUnclaimConfirmationForms();
    }
  };

  getPartyLabel = (representative_id) => {
    if(representative_id === 1) {
      return this.props.createClaim.sub_sale_type_id === "1" ? "Vendor" : "Landlord"
    } else {
      return this.props.createClaim.sub_sale_type_id === "1" ? "Purchaser" : "Tenant"
    }
  }

  onDisableCreate = () => {
    const {
      agency_id,
      sub_sale_type_id,
      form_type_id,
      representative_id,
      confirmation_form_id
    } = this.props.createClaim;
    let tmpDisabled = false;

    if (["1", "2"].indexOf(form_type_id) > -1) {
      tmpDisabled = !representative_id || !confirmation_form_id || tmpDisabled;
    } else if (form_type_id === "3") {
      tmpDisabled = !representative_id || !sub_sale_type_id || !agency_id || tmpDisabled;
    } else if (form_type_id === "4") 
      tmpDisabled = false
    return tmpDisabled;
  };

  onChangeCreateForm = (val, context) => {
    const nonPropertyFields = [
      "form_type_id",
      "agency_id",
      "sub_sale_type_id",
      "form_method_id",
      "representative_id",
    ];
    let temp = _.cloneDeep(this.props.createClaim);

    if (nonPropertyFields.indexOf(context) > -1) {
      temp[context] = val;
    } else {
    }

    if (context === "form_type_id") {
      temp.confirmation_form_id = "";
      temp.confirmation_form_number = "";
      temp.form_method_id = "2";
      temp.agency_id = "";
      temp.sub_sale_type_id = "";
      temp.representative_id = "";
      this.setState({selectedClaimType: ""})

      if (val === "2") {
        this.props.getUnclaimPaperForm();
      }
    }
    if (context === "representative_id") {
      this.props.onChangeConfirmationFormHOC([], "unclaimedConfirmationForms")
      temp.confirmation_form_id = "";
      temp.confirmation_form_number = "";
    }

    this.props.onChangeCreateClaimHOC("createClaim", temp);
  };

  getRepresentativePartyName = (representative_id, type_id) => {
    switch(type_id) {
      case 1:
        return representative_id === 1 ? "Vendor" : "Purchaser";
      case 2:
        return representative_id === 1 ? "Landlord" : "Tenant";
      default:
        return "N/A"
    }
  }

  // Submit Confirmation Message for Digital or Physical Form
  getSubmitConfirmationMessage = () => {
    const { 
      form_type_id,
      representative_id
    } = this.props.createClaim

    return [1, 2].indexOf(representative_id) > -1
      ? (
        <div>
          <p className="text-left fs-2">
            This claim is set as a <b>Separate Claim</b>, meaning you are managing it as the{" "}
            <b>{this.getRepresentativePartyName(representative_id, this.state.subsales_type_id)}</b>'s representative agent.
          </p>
          <ul 
            className="d-flex flex-column text-left" 
            style={{listStyleType: "disc", margin: "0.5rem 1rem", fontSize: 14}}>
            <li style={{width: "fit-content"}}>You can only submit and manage claims for your own client.</li>
            <li style={{width: "fit-content"}}>You cannot request documents (e.g., Invoice, Official Receipt) on behalf of the other party.</li>
            <li style={{width: "fit-content"}}>As this is an internal co-broke arrangement, the other party must submit their claim separately.</li>
          </ul>
          {form_type_id === "1" && (
            <p className="mb-2 fs-2 text-left">Want to manage claims for both parties? Update your <b>Confirmation Form</b> by deauthorising your co-agent under <b>Participants</b>.</p>
          )}
          {form_type_id === "2" && (
            <p className="mb-2 fs-2 text-left">If you need to submit <b>Separate Claims</b>, please contact the admin team for assistance.</p>
          )}
          <p className="fs-2">Are you sure you want to proceed?</p>
        </div>
      )
      : (
        <div>
          <p className="fs-2 text-left">
            This claim is set as a <b>Joint Claim</b>, meaning you are solely responsible for submitting and managing claims.
          </p>
          <ul 
            className="d-flex flex-column text-left" 
            style={{listStyleType: "disc", margin: "0.5rem 1rem", fontSize: 14}}>
            <li style={{width: "fit-content"}}>You either represent both clients or are in a <b>Co-Agent Deal</b> with another agent (Internal or external).</li>
            <li style={{width: "fit-content"}}>Only you have submission rights—your co-agent cannot submit a separate claim.</li>
            <li style={{width: "fit-content"}}>You can request documents (e.g., invoices, official receipts) for both parties.</li>
          </ul>
          {form_type_id === "1" && (
            <p className="mb-2 fs-2 text-left">Need to manage claims separately? Update your <b>Confirmation Form</b> to authorise your co-agent under Participants.</p>
          )}
          {form_type_id === "2" && (
            <p className="mb-2 fs-2 text-left">If you need to submit <b>Separate Claims</b>, please contact the admin team for assistance.</p>
          )}
          <p className="fs-2">Are you sure you want to proceed?</p>
        </div>
      )
  }

  render = () => {
    const {
      form_type_id,
      agency_id,
      sub_sale_type_id,
      form_method_id,
      representative_id,
      type_id
    } = this.props.createClaim;

    let tempAgency = _.find(this.props.agencies, { id: agency_id });

    let tmpShowSelectRepresentative = false
    if(!(form_type_id === "3" && form_method_id === "2")) {
      if(["1", "2"].indexOf(form_type_id) > -1) {
        tmpShowSelectRepresentative = this.state.selectedClaimType === "separate"
      } else if(["4"].indexOf(form_type_id) > -1) {
        tmpShowSelectRepresentative = false
      } else {
        tmpShowSelectRepresentative = true
      }
    } else {
      // show co-agency representation field
      tmpShowSelectRepresentative = true
    }

    return (
      <>
        <div style={{ textAlign: "justify" }}>
          <div className="at-form_fields_cont">
            <section className="at-form_field-col-12">
              <h2 className="at-form-input__title">{"Select Form Type"}</h2>
              <AtlasRadioGroup
                horizontal={true}
                checkedValue={form_type_id}
                selectedRadioValue={(val) =>
                  this.onChangeCreateForm(val, "form_type_id")
                }
                options={FormType}
              />
            </section>
            {(form_type_id === "3" && form_method_id === "2") && (
              <section className="at-form_field-col-12">
                <h4 className="at-form-input__title">Choose an Agency</h4>
                <CustomTypeahead
                  required
                  showClearButton
                  footerStyle={{ marginBottom: 0 }}
                  containerClass="responsive"
                  typeaheadId={"agencies"}
                  options={this.props.agencies}
                  filterBy={["label"]}
                  selectedValue={tempAgency ? [tempAgency] : []}
                  labelKey={"label"}
                  onSelect={(val) => {
                    if (val && val.length > 0) {
                      this.onChangeCreateForm(val[0].id, "agency_id");
                    }
                  }}
                  onSearch={(val) => this.onChangeCreateForm(val, "agency_id")}
                  onClear={() => this.onChangeCreateForm("", "agency_id")}
                  childrenHead={(rowItem) => <p>{rowItem.label}</p>}
                  highlighterData={[
                    (option) =>
                      `Registration Number: ${option.registration_number} | Contact: ${option.phone_number}`,
                    <br />,
                    (option) => option.address,
                  ]}
                />
              </section>
            )}
            {
              tmpShowSelectRepresentative && (
                <section className="at-form_field-col-12">
                  <h2 className="at-form-input__title">
                    {(form_type_id === "3" && form_method_id === "2")
                      ? "Select Co-agency Representation"
                      : "Select Representative"}
                  </h2>
                  <AtlasRadioGroup
                    horizontal={true}
                    checkedValue={representative_id}
                    selectedRadioValue={(val) =>
                      this.onChangeCreateForm(val, "representative_id")
                      }
                    options={RepresentativeInfo(false)}
                  />
                  {(form_type_id === "3" && form_method_id === "2") && 
                    <FormHelperText>Selecting Co-agency Representation will result in Atlas choosing the opposing party for your claim.</FormHelperText>
                  }
                </section>
              )
            }
            {form_type_id === "1" && (
              <section className="at-form_field-col-12">
                <h2 className="at-form-input__title" required>
                  {"Form Number"}
                </h2>
                <CustomTypeahead
                  wrapperClassName={"mb-3"}
                  typeaheadId={"confirmation_form_number"}
                  options={this.props.unclaimedConfirmationForms}
                  selectedValue={_.find(this.props.unclaimedConfirmationForms, { id: this.props.createClaim.confirmation_form_id })
                    ? [_.find(this.props.unclaimedConfirmationForms, { id: this.props.createClaim.confirmation_form_id })]
                    : []}
                  labelKey={"transaction_number"}
                  onSelect={(val) => {
                    const value = val?.[0]
                    let tmp = _.cloneDeep(this.props.createClaim);
                    tmp.confirmation_form_number = value?.transaction_number ?? "";
                    tmp.confirmation_form_id = value?.id ?? "";
                    tmp.representative_id = value?.representative_id ?? "";
                    this.setState({subsales_type_id: value?.type_id || ""})
                    return this.props.onChangeCreateClaimHOC("createClaim", tmp);
                  }}
                  filterBy={["transaction_number", "property_address", "owner", "buyer"]}
                  helpText={"e.g. Form Number, Property Address, Owner, Buyer"}
                  childrenHead={(rowItem, props) => (
                    <div>
                      <p className="at-form_number-typeahead-head">{rowItem?.transaction_number}</p>
                      <div className="at-form_number-typeahead-highlight">
                        <p className="at-form_number-typeahead-highlight-address"><Highlighter search={props.text}>{rowItem?.property_address || "-"}</Highlighter></p>
                        <p className="at-form_number-typeahead-highlight-party">
                          <span className="at-form_number-typeahead-highlight-owner"><Highlighter search={props.text}>{`${rowItem.type_id === 1 ? "Vendor" : "Landlord"}: ${rowItem?.owner || "-"}`}</Highlighter></span>
                          <span className="at-form_number-typeahead-highlight-buyer"><Highlighter search={props.text}>{`${rowItem.type_id === 1 ? "Purchaser" : "Tenant"}: ${rowItem?.buyer || "-"}`}</Highlighter></span>
                        </p>
                      </div>                      
                    </div>
                  )}
                  highlighterData={[]}
                />
              </section>
            )}
            {(form_type_id === "2" || (["3", "4"].indexOf(form_type_id) !== -1 && form_method_id === "1")) && (
              <section className="at-form_field-col-12">
                <h2 className="at-form-input__title" required>
                  {"Form Number"}
                </h2>
                <CustomTypeahead
                  typeaheadId={"confirmation_form_number"}
                  options={this.props.paperForms}
                  selectedValue={_.find(this.props.paperForms, { id: this.props.createClaim.confirmation_form_id })
                    ? [_.find(this.props.paperForms, { id: this.props.createClaim.confirmation_form_id })]
                    : []}
                  labelKey={"tracking_number"}
                  onSelect={(val) => {
                    const value = val?.[0]
                    let tmp = _.cloneDeep(this.props.createClaim);
                    tmp.confirmation_form_number = value?.tracking_number ?? "";
                    tmp.confirmation_form_id = value?.id ?? "";
                    tmp.representative_id = value?.representative_id ?? "";
                    tmp.type_id = value?.type_id ?? "";
                    this.setState({subsales_type_id: value?.type_id ?? ""})
                    return this.props.onChangeCreateClaimHOC("createClaim", tmp);
                  }}
                  filterBy={["tracking_number"]}
                  childrenHead={(rowItem) => <p>{rowItem.tracking_number}</p>}
                />
              </section>
            )}
            {(form_type_id === "3" && form_method_id === "2") && (
              <section className="at-form_field-col-12">
                <h2 className="at-form-input__title">{"Type"}</h2>
                <AtlasRadioGroup
                  horizontal={true}
                  checkedValue={sub_sale_type_id}
                  selectedRadioValue={(val) => this.onChangeCreateForm(val, "sub_sale_type_id")}
                  options={Type}
                />
              </section>
            )}
            {(form_type_id === "1" || form_type_id === "2") && (
              <section className="at-form_field-col-12">
                <h2 className="at-form-input__title">{"Agent Representing"}</h2>
                <p>{_.find(AgentRepresentOptions[this.state.subsales_type_id || 1], {value: representative_id})?.label ?? "-"}</p>
              </section>
            )}
            <section className="at-form_field-col-12">
              <hr className="mb-0" />
            </section>
            <section className="at-form_field-col-12">
              {this.props.createClaim.form_type_id === "1" && (
                <>
                  <h5 className="mt-1">
                    <b>Where can I find the Digital Form Number?</b>
                  </h5>
                  <div className="mt-2 form-admin-description-img"></div>
                  <p className="mt-10">
                    The form number can be found on the top right corner of your{" "}
                    <b>Letter of confirmation</b>.
                  </p>
                </>
              )}
              <p className="mt-20">
                <b>Gentle Reminder:</b>
              </p>
              <p>
                1. Choosing the wrong form number can delay the progress of
                submission approval.{" "}
              </p>
              <p>
                2. If you are unable to find the form number on the list and you
                are sure you have not yet submitted that confirmation form
                number for claim before, please contact your branch admin for
                assistance.
              </p>
            </section>
          </div>
        </div>
        <div className="at-modal_dialog-container-footer d-flex mt-3">
          <CustomButton
            className={"btn-new btn-new--primary"}
            children={"New Claim Form"}
            disabled={
              this.props.disableCreateButton || this.state.disabledCreate
            }
            onClick={() => {
              if(this.props.createClaim.form_type_id === "4") {
                this.props.postClaim({
                  ...this.props.createClaim,
                });
              } else {
                this.props.onChangeCreateClaimHOC("showSubmitConfirmation", true)
              }
            }}
          />
          <button
            type={"button"}
            className="btn-new btn-new--outline-secondary ml-2"
            onClick={() => this.props.onClose()}>
            Cancel
          </button>
        </div>
        {/* Confirmation Dialog for CoAgency Form */}
        <ConfirmationModal
          mode={"info"}
          open={this.props.showSubmitConfirmation && this.props.createClaim.form_type_id === "3"}
          title={"Co-Agency Claim Form"}
          message={
            <>
              <p className="text-left fs-2">Based on your selection, please confirm if the following is correct for this case:</p>
              <div className="d-flex justify-content-center mt-3">
                <ul style={{listStyleType: "disc", paddingLeft: 20}}>
                  <li className="text-left fs-2 mb-1">Transaction Type: {this.props.createClaim?.sub_sale_type_id === "1" ? "Sale" : "Rental"}</li>
                  <li className="text-left fs-2 mb-1">Co-Agency: {this.getPartyLabel(this.props.createClaim?.representative_id)}</li>
                  <li className="text-left fs-2 mb-1">IQI (you): {this.getPartyLabel(this.props.createClaim?.representative_id === 1 ? 2 : 1)}</li>
                </ul>
              </div>
            </>
          }
          loading={this.props.onLoadCreateClaim}
          positiveText={"Confirm"}
          positiveAction={() => {
            this.props.postClaim({
              ...this.props.createClaim
            });
 
          }}
          negativeText={"Cancel"}
          negativeAction={() =>
            this.props.onChangeCreateClaimHOC("showSubmitConfirmation", false)
          }
        />
        {/* Confirmation Dialog for Digital Form and Physical Form */}
        <ConfirmationModal
          mode={"info"}
          open={this.props.showSubmitConfirmation && ["1", "2"].indexOf(this.props.createClaim.form_type_id) > -1}
          title={"Closing Deal"}
          message={this.getSubmitConfirmationMessage()}
          loading={this.props.onLoadCreateClaim}
          positiveText={"Confirm"}
          positiveAction={() => {
            this.props.postClaim({
              ...this.props.createClaim
            });
          }}
          negativeText={"Cancel"}
          negativeAction={() =>
            this.props.onChangeCreateClaimHOC("showSubmitConfirmation", false)
          }
        />
        {(this.props.onLoadConfirmationForms ||
          this.props.onLoadPaperForms ||
          this.props.onLoadCreateClaim ||
          this.props.onLoadClaims) && <LoadingModal />}
      </>
    );
  };
}

export default compose(
  ConfirmationFormHOC,
  PaperFormHOC,
  CreateClaimHOC,
  ListingHOC,
)(CreateClaimForm);
