import React from "react";
import _ from "lodash";

import FormInputs from "../formInputs";
import AtlasAutosuggest from "components/Autosuggest";

import { getTranslation } from "../../assets";

const ContactDetails = ({
  onChangeFormData,
  data,
  onChangeField,
  updateNewAgent,
  tmpProfileReducer,
  onChangeProfileHOC
}) => {
  const { country_id } = window.location.href.includes("/admin-impersonate")
    ? data.currentSignInProfileReducer
    : data.profileReducer;
  const banks = data.dictionaryReducer.banks?.filter(
    (bank) => bank.country_id === country_id
  );
  const language = country_id === 14 ? "jp" : "en";

  const closeOptions = [
    { value: false, label: "No" },
    { value: true, label: "Yes" },
  ];

  const inputConfig = [
    {
      type: "select",
      label: getTranslation("agent.profile.bank_name", language),
      id: "bank_id",
      required: true,
      hidden: country_id === 14,
      options: banks.map((option) => {
        return { ...option, value: option.name };
      }),
    },
    {
      type: "custom-output",
      label: getTranslation("agent.profile.bank_name", language),
      id: "new_bank_name",
      required: true,
      hidden: country_id !== 14,
      content: () => (
        <AtlasAutosuggest
          required
          language={language}
          placeholder={getTranslation("agent.profile.bank_name", language)}
          value={updateNewAgent.agent_registration_attributes?.new_bank_name ?? ""}
          updateValue={(val) => {
            const { name, id } = _.find(banks, option => option?.name?.toLowerCase() === val?.toLowerCase()) || {};

            if(name) {
              let temp = _.cloneDeep(updateNewAgent)
              temp.bank_id = id
              temp.agent_registration_attributes.new_bank_name = name
              onChangeProfileHOC(temp, "updateNewAgent")
            } else {
              let temp = _.cloneDeep(updateNewAgent)
              temp.bank_id = ""
              temp.agent_registration_attributes.new_bank_name = val
              onChangeProfileHOC(temp, "updateNewAgent")
            }
          }}
          onSelect={ val => {
            let temp = _.cloneDeep(updateNewAgent)
            temp.bank_id = val?.id
            temp.agent_registration_attributes.new_bank_name = val?.name
            onChangeProfileHOC(temp, "updateNewAgent")
          }}
          options={ banks.map((option) => ({ ...option, value: option.name })) }
        />
      )
    },
    {
      label: getTranslation("agent.profile.bank_branch", language),
      id: "bank_branch",
      required: true,
      isReplaceNumeric: true,
      hidden: country_id !== 14,
    },
    {
      label: getTranslation("agent.profile.bank_account_holder_name", language),
      id: "bank_owner_name",
      required: true,
      pattern: /^[a-zA-Z0-9\s]*$/,
    },
    {
      label: getTranslation("agent.profile.bank_account_number", language),
      id: "bank_account_number",
      required: true,
      pattern: /^[0-9]*$/,
    },
    {
      type: "select",
      label: getTranslation("agent.profile.bank_account_type", language),
      id: "bank_account_type_id",
      required: true,
      options: [
        { id: 1, value: getTranslation("agent.profile.saving_acc", language) },
        { id: 2, value: getTranslation("agent.profile.current_acc", language) },
      ],
      hidden: country_id !== 14,
    },
    {
      type: "textarea",
      label: getTranslation("agent.profile.current_address", language),
      id: "current_address",
      required: true,
      hidden: country_id === 14,
    },
    {
      label: getTranslation("agent.profile.current_address", language),
      id: "current_address_1",
      placeholder: getTranslation("agent.profile.current_address_1_placeholder", language),
      hidden: country_id !== 14,
    },
    {
      id: "current_address_2",
      placeholder: getTranslation("agent.profile.current_address_2_placeholder", language),
      hidden: country_id !== 14,
    },
    {
      id: "current_address_3",
      placeholder: getTranslation("agent.profile.current_address_3_placeholder", language),
      hidden: country_id !== 14,
    },
    {
      id: "current_address_4",
      placeholder: getTranslation("agent.profile.current_address_4_placeholder", language),
      hidden: country_id !== 14,
      required: true,
    },
    {
      label: getTranslation("agent.profile.income_tax_number", language),
      id: "income_tax_number",
      containerStyle: { marginBottom: 16 },
      placeholder: getTranslation("agent.profile.income_tax_number_placeholder", language),
    }
  ];

  const inputAgentAttributesConfig = [
    {
      label: getTranslation("agent.profile.active_area", language),
      id: "area_of_activity",
      required: true,
      hidden: country_id !== 14,
    },
    {
      label: getTranslation("agent.profile.emergency_contact_name", language),
      id: "emergency_person_name",
      required: true,
      pattern: /^[a-zA-Z\s]*$/,
    },
    {
      label: getTranslation("agent.profile.emergency_contact_number", language),
      id: "emergency_contact_number",
      required: true,
      type: "number",
    },
    {
      label: getTranslation("agent.profile.emergency_contact_relationship", language),
      id: "emergency_contact_relationship",
      required: true,
      hidden: tmpProfileReducer.country_id !== 14,
    },
    {
      label: "Previous Company Name",
      id: "previous_company",
      required: true,
      isReplaceNumeric: true,
      hidden: tmpProfileReducer.country_id !== 1,
    },
    {
      label: "Job Title in Previous Company",
      id: "previous_company_job_title",
      required: true,
      isReplaceNumeric: true,
      hidden: tmpProfileReducer.country_id !== 1,
    },
    {
      type: "radio",
      label: "Are you currently an undischarged bankrupt person or entity or is there any bankruptcy petition pending or threatened against you?",
      id: "is_undischarged_bankrupt_person",
      options: closeOptions,
      hidden: tmpProfileReducer.country_id !== 1,
    },
    {
      type: "radio",
      label: "Have you ever been convicted of a crime or any offence in any country or been charged with any offence?",
      id: "has_been_convicted_crime",
      options: closeOptions,
      hidden: tmpProfileReducer.country_id !== 1,
    },
  ];

  return (
    <>
      <p className={"mb-4"}>{country_id === 1
        ? `Before proceeding to the next step, please fill in all the necessary
          additional details for commission payout and record purposes.`
        : ""
      }</p>
      <div className={"d-flex flex-column g-3"}>
        <FormInputs
          language={language}
          payload={updateNewAgent}
          inputConfig={inputConfig}
          onChange={onChangeField}
        />
        <FormInputs
          language={language}
          payload={updateNewAgent.agent_registration_attributes}
          inputConfig={inputAgentAttributesConfig}
          onChange={onChangeFormData}
        />
      </div>
    </>
  );
};

export default ContactDetails;
