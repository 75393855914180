import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import LoadingModal from "components/LoadingModal";
import CountryCodeContent from "components/country_code";
import PartyTable from "./PartyTable";
import AtlasConfirmationDialog from "components/Modal/delete";

import EditContactContent from "./editAssignContact";
import AssignContactContent from "components/AssignContactContent";
import CreateContactContent from "components/CreateAssignContact";
import InternalAgentContent from "components/AssignInternalAgent";

import NationalityHOC from "actions/nationality";
import permissionsChecker from "utils/permissionsChecker";

const tableHeader = [
  {
    label: "Full Name",
    value: "participants_name",
  },
  {
    label: "Email",
    value: "email",
  },
  {
    label: "NRIC/Passport No.",
    value: "identification_number",
  },
  {
    label: "Mobile No.",
    value: "mobile_contact_number",
  },
];

class PartyContent extends Component {
  state = {
    selectedPartyId: null,
    selectedTitle: "",
    selectedDeleteContact: null,
    showCountryCodeModal: false,
  };

  componentDidMount = () => {
    this.props.getNationalities()
  };

  onClickCreate = (val, partyId, title) => {
    this.props.onChangePartyHOC(true, val);
    this.setState({
      selectedPartyId: partyId,
      selectedTitle: title,
    });
  };

  onChangeEditField = (val, context) => {
    let temp = _.cloneDeep(this.props.selectedContact);
    temp[context] = val;
    this.props.onChangePartyHOC(temp, "selectedContact");
  };

  renderInternalContent = () => (
    <InternalAgentContent
      selectedTitle={this.state.selectedTitle}
      selectedPartyId={this.state.selectedPartyId}
      selected_form={this.props.selected_form}
      assignParty={this.props.assignParty}
      nationalities={this.props.nationalities}
      getInternalAgent={this.props.getInternalAgent}
      onLoadParty={this.props.onLoadParty}
      onLoadInternalAgents={this.props.onLoadInternalAgents}
      onChangePartyHOC={this.props.onChangePartyHOC}
    />
  );

  renderAssignContactContent = () => (
    <AssignContactContent
      contacts={this.props.contactList}
      assignContact={this.props.assignContact}
      onLoadParty={this.props.onLoadParty}
      selected_form={this.props.selected_form}
      nationalities={this.props.nationalities}
      onChangePartyHOC={this.props.onChangePartyHOC}
      selectedPartyId={this.state.selectedPartyId}
      selectedTitle={this.state.selectedTitle}
    />
  );

  renderCreateContactContent = () => (
    <CreateContactContent
      data={this.props.data}
      form_type={this.props.form_type}
      addressInfo={this.props.addressInfo}
      onLoadParty={this.props.onLoadParty}
      selectedPartyId={this.state.selectedPartyId}
      selected_form={this.props.selected_form}
      createParty={this.props.createParty}
      nationalities={this.props.nationalities}
      onChangePartyHOC={this.props.onChangePartyHOC}
      selectedTitle={this.state.selectedTitle}
      getAddressInfo={this.props.getAddressInfo}
      onChangeFormHOC={this.props.onChangeFormHOC}
    />
  );

  renderEditContent = () => {
    const {can_update} = permissionsChecker("Forms", this.props.data);
    const { participants_crud } = this.props.selected_form;
    
    return (
      <EditContactContent
        title={this.state.selectedTitle}
        can_update={can_update && participants_crud?.can_update}
        data={this.props.data}
        form_type={this.props.form_type}
        addressInfo={this.props.addressInfo}
        onLoadParty={this.props.onLoadParty}
        selected_form={this.props.selected_form}
        nationalities={this.props.nationalities}
        selectedPartyId={this.state.selectedPartyId}
        onClose={() => this.props.onChangePartyHOC(false, "showEditPartyModal")}
        getAddressInfo={this.props.getAddressInfo}
        selectedContact={this.props.selectedContact}
        onChangePartyHOC={this.props.onChangePartyHOC}
        updatePartyContact={this.props.updatePartyContact}
        extactIcDetails={this.props.extactIcDetails}
        suggested_ic={this.props.suggested_ic}
      />
    );
  }
  
  render = () => {
    const {
      mode,
      form_type,
      selected_form,
      partyData,
      partyDictionary,
      deletePartyContact,
      showEditPartyModal,
      showAssignContactModal,
      showAssignInternalModal,
      showDeletePartyModal,
      showCreateContactModal,
      onLoadParty,
    } = this.props;
    const { 
      type_id,
      isLocked,
      is_claimed,
      is_buyer_claimed,
      is_owner_claimed,
      participants_crud
    } = selected_form;

    let tmpIsClaimed = form_type !== 'confirmation'
      ? isLocked 
      : (is_claimed || is_buyer_claimed || is_owner_claimed)

    const { can_update, can_create, can_destroy } = permissionsChecker("Forms", this.props.data);

    return (
      <>
        {partyData &&
          partyData.map((item, index) => {
            let tmpActionColumn = [];

            if(can_update && participants_crud?.can_update) {
              tmpActionColumn.push({
                name: "edit",
                onShow: content => content.contactable_type_id === 1,
                onClick: (rowData) => {
                  Promise.all([
                    this.props.onChangeFormHOC("addressInfo", {}),
                    this.props.onChangePartyHOC(
                      {
                        ...rowData,
                        signee_type_id: `${rowData.signee_type_id}`,
                      },
                      "selectedContact",
                    ),
                    this.setState({selectedTitle: tempTitle})
                  ]).then(() => {
                    this.props.onChangePartyHOC(true, "showEditPartyModal");
                  });
                },
              })
            } else {
              tmpActionColumn.push({
                name: "details",
                onShow: content => content.contactable_type_id === 1,
                onClick: (val) => {
                  Promise.all([
                    this.props.onChangeFormHOC("addressInfo", {}),
                    this.props.onChangePartyHOC(
                      {
                        ...val,
                        signee_type_id: `${val.signee_type_id}`,
                      },
                      "selectedContact",
                    ),
                    this.setState({selectedTitle: tempTitle})
                  ]).then(() => {
                    this.props.onChangePartyHOC(true, "showEditPartyModal");
                  });
                },  
              });
            }

            if (can_update && participants_crud?.can_destroy) {
              tmpActionColumn.push({
                name: "authorise",
                onShow: content => (form_type === 'confirmation' && content.can_be_authorized),
                onClick: (rowData) => {
                  this.props.onChangeConfirmationFormHOC("showAuthoriseSubmission", true)
                  this.props.onChangeConfirmationFormHOC("toSubmitData", rowData)
                },
              })

              tmpActionColumn.push({
                name: "unauthorise",
                onShow: content => (form_type === 'confirmation' && content.authorized_for_separate_claim),
                onClick: (rowData) => {
                  this.props.onChangeConfirmationFormHOC("showUnauthoriseSubmission", true)
                  this.props.onChangeConfirmationFormHOC("toSubmitData", rowData)
                },
              });
            } else {
              tmpActionColumn.push({
                name: "authorised",
                onShow: content => (form_type === 'confirmation' && content.authorized_for_separate_claim),
                disabled: true,
                onClick: (rowData) => {},
              });
            }

            if (can_destroy && participants_crud?.can_destroy) {
              tmpActionColumn.push({
                name: "delete",
                onShow: content => (form_type === 'confirmation' || (form_type !== 'confirmation' && content.contactable_type_id === 1)),
                onClick: (rowData) => {
                  this.setState({ selectedDeleteContact: rowData });
                  this.props.onChangePartyHOC(true, "showDeletePartyModal");
                },
              });
            }

            let temp = _.find(partyDictionary, { id: item.id });
            let tempTitle = type_id === 1 ? temp.subsalesTitle : temp.subrentTitle;
            const allowedCreate = (form_type === "confirmation" && [2, 4].includes(item.id))
              ? true
              : participants_crud?.can_create && can_create

            return (
              <PartyTable
                mode={mode}
                key={item.id}
                index={index}
                title={tempTitle}
                form_type={form_type}
                selected_form={selected_form}
                partyDictionary={partyDictionary}
                headerData={tableHeader}
                actionColumn={tmpActionColumn.length !== 0}
                actionColumnContent={tmpActionColumn}
                rowData={item.data}
                partyId={item.id}
                hideSearch={true}
                showCreate={allowedCreate}
                isClaimed={tmpIsClaimed}
                onClickAssignFormOwner={() => {
                  this.props.assignParty(
                    {
                      party_id: item.id,
                      agent_id: selected_form.user_id,
                    },
                    selected_form.id,
                    tempTitle,
                  );
                }}
                onClickAssignContact={() => {
                  this.onClickCreate(
                    "showAssignContactModal",
                    item.id,
                    tempTitle,
                  );
                }}
                onClickCreateContact={() =>
                  this.onClickCreate(
                    "showCreateContactModal",
                    item.id,
                    tempTitle,
                  )
                }
                onClickAssignInternal={() =>
                  this.onClickCreate(
                    "showAssignInternalModal",
                    item.id,
                    tempTitle,
                  )
                }
                rowKey={"id"}
              />
            );
          })}
        {showAssignInternalModal && this.renderInternalContent()}
        {showCreateContactModal && this.renderCreateContactContent()}
        {showAssignContactModal && this.renderAssignContactContent()}
        {showEditPartyModal && this.renderEditContent()}
        {this.state.showCountryCodeModal && (
          <CountryCodeContent
            onClose={() => this.setState({ showCountryCodeModal: false })}
          />
        )}
        <div
          className={`at-floating_panel${this.props.menuExpand ? " menu-expand" : ""}`}
        >
          {this.props.renderNavButton && this.props.renderNavButton()}
        </div>
        <AtlasConfirmationDialog
          open={showDeletePartyModal}
          loading={onLoadParty}
          title="Delete Record"
          message={"Are you sure to delete this record?"}
          positiveAction={() =>
            deletePartyContact(
              this.state.selectedDeleteContact.form_id,
              this.state.selectedDeleteContact.id,
            )
          }
          negativeAction={() =>
            this.props.onChangePartyHOC(false, "showDeletePartyModal")
          }
        />
        {onLoadParty && <LoadingModal />}
      </>
    );
  };
}

const mapStateToProps = (state) => ({ data: state });
export default connect(mapStateToProps)(NationalityHOC(PartyContent));
