import React, { useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Select, MenuItem, OutlinedInput } from "@material-ui/core";

import CustomCheckbox from "components/Checkbox";
import LoadingModal from "components/LoadingModal";
import AtlasRadioGroup from "components/RadioGroup";
import AtlasCloseButton from "components/Button/prev";

const refundType = [
  { id: 1, label: "Refund" },
  { id: 2, label: "Forfeit" },
  { id: 3, label: "Refund & Forfeit" },
];

const refundPartyData = [
  { value: "name", label: "Name" },
  { value: "nric", label: "NRIC" },
  { value: "mobile_phone", label: "Tel." },
  { value: "address", label: "Address" },
];

const fieldData = [
  {
    party: "seller",
    sectionLabel: "Refund for Seller",
    checkboxField: "refund_for_seller",
    field: [
      { label: "Bank Holder Name", value: "bank_account_holder_name" },
      { label: "Bank Name", value: "bank_id", type: "select" },
      { label: "Bank Account", value: "bank_account_number", type: "number" },
      { label: "Percentage", value: "percentage", type: "number" },
    ],
  },
  {
    party: "buyer",
    sectionLabel: "Refund for Buyer",
    checkboxField: "refund_for_buyer",
    field: [
      { label: "Bank Holder Name", value: "bank_account_holder_name" },
      { label: "Bank Name", value: "bank_id", type: "select" },
      { label: "Bank Account", value: "bank_account_number", type: "number" },
      { label: "Percentage", value: "percentage", type: "number" },
    ],
  },
];

const RefundForm = ({
  banks,
  formId,
  refundPartyInfo,
  onLoadRefund,
  refundFormData,

  getRefundForm,
  onClickClose,
}) => {
  useEffect(() => {
    getRefundForm(formId);
  }, []);

  return (
    <>
      <div className="at-card__header">
        <AtlasCloseButton onClick={() => onClickClose()} />
        <h5 className="at-card__title">
          {"Authorisation letter to refund/forfeit earnest deposit"}
        </h5>
      </div>
      <div className="at-card bg-white pb-3 mb-10">
        {refundPartyData.map((item) => (
          <div key={item.label} className={"mb-10 d-flex"}>
            <label
              className="at-form-input__title"
              style={{ minWidth: 120 }}
            >{`${item.label}:`}</label>
            <span>{refundPartyInfo[item.value]}</span>
          </div>
        ))}
      </div>
      <hr />
      <>
        <p className={"mt-20"}>Dear Sir/ Madam</p>
        <strong>
          Earnest Deposit Payable on sale / Lease of property address
        </strong>
        <div
          style={{ borderBottom: "1px solid #000", height: 25 }}
          className={"mt-10"}
        >
          {refundFormData.property_address}
        </div>
        <p className={"mt-20"}>
          {`I/ We hereby authorizing `}
          <strong>IQI Realty Sdn Bhd</strong>
          {` to  `}
          <AtlasRadioGroup
            valueKey={"id"}
            disabled={true}
            horizontal={true}
            checkedValue={refundFormData.type_id || ""}
            containerClassName={"w-100"}
            options={refundType}
          />
          {`the earnest deposit which has been held by `}
          <strong>IQI Realty Sdn Bhd</strong>
          {` as a stakeholder:-`}
        </p>
        <div className={"subsales-letter-points"}>
          <div className="subsales-letter-subpoints">
            {`- Purchase / Rental Price: RM ${refundFormData.price}`}
          </div>
          <div className="subsales-letter-subpoints">
            {`- Earnest Deposit: RM ${refundFormData.earnest_deposit_amount}`}
          </div>
          <div className="subsales-letter-subpoints">
            - Reason for aborting:
            <textarea
              disabled={true}
              className="subsales-letter-input mb-10"
              style={{ width: "100%", maxWidth: "100%" }}
              value={refundFormData.abort_reason}
              onChange={() => {}}
            />
          </div>
        </div>
      </>
      <hr />
      <div className={"grid-control mb-20"}>
        {fieldData.map((item, index) => (
          <section key={index} className="grid-full-col refund-party-container">
            <CustomCheckbox
              disabled={true}
              checked={refundFormData[item.checkboxField]}
              content={item.sectionLabel}
            />
            {refundFormData.refund_details[item.party].length > 0 &&
              refundFormData.refund_details[item.party].map(
                (user, userIndex) => (
                  <div className="refund-party-member">
                    <h2 className="refund-party-member-label">
                      {item.party} {userIndex + 1}
                    </h2>
                    <div className="row">
                      {item.field.map((fieldItem) => (
                        <div key={fieldItem.label} className={"mb-10 col-md-6"}>
                          <p className="at-form-input__title" required>
                            {fieldItem.label}
                          </p>
                          {fieldItem.type === "select" && (
                            <Select
                              autoWidth={true}
                              fullWidth
                              disabled={true}
                              input={<OutlinedInput labelWidth={0} />}
                              value={user[fieldItem.value]}
                            >
                              {banks.map((item) => {
                                return (
                                  <MenuItem key={item.id} value={item.id}>
                                    {item.name} - {item.country?.name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          )}
                          {fieldItem.type !== "select" && (
                            <input
                              disabled={true}
                              className="refund-letter-input d-block ml-0"
                              required={true}
                              type={fieldItem.type}
                              style={{ width: "100%", maxWidth: "100%" }}
                              value={user[fieldItem.value]}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                ),
              )}
          </section>
        ))}
      </div>
      {onLoadRefund && <LoadingModal />}
    </>
  );
};

const mapStateToProps = (state) => ({ data: state });
export default connect(mapStateToProps)(RefundForm);
