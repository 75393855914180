import React, { Component } from "react";
import { FaUserTie } from "react-icons/fa";
import _ from "lodash";
import { MenuItem } from "@material-ui/core";

import CustomButton from "components/Button";
import LoadingModal from "components/LoadingModal";
import AtlasTable from "components/NewPaginatedTable";
import CustomDropdown from "components/Button/dropdown";
import CustomCheckbox from "components/Checkbox";
import DeleteConfirmationModal from "components/Modal/delete";
import ContactFormDialog from "../components/formDialog";
import ShareLinkToRepresentative from "containers/Dashboard/SubsalesClaims/containers/ClaimForm/components/shareLinkToRepresentative"
import AtlasIcon from "components/Icon/atlasIcon";

import permissionsChecker from "utils/permissionsChecker";
import { getColorBadge } from "dictionary/badgeColor";
import {oppositeInfoStatusBadges} from "containers/Dashboard/Administrator/SubsalesClaimsBeta/assets";

import "stylesheets/containers/subsales-claims/index.scss";

const columnData = [
  {
    header: "Full Name",
    renderColumn: (rowData) => (
      <div className={"d-flex align-items-center party-card__badge-title-icon at-subsales-contact_form_type-title-icon"}>
        {["3", "4"].indexOf(rowData.contact_type_id) === -1 && (
          <>
            {rowData.is_company && (
              <>
                <AtlasIcon svgHref={"atlas-building-company"}/>
                <div className={"ml-1"}>
                  {rowData.company_name}
                  <br />({rowData.company_registration_no})
                  <br />
                  {rowData.full_name}
                </div>
              </>
            )}
            {!rowData.is_company && (
              <>
                <AtlasIcon svgHref={"atlas-icon-user"}/>
                {rowData.full_name}
              </>
            )}
          </>
        )}
        {["3", "4"].indexOf(rowData.contact_type_id) > -1 && (
          <>
            <FaUserTie />
            {rowData.company_name}
            <br />({rowData.company_registration_no})
          </>
        )}
      </div>
    ),
  },
  { header: "NRIC/Passport No.", accessor: "nric" },
  { header: "Mobile/Office Contact No.", accessor: "contact_number" },
];

class Client extends Component {
  state = {
    newTypeId: "",
    showConfirmationModal: false,
    toRemoveItem: {},
  };

  componentDidMount = () => {
    const { id: opposite_claim_id } =
      this.props.selected_claim?.opposite_claim ?? {};

    this.props.getAssignedContactType({
      claim_id: this.props.selected_claim.id,
      type_id: 2,
      isPrimaryClaim: true,
    });

    if (opposite_claim_id) {
      this.props.getAssignedContactType({
        claim_id: opposite_claim_id,
        type_id: 2,
        isPrimaryClaim: false,
      });
    }
  };

  onClickEditProfile = (id, data) => {
    this.props.onChangeAgencyHOC( "addressInfo", {} );
    this.props.getSelectedEntity(id, data.type_id, data.id);
  };
  onClickRemoveProfile = (data) =>
    this.setState({ showConfirmationModal: true, toRemoveItem: data });

  deleteSelectedItem = () =>
    this.setState({ showConfirmationModal: false }, () => {
      this.props.removeSelectedEntity(
        this.props.selected_claim.id,
        2,
        this.state.toRemoveItem.id,
      );
    });

  updateSelectedEntity = (data) => 
    this.props.updateSelectedEntity(
      data,
      this.props.selected_claim.id,
      2,
      data.id,
    );

  renderEditDialog = () => {
    const tmpIsPrimaryClaimRepresentative = this.props.selected_claim.representative_id === 1 && this.props.selectedContact.contact_type_id === 2;

    if (this.props.showEditEntityDialog) {
      return (
        <ContactFormDialog
          title={"Edit"}
          banks={this.props.banks}
          formTypes={
            this.props.selectedContact.contact_type_id === 2
              ? this.props.formTypes
              : []
          }
          loading={this.props.onLoadContactType}
          addressInfo={this.props.addressInfo}
          allowToHideContact={tmpIsPrimaryClaimRepresentative}
          personalForms={this.props.personalForms}
          companyForms={this.props.companyForms}
          titleContext={
            this.props.selectedContact.contact_type_id === 2
              ? this.props.selected_claim.sub_sale_type_id === 2 
                ? "Tenant" 
                : "Purchaser"
              : "Law Firm"
          }
          onToggleDialog={() => this.props.onChangeContactTypeHOC(false, "showEditEntityDialog")}
          onChangeValue={this.props.onChangeEditContact}
          submitData={(data) => this.props.submitUpdatedData(this.props.selected_claim.id, data)}
          nationalities={this.props.nationalities}
          storedAddresses={this.props.data.addressReducer.address}
          selectedClaim={this.props.selected_claim}
          selectedContact={this.props.selectedContact}
          suggested_ic={this.props.suggested_ic}
          extactIcDetails={this.props.extactIcDetails}
          getAddressInfo={this.props.getAddressInfo}
          onChangeAgencyHOC={this.props.onChangeAgencyHOC}
          onChangeContactTypeHOC={this.props.onChangeContactTypeHOC}
          requestContactFromOppositeAgent={this.props.requestContactFromOppositeAgent}
        />
      );
    }
  };

  renderNewDialog = () => {
    let tmpIsPrimaryClaimRepresentative = this.props.selected_claim.representative_id === 1 && this.state.newTypeId === "2";

    if (this.props.showNewEntityDialog) {
      return (
        <ContactFormDialog
          title={"Add"}
          banks={this.props.banks}
          titleContext={
            this.state.newTypeId === "2" 
              ? this.props.selected_claim.sub_sale_type_id === 2 
                ? "Tenant"
                : "Purchaser"
              : "Law Firm"
          }
          formTypes={this.state.newTypeId === "2" ? this.props.formTypes : []}
          selectedClaim={this.props.selected_claim}
          addressInfo={this.props.addressInfo}
          allowToHideContact={tmpIsPrimaryClaimRepresentative}
          personalForms={this.props.personalForms}
          companyForms={this.props.companyForms}
          onToggleDialog={() =>
            this.props.onChangeContactTypeHOC(false, "showNewEntityDialog")
          }
          loading={this.props.onLoadContactType}
          submitData={(newContact) => {
            let tmpNewContact = {
              ...newContact,
              contact_type_id: this.state.newTypeId,
              type_id: 2,
            };
            this.props.createEntity(
              tmpNewContact,
              this.props.selected_claim.id,
              2,
            );
          }}
          getAddressInfo={this.props.getAddressInfo}
          nationalities={this.props.nationalities}
          onChangeAgencyHOC={this.props.onChangeAgencyHOC}
          storedAddresses={this.props.data.addressReducer.address}
          onChangeContactTypeHOC={this.props.onChangeContactTypeHOC}
          requestContactFromOppositeAgent={this.props.requestContactFromOppositeAgent}
        />
      );
    }
  };

  renderTableHeading = (representative_id, can_create) => {    
    const { 
      form_type_id,
      opposite_info_status, 
      opposite_representative_party,
    } = this.props.selected_claim ?? {};

    let tmpOppositeRepresentativeParty = opposite_representative_party?.toLowerCase() ?? ""

    const { colorName = "" } =
      _.find(
        oppositeInfoStatusBadges,
        item => item.value === opposite_info_status,
      ) || {};

    if (can_create || (representative_id < 3 && representative_id !== null)) {
      return (
        <>
          {
            (representative_id === 1 && form_type_id !== 4) && (
              <div className={`at-table_heading flex-column align-items-start grid_gap-0 mb-lg-0 mb-3`}>
                <CustomCheckbox
                  disabled={true}
                  labelClassname={"at-party-checkbox"}
                  content={ <p className="at-form-input__title fw-500 mb-0">Let {tmpOppositeRepresentativeParty} or representative to add & update {tmpOppositeRepresentativeParty} details</p> }
                  checked={this.props.selected_claim.info_provided_by_opposite_agent}
                  onChangeCheckboxValue={() => {}}/>
                {opposite_info_status !== null && (
                  <div className="ml-4 pl-1">
                    <p className="at-form-input__title fw-500 mb-1">Representative Updated Status</p>
                    <div>
                      <span
                        style={{ ...getColorBadge(colorName) }}
                        className={`at-status_badge`}>
                        {opposite_info_status}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            )
          }
          <div className={`at-table_heading g-2`}>
            {can_create && (
              <>
                {this.renderDropDown(this.props.selected_claim.sub_sale_type_id === 2 ? "Tenant" : "Purchaser")}
                <CustomButton
                  className="btn-new btn-new--primary"
                  onClick={() => {
                    this.props.onChangeContactTypeHOC(
                      true,
                      "showNewEntityDialog",
                    );
                    this.setState({ newTypeId: "4" });
                  }}
                  children="Add Law Firm"
                  tooltip={ false }
                  tooltipChildren={ "Details cannot be added until representative updated status is Complete or uncheck the Share Link" }/>
              </>
            )}
          </div>
        </>
      );
    }
  };

  onClickSelectFromContacts = (party_title) => {
    this.props.updateSelectedParticipantTypeId(2)
    this.props.updateSelectedTitle(party_title)
    this.props.onChangeClaimsHOC("showAssignContactModal", true)
  }

  renderDropDown = (party_title) => {
    return (
      <CustomDropdown label={`Add ${party_title}`}>
        <MenuItem
          onClick={() => this.onClickSelectFromContacts(party_title)}
          classes={{ root: `at-btn__dropdown--list` }}>
          Select from My Contact
        </MenuItem>
        <MenuItem
          onClick={() => {
            this.props.onChangeAgencyHOC( "addressInfo", {} );
            this.props.onChangeContactTypeHOC(
              true,
              "showNewEntityDialog",
            );
            this.setState({ newTypeId: "2" });
          }}
          classes={{ root: `at-btn__dropdown--list` }}>
          Create a New Contact
        </MenuItem>
      </CustomDropdown>
    );
  }

  render = () => {
    const { clients, opposite_claim_clients } = this.props;
    const { can_create, can_destroy, can_update } = permissionsChecker(
      "Subsales Claims",
      this.props.data,
    );
      
    const { 
      representative_id,
      info_provided_by_opposite_agent,
      opposite_info_status, 
      opposite_info_link,
    } = this.props.selected_claim ?? {};

    return (
      <>
        <AtlasTable
          columns={columnData}
          meta={[]}
          searchParams={[]}
          totalPages={["1"]}
          rowData={clients || []}
          actionColumnContent={[
            {
              name: "info",
              onShow: () => !can_update,
              onClick: (rowData) =>
                this.onClickEditProfile(
                  this.props.selected_claim.id,
                  rowData
                ),
            },
            {
              name: "edit",
              onShow: () => can_update,
              onClick: (rowData) =>
                this.onClickEditProfile(
                  this.props.selected_claim.id,
                  rowData
                ),
            },
            {
              name: "delete",
              color: "#F04438",
              onShow: () => can_destroy,
              onClick: (rowData) => this.onClickRemoveProfile(rowData),
            },
          ]}
          renderExtraContent={() =>
            this.renderTableHeading(representative_id, can_create)
          }
        />
        {
          this.props.showShareLink && (
            <ShareLinkToRepresentative
              representative_title={this.props.selected_claim.opposite_representative_party}
              infoPassword={this.props.infoPassword}
              onChangeInfoPassword={val => this.props.onChangeOppositePaticipantHOC(val, "infoPassword")}
              disabledShareButtons={this.props.disabledShareButtons}
              selected_claim={this.props.selected_claim}
              opposite_info_status={opposite_info_status}
              opposite_info_link={opposite_info_link}
              info_provided_by_opposite_agent={info_provided_by_opposite_agent}
              updateOppositeInfoProvided={this.props.updateOppositeInfoProvided}
              sendOppositeInfoRequestEmail={this.props.sendOppositeInfoRequestEmail}
              onClickShareEmail={ () => this.props.onChangeOppositePaticipantHOC(true, "showSendEmailLink") }
              onToggleDialog={val => {
                Promise.all([
                  this.props.onChangeOppositePaticipantHOC(val, "showShareLink")
                ]).then(() => {
                  this.props.onChangeOppositePaticipantHOC("", "infoPassword")
                })
              }}/>
          )
        }
        {this.renderNewDialog()}
        {this.renderEditDialog()}
        {this.props.onLoadContactType && <LoadingModal />}
        <DeleteConfirmationModal
          open={this.state.showConfirmationModal}
          loading={this.props.onLoadContactType}
          positiveAction={this.deleteSelectedItem}
          negativeAction={() => this.setState({ showConfirmationModal: false })}
          message={`You will not able to recover the data`}
        />
      </>
    );
  };
}

export default (Client);
