import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";
import { IoMdAdd } from "react-icons/io";

import CustomCard from "components/Card";
import AlertBox from "components/AlertBox";
import AtlasDialog from "components/Dialog";
import AtlasIcon from "components/Icon/atlasIcon";
import AtlasSnackbar from "components/Snackbar/v2";
import ModuleHeader from "components/ModuleHeader";
import LoadingModal from "components/LoadingModal";
import ConfirmDeleteModal from "components/Modal/delete";
import AtPaginatedTable from "./components/Table";
import ImportExcelContent from "./ImportExcel";
import ModalDialog from "components/Modal/ModalDialog";

import NewModal from "./New";
import EditModal from "./Edit";
import ViewModal from "./View";

import withLocalization from "actions/localisation";
import SubsalesHOC from "./actions";
import DeleteHOC from "./actions/delete";
import FloorPlanHOC from "./actions/floorplan";
import MediaLinkHOC from "./actions/mediaLink";
import CoBrokeHOC from "./actions/cobroke-request";
import SubsalesStatusHOC from "actions/subsalesStatus";
import { getTranslation } from "assets/translation";
import permissionsChecker from "utils/permissionsChecker";
import { initGA } from "utils/ga";

import { categories } from "dictionary/subsales";

class Subsales extends Component {
  componentDidMount = () => {
    initGA("/dashboard/subsales");
    this.props.getCommRate();
    this.props.getCountryDictionary();
    this.props.getAllDictionaryData();
    this.props.getSubsalesCurrentUser(1, "");
    this.props.getAgentModuleKeys(14, this.props.data.languageReducer.language);
  };

  componentDidUpdate = (pp) => {
    let temp = _.cloneDeep(this.props.searchParams);

    let listingCategoryIndex = _.findIndex(this.props.searchParams, {
      value: "category_id_eq",
    });
    let propertyTypeIndex = _.findIndex(this.props.searchParams, {
      value: "property_type_id_eq",
    });

    if (listingCategoryIndex !== -1) {
      let selectedCategoryParam = this.props.searchParams[listingCategoryIndex].param;
      if (pp.searchParams[listingCategoryIndex].param !== selectedCategoryParam) {
        selectedCategoryParam = _.find(categories, { id: selectedCategoryParam });
        let tmpPropertyTypes = selectedCategoryParam
          ? selectedCategoryParam.group_types.map((item) => {
            let children = item.children.map((child) => ({
              id: child.id,
              name: child.name,
              label: child.name,
              value: child.id,
              key: child.key || null,
              groupName: item.name,
              })
            );
            let tmpParent = {
              name: item.name,
              key: item.key || null,
              children: children,
            };
            return tmpParent;
          })
        : [];
        temp[propertyTypeIndex].param = "";
        temp[propertyTypeIndex].options = tmpPropertyTypes;
        this.props.onChangeSubsalesHOC(temp, "searchParams");
      }
    }

    let countryIndex = _.findIndex(this.props.searchParams, {
      value: "country_id_eq",
    });
    let stateIndex = _.findIndex(this.props.searchParams, {
      value: "state_id_eq",
    });
    let townIndex = _.findIndex(this.props.searchParams, {
      value: "township_cont",
    });

    if (pp.searchParams[countryIndex].param !== temp[countryIndex].param) {
      let selectedCountry = _.find(
        this.props.data.dictionaryReducer.countries,
        { id: this.props.searchParams[countryIndex].param },
      );
      temp[stateIndex].options = selectedCountry ? selectedCountry.states : [];
      temp[stateIndex].param = "";
      temp[townIndex].param = "";
      temp[townIndex].options = [];
      pp.searchParams[stateIndex].param = "";

      this.props.onChangeSubsalesHOC(temp, "searchParams");
    }

    if (
      pp.searchParams[stateIndex].param !== temp[stateIndex].param &&
      temp[stateIndex].param !== ""
    ) {
      this.props.getSelectedTownShip(temp[stateIndex].param);
    }

    if (
      pp.showEditModal !== this.props.showEditModal &&
      !this.props.showEditModal
    ) {
      this.props.onChangeSubsalesHOC([], "propertyOptions");
    }
  };

  renderCardContent = () => {
    const { can_destroy } = permissionsChecker(
      "Listing Centre",
      this.props.data,
    );
    const { status_id } = window.location.href.includes("/admin-impersonate")
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;
    const status_permissions =
      (status_id !== 2 && this.props.completed_trainings) ||
      this.props.whitelisted;

    return (
      <>
        <AtPaginatedTable
          totalPendingRequest={this.props.totalPendingRequest}
          rowData={
            this.props.tmpSubsalesCurrentUser.data
              ? this.props.tmpSubsalesCurrentUser.data
              : []
          }
          meta={
            this.props.tmpSubsalesCurrentUser.meta &&
            this.props.tmpSubsalesCurrentUser.meta
          }
          onClickCard={(val) => {
            Promise.all([
              this.props.getSelectedSubsales(val.id),
              this.props.getFloorPlans(val.id),
            ]).then(() => {
              this.props.onChangeSubsalesHOC(true, "showViewModal");
            });
          }}
          onClickEdit={(val) => {
            Promise.all([
              this.props.getSelectedSubsales(val.id),
              this.props.getFloorPlans(val.id),
            ]).then(() => {
              this.props.onChangeSubsalesHOC(true, "showEditModal");
            });
          }}
          onClickDelete={(val) => {
            this.props.onChangeSubsalesHOC(true, "showDeleteModal");
            this.props.onChangeDeleteHOC(val.id, "selectedDeleteSubsaleId");
            this.props.onChangeDeleteHOC(val.code, "selectedDeleteSubsaleCode");
          }}
          onToggleCobroke={() =>
            this.props.onChangeSubsalesHOC(true, "showCobrokeRequest")
          }
          onClickFavourite={(id) => this.props.toggleFavourite(id)}
          searchParams={this.props.searchParams}
          onChangeSearchParams={(val) =>
            this.props.onChangeSubsalesHOC(val, "searchParams")
          }
          getListAPI={this.props.getSubsalesCurrentUser}
          totalPages={this.props.subsalesPages}
          status_permissions={status_permissions}
          can_destroy={can_destroy}
          sideMenuExpand={this.props.data.sideMenuReducer.sideMenuExpand}
          getLocalised={this.props.getLocalised}
          getFilterKey={this.props.getFilterKey}
          renderSpecialMessage={this.props.renderSpecialMessage}
        />
      </>
    );
  };

  renderCardContentModal = () => (
    <>
      <h4>Notification</h4>
      <hr />
      <p>
        Please wait awhile while we load the necessarily information required
        from the server for you.
      </p>
    </>
  );

  renderAlertContent = () => {
    const { data, completed_trainings } = this.props;
    const { language } = data.languageReducer;

    const { status_id } = window.location.href.includes("/admin-impersonate")
      ? data.currentSignInProfileReducer
      : data.profileReducer;
    if (status_id === 2 || !completed_trainings) {
      return (
        <>
          {status_id === 2 && (
            <AlertBox
              mode={"danger"}
              containerClassName={!completed_trainings ? "mb-3" : ""}
              description={getTranslation("account_inactive", language)}
            />
          )}
          {!completed_trainings && (
            <AlertBox
              mode={"danger"}
              title={getTranslation("training_not_completed", language)}
              description={getTranslation("required_to_finish_training", language)}
            />
          )}
        </>
      );
    }
    return "";
  };

  render = () => {
    const {
      getLocalised,
      showNewModal,
      showEditModal,
      showViewModal,
      onLoadSubsales,
      onLoadDeleteSubsale,
      onLoadCobroke,
      onLoadLocalisation,
    } = this.props;
    const { language } = this.props.data.languageReducer;
    const { sideMenuExpand } = this.props.data.sideMenuReducer;
    const { can_create } = permissionsChecker(
      "Listing Centre",
      this.props.data,
    );
    const { role, status_id } = window.location.href.includes(
      "/admin-impersonate",
    )
      ? this.props.data.currentSignInProfileReducer
      : this.props.data.profileReducer;
    const status_permissions =
      (status_id !== 2 && this.props.completed_trainings) ||
      this.props.whitelisted;

    return (
      <>
        <ModuleHeader
          title={getLocalised("title.listing_centre", "Listing Centre")}
          showPABadge={role === "Personal Assistant"}
          atlasIcon={"atlas-building-3"}
          renderAlertContent={this.renderAlertContent()}
          actionButton={[
            {
              label: getLocalised("title.details.new", "New Listing"),
              onShow: can_create && status_permissions,
              className: "btn-new btn-new--primary",
              icon: (
                <IoMdAdd style={{ height: 18, width: 18, marginRight: 8 }} />
              ),
              onClick: () =>
                this.props.onChangeSubsalesHOC(true, "showNewModal"),
            },
          ]}
        />
        {showNewModal && (
          <ModalDialog
            title={getLocalised("title.details.new", "New Listing")}
            onClose={() =>
              this.props.onChangeSubsalesHOC(false, "showNewModal")
            }
            children={<NewModal {...this.props} />}
          />
        )}
        {showEditModal && (
          <ModalDialog
            title={getLocalised("title.details.edit", "Edit Listing")}
            fullWidth={true}
            fullHeight={true}
            onClose={() =>
              this.props.onChangeSubsalesHOC(false, "showEditModal")
            }
            children={<EditModal {...this.props} />}
          />
        )}
        {showViewModal && (
          <ModalDialog
            title={getLocalised("title.details.view", "View Listing")}
            fullWidth={true}
            fullHeight={true}
            onClose={() =>
              this.props.onChangeSubsalesHOC(false, "showViewModal")
            }
            children={
              <ViewModal
                {...this.props}
                status_permissions={status_permissions}
              />
            }
          />
        )}
        <AtlasDialog
          paperClassName={"paper"}
          open={this.props.showSubsaleInfoLoadingModal}
        >
          <CustomCard cardContent={this.renderCardContentModal()} />
        </AtlasDialog>
        <AtlasDialog open={this.props.showImportModal}>
          <CustomCard
            className={"h-100"}
            cardContent={
              <ImportExcelContent
                onClose={() =>
                  this.props.onChangeSubsalesHOC(false, "showImportModal")
                }
              />
            }
          />
        </AtlasDialog>
        <ConfirmDeleteModal
          open={this.props.showDeleteModal}
          loading={this.props.onLoadDeleteSubsale}
          title={getLocalised("title.details.delete", "Delete Listing")}
          message={
            <>
              {getLocalised(
                "label.details.delete.message_1", 
                `You are about to permanently delete this listing. This action is
                irreversible and the following will be occur:`
              )}
              <div className={"at-subsales__delete"}>
                <div>
                  <AtlasIcon svgHref={"atlas-danger"} />
                  {getLocalised(
                    "label.details.delete.message_2", 
                    `The listing will be removed from the published sites.`
                  )}
                </div>
                <div>
                  <AtlasIcon svgHref={"atlas-close-circle"} />
                  {getLocalised(
                    "label.details.delete.message_3", 
                    `All Co-broke agreements for this listing will be set to No
                    Longer Available.`
                  )}
                </div>
                <div className="mb-2">
                  <AtlasIcon svgHref={"atlas-notification"} />
                  {getLocalised(
                    "label.details.delete.message_4", 
                    `A notification will be sent to any existing Co-broke agents for this listing.`
                  )}
                </div>
              </div>
            </>
          }
          positiveText={getTranslation("delete", language)}
          positiveAction={() => {
            this.props.onClickDeleteSubsale(
              this.props.selectedDeleteSubsaleId,
              this.props.selectedDeleteSubsaleCode,
            );
          }}
          negativeText={getTranslation("cancel", language)}
          negativeAction={() =>
            this.props.onChangeSubsalesHOC(false, "showDeleteModal")
          }
        />

        <CustomCard cardContent={this.renderCardContent()} />

        <AtlasSnackbar
          open={this.props.openSnackBar}
          handleClose={() =>
            this.props.onChangeSubsalesHOC(false, "openSnackBar")
          }
          message={this.props.snackBarMessage}
        />
        {(onLoadSubsales || onLoadDeleteSubsale || onLoadLocalisation) && <LoadingModal />}
      </>
    );
  };
}

export default compose(
  withLocalization,
  SubsalesStatusHOC,
  SubsalesHOC,
  FloorPlanHOC,
  MediaLinkHOC,
  CoBrokeHOC,
  DeleteHOC
)(Subsales);
