import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import Moment from "moment";

import { Get, GetFile } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      includeResigned: false, 
      showDetailsModal: false,
      searchParams: [],

      teamMembersList: {},
      showSearchTips: false,
      selectedTeamMember: {},
      showAgentSales: false,
    };

    load = (param) => this.setState({ loading: param });

    componentDidMount() {
      this.updateSearchParams();
    }

    updateSearchParams = () => {
      const newParams = this.generateSearchParams(this.props.data, this.state.includeResigned);
      
      const mergedParams = _.map(newParams, newParam => {
        const existingParam = _.find(this.state.searchParams, { value: newParam.value });
        
        if (existingParam && !_.isUndefined(existingParam.param)) {
          return { ...newParam, param: existingParam.param };
        }
        
        if (newParam.value === "include_resigned") {
          return { ...newParam, param: this.state.includeResigned ? "true" : "false" };
        }
        
        return newParam;
      });
      
      this.setState({ searchParams: mergedParams });
    }

    generateSearchParams = (data, includeResigned) => {
      let tempBranch = _.map(data.branchReducer.branch, (branch) => {
        return {
          ...branch,
          value: branch.id,
        };
      });

      const allStatusOptions = [
        { label: "Active", value: "1" },
        { label: "Inactive", value: "2" },
        { label: "Pending", value: "6" },
        { label: "Waiting Approval", value: "8" },
        { label: "Resigned", value: "5" },
        { label: "Resigned Inactive", value: "9" },
      ];

      // Filter status options based on includeResigned
      const filteredStatusOptions = includeResigned 
        ? allStatusOptions 
        : allStatusOptions.filter(option => option.value !== "5" && option.value !== "9");

      return [
        {
          label: "Full Name",
          value: "full_name_cont",
          type: "input",
          param: "",
        },
        {
          label: "Display Name",
          value: "display_name_cont",
          type: "input",
          param: "",
        },
        {
          label: "Email",
          value: "email_cont",
          type: "input",
          param: "",
        },
        {
          label: "Mobile Number",
          value: "mobile_contact_number_cont",
          type: "input",
          param: "",
        },
        {
          label: "Branch",
          value: "branch_id_eq",
          type: "select",
          param: "",
          options: tempBranch,
        },
        {
          label: "Status",
          value: "status_id_eq",
          type: "radio",
          col: 12,
          param: "",
          options: filteredStatusOptions,
        },
        {
          label: "Include Resigned Agent?",
          value: "include_resigned",
          type: "radio",
          param: includeResigned ? "true" : "false",
          options: [
            { label: "Yes", value: "true" },
            { label: "No", value: "false" },
          ]
        }
      ];
    };

    onChangeHOC = (val, context) => {
      if (context === "searchParams") {
        this.setState({ searchParams: val }, () => {
          const includeResignedParam = _.get(
            _.find(val, { value: "include_resigned" }),
            "param",
            String(this.state.includeResigned)
          );
          
          const shouldIncludeResigned = includeResignedParam === "true";
          
          if (shouldIncludeResigned !== this.state.includeResigned) {
            this.setState({ includeResigned: shouldIncludeResigned }, () => {
              const statusParam = _.get(
                _.find(val, { value: "status_id_eq" }),
                "param",
                ""
              );
              
              if (!shouldIncludeResigned && _.includes(["5", "9"], statusParam)) {
                const updatedParams = _.map(val, param => 
                  param.value === "status_id_eq" ? { ...param, param: "" } : param
                );
                
                const newParams = this.generateSearchParams(this.props.data, false);
                const statusOptions = _.find(newParams, { value: "status_id_eq" }).options;
                
                const finalParams = _.map(updatedParams, param => 
                  param.value === "status_id_eq" ? { ...param, options: statusOptions } : param
                );
                
                this.setState({ searchParams: finalParams });
              } else {
                this.updateSearchParams();
              }
            });
          }
        });
      } else if (context === "includeResigned") {
        this.setState({ includeResigned: val }, this.updateSearchParams);
      } else {
        this.setState({ [context]: val });
      }
    };

    getTeamMembersList = (page, query) => {
      let tmpQuery = _.cloneDeep(query);
      const isIncludeResignedTrue = query.includes("q[include_resigned]=true");
      tmpQuery = query.replace(/q\[include_resigned\]=(true|false)(&|$)/, "");
      Get(
        `/team_members?${tmpQuery}page=${page}${isIncludeResignedTrue ? "&include_resigned=true" : ""}`,
        this.getTeamMembersListSuccess,
        this.getTeamMembersListError,
        this.load,
      );
    }
    getTeamMembersListSuccess = (payload) => {
      let tmpData = payload.data.map((item) => ({
        ...item,
        dob: item.dob ? Moment(item.dob).format("DD-MMM-YYYY") : "N/A",
      }));

      this.setState({
        teamMembersList: {
          ...payload,
          data: tmpData,
        },
      });
    };
    getTeamMembersListError = (error) => requestError(error);

    getSelectedTeamMember = (id) =>
      Get(
        `/team_members/member?encoded_identity=${id}`,
        this.getSelectedTeamMemberSuccess,
        this.getSelectedTeamMemberError,
        this.load,
      );
    getSelectedTeamMemberSuccess = (payload) =>
      this.setState({
        selectedTeamMember: payload,
        showDetailsModal: true,
      });
    getSelectedTeamMemberError = (error) => requestError(error && error);

    exportTeamMembersList = () =>
      GetFile(
        `/team_members.xls`,
        "Team_Members_List.xls",
        this.exportTeamMembersListSuccess,
        this.exportTeamMembersListError,
        this.load,
      );
    exportTeamMembersListSuccess = () =>
      requestSuccess("Exported Successfully");
    exportTeamMembersListError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          onLoadTeamMembers={this.state.loading}
          selectedTeamMember={this.state.selectedTeamMember}
          teamMembersList={this.state.teamMembersList}
          showDetailsModal={this.state.showDetailsModal}
          searchParams={this.state.searchParams}
          showAgentSales={this.state.showAgentSales}
          showSearchTips={this.state.showSearchTips}
          onChangeHOC={this.onChangeHOC}
          getTeamMembersList={this.getTeamMembersList}
          exportTeamMembersList={this.exportTeamMembersList}
          getSelectedTeamMember={this.getSelectedTeamMember}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
