import React, { useEffect } from "react";
import { Card, CardContent, CardHeader, Dialog } from "@material-ui/core";

import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";

import "./ModalDialog.scss";

const ModalDialog = ({
  title,
  removeFooter,
  onLoad,
  children,
  footer,
  fullWidth,
  style,
  fullHeight,
  containerClasses,
  contentStyle,
  responsiveSize = "md",
  onClose,
  cardClassName,
  contentClassName,
  loaderClassName,
  loaderContainerClass,
  maxWidth = "lg",
}) => {
  useEffect(() => {
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  const handleKeyUp = (e) => {
    if (e.key === "Escape") {
      onClose();
    }
  };

  return (
    <Dialog
      open={true}
      maxWidth={maxWidth}
      classes={{
        paper: `m-0 at-modal_dialog-${responsiveSize}-responsive`,
        paperScrollPaper: `at-modal_dialog-scrollPaper ${containerClasses?.paperScrollPaper || ''} ${fullWidth ? "w-100" : ""}`,
      }}
    >
      <Card
        classes={{ root: `${cardClassName} at-card__overlay` }}
        style={{ ...style }}
      >
        {(title || onClose) && (
          <CardHeader
            classes={{ root: "at-modal_dialog-header", action: "m-0" }}
            action={
              <button
                className="btn-new btn-new--outline-secondary ml-auto"
                style={{
                  width: 32,
                  height: 32,
                  minHeight: 32,
                  padding: "4px 8px",
                }}
                onClick={() => onClose()}
              >
                <AtlasIcon
                  svgHref={"atlas-X"}
                  style={{ width: 14, height: 14, color: "#374151" }}
                />
              </button>
            }
            title={<h2 className="fs-4 fw-600">{title}</h2>}
          />
        )}
        <CardContent
          classes={{
            root: `at-modal_dialog-content ${fullHeight ? "at-modal_dialog-content-minHeight" : ""} ${removeFooter ? "at-modal_dialog-no_footer" : ""} ${!title ? 'mt-0' : ''} ${contentClassName || ""}`,
          }}
          style={contentStyle || {}}>
          {children}
        </CardContent>
        {footer && <div className="at-modal_dialog-container-footer">{footer}</div>}
        {onLoad && 
          <LoadingModal 
            loaderClassName={loaderClassName}  
            loaderContainerClass={loaderContainerClass}/>}
      </Card>
    </Dialog>
  );
};

export default ModalDialog;
