import React, { Component } from "react";
import Moment from "moment";
import _ from "lodash";
import { Buffer } from "buffer";
import { connect } from "react-redux";
import { Post, Put, Get, Delete, GetFile } from "utils/axios";
import { storeLastView } from "actions/lastView";

import { requestError, requestSuccess } from "utils/requestHandler";

const searchParams = [
  {
    label: "Full Name",
    value: "full_name_or_company_cont",
    type: "input",
    param: "",
  },
  {
    label: "Email",
    value: "email_cont",
    type: "input",
    param: "",
  },
  {
    label: "NRIC/Passport No.",
    value: "identification_number_cont",
    type: "input",
    param: "",
  },
  {
    label: "Mobile Number",
    value: "mobile_contact_number_cont",
    type: "input",
    param: "",
  },
  {
    label: "REN Tag",
    value: "ren_tag_cont",
    type: "input",
    param: "",
  },
  {
    label: "Contact Types",
    value: "type_id_eq",
    type: "radio",
    param: "",
    col: 12,
    options: [
      { value: "", label: "All" },
      { value: "1", label: "Personal" },
      { value: "2", label: "Company" },
    ],
  },
];

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      searchParams: searchParams,
      loading: false,

      addressInfo: {},
      reassignSubmissions: {},

      showViewContactModal: false,
      showDeleteConfirmation: false,
      showEditContactModal: false,
      showCreateContactModal: false,
      showExportConfirmation: false,

      contacts: {},
      selectedContact: null,
      contactPages: [],
    };

    load = (param) => this.setState({ loading: param });

    onChangeContactHOC = (val, context) => this.setState({ [context]: val });

    getContacts = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);
      Get(
        `/contacts?${search}page=${page}`,
        this.getContactsSuccess,
        this.getContactsError,
        this.load,
      );
    };
    getContactsSuccess = (payload) => {
      let tempPages = [];
      for (let index = 0; index < payload.meta.pages; index++) {
        tempPages.push(index);
      }
      this.setState({
        contacts: payload,
        contactPages: tempPages,
      });
    };
    getContactsError = (error) => requestError(error);

    getSelectedContact = (id, mode) =>
      Get(
        `/${this.isPAFlag ? "personal_assistant/" : ""}contacts/${id}`,
        (payload) => this.getSelectedContactSuccess(payload, mode),
        this.getSelectedContactError,
        this.load,
      );
    getSelectedContactSuccess = (payload, mode) => {
      this.setState({
        selectedContact: {
          ...payload,
          date_of_birth: Moment(payload.date_of_birth, "YYYY-MM-DD").isValid()
            ? Moment(payload.date_of_birth, "YYYY-MM-DD").format("DD-MM-YYYY")
            : payload.date_of_birth
        },
        showViewContactModal: mode === "view",
        showEditContactModal: mode !== "view"
      });
    }
    getSelectedContactError = (error) => requestError(error);
    getSelectedContactError = (error) => requestError(error);

    createContact = (dataToSubmit) =>
      Post(
        `/${this.isPAFlag ? "personal_assistant/" : ""}contacts`,
        dataToSubmit,
        this.createContactSuccess,
        this.createContactError,
        this.load,
      );
    createContactSuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getContacts(currentPage, searchParams);

      this.setState({ showCreateContactModal: false, addressInfo: {} });
      requestSuccess(`Contact has been created successfully.`);
    };
    createContactError = (error) => requestError(error);

    updateContact = (dataToSubmit) =>
      Put(
        `/${this.isPAFlag ? "personal_assistant/" : ""}contacts/${dataToSubmit.id}`,
        dataToSubmit,
        this.updateContactSuccess,
        this.updateContactError,
        this.load,
      );
    updateContactSuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getContacts(currentPage, searchParams);

      this.setState({ showEditContactModal: false, addressInfo: {} });
      requestSuccess(`Contact has been updated successfully.`);
    };
    updateContactError = (error) => requestError(error);

    deleteContact = (id) =>
      Delete(
        `/${this.isPAFlag ? "personal_assistant/" : ""}contacts/${id}`,
        this.deleteContactSuccess,
        this.deleteContactError,
        this.load,
      );
    deleteContactSuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;
      this.getContacts(currentPage, searchParams);
      this.setState({ showDeleteConfirmation: false });
      requestSuccess(`Contact has been deleted successfully`);
    };
    deleteContactError = (error) => requestError(error);

    exportContact = () => {
      this.setState({ showExportConfirmation: false });
      GetFile(
        `/contacts/download`,
        `${this.props.data.profileReducer.full_name}_Contact_Export`,
        () => {},
        this.exportContactError,
        this.load,
      );
    };
    exportContactError = (error) => requestError(error);

    getAddressInfo = address => {
      const base64Address = encodeURIComponent(address)
      Get(
        `/townships/get_address_info?address=${base64Address}`,
        this.getAddressInfoSuccess,
        this.getAddressInfoError,
        () => {}
      )
    }
    getAddressInfoSuccess = (payload) => this.setState({ addressInfo: payload });
    getAddressInfoError = () => this.setState({ addressInfo: {} });

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            {...this.state}
            onLoadContact={this.state.loading}

            onChangeContactHOC={this.onChangeContactHOC}
            getContacts={this.getContacts}
            getSelectedContact={this.getSelectedContact}
            exportContact={this.exportContact}
            createContact={this.createContact}
            updateContact={this.updateContact}
            deleteContact={this.deleteContact}
            getAddressInfo={this.getAddressInfo}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps, { storeLastView })(WithHOC);
};

export default HOC;
