import React, { Component } from "react";
import _ from "lodash";
import { Buffer } from "buffer";

import { Get } from "utils/axios";
import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      agencies: [],
      addressInfo: {},
      showLoadingModal: false,
    };

    load = (param) => this.setState({ showLoadingModal: param });
    onChangeAgencyHOC = (context, value) => this.setState({ [context]: value });

    getAgencies = () =>
      Get(
        `/agencies`,
        this.getAgenciesSuccess,
        this.getAgenciesError,
        this.load,
      );
    getAgenciesSuccess = (payload) => {
      let tmp = [];
      payload.map((item) => {
        tmp.push({
          ...item,
          label: item.name,
        });
      });
      return this.setState({ agencies: tmp });
    };
    getAgenciesError = (error) => requestError(error);

    getAddressInfo = address => {
      const base64Address = encodeURIComponent(address)
      Get(
        `/townships/get_address_info?address=${base64Address}`,
        this.getAddressInfoSuccess,
        this.getAddressInfoError,
        () => {}
      )
    }
    getAddressInfoSuccess = (payload) => this.setState({ addressInfo: payload });
    getAddressInfoError = () => this.setState({ addressInfo: {} });

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          onLoadAgencies={this.state.showLoadingModal}

          getAgencies={this.getAgencies}
          getAddressInfo={this.getAddressInfo}
          onChangeAgencyHOC={this.onChangeAgencyHOC}
        />
      );
    };
  }

  return WithHOC;
};

export default HOC;
