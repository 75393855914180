import React from "react";
import _ from "lodash";

import CustomUpload from "components/Upload";
import CustomCheckbox from "components/Checkbox";
import CustomFormInput from "components/Input/formInput";

import "tui-image-editor/dist/tui-image-editor.css";

const Photo = "https://atlasfrontendassets.s3.ap-southeast-1.amazonaws.com/namecard-template-photo.jpg";
const PhotoQR = "https://atlasfrontendassets.s3.ap-southeast-1.amazonaws.com/with_photo_without_qr.jpg";
const NonePick = "https://atlasfrontendassets.s3.ap-southeast-1.amazonaws.com/namecard-template.png";
const NonePickQR = "https://atlasfrontendassets.s3.ap-southeast-1.amazonaws.com/without_photo_without_qr.jpg";

const NameCardContent = ({
  nameCardDisplayNameError,

  onChangeState,
  updateAgentInfo,
  onChangeUpdateAgent,
  onHandleEdit,
}) => {
  const updateAgentInfoData = (key, val) => {
    let tmp = _.cloneDeep(updateAgentInfo.name_card_attributes);
    tmp[key] = val;

    if (key === "name_card_display_name") {
      onChangeState(
        "nameCardDisplayNameError",
        val ? "" : "Please fill in the required field."
      );
    }
    onChangeUpdateAgent(tmp, "name_card_attributes");
  };

  return (
    <>
      <section className="grid-half-col p-2">
        <h2 className="at-form-input__title">Sample of Name Card</h2>
        {!updateAgentInfo.name_card_attributes.include_profile_photo && (
          <>
            {updateAgentInfo.name_card_attributes.include_qr_photo ? (
              <img
                src={NonePick}
                alt="Name card"
                style={{ maxHeight: 400, paddingBottom: 20 }}
              />
            ) : (
              <img
                src={NonePickQR}
                alt="Name card QR"
                style={{ maxHeight: 400, paddingBottom: 20 }}
              />
            )}
          </>
        )}
        {updateAgentInfo.name_card_attributes.include_profile_photo && (
          <>
            {updateAgentInfo.name_card_attributes.include_qr_photo ? (
              <img
                src={Photo}
                alt="Name card profile"
                style={{ maxHeight: 400, paddingBottom: 20 }}
              />
            ) : (
              <img
                src={PhotoQR}
                alt="Name card profile QR"
                style={{ maxHeight: 400, paddingBottom: 20 }}
              />
            )}
          </>
        )}
        <h2 className="at-form-input__title mt-10">Namecard Option</h2>
        <div className={"d-flex g-3"}>
          <CustomCheckbox
            content={"Photo"}
            checked={updateAgentInfo.name_card_attributes.include_profile_photo}
            onChangeCheckboxValue={(e) =>
              updateAgentInfoData("include_profile_photo", e.target.checked)
            }
          />
          <CustomCheckbox
            content={"Include QR Code"}
            checked={updateAgentInfo.name_card_attributes.include_qr_photo}
            onChangeCheckboxValue={(e) =>
              updateAgentInfoData("include_qr_photo", e.target.checked)
            }
          />
        </div>
      </section>
      <section className="grid-half-col p-2">
        {updateAgentInfo.name_card_attributes.include_profile_photo && (
          <>
            <h2 className="at-form-input__title">
              Click to upload an image for Name Card.
            </h2>
            <CustomUpload
              containerClass="at-img_container-fit_size"
              accepts={"image/png,image/jpg,image/jpeg"}
              className="at-img_display-full_size"
              style={{ height: 375 }}
              imageUrl={updateAgentInfo.name_card_attributes.photo}
              uploadImage={(val) => onHandleEdit(val)}
            />
          </>
        )}
      </section>
      <section className="grid-half-col p-2">
        <h2 className="at-form-input__title" required>
          Name on card
        </h2>
        <CustomFormInput
          type="text"
          value={updateAgentInfo.name_card_attributes.name_card_display_name}
          onChangeValue={(val) =>
            updateAgentInfoData("name_card_display_name", val)
          }
          required={true}
          inputError={nameCardDisplayNameError}
          placeholder={"Enter your namecard display name"}
        />
      </section>
      <section className="grid-half-col p-2">
        <h2 className="at-form-input__title">
          Name in local language ( optional )
        </h2>
        <CustomFormInput
          type="text"
          value={updateAgentInfo.name_card_attributes.additional_display_name}
          onChangeValue={(val) =>
            updateAgentInfoData("additional_display_name", val)
          }
          placeholder={"李安安, इरफान खान, Phúc Dũng"}
        />
      </section>
      <section className="grid-half-col p-2">
        <h2 className="at-form-input__title">Primary Mobile Number</h2>
        <CustomFormInput
          type="number"
          disabled={true}
          value={`${updateAgentInfo.mobile_contact_number}`}
          onChangeValue={(val) => {}}
        />
      </section>
      <section className="grid-half-col p-2">
        <h2 className="at-form-input__title">
          Additional Mobile Number( optional )
        </h2>
        <CustomFormInput
          type="number"
          value={`${updateAgentInfo.name_card_attributes.mobile_number}`}
          onChangeValue={(val) => updateAgentInfoData("mobile_number", val)}
          placeholder={
            "Enter your mobile contact number to display on namecard."
          }
        />
      </section>
      <section className="grid-half-col p-2">
        <h2 className="at-form-input__title">{`Title of Academy Achievement (${50 - (updateAgentInfo.name_card_attributes.qualification ? updateAgentInfo.name_card_attributes.qualification.length : 0)}/50)`}</h2>
        <CustomFormInput
          type="text"
          value={`${updateAgentInfo.name_card_attributes.qualification || ""}`}
          onChangeValue={(val) => {
            if (val.length < 51) {
              updateAgentInfoData("qualification", val);
            }
          }}
          placeholder={"BSc Engineering, BA Imperial College, MA Finance"}
        />
      </section>
      <section className="grid-half-col p-2">
        <h2 className="at-form-input__title">{`Designation`}</h2>
        <p style={{ color: "#F18E05", fontSize: 14 }}>
          Please consult with your referrer if you want to change your
          designation
        </p>
        <CustomFormInput
          type="text"
          disabled={true}
          value={`${updateAgentInfo.job_title_display_name || ""}`}
          onChangeValue={(val) => {}}
          placeholder={""}
        />
      </section>
      <section className="grid-half-col p-2">
        <h2 className="at-form-input__title">Board Reg. No</h2>
        <CustomFormInput
          type="text"
          value={`${updateAgentInfo.name_card_attributes.license_number || ""}`}
          disabled={true}
          placeholder={""}
        />
      </section>
    </>
  );
};

export default NameCardContent;
