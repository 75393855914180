import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import Moment from "moment";
import { storeLastView } from "actions/lastView";

import { requestError, requestSuccess } from "utils/requestHandler";

import { Get, Post, Put } from "utils/axios";
import { refreshToken } from "actions/login";

const searchParams = [
  {
    label: "Agent Name/Email",
    value: "user_full_name_or_user_email_cont",
    type: "input",
    param: "",
  },
  {
    label: "Form Number",
    value: "tracking_number_cont",
    type: "input",
    param: "",
  },
  {
    label: "Status",
    value: "status_id_eq",
    param: "",
    type: "radio",
    options: [
      { value: "", label: "All" },
      { value: "2", label: "Lost" },
      { value: "0", label: "Taken" },
      { value: "1", label: "Submitted" },
    ],
  },
];

const PaperFormHOC = (WrappedComponent) => {
  class PaperFormWrappedComponent extends Component {
    state = {
      paperForms: [],
      paperFormPages: null,
      selectedPaperForm: {},
      loading: false,

      showCreate: false,
      showUpdate: false,
      showSelectForm: false,
      showSubmitConfirmation: false,
      showDetachConfirmation: false,
      searchParams: searchParams,
    };

    load = (param) => this.setState({ loading: param });

    onChangePaperFormHOC = (key, val) => this.setState({ [key]: val });

    closeSubmitConfirmation = () =>
      this.setState({
        showUpdate: false,
        showSubmitConfirmation: false,
        showSelectForm: false,
        showDetachConfirmation: false,
      });

    getPaperForms = (page, search) => {
      let temp = {
        currentPage: page,
        searchParams: search,
      };
      this.props.storeLastView(temp);
      Get(
        `/admin/forms/sub_sales_forms?${search}page=${page}`,
        this.getPaperFormsSuccess,
        this.getPaperFormsError,
        this.load,
      );
    };
    getPaperFormsSuccess = (payload) => {
      let tmp = [];
      let tmpTotalPages = [];

      payload.data &&
        payload.data.map((item) => {
          tmp.push({
            ...item,
            date_taken: Moment(item.date_taken).format("DD MMM YYYY"),
            date_submitted: item.date_submitted
              ? Moment(item.date_submitted).format("DD MMM YYYY")
              : "",
          });
        });

      for (let index = 0; index < payload.meta.pages; index++) {
        tmpTotalPages.push(index);
      }
      return this.setState({
        paperForms: {
          data: tmp,
          meta: payload.meta,
        },
        paperFormPages: tmpTotalPages,
      });
    };
    getPaperFormsError = (error) => requestError(error);

    getSelectedPaperForm = (id) =>
      Get(
        `/admin/forms/sub_sales_forms/${id}`,
        this.getSelectedPaperFormSuccess,
        this.getSelectedPaperFormError,
        this.load,
      );
    getSelectedPaperFormSuccess = (payload) =>
      this.setState({
        selectedPaperForm: {
          ...payload,
          date_taken: Moment(payload.date_taken).isValid()
            ? Moment(payload.date_taken).format("DD-MM-YYYY")
            : payload.date_taken,
          user: payload.agent,
          form_type_id: payload.form_type_id.toString(),
          type_id: payload.type_id.toString(),
        },
        showUpdate: true,
      });
    getSelectedPaperFormError = (error) => requestError(error);

    createPaperForm = (dataToSubmit) => {
      let tempDataToSubmit = _.cloneDeep(dataToSubmit);
      delete tempDataToSubmit.user;

      Post(
        `/admin/forms/sub_sales_forms`,
        tempDataToSubmit,
        this.createPaperFormSuccess,
        this.createPaperFormError,
        this.load,
      );
    };
    createPaperFormSuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;

      requestSuccess("Other form is created successfully.");
      this.getPaperForms(currentPage, searchParams);
      this.setState({ showCreate: false });
    };
    createPaperFormError = (error) => requestError(error);

    updatePaperForm = (dataToSubmit) => {
      let tempDataToSubmit = _.cloneDeep(dataToSubmit);
      tempDataToSubmit.user_id = dataToSubmit.user.id;
      delete tempDataToSubmit.user;

      Put(
        `/admin/forms/sub_sales_forms/${dataToSubmit.id}`,
        tempDataToSubmit,
        this.updatePaperFormSuccess,
        this.updatePaperFormError,
        this.load,
      );
    };
    updatePaperFormSuccess = () => {
      const { currentPage, searchParams } =
        this.props.data.lastViewReducer.lastView;

      requestSuccess("Other form is updated successfully.");
      this.getPaperForms(currentPage, searchParams);
    };
    updatePaperFormError = (error) => requestError(error);

    render = () => {
      return (
        <>
          <WrappedComponent
            {...this.props}
            paperFormPages={this.state.paperFormPages}
            searchParams={this.state.searchParams}
            paperForms={this.state.paperForms}
            selectedPaperForm={this.state.selectedPaperForm}
            onLoadPaperForms={this.state.loading}
            showCreate={this.state.showCreate}
            showUpdate={this.state.showUpdate}
            showSelectForm={this.state.showSelectForm}
            showSubmitConfirmation={this.state.showSubmitConfirmation}
            showDetachConfirmation={this.state.showDetachConfirmation}
            getPaperForms={this.getPaperForms}
            getSelectedPaperForm={this.getSelectedPaperForm}
            createPaperForm={this.createPaperForm}
            updatePaperForm={this.updatePaperForm}
            closeSubmitConfirmation={this.closeSubmitConfirmation}
            onChangePaperFormHOC={this.onChangePaperFormHOC}
          />
        </>
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });

  return connect(mapStateToProps, {
    refreshToken,
    storeLastView,
  })(PaperFormWrappedComponent);
};

export default PaperFormHOC;
