import FormInputs from "../formInputs";

const closeOptions = [
  { value: false, label: "No" },
  { value: true, label: "Yes" },
];

const InternationalAdditionalDetail = ({
  onChangeFormData,
  onChangeField,
  updateNewAgent,
}) => {
  const inputConfig = [
    {
      type: "textarea",
      label: "Current Address",
      id: "current_address",
      required: true,
    },
  ];

  const inputAgentAttributesConfig = [
    {
      label: "Emergency Contact Person Full Name",
      id: "emergency_person_name",
      required: true,
      isReplaceNumeric: true,
    },
    {
      label: "Emergency Contact Number",
      id: "emergency_contact_number",
      required: true,
    },
    {
      type: "radio",
      label:
        "Are you currently an undischarged bankrupt person or entity or is there any bankruptcy petition pending or threatened against you?",
      id: "is_undischarged_bankrupt_person",
      required: true,
      options: closeOptions,
    },
    {
      type: "radio",
      label:
        "Have you ever been convicted of a crime or any offence in any country or been charged with any offence?",
      id: "has_been_convicted_crime",
      required: true,
      options: closeOptions,
    },
  ];

  return (
    <>
      <p className={"mb-4"}>
        Before proceeding to the next step, please fill in all the necessary
        additional details for record purposes.
      </p>
      <div className={"container-fluid p-0"}>
        <FormInputs
          payload={updateNewAgent}
          inputConfig={inputConfig}
          onChange={onChangeField}
        />

        <FormInputs
          payload={updateNewAgent.agent_registration_attributes}
          inputConfig={inputAgentAttributesConfig}
          onChange={onChangeFormData}
        />
      </div>
    </>
  );
};

export default InternationalAdditionalDetail;
