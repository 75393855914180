import { connect } from "react-redux";

import CustomButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";
import { Fragment } from "react";

import "./index.scss";

const FormChartIcons = [
  {
    id: 0,
    label: "Appointment Letter",
    keyword: "Appointment",
    icon: <AtlasIcon svgHref={"svg-document-user"} />,
  },
  {
    id: 1,
    label: "Authorisation Form",
    keyword: "Authorisation",
    icon: <AtlasIcon svgHref={"svg-document-home"} />,
  },
  {
    id: 2,
    label: "Physical Form",
    keyword: "Physical",
    icon: <AtlasIcon svgHref={"svg-document-tick"} />,
  },
  {
    id: 3,
    label: "Confirmation Form",
    keyword: "Confirmation",
    icon: <AtlasIcon svgHref={"svg-document-tick"} />,
  },
  {
    id: 4,
    label: "Claim Form",
    keyword: "Claim",
    icon: <AtlasIcon svgHref={"svg-building-finance"} />,
  },
];

const StackFormChartIcons = [
  {
    id: 0,
    icon: <AtlasIcon svgHref={"svg-document-user"} />,
  },
  {
    id: 1,
    icon: <AtlasIcon svgHref={"svg-document-tick"} />,
  },
  {
    id: 2,
    icon: <AtlasIcon svgHref={"svg-building-finance"} />,
  },
];

const defaultIcon = <AtlasIcon svgHref={"atlas-bootstrap-slash-lg"} />;

const pathNameInfo = [
  {
    label: "Appointment Letter",
    adminPath: "/dashboard/admin/forms/appointment-letter-management",
    nonAdminPath: "/dashboard/personal/forms/appointment-letter",
  },
  {
    label: "Authorisation Form",
    adminPath: "/dashboard/admin/forms/authorisation-form-management",
    nonAdminPath: "/dashboard/personal/forms/authorisation-form",
  },
  {
    label: "Confirmation Form",
    adminPath: "/dashboard/admin/forms/confirmation-forms-management",
    nonAdminPath: "/dashboard/personal/forms/confirmation-form",
  },
  {
    label: "Physical Form",
    adminPath: "/dashboard/admin/forms/paper-forms-management",
    nonAdminPath: "/dashboard/personal/forms/confirmation-form",
  },
  {
    label: "Claim Form",
    adminPath: "/dashboard/admin/subsales-claims",
    nonAdminPath: "/dashboard/personal/subsales-claims",
  },
];

const getAppointAuthoPath = (isAdmin, isAuthoForm) => {
  if (isAdmin) {
    return isAuthoForm
      ? "/dashboard/admin/forms/authorisation-form-management"
      : "/dashboard/admin/forms/appointment-letter-management";
  } else {
    return isAuthoForm
      ? "/dashboard/personal/forms/authorisation-form"
      : "/dashboard/personal/forms/appointment-letter";
  }
};

const paperFormPathInfo = (isAuthoForm) => [
  {
    label: "Appointment Letter",
    adminPath: getAppointAuthoPath(true, isAuthoForm),
    nonAdminPath: getAppointAuthoPath(false, isAuthoForm),
  },
  {
    label: "Appointment Letter / Authorisation Form",
    adminPath: getAppointAuthoPath(true, isAuthoForm),
    nonAdminPath: getAppointAuthoPath(false, isAuthoForm),
  },
  {
    label: "Physical Form",
    adminPath: "/dashboard/admin/forms/paper-forms-management",
    nonAdminPath: "",
  },
  {
    label: "Claim Form",
    adminPath: "/dashboard/admin/subsales-claims",
    nonAdminPath: "/dashboard/personal/subsales-claims",
  },
];

const isAdmin = (data) => {
  const { role } =
    window.location.href.indexOf("/admin-impersonate") > -1
      ? data.currentSignInProfileReducer
      : data.profileReducer;

  return ["Admin", "Super Admin"].indexOf(role) > -1;
};

const getIcon = (item) => {
  return (
    FormChartIcons.find((chartItem) => {
      if (item.label === "Appointment Letter / Authorisation Form") {
        if (item.form_no?.includes("ALSP")) {
          return chartItem?.includes("Authorisation");
        } else {
          return chartItem?.includes("Appointment");
        }
      } else {
        return item.type?.includes(chartItem?.keyword);
      }
    })?.icon || defaultIcon
  );
};

const FormChart = ({
  data = {},
  infoData,
  containerClass,
  history,
  currentFormType,
  forms_chart,
  onClickButton = () => {},
  onClickDetach = () => {},
  onClickSubmit = {},
}) => {
  const { sideMenuExpand = false } = data.sideMenuReducer;

  const getConditionClass = (form, index) => {
    let tmpClass = "";
    const {
      type,
      can_redirect,
      form_no,
      can_submit,
    } = form;
    let nextForm = forms_chart[index + 1] || {};
    const { can_redirect: next_form_can_redirect } = nextForm;
    if (type === currentFormType) {
      tmpClass = "at-form_chart-is_current_form_type ";
    }

    if (can_redirect) {
      tmpClass = tmpClass + "at-form_chart-can_redirect ";
    }
    if (next_form_can_redirect && form_no !== null) {
      tmpClass = tmpClass + "at-form_chart-can_redirect_to_next ";
    }

    if (can_submit) {
      tmpClass = tmpClass + "at-form_chart-can_submit";
    }
    return tmpClass;
  };

  // Class for stack form
  const getClassForArray = (form, formIndex) => {
    let tmpClass = "";
    if (Array.isArray(form)) {
      form.map((item) => {
        const {
          type,
          form_no,
          can_redirect,
          can_submit,
        } = item;
        let nextForm =
          forms_chart[formIndex + 1]?.find(
            (formItem) => formItem.can_redirect,
          ) || {};

        const { can_redirect: next_form_can_redirect } = nextForm;
        if (type === currentFormType) {
          tmpClass = "at-form_chart-is_current_form_type ";
        }

        if (can_redirect) {
          tmpClass = tmpClass + "at-form_chart-can_redirect ";
        }
        if (next_form_can_redirect && form_no !== null) {
          tmpClass = tmpClass + "at-form_chart-can_redirect_to_next ";
        }

        if (can_submit) {
          tmpClass = tmpClass + "at-form_chart-can_submit";
        }
        return "";
      });
      return tmpClass;
    }
  };

  const renderButton = (item, index) => {
    let tmpTitle = "";
    if (["Appointment Letter", "Authorisation Form"].indexOf(item?.type) > -1) {
      if (currentFormType === "Claim Form") {
        tmpTitle = "Attach";
      } else {
        tmpTitle = "Closing";
      }
    } else {
      tmpTitle = "Submit";
    }

    let tmpDetachActionData = {};
    let tmpToSubmitData = {
      module_type: currentFormType?.replace(" ", ""),
    };

    let tmpConfirmationFormData = forms_chart.find(
      (form) => form?.type === "Confirmation Form",
    );

    const detachClaimForm = (
      forms_chart[index]?.form_type_id === 3 && // if the claim is CoAgency Type Form 
      item.type === "Claim Form" &&
      currentFormType === "Appointment Letter" && 
      item?.id !== null &&
      isAdmin(data) &&
      !item.can_submit
    )

    if (index > 0) {
      tmpDetachActionData = {
        associatedForms: [
          forms_chart[index - 1]?.type,
          forms_chart[index]?.type,
        ],
        associatedTransNumber: [
          forms_chart[index - 1]?.form_no,
          forms_chart[index]?.form_no,
        ],
        module_type: currentFormType?.replace(" ", ""),
        form_id: infoData.id,
        form_type: item.type,
        ...(tmpConfirmationFormData
          ? { confirmation_form_id: tmpConfirmationFormData?.id }
          : {
              physical_form_id: forms_chart.find(
                (form) => form?.type === "Physical Form",
              )?.id,
            }),
      };
    }

    if (
      ["Appointment Letter", "Authorisation Form"].indexOf(currentFormType) > -1
    ) {
      tmpToSubmitData["form_id"] = item.id;
    } else {
      tmpToSubmitData.appointment_letter_id = null;
      tmpToSubmitData.authorisation_form_id = null;
      tmpToSubmitData.id = infoData.id;
      tmpToSubmitData.representative_id = infoData.representative_id;
    }
    tmpToSubmitData.type = item?.type;

    return (
      <>
        {item?.can_submit && (
          <CustomButton
            className={"btn-new btn-new--primary"}
            children={tmpTitle}
            onClick={() => onClickButton(tmpToSubmitData)}
          />
        )}
        {["Confirmation Form", "Physical Form"].indexOf(item.type) !== -1 &&
          ["Claim Form", "Confirmation Form", "Physical Form"].indexOf(currentFormType) === -1 &&
          forms_chart[index]?.id !== null &&
          isAdmin(data) &&
          !item.can_submit && (
            <CustomButton
              className={"btn-new btn-new--danger"}
              children={"Detach"}
              onClick={() => onClickDetach(tmpDetachActionData)}
            />
          )}
        {/* Detach Claim Form in Appt Letter */}
        {/* ONLY for COA claim form (form_type_id = 3) without confirmation form or physical form */}
        {
          detachClaimForm && (
            <CustomButton
              className={"btn-new btn-new--danger"}
              children={"Detach"}
              onClick={() => onClickDetach({
                associatedForms: [
                  forms_chart[0]?.type,
                  forms_chart[index]?.type,
                ],
                associatedTransNumber: [
                  forms_chart[0]?.form_no,
                  forms_chart[index]?.form_no,
                ],
                form_type: item.type,
                claim_id: item.id,
                form_id: infoData.id,
                module_type: currentFormType?.replace(" ", ""),
              })}
            />
          )
        }
      </>
    );
  };

  const renderButtonForNestedArray = (item, nestedIndex) => {
    let tmpTitle = "";
    if (["Appointment Letter", "Authorisation Form"].indexOf(item?.type) > -1) {
      if (currentFormType === "Claim Form") {
        tmpTitle = "Attach";
      } else {
        tmpTitle = "Closing";
      }
    } else {
      tmpTitle = "Submit";
    }

    let tmpDetachActionData = {};
    let tmpToSubmitData = {
      module_type: currentFormType?.replace(" ", ""),
    };

    let tmpConfirmationFormData = forms_chart.find(
      (form) => form?.type === "Confirmation Form",
    );
    // Admin view only: Confirmation Form and Physical Form able to be detached in module of Claim Form, Confirmation Form, Physical Form
    const tmpChecking = (["Confirmation Form", "Physical Form"].indexOf(item.type) !== -1) 
      && (["Claim Form", "Confirmation Form", "Physical Form"].indexOf(currentFormType) === -1) 
      && forms_chart[nestedIndex]?.id !== null 
      && isAdmin(data) 
      && !item?.can_submit

    if (nestedIndex > 0 && tmpChecking) {
      tmpDetachActionData = {
        associatedForms: [
          forms_chart[nestedIndex - 1]?.type,
          forms_chart[nestedIndex]?.type,
        ],
        associatedTransNumber: [
          forms_chart[nestedIndex - 1]?.form_no,
          forms_chart[nestedIndex]?.form_no,
        ],
        module_type: currentFormType?.replace(" ", ""),
        form_id: infoData.id,
        form_type: item.type,
        ...(tmpConfirmationFormData
          ? { confirmation_form_id: tmpConfirmationFormData?.id }
          : {
              physical_form_id: forms_chart.find(
                (form) => form?.type === "Physical Form",
              )?.id,
            }),
      };
    }

    if (
      ["Appointment Letter", "Authorisation Form"].indexOf(currentFormType) > -1
    ) {
      tmpToSubmitData["form_id"] = item.id;
    } else {
      tmpToSubmitData.appointment_letter_id = null;
      tmpToSubmitData.authorisation_form_id = null;
      tmpToSubmitData.id = infoData.id;
      
      if(currentFormType === 'Confirmation Form' && item.type === 'Confirmation Form') {
        tmpToSubmitData.representativeId = item.representative_id
      }
    }

    if(item?.can_submit || tmpChecking) {
      return (
        <div>
          {(item?.can_submit) && (
            <CustomButton
              className={"btn-new btn-new--primary"}
              children={tmpTitle}
              onClick={() => onClickButton(tmpToSubmitData)}
            />
          )}
          {tmpChecking && (
            <CustomButton
              className={"btn-new btn-new--danger"}
              children={"Detach"}
              onClick={() => onClickDetach(tmpDetachActionData)}
            />
          )}
        </div>
      );
    }
    return ''
  };

  const renderFormComponent = (form, index) => {
    let { adminPath = "", nonAdminPath = "" } =
      pathNameInfo.find((formItem) => formItem.label === form?.type) ?? {};

    let tmpPostPath = `${isAdmin(data) ? adminPath : nonAdminPath}`;
    let tmpIcon = getIcon(form);

    return (
      <Fragment key={`form_charts-${index}`}>
        <div className={`at-form_chart-item ${getConditionClass(form, index)}`}>
          {tmpIcon && <div className="at-form_chart-icon">{tmpIcon}</div>}
          <div className="at-form_chart-item_info">
            <p
              className={`at-form_chart-item_title ${form?.type === currentFormType ? "fw-600" : ""}`}
            >
              {form?.type}
            </p>
            {form?.form_no && form?.form_no !== null && (
              <p style={{ minHeight: 22 }}>
                {form.can_redirect && (
                  <a
                    className="fw-500"
                    aria-disabled={!form.can_redirect}
                    onClick={() => {
                      if (form.can_redirect) {
                        const tmpPrefixPath =
                          window.location.href.indexOf("/admin-impersonate") >
                          -1
                            ? data.adminSignInReducer.prefix_path
                            : "";

                        let tmpData = {
                          pathname: `${tmpPrefixPath}${tmpPostPath}`,
                          state: {
                            id: form?.id,
                          },
                        };

                        history.push(tmpData);
                      }
                    }}
                  >
                    {form?.form_no}
                  </a>
                )}
                {!form?.can_redirect && form?.form_no}
              </p>
            )}
            <div>{renderButton(form, index)}</div>
          </div>
        </div>
        {index !== forms_chart.length - 1 && (
          <div
            className={`at-form_chart-progress_line_horizontal ${getConditionClass(form, index)}`}
          >
            <div></div>
          </div>
        )}
      </Fragment>
    );
  };

  const renderStackFormComponent = (form, index) => {
    return (
      <Fragment key={`form_charts-${index}`}>
        <div
          className={`at-form_chart-item at-form_charts-stack_form ${getClassForArray(form, index)}`}>
          <div className="at-form_chart-icon">
            {form?.length > 0 && form.some((item) => item.type !== "N/A")
              ? StackFormChartIcons[index]?.icon
              : defaultIcon}
          </div>
          <div className="at-form_chart-stack_item">
            {form.map((item, itemIndex) => {
              let { adminPath = "", nonAdminPath = "" } =
                pathNameInfo.find(
                  (infoItem) => infoItem.label === item?.type,
                ) ?? {};

              let tmpPostPath = `${isAdmin(data) ? adminPath : nonAdminPath}`;

              return (
                <div
                  key={`${item.form_no}-${itemIndex}`}
                  className={`at-form_chart-item_info`}>
                  {(itemIndex === 0 || (itemIndex > 0 && form[itemIndex - 1]?.type !== item?.type)) && (
                    <p
                      className={`at-form_chart-item_title`}
                      style={item?.type === currentFormType
                        ? { fontWeight: 700 }
                        : {}
                      }>
                      {item?.type}
                    </p>
                  )}
                  {item?.form_no && item?.form_no !== null && (
                    <p>
                      {item.can_redirect && (
                        <a
                          className="fw-500"
                          aria-disabled={!item.can_redirect}
                          onClick={() => {
                            if (item.can_redirect) {
                              const tmpPrefixPath =
                                window.location.href.indexOf(
                                  "/admin-impersonate",
                                ) > -1
                                  ? data.adminSignInReducer.prefix_path
                                  : "";

                              let tmpData = {
                                pathname: `${tmpPrefixPath}${tmpPostPath}`,
                                state: {
                                  id: item?.id,
                                },
                              };

                              history.push(tmpData);
                            }
                          }}
                        >
                          {`${item?.represent ? `${item?.represent}: ` : ""}${item?.form_no}`}
                        </a>
                      )}
                      {!item?.can_redirect && (
                        <p style={{ minHeight: 22 }}>
                          {`${item?.represent ? `${item?.represent}: ` : ""}${item?.form_no}`}
                        </p>
                      )}
                    </p>
                  )}
                  {renderButtonForNestedArray(item, itemIndex, index)}
                </div>
              );
            })}
          </div>
        </div>
        {index !== forms_chart.length - 1 && (
          <div className={`at-form_chart-progress_line_horizontal ${getClassForArray(form, index)}`}>
            <div></div>
          </div>
        )}
      </Fragment>
    );
  };

  const renderFormChart = () => {
    return (
      <div className={`at-form_chart__panel ${containerClass}`}>
        <div className={`at-form_charts-cont ${sideMenuExpand ? "menu-expand" : ""}`}>
          {forms_chart?.map((form, index) => {
            if (Array.isArray(form)) {
              return renderStackFormComponent(form, index);
            }
            return renderFormComponent(form, index);
          })}
        </div>
      </div>
    );
  };

  return renderFormChart();
};

const mapStateToProps = (state) => ({ data: state });

export default connect(mapStateToProps)(FormChart);
