import React, { Component } from "react";
import _ from "lodash";
import { compose } from "redux";

import LoadingModal from "components/LoadingModal";
import CustomConfirmationDialog from "components/Modal/confirmation";
import MyCartTable from "./components/Table";
import CheckoutOrder from "./components/checkoutOrder";

import isEmptyValue from "utils/isEmpty";
import permissionsChecker from "utils/permissionsChecker";
import MyCartHOC from "./actions";
import MyCartDeleteHOC from "./actions/delete";
import MyCartUpdateHOC from "./actions/update";
import DeliveryBranchesHOC from "./actions/deliveryBranch";
import ModalDialog from "components/Modal/ModalDialog";

//product type needed to provide delivery branch field
const deliveryProduct = [2];

const headerData = [
  { label: "Product", value: "product", width: "250%" },
  { label: "Unit Price", value: "price", width: "50%" },
  { label: "Quantity", value: "quantity", width: "80%" },
  { label: "Total Price", value: "totalPrice", width: "50%" },
];

const onValidateValue = (item, defaultValue) =>
  !isEmptyValue(item) ? item : defaultValue;

class MyCart extends Component {
  componentDidMount = () => {
    const state = !isEmptyValue(this.props.location.state)
      ? this.props.location.state
      : {};
    this.onUpdateCartItem();
    this.props.getDeliveryBranches();

    if (this.props.location.pathname.includes("/redo-payment")) {
      if (state) {
        this.props.onChangeMyCartHOC(true, "showCheckoutModal");
      }
    }
  };

  componentDidUpdate = (pp) => {
    if (this.props.data.myCartReducer !== pp.data.myCartReducer) {
      this.onUpdateCartItem();
    }
  };

  onUpdateCartItem = () => {
    let tempData = [];
    let temp = _.cloneDeep(this.props.data.myCartReducer.cartItem);
    temp.length > 0 &&
      temp.map((item) => {
        const tmpMarketProduct = onValidateValue(item.market_product, {});
        const isProductVariant =
          item.selected_market_product_attached_product_type ===
          "ProductVariant";
        const productVariantTitle = isProductVariant
          ? item.selected_market_product_attached_product.options.map(
              (option, index) =>
                `${option.value}${index + 1 !== item.selected_market_product_attached_product.options.length ? " - " : ""}`,
            )
          : "";

        if (
          !isProductVariant ||
          item.selected_market_product_attached_product !== null
        ) {
          tempData.push({
            ...item,
            title: productVariantTitle,
            isProductVariant: isProductVariant,
            is_quantity_fixed: tmpMarketProduct.is_quantity_fixed,
            description: tmpMarketProduct.description || "",
            name: tmpMarketProduct.name || "",
            type_id: tmpMarketProduct.type_id || "",
            photo_url: tmpMarketProduct.photo_url || "",
            price: ([2, 3].indexOf(tmpMarketProduct.type_id) > -1
              ? item?.selected_market_product_attached_product?.price ?? 0
              : tmpMarketProduct.price
            ).toFixed(2),
            totalPrice: (
              ([2, 3].indexOf(tmpMarketProduct.type_id) > -1
                ? item?.selected_market_product_attached_product?.price ?? 0
                : tmpMarketProduct.price) * item.quantity
            ).toFixed(2),
          });
        }
      });
    let tempDeliveryFlag = tempData.some(
      (item) => deliveryProduct.indexOf(item.type_id) !== -1,
    );

    // this.props.onChangeMyCartHOC( tempDeliveryFlag, 'showDeliveryBranchField')
    this.props.onChangeMyCartHOC(tempData, "cartItems");
  };

  onHandleCheckBox = (id) => {
    let tempIndex = this.props.checkedArray.indexOf(id);
    let temp = _.cloneDeep(this.props.checkedArray);
    tempIndex === -1 ? temp.push(id) : temp.splice(tempIndex, 1);

    this.props.onChangeMyCartHOC(temp, "checkedArray");
  };

  onHandleCheckAllBox = () => {
    let tempNewChecked = [];
    if (this.props.checkedArray.length < this.props.rowData.length) {
      this.props.rowData.map((item) => {
        tempNewChecked.push(item.id);
      });
    } else {
      tempNewChecked = [];
    }
    this.props.onChangeMyCartHOC(tempNewChecked, "checkedArray");
  };

  onChangeInputStepperValue = (val, id) => {
    let temp = _.cloneDeep(this.props.cartItems);
    let index = _.findIndex(this.props.cartItems.data, { id: id });
    temp.data[index].quantity = val;
    this.props.onChangeMyCartHOC(temp, "cartItems");
  };

  renderCardContent = () => {
    const { cartItems, history, deliveryBranches, deliveryBranchId } =
      this.props;

    const { can_update, can_create, can_destroy } = permissionsChecker(
      "Atlas Store",
      this.props.data,
    );

    const tmpPath =
      window.location.href.indexOf("/admin-impersonate") > -1
        ? this.props.data.adminSignInReducer.prefix_path
        : "";

    return (
      <MyCartTable
        headerData={headerData}
        history={history}
        deliveryBranches={deliveryBranches}
        can_update={can_update}
        can_create={can_create}
        disabledDelete={!can_destroy}
        deliveryBranchId={deliveryBranchId}
        selectColumn={false}
        actionColumnContent={["delete"]}
        rowData={cartItems || []}
        onClose={() =>
          history.push({
            pathname: `${tmpPath}/marketing-centre/products-catalog`,
          })
        }
        onChangeMyCartHOC={this.props.onChangeMyCartHOC}
        onChangeInputStepperValue={(val) => this.props.updateCartItem(val)}
        deleteClick={(val) => {
          this.props.onChangeMyCartHOC(true, "showDeleteConfirmation");
          this.props.onChangeDeleteHOC(val.id, "selectedDeleteId");
        }}
        onClickCheckout={() =>
          this.props.onChangeMyCartHOC(true, "showCheckoutConfirmation")
        }
      />
    );
  };

  render = () => {
    const {
      onLoadDeliveryBranches,
      onLoadMyCart,
      deliveryBranchId,
      orderNumber,
      showCheckoutModal,
    } = this.props;

    return (
      <>
        <ModalDialog
          title={"My Cart"}
          fullHeight={true}
          fullWidth={true}
          onClose={() => this.props.history.goBack()}
          children={this.renderCardContent()}
        />
        <CustomConfirmationDialog
          open={this.props.showDeleteConfirmation}
          loading={this.props.onLoadDelete}
          message={"Are you sure to remove this item from cart?"}
          mode="alert"
          positiveText={"Delete"}
          negativeText={"Cancel"}
          positiveAction={() =>
            this.props.deleteCartItem(this.props.selectedDeleteId)
          }
          negativeAction={() =>
            this.props.onChangeMyCartHOC(false, "showDeleteConfirmation")
          }
        />
        <CustomConfirmationDialog
          mode="info"
          open={this.props.showCheckoutConfirmation}
          loading={this.props.onLoadMyCart}
          title={"Proceed To Checkout"}
          message={
            "Selecting Confirm will take you to the payment page, where all the items in your cart will be combined into an order number."
          }
          positiveText={"Confirm"}
          negativeText={"Cancel"}
          positiveAction={() =>
            this.props.checkCheckoutOrders(deliveryBranchId)
          }
          negativeAction={() =>
            this.props.onChangeMyCartHOC(false, "showCheckoutConfirmation")
          }
        />
        <CustomConfirmationDialog
          open={this.props.showCancelConfirmation}
          loading={this.props.onLoadMyCart}
          title={""}
          message={
            <>
              <p>
                No payment will be made when you click Yes to leave the payment
                page.
              </p>
              <p>
                Click the Pay button of the pending order in Order History tab
                when you are ready.
              </p>
              <p>Are you sure you want to exit now?</p>
            </>
          }
          mode="warning"
          positiveText={"Confirm"}
          negativeText={"Cancel"}
          positiveAction={() => this.props.cancelPayment()}
          negativeAction={() =>
            this.props.onChangeMyCartHOC(false, "showCancelConfirmation")
          }
        />
        {showCheckoutModal && (
          <CheckoutOrder
            paymentDetails={this.props.paymentDetails}
            checkoutDetails={this.props.checkoutDetails}
            orderNumber={orderNumber}
            open={showCheckoutModal}
            data={this.props.data}
            cancelPayment={this.props.cancelPayment}
            getCheckoutPayments={this.props.getCheckoutPayments}
            cartItems={this.props.cartItems || []}
            onClose={() =>
              this.props.onChangeMyCartHOC(true, "showCancelConfirmation")
            }
          />
        )}
        {(onLoadMyCart || onLoadDeliveryBranches) && <LoadingModal />}
      </>
    );
  };
}

export default compose(
  MyCartHOC,
  MyCartDeleteHOC,
  MyCartUpdateHOC,
  DeliveryBranchesHOC,
)(MyCart);
