import _ from "lodash"
import { FaUserTie } from "react-icons/fa";
import AtlasIcon from "components/Icon/atlasIcon";

import {
  categories,
  tenure_types,
  bedroom_options,
  bathroom_options,
} from "dictionary/subsales";

const categoryTypeDictionary = [
  { id: 1, label: "Residential" },
  { id: 2, label: "Commercial" },
  { id: 3, label: "Industrial" },
  { id: 4, label: "Agricultural" },
  { id: 5, label: "Unknown" },
];

const tenureTypeDictionary = [
  { id: 1, label: "Freehold" },
  { id: 3, label: "Leasehold" },
];

const formTypes = [
  {
    label: 'Subsale',
    value: 1
  },
  {
    label: 'Rental',
    value: 2
  }
]

export const unit_types = [
  {
    id: 1,
    name: "Corner",
    value: 1,
    label: "Corner",
  },
  {
    id: 2,
    name: "Duplex",
    value: 2,
    label: "Duplex",
  },
  {
    id: 3,
    name: "Endlot",
    value: 3,
    label: "Endlot",
  },
  {
    id: 4,
    name: "Intermediate",
    value: 4,
    label: "Intermediate",
  },
  {
    id: 5,
    name: "Penthouse",
    value: 5,
    label: "Penthouse",
  },
  {
    id: 6,
    name: "Studio",
    value: 6,
    label: "Studio",
  },
  {
    id: 7,
    name: "Triplex",
    value: 7,
    label: "Triplex",
  },
  {
    id: 8,
    name: "Unknown",
    value: 8,
    label: "Unknown",
  },
  {
    id: 9,
    name: "SOHO",
    value: 9,
    label: "SOHO",
  },
];

export const ClaimDetails = [
  {
    title: "Agent",
    type: "listing",
    icon: "atlas-user-tie",
    objValue: "user",
    listing_props: [
      {
        label: "Agent Name",
        typoClassName: "at-new_header-title-1",
        icon: "",
        value: "full_name"
      },
      {
        label: "Company Name",
        className: "mt-2",
        icon: "atlas-team",
        value: "team_name"
      },
      {
        label: "Email",
        className: "mt-2",
        icon: "atlas-sms",
        value: "email"
      },
      {
        label: "Mobile Number",
        className: "mt-2",
        icon: "atlas-mobile",
        value: "mobile_contact_number"
      },
    ]
  },
  {
    title: "Booking Details",
    type: "listing",
    icon: "atlas-building-4",
    listing_props: [
      {
        label: "Transaction Type",
        value: "sub_sale_type_id",
        type: "select",
        renderValue: ({sub_sale_type_id}) => {
          let tmpFormType = formTypes.find( formType => formType.value === sub_sale_type_id )
          return tmpFormType?.label ?? "N/A"
        }
      },
      {
        label: "Booking Date",
        value: "booking_date",
        type: "date",
        dateFormat: "DD-MM-YYYY"
      },
      {
        label: "SPA Date/TA Date",
        value: "agreement_date",
        type: "date",
        dateFormat: "DD-MM-YYYY"
      },
      {
        label: "Selling Price / Monthly Rental (RM)",
        value: "net_price"
      },
      {
        label: "Earnest Deposit Received (RM)",
        value: "earnest_deposit_received_amount"
      },
    ]    
  },
  {
    title: "Property Info",
    type: "listing",
    objValue: "property_location",
    icon: "atlas-home",
    listing_props: [
      {
        label: "Property Category",
        value: "category_id",
        renderValue: ({ property_location: { category_id } }) => {
          let tmpCategory = categories.find(category => category.id === +category_id );
          return tmpCategory?.label ?? "N/A"
        }
      },
      {
        label: "Property Type",
        value: "property_type_id",
        renderValue: ({ property_location: { category_id, property_type_id }}) => {
          let selectedCategory = categories.find(category => category.id === +category_id ) ?? [];
          let tmpSelectedGroupType = selectedCategory?.group_types?.find( groupType => {
            return groupType.children?.find( child => child.id === property_type_id )
          }) ?? {};

          let tmpSelectedPropertyType = tmpSelectedGroupType?.children?.find( child => child.id === property_type_id ) ?? {}

          return tmpSelectedPropertyType?.name ?? "N/A"
        }
      },
      {
        label: "Property Name",
        value: "property_name"
      },
      {
        label: "Unit No.",
        value: "unit_no"
      },
      {
        label: "Address",
        value: "address"
      },
      {
        label: "Country",
        value: "country_id",
        optionAccessor: "countries",
        renderValue: ({ countries, property_location: { country_id }}) => {
          let tmpCountry = _.find(countries, (country) => country.id === country_id);
          return tmpCountry?.label ?? "N/A"
        }
      },
      {
        label: "State / Province",
        value: "state_id",
        renderValue: ({ countries, property_location: { country_id, state_id }}) => {
          let tempCountry = _.find(countries, (country) => country.id === country_id);
          if (tempCountry) {
            let tempStates = _.cloneDeep(tempCountry.states);
            let tempState = _.find(tempStates, (state) => state.id === state_id);
            return tempState.label
          }
          return "N/A"
        }
      },
      {
        label: "Area / City",
        value: "township_id",
        renderValue: ({ township, property_location: { township_id }}) => {
          let tmpTown = _.find(township, (town) => town.id === township_id);
          return tmpTown?.name ?? "N/A"
        }
      },
      {
        label: "Postcode",
        value: "postcode"
      },
      // {
      //   label: "Land Area (Square Feet)",
      //   value: "land_area"
      // },
      // {
      //   label: "Built Up (Square Feet)",
      //   value: "built_up"
      // },
      // {
      //   label: "Layout",
      //   value: "layout",
      //   renderValue: ({ property_location: { unit_type_id }}) => {
      //     let tmpUniType = _.find(unit_types, (unitType) => unitType.id === unit_type_id);
      //     return tmpUniType?.label ?? "N/A"
      //   }
      // },
      // {
      //   label: "Tenure",
      //   value: "unit_type_id",
      //   renderValue: ({ property_location: { tenure_type_id }}) => {
      //     let tenureType = _.find(tenure_types, tenure_type => tenure_type.id === tenure_type_id);
      //     return tenureType?.label ?? "N/A"
      //   }
      // },
      // {
      //   label: "Bedrooms",
      //   value: "bedrooms",
      //   renderValue: ({ property_location: { bedrooms }}) => {
      //     let tmpBedroom = bedroom_options.find(
      //       (item) => item.value === bedrooms || "",
      //     )
      //     return tmpBedroom?.label ?? "N/A"
      //   }
      // },
      // {
      //   label: "Bathrooms",
      //   value: "bathrooms",
      //   renderValue: ({ property_location: {  bathrooms }}) => {
      //     let tmpBathroom = bathroom_options.find(
      //       (item) => item.value === bathrooms || "",
      //     )
      //     return tmpBathroom?.label ?? "N/A"
      //   }
      // },
    ]    
  },
  {
    renderTitle: ({ representative_party }) => `${representative_party}s`,
    type: "column",
    columnValue: "read_only_participants",
    canCreate: false,
    icon: "atlas-profile-circle",
    getColumnActions: () => [],
    columnData: [
      {
        header: "Full Name",
        searchFlag: "",
        renderColumn: (rowData) => (
          <div className={"d-flex align-items-center party-card__badge-title-icon"}>
            {[3, 4].indexOf(rowData.contact_type_id) === -1 && (
              <>
                {rowData.is_company && (
                  <>
                    <AtlasIcon svgHref={"atlas-building-company"}/>
                    <div className={"ml-1"}>
                      {rowData.company_name}
                      <br />({rowData.company_registration_no})
                      <br />
                      {rowData.full_name}
                    </div>
                  </>
                )}
                {!rowData.is_company && (
                  <>
                    <AtlasIcon svgHref={"atlas-icon-user"}/>
                    {rowData.full_name}
                  </>
                )}
              </>
            )}
            {[3, 4].indexOf(rowData.contact_type_id) > -1 && (
              <>
                <FaUserTie />
                {rowData.company_name}
                <br />({rowData.company_registration_no})
              </>
            )}
          </div>
        ),
      },
      {
        header: "NRIC/Passport No.",
        accessor: "nric",
        searchFlag: ""
      }
    ]    
  },
  {
    renderTitle: ({ opposite_representative_party }) => `${opposite_representative_party}s`,
    type: "column",
    columnValue: "opposite_participants",
    canCreate: true,
    description: (
      <>
        <p>
          The contact information you enter here will be held privately and not disclosed to any other parties.
        </p>
        <p>
          This will protect your interest and your client.
        </p>
      </>
    ),
    icon: "atlas-icon-user",
    getColumnActions: ({getOppositePartyDetails, onChangeAccessCaseFileHOC}) => [
      {
        name: "edit",
        onClick: rowData => {
          onChangeAccessCaseFileHOC({}, "addressInfo")
          getOppositePartyDetails(rowData.id)
        },
      },
    ],
    columnData: [
      {
        header: "Full Name",
        accessor: "full_name",
        searchFlag: "",
        renderColumn: (rowData) => (
          <div className={"d-flex align-items-center party-card__badge-title-icon"}>
            {[3, 4].indexOf(rowData.contact_type_id) === -1 && (
              <>
                {rowData.is_company && (
                  <>
                    <AtlasIcon svgHref={"atlas-building-company"}/>
                    <div className={"ml-1"}>
                      {rowData.company_name}
                      <br />({rowData.company_registration_no})
                      <br />
                      {rowData.full_name}
                    </div>
                  </>
                )}
                {!rowData.is_company && (
                  <>
                    <AtlasIcon svgHref={"atlas-icon-user"}/>
                    {rowData.full_name}
                  </>
                )}
              </>
            )}
            {[3, 4].indexOf(rowData.contact_type_id) > -1 && (
              <>
                <FaUserTie />
                {rowData.company_name}
                <br />({rowData.company_registration_no})
              </>
            )}
          </div>
        ),
      },
      {
        header: "NRIC/Passport No.",
        accessor: "nric",
        searchFlag: ""
      },
      {
        header: "Mobile/Office Contact No.",
        accessor: "contact_number",
        searchFlag: ""
      }
    ]    
  }
]