export const getTranslation = (key, language) => {
  const translation = japaneseTranslation.find((translation) => translation.key === key);
  if (translation) {
    return translation[language];
  }
  return key;
}

export const japaneseTranslation = [
  {
    key: "yes",
    en: "Yes",
    jp: "はい",
  },
  {
    key: "no",
    en: "No",
    jp: "いいえ",
  },
  {
    key: "full_name",
    en: "Full Name",
    jp: "フルネーム",
  },
  {
    key: "email",
    en: "Email",
    jp: "メールアドレス",
  },
  {
    key: "mobile_contact_number",
    en: "Contact Number",
    jp: "電話番号",
  },
  {
    key: "identification_number",
    en: "ID/NRIC",
    jp: "身分証明書番号",
  },
  {
    key: "referrer_name",
    en: "Referrer Name",
    jp: "参照元名",
  },
  {
    key: "display_name",
    en: "Display Name",
    jp: "表示名",
  },
  {
    key: "display_name_placeholder",
    en: "Subsales listing personal display name",
    jp: "表示名は名刺に印刷されます",
  },
  {
    key: "gender",
    en: "Gender",
    jp: "性別"
  },
  {
    key: "male",
    en: "Male",
    jp: "男",
  },
  {
    key: "female",
    en: "Female",
    jp: "女",
  },
  {
    key: "single",
    en: "Single",
    jp: "独身",
  },
  {
    key: "married",
    en: "Married",
    jp: "既婚",
  },
  {
    key: "divorced",
    en: "Divorced",
    jp: "離婚",
  },
  {
    key: "date_of_birth",
    en: "Date of Birth",
    jp: "生年月日",
  },
  {
    key: "marital_status",
    en: "Marital Status",
    jp: "配偶者の有無",
  },
  {
    key: "branch_name",
    en: "Branch Name",
    jp: "支店名",
  },
  {
    key: "team",
    en: "Team",
    jp: "チーム",
  },
  {
    key: "next",
    en: "Next",
    jp: "続く",
  },
  {
    key: "back",
    en: "Back",
    jp: "戻る",
  },
  {
    key: "step",
    en: "Step",
    jp: "ステップ",
  },
  {
    key: "submit",
    en: "Submit",
    jp: "提出する",
  },
  {
    key: "successfull",
    en: "Successful!",
    jp: "成功！",
  },
  {
    key: "ok",
    en: "Ok",
    jp: "OK! クリック",
  },
  {
    key: "agent.profile.title1",
    en: "Personal Details",
    jp: "個人情報",
  },
  {
    key: "agent.profile.title2",
    en: "Additional Details",
    jp: "追加情報",
  },
  {
    key: "agent.profile.title3",
    en: "Upload These Files",
    jp: "ファイルをアップロード",
  },
  {
    key: "agent.profile.welcome1",
    en: "Welcome to IQI Atlas",
    jp: "IQI アトラスへようこそ",
  },
  {
    key: "agent.profile.welcome2",
    en: "IQI Atlas Profile",
    jp: "IQI アトラスへようこそ",
  },
  {
    key: "agent.profile.personal_info",
    en: "Personal Info",
    jp: "個人情報",
  },
  {
    key: "agent.profile.almost_there",
    en: "You're Almost There!",
    jp: "もうすぐ完了です！",
  },
  {
    key: "agent.profile.welcome_message",
    en: "You're almost ready to be part of IQI. Next, you'll be guided to complete your profile details. Please prepare the following documents to ease the process:",
    jp: "IQI に参加する準備がほぼ整いました。 次のいくつかの手順では、プロフィールの残りの詳細を完成させることができます。 以下のものをご用意ください。"
  },
  {
    key: "agent.profile.verification_item",
    en: "Identification documents for verification purpose",
    jp: "確認目的の身分証明書番号またはパスポート",
  },
  {
    key: "agent.profile.please_complete",
    en: "Please complete your basic information stated below.",
    jp: "以下に記載された基本情報を入力してください。",
  },
  {
    key: "agent.profile.bank_name",
    en: "Bank Name",
    jp: "銀行名",
  },
  {
    key: "agent.profile.bank_account_number",
    en: "Bank Account Number",
    jp: "口座番号",
  },
  {
    key: "agent.profile.bank_account_holder_name",
    en: "Bank Account Holder Name",
    jp: "口座名義人",
  },
  {
    key: "agent.profile.bank_branch",
    en: "Bank Branch",
    jp: "銀行支店",
  },
  {
    key: "agent.profile.bank_account_type",
    en: "Bank Account Type",
    jp: "口座タイプ",
  },
  {
    key: "agent.profile.saving_acc",
    en: "Saving Account",
    jp: "普通預金",
  },
  {
    key: "agent.profile.current_acc",
    en: "Current Account",
    jp: "当座預金",
  },
  {
    key: "agent.profile.current_address",
    en: "Current Address",
    jp: "現住所",
  },
  {
    key: "agent.profile.current_address_1_placeholder",
    en: "",
    jp: "（例）〒530-0035",
  },
  {
    key: "agent.profile.current_address_2_placeholder",
    en: "",
    jp: "（例）大阪市北区同い",
  },
  {
    key: "agent.profile.current_address_3_placeholder",
    en: "",
    jp: "（例）1-6-13",
  },
  {
    key: "agent.profile.current_address_4_placeholder",
    en: "",
    jp: "（例）ベルメノファンマンション 101",
  },
  {
    key: "agent.profile.income_tax_number",
    en: "Income Tax No.",
    jp: "インボイス番号",
  },
  {
    key: "agent.profile.income_tax_number_placeholder",
    en: "Income Tax No.",
    jp: "該当する場合のみ ",
  },
  {
    key: "agent.profile.active_area",
    en: "Active Area",
    jp: "活動地域",
  },
  {
    key: "agent.profile.emergency_contact_relationship",
    en: "Emergency Contact Relationship",
    jp: "緊急連絡先続柄",
  },
  {
    key: "agent.profile.emergency_contact_name",
    en: "Emergency Contact Person Full Name",
    jp: "緊急連絡先名",
  },
  {
    key: "agent.profile.emergency_contact_number",
    en: "Emergency Contact Number",
    jp: "緊急連絡先番号",
  },
  //Upload Document
  {
    key: "agent.profile.ic_front",
    en: "Identification card (Front)",
    jp: "身分証明書の表面",
  },
  {
    key: "agent.profile.ic_back",
    en: "Identification card (Back)",
    jp: "身分証明書の裏面 (任意)",
  },
  {
    key: "agent.profile.other_real_estate_agency",
    en: "Were you previously from any other real estate agency?",
    jp: "以前他の不動産会社に勤務したことはありますか？",
  },
  {
    key: "agent.profile.previous_agency",
    en: "Previous Agency",
    jp: "前不動産会社名",
  },
  {
    key: "agent.profile.has_been_agent",
    en: "Are/were you previously from any other real estate agency?",
    jp: "以前他の不動産会社に勤務したことはありますか？",
  },
  {
    key: "agent.profile.ic_front_datatype",
    en: "JPG, JPEG or PNG",
    jp: "JPG, JPEG または PNG",
  },
  {
    key: "agent.profile.loading_message",
    en: "Atlas is working hard to check the data...",
    jp: "Atlasはデータのアップロードに懸命だ...",
  }
]