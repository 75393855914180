import React, { useState, useEffect, useMemo } from "react";
import _ from "lodash";

import CKEditor from "components/CKEditor";
import CustomButton from "components/Button";
import CustomSelect from "components/Select";
import LoadingModal from "components/LoadingModal";
import AtlasRadioGroup from "components/RadioGroup";

import isEmptyValue from "utils/isEmpty";

const typeOptions = [
  { label: "Sale", value: "1" },
  { label: "Rental", value: "2" },
];

const GenerateForm = ({
  data,
  formType,
  title,
  loading,
  invoicingBranch,
  generateForm,
  onClickCloseFormGenerator,
  sendRequestMoreFormMessage,
  message_request_more_form,
  updateRequestMoreMessage,
}) => {
  const [newForm, onChangeCreate] = useState({
    type_id: null,
  });

  const { type_id } = newForm;

  // useEffect(() => {
  //   const { branch_id = "" } = window.location.href.includes(
  //     "/admin-impersonate",
  //   )
  //     ? data.currentSignInProfileReducer
  //     : data.profileReducer;
  //   const index = invoicingBranch.findIndex((item) => item.id === branch_id);
  //   if (branch_id > -1 && index > -1) {
  //     onChangeForm(branch_id, "invoicing_branch_id");
  //   }
  // }, []);

  const onChangeForm = (val, context) => {
    let tmpFormData = _.cloneDeep(newForm);
    tmpFormData[context] = val;
    onChangeCreate(tmpFormData);
  };

  const onDisableCreate = useMemo(() => {
    let tmpDisabled = false;
    const Obj = Object.entries(newForm);
    Obj.some(([key, value]) => {
      if (value !== null && typeof value === "object") {
        let valueObj = Object.entries(newForm[key]);
        if (valueObj.length > 0) {
          valueObj.some(([nestedKey, nestedValue]) => {
            tmpDisabled = isEmptyValue(nestedValue) || tmpDisabled;
          });
        }
      } else {
        tmpDisabled = isEmptyValue(value) || tmpDisabled;
      }
    });
    return tmpDisabled;
  }, [newForm]);

  return (
    <>
      <div className="at-form_fields_cont">
        <section className="at-form_field-col-12">
          <h2 className="at-form-input__title">Type</h2>
          <AtlasRadioGroup
            horizontal={true}
            checkedValue={type_id}
            selectedRadioValue={(val) => onChangeForm(val, "type_id")}
            options={typeOptions}
          />
        </section>
        {!_.isEmpty(invoicingBranch) && (
          <section className="grid-full-col">
            <h2 className="at-form-input__title">Branch</h2>
            <p>{invoicingBranch.name}</p>
          </section>
        )}
      </div>
      <section className="at-modal_dialog-container-footer">
        <button
          disabled={onDisableCreate}
          className={"btn-new btn-new--primary"}
          onClick={() => generateForm(newForm)}
        >
          Create Now
        </button>
        <button
          type={"button"}
          className="btn-new btn-new--outline-secondary ml-2"
          onClick={onClickCloseFormGenerator}
        >
          Cancel
        </button>
      </section>
      {formType === "confirmation-form" && (
        <section className="grid-full-col">
          <CKEditor
            disabled={false}
            hideToolbar={true}
            data={message_request_more_form}
            onChange={(event, editor) => {
              const data = editor.getData();
              updateRequestMoreMessage(data);
            }}
          />
          <CustomButton
            className={"btn btn-new--secondary btn-md"}
            children={"Send request more forms message"}
            onClick={sendRequestMoreFormMessage}
          />
        </section>
      )}
      {loading && <LoadingModal />}
    </>
  );
};

export default GenerateForm;
