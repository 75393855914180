import React, { Component } from "react"
import TooltipWrapper from "components/TooltipWrapper"

import AtlasIcon from "components/Icon/atlasIcon"

import VersionCheckHOC from "actions/versionCheck"
import { cardsData } from "./assets"
class MarketingTools extends Component {
  render = () => {
    return (
      <div className="d-flex justify-content-center">
        <ul id="roomListing" className="at-request_list__container no-gutters">
          {cardsData.map((item, index) => {
            return (
              <li key={index} className={`at-request__item`}>
                <TooltipWrapper
                  title={
                    item.disabled ?
                      <div style={{ textAlign: "center" }}>
                        Registration is closed. For inquiries, email rentag@iqirealty.com.
                      </div>
                    : ""
                  }
                  placement="bottom"
                  child={
                    <div
                      className={`at-request-form__container ${item.disabled ? "disabled-item" : ""}`}
                      style={{ cursor: item.disabled ? "not-allowed" : "pointer" }}
                      onClick={() => !item.disabled && this.props.redirectTo(item)}>
                      <div style={{ background: item.color, borderRadius: "8px", padding: "8px" }}>
                        <AtlasIcon svgHref={item.icon} style={{ width: 32, height: 32 }} />
                      </div>
                      <label
                        className={"at-request-form__title"}
                        style={{ cursor: item.disabled ? "not-allowed" : "pointer" }}>
                        {item.title}{" "}
                      </label>
                      <span
                        className={"at-request-form__desc"}
                        style={{ cursor: item.disabled ? "not-allowed" : "pointer" }}>
                        {item.description}
                      </span>
                    </div>
                  }></TooltipWrapper>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}

export default VersionCheckHOC(MarketingTools)
