import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { Post } from "utils/axios";
import { requestError } from "utils/requestHandler";

const TokenHOC = (WrappedComponent) => {
  class TokenWrappedComponent extends Component {
    state = {
      requests: [],
      loading: false,

      showBlockerTutorial: false,
    };

    load = (param) => this.setState({ loading: param });
    onChangeTokenHOC = (val, context) => this.setState({ [context]: val });

    getSSOToken = (origin) =>
      Post(
        `/tokens/single_sign_on`,
        { origin: origin },
        (payload) => this.getSSOTokenSuccess(payload, origin),
        this.getSSOTokenError,
        this.load,
      );
    getSSOTokenSuccess = (payload, origin) => {
      let temp = "";
      Promise.all([
        (temp = window.open(
          `${origin}?token=${payload.auth_token}&refresh-token=${payload.refresh_token}`,
          "_blank",
        )),
      ]).then(() => {
        if (temp === null) {
          this.setState({ showBlockerTutorial: true });
        }
      });
    };
    getSSOTokenError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          showBlockerTutorial={this.state.showBlockerTutorial}
          getSSOToken={this.getSSOToken}
          onChangeTokenHOC={this.onChangeTokenHOC}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(TokenWrappedComponent);
};

export default TokenHOC;
