import React from "react";
import _ from "lodash";
import { BsInfoCircleFill } from "react-icons/bs";
import { Avatar } from "@material-ui/core";

import AlertBox from "components/AlertBox";
import CustomBox from "components/Card/projectCard";
import CustomButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";

import { getColorBadge } from "dictionary/badgeColor";

import { 
  agentContents,
  companyContents, 
  addressContents, 
  bankContents, 
  amlaCompanyContentsPartOne,
  confirmationAmlaCompanyContents,
  amlaCompanyContentsPartTwo
} from "../assets";

const ConfirmationBuyerDetail = ({ 
  dataInfo,
  selectedForm,
}) => { 

  const DataField = ({className,label,value}) => (
    <div className={className}>
      <p style={{fontSize:14,fontWeight:600}}>{label}</p>
      <p style={{fontSize:14,fontWeight:400}}>{value}</p>
    </div>
  );

  const DocumentField = ({ className, label, fileName, fileLink }) => (
    <div className={className}>
      <p style={{fontSize:14,fontWeight:600}}>{label}</p>
      {
        fileLink ? (
          <div className="d-flex" style={{alignItems: "center", gap: 8}}>
            <span style={{fontSize:14,fontWeight:400}}>{fileName}</span>
            <CustomButton
              className={"at-table-icon-button"}
              onClick={() => window.open(fileLink, '_blank')}
              children={<BsInfoCircleFill style={{ width: 20, height: 20, color: "#007BFF" }} />}
            />
          </div>
        ) : <p style={{fontSize:14,fontWeight:400}}>-</p>
      }
    </div>
  );

  const formType = selectedForm === 'Confirmation Forms' 
    ? dataInfo.form_type 
    : dataInfo.claim_type;
  
  const formDate = selectedForm === 'Confirmation Forms' 
    ? `Confirmation Date: ${dataInfo.confirmation_date}` 
    : `SPA Date / TA Date: ${dataInfo.agreement_date}`;

  return (
    <>
      <div className="d-flex mb-3">
        <Avatar
          style={{
            backgroundColor: "#FFEDD5",
            marginRight: 12,
            width: 32,
            height: 32,
          }}
        >
          <AtlasIcon
            svgHref={`atlas-money`}
            style={{
              width: 16,
              height: 16,
              fill: "#F0631D",
            }}
          />
        </Avatar> 
        <div className="d-flex flex-column align-items-start gap-2">
          <div className="d-flex"> 
            <spam style={{alignSelf: "end", fontSize: 18, fontWeight:600, marginRight: 8}}>
              {dataInfo.form_no}
            </spam>
            <div
              className="at-status_badge"
              style={{ ...getColorBadge(`${ formType === 'Rental' ? "Yellow": "Blue" }`) }}
            >
              {formType}
            </div>
          </div>
          <div style={{marginTop: 8}}>
            <spam style={{fontSize: 14, fontWeight:400}}>
               {formDate}
            </spam>
          </div>
        </div>        
      </div>
      <CustomBox icon={"money"} title={"Agent Details"}>
        <div className="row at-cdd-field_col">
          {
            agentContents.map((content,index) => {
              return (
                <DataField
                  key={`agent_${content.value}`}
                  className={`col-xl-4 col-lg-4 col-md-4 text-left at-cdd-3-col`}
                  label={content.label}
                  value={dataInfo["agent"][content.value] || 'N/A'}
                />
              )
            })
          }
        </div>
      </CustomBox>
      <CustomBox icon={"money"} title={"Company Details"}>
          <div className="row at-cdd-field_col">
            {
              companyContents(selectedForm).map((content,index) => (
                <DataField
                  key={content.value}
                  className={`col-xl-4 col-lg-4 col-md-4 text-left ${selectedForm === 'Subsales Claims' ? 'at-cdd-last-3-col' : 'at-cdd-last-col'}`}
                  label={content.label}
                  value={dataInfo[content.value] || 'N/A'}
                />
              ))
            }
          </div>
      </CustomBox>
      <CustomBox icon={"money"} title={"Company Address"}>
        <div className="row at-cdd-field_col">
          {
            addressContents.map((content,index) => (
              <DataField
                key={content.value}
                className={`${content.value === 'address' ? 'col-xl-8 col-lg-8 col-md-8' : 'col-xl-4 col-lg-4 col-md-4'} text-left at-cdd-last-2-col`}
                label={content.label}
                value={dataInfo[content.value] || 'N/A'}
              />
            ))
          }
        </div>
      </CustomBox>
      {
        (selectedForm === 'Subsales Claims') && (
          <CustomBox icon={"money"} title={"Bank Details"}>
            <div className="row at-cdd-field_col">
              {
                bankContents.map((content) => (
                  <DataField
                    key={content.value}
                    className={`col-xl-4 col-lg-4 col-md-4 text-left at-cdd-3-col`}
                    label={content.label}
                    value={dataInfo[content.value] || 'N/A'}
                  />
                ))
              }
            </div>
          </CustomBox>
        )
      }
      { 
        (selectedForm === 'Subsales Claims' && dataInfo['declined_to_share_info'] === "No") && ( 
          <CustomBox icon={"money"} title={"Anti-Money Laundering Act Information"} containerClass={'mb-0'}>
            <div className="row at-cdd-field_col mb-3">
              {
                amlaCompanyContentsPartOne.map((content,index) => {
                  return (
                    <DataField
                      key={content.value}
                      className={'col-xl-4 col-lg-4 col-md-4 text-left'}
                      label={content.label}
                      value={dataInfo[content.value] || 'N/A'}
                    />
                  )
                })
              }
              <DocumentField
                key={'cdd-company_ssm'}
                className={'col-xl-4 col-lg-4 col-md-4 text-left mb-0'}
                label={"Annual Return / SSM"}
                fileName={dataInfo['ssm_copy_file_name']}
                fileLink={dataInfo['ssm_copy']}
              />
            </div>
            <div className="at-cdd-director_col">
              <div key={'cdd-company_director'} className={"text-left"}>
                <p style={{fontSize:14,fontWeight:600}}>Name of Directors</p>
                <ul style={{listStyle: "disc", marginInlineStart: 16}}>
                  {
                    (dataInfo['directors'] && dataInfo['directors'].length > 0)  
                      ? dataInfo['directors'].map((director,index) => (
                          <li key={index} style={{fontSize:14,fontWeight:400}}>{director}</li>
                        ))
                      : <p style={{fontSize:14,fontWeight:400}}>N/A</p>
                  }
                </ul>
              </div>
            </div>
            <div className="at-cdd-shareholder_col">
              <div key={'cdd-company_director'} className={"text-left"}>
                <p style={{fontSize:14,fontWeight:600}}>Shareholders</p>
                <ul style={{listStyle: "disc", marginInlineStart: 16}}>
                {
                  (dataInfo['shareholders'] && dataInfo['shareholders'].length) > 0 
                    ? dataInfo['shareholders'].map((shareholder,index) => (
                        <li style={{fontSize:14,fontWeight:400}} key={index}>{`${shareholder.name} - ${shareholder.type}, ${shareholder.percentage} %`}</li>
                      ))
                    : <p style={{fontSize:14,fontWeight:400}}>N/A</p>
                }
              </ul>
              </div>
            </div>
            <div className="row at-cdd-field_col mt-3 text-left">
              {
                amlaCompanyContentsPartTwo.map((content,index) => {
                  const value = dataInfo[content.value] || 'N/A';
                  return (
                    <DataField
                      key={content.value}
                      className={'col-xl-4 col-lg-4 col-md-4 text-left at-cdd-last-2-col'}
                      label={content.label}
                      value={value}
                    />
                  )
                })
              }
            </div>
          </CustomBox>
        ) 
      }
      { 
        (selectedForm === 'Subsales Claims' && dataInfo['declined_to_share_info'] !== "No") && (
          <CustomBox icon={"money"} title={"Anti-Money Laundering Act Information"}>
            <AlertBox
              mode={"info"}
              disabledClose={true}
              containerStyle={{marginBottom: 0}}
              description={
                dataInfo['declined_to_share_info'] === "Yes" 
                ? "Customer declined to share information" 
                : "Represented by Co-Agency"
              }
            />
          </CustomBox>
        )
      }      
      { 
        (selectedForm === 'Confirmation Forms') && ( 
          <CustomBox icon={"money"} title={"Anti-Money Laundering Act Information"} containerClass={'mb-0'}>
            <div className="row at-cdd-field_col">
              {
                confirmationAmlaCompanyContents.map((content,index) => {
                  const value = dataInfo[content.value] || 'N/A';
                  return (
                    <DataField
                      key={content.value}
                      className={'col-xl-4 col-lg-4 col-md-4 text-left at-cdd-last-2-col'}
                      label={content.label}
                      value={value}
                    />
                  )
                })
              }
            </div>
          </CustomBox>
        )
      }
    </>
  )
}

export default ConfirmationBuyerDetail;