import React, { Component } from "react";
import { compose } from "redux";
import { FaUserTie } from "react-icons/fa";
import { MenuItem } from "@material-ui/core";
import _ from "lodash"

import AtlasTable from "components/NewPaginatedTable";
import AtlasIcon from "components/Icon/atlasIcon";
import ModalDialog from "components/Modal/ModalDialog";
import ContactFormDialog from "../components/formDialog";
import LoadingModal from "components/LoadingModal";
import DeleteConfirmationModal from "components/Modal/delete";
import CustomCheckbox from "components/Checkbox";
import ShareLinkToRepresentative from "../components/shareLinkToRepresentative"
import AtlasFormInput from "components/Input/formInput";
import ConfirmationModal from "components/Modal/confirmation"
import CustomDropdown from "components/Button/dropdown";

import { oppositeInfoStatusBadges } from "containers/Dashboard/SubsalesClaims/assets"
import { getColorBadge } from "dictionary/badgeColor";
import permissionsChecker from "utils/permissionsChecker";
import RegionHOC from "../../../actions/region";
import OppositeParticipant from "../../../actions/oppositeParticipant";

import "stylesheets/containers/subsales-claims/index.scss";

const columnData = [
  {
    header: "Full Name",
    renderColumn: (rowData) => (
      <div className={"d-flex align-items-center party-card__badge-title-icon at-subsales-contact_form_type-title-icon"}>
        {["3", "4"].indexOf(rowData.contact_type_id) === -1 && (
          <>
            {rowData.is_company && (
              <>
                <AtlasIcon svgHref={"atlas-building-company"}/>
                <div className={"ml-1"}>
                  {rowData.company_name}
                  <br />({rowData.company_registration_no})
                  <br />
                  {rowData.full_name}
                </div>
              </>
            )}
            {!rowData.is_company && (
              <>
                <AtlasIcon svgHref={"atlas-icon-user"}/>
                {rowData.full_name}
              </>
            )}
          </>
        )}
        {["3", "4"].indexOf(rowData.contact_type_id) > -1 && (
          <>
            <FaUserTie />
            {rowData.company_name}
            <br />({rowData.company_registration_no})
          </>
        )}
      </div>
    ),
  },
  { header: "NRIC/Passport No.", accessor: "nric" },
  { header: "Mobile/Office Contact No.", accessor: "contact_number" },
];

class OwnerLawyer extends Component {
  state = {
    newTypeId: "",
    showConfirmationModal: false,
    toRemoveItem: {},
    info_provided_by_opposite_agent: false
  };

  componentDidMount = () => {
    Promise.all([
      this.props.onChangeOppositePaticipantHOC(this.props.selected_claim.opposite_info_password,"infoPassword")
    ]).then( () => {
      this.props.onChangeOppositePaticipantHOC( 1, "currentTypeId")
    })
    this.props.getAssignedContactType(this.props.selected_claim.id, 1);
    this.setState({ info_provided_by_opposite_agent: this.props.selected_claim.info_provided_by_opposite_agent })
  };

  componentDidUpdate = prevProps => {
    if(prevProps.selected_claim.info_provided_by_opposite_agent !== this.props.selected_claim.info_provided_by_opposite_agent) {
      this.setState({
        info_provided_by_opposite_agent: this.props.selected_claim.info_provided_by_opposite_agent
      })
    }
  }

  onCloseSendEmail = () => Promise.all([
    this.props.onChangeOppositePaticipantHOC(false, "showSendEmailLink")
  ]).then(() => {
    this.props.onChangeOppositePaticipantHOC("", "recipientEmail")
  })
  
  onClickRemoveProfile = (data) =>
    this.setState({ showConfirmationModal: true, toRemoveItem: data });

  onSubmitData = (newContact) => {
    let tmpNewContact = {
      ...newContact,
      contact_type_id: this.state.newTypeId,
      type_id: 1,
    };
    this.props.createEntity(tmpNewContact, this.props.selected_claim.id, 1);
  };

  onSubmitUpdatedData = (data) =>
    this.props.submitUpdatedData(this.props.selected_claim.id, data);

  onClickEditProfile = (data) => {
    this.props.onChangeAgencyHOC("addressInfo", {});
    this.props.getSelectedEntity(
      this.props.selected_claim.id,
      data.type_id,
      data.id,
    );
  }

  onClickUpdateRefund = (data, refund_amount) => {
    let tmp = {
      ...data,
      refund_amount: refund_amount,
    };
    return this.props.updateSelectedEntity(
      tmp,
      this.props.selected_claim.id,
      tmp.contact_type_id,
      data.id,
    );
  };

  onClickRemoveData = (data) =>
    this.setState({ showConfirmationModal: true, toRemoveItem: data });

  deleteSelectedItem = () =>
    this.setState({ showConfirmationModal: false }, () => {
      this.props.removeSelectedEntity(
        this.props.selected_claim.id,
        1,
        this.state.toRemoveItem.id,
      );
    });

  updateSelectedEntity = (data, contact_type_id) =>
    this.props.updateSelectedEntity(
      data,
      this.props.selected_claim.id,
      contact_type_id,
      data.id,
    );

  renderEditOwnerDialog = () => {
    // If claim owner is owner/landlord and contact type is not owner/landlord, email and mobile number are optional to be filled in
    const tmpIsPrimaryClaimRepresentative = this.props.selected_claim.representative_id === 2 && this.props.selectedContact.contact_type_id === 1;

    if (this.props.showEditEntityDialog) {
      return (
        <ContactFormDialog
          title={"Edit"}
          formTypes={
            this.props.selectedContact.contact_type_id === 1
              ? this.props.formTypes
              : []
          }
          allowToHideContact={tmpIsPrimaryClaimRepresentative}
          personalForms={this.props.personalForms}
          companyForms={this.props.companyForms}
          loading={this.props.onLoadContactType}
          type={
            this.props.selectedContact.contact_type_id === 1
              ? this.props.selected_claim.sub_sale_type_id === 2
                ? "Landlord"
                : "Vendor"
              : "Law Firm"
          }
          onToggleDialog={() =>
            this.props.onChangeContactTypeHOC(false, "showEditEntityDialog")
          }
          onChangeValue={this.props.onChangeEditContact}
          onChangeContactTypeHOC={this.props.onChangeContactTypeHOC}
          submitData={this.onSubmitUpdatedData}
          banks={this.props.banks}
          storedAddresses={this.props.data.addressReducer.address}
          nationalities={this.props.nationalities}
          selectedClaim={this.props.selected_claim}
          selectedContact={this.props.selectedContact}
          suggested_ic={this.props.suggested_ic}
          extactIcDetails={this.props.extactIcDetails}
          addressInfo={this.props.addressInfo}
          getAddressInfo={this.props.getAddressInfo}
          onChangeAgencyHOC={this.props.onChangeAgencyHOC}
          requestContactFromOppositeAgent={this.props.requestContactFromOppositeAgent}
        />
      );
    }
  };

  renderNewOwnerDialog = () => {
    let tmpIsPrimaryClaimRepresentative = this.props.selected_claim.representative_id === 2 && this.state.newTypeId === "1";

    if (this.props.showNewEntityDialog) {
      return (
        <ContactFormDialog
          title={"Add"}
          banks={this.props.banks}
          type={this.state.newTypeId === "1" 
            ? this.props.selected_claim.sub_sale_type_id === 2
              ? "Landlord"
              : "Vendor"
            : "Law Firm"
          }
          formTypes={this.state.newTypeId === "1" ? this.props.formTypes : []}
          allowToHideContact={tmpIsPrimaryClaimRepresentative}
          personalForms={this.props.personalForms}
          companyForms={this.props.companyForms}
          loading={this.props.onLoadContactType}
          onChangeContactTypeHOC={this.props.onChangeContactTypeHOC}
          onToggleDialog={() => {
            this.props.onChangeContactTypeHOC(false, "showNewEntityDialog");
          }}
          submitData={this.onSubmitData}
          nationalities={this.props.nationalities}
          selectedClaim={this.props.selected_claim}
          addressInfo={this.props.addressInfo}
          getAddressInfo={this.props.getAddressInfo}
          onChangeAgencyHOC={this.props.onChangeAgencyHOC}
          storedAddresses={this.props.data.addressReducer.address}
          requestContactFromOppositeAgent={this.props.requestContactFromOppositeAgent}
        />
      );
    }
  };

  onClickSelectFromContacts = (party_title) => {
    this.props.updateSelectedParticipantTypeId(1)
    this.props.updateSelectedTitle(party_title)
    this.props.onChangeSubsaleClaimsHOC(true, "showAssignContactModal")
  }

  renderDropDown = (party_title, tmpOppositeClaimChecking) => {
    return (
      <CustomDropdown 
        label={`Add ${party_title}`}
        disabled={tmpOppositeClaimChecking}>
        <MenuItem
          onClick={() => this.onClickSelectFromContacts(party_title)}
          classes={{ root: `at-btn__dropdown--list` }}>
          Select from My Contact
        </MenuItem>
        <MenuItem
          onClick={() => {
            this.props.onChangeAgencyHOC( "addressInfo", {} );
            this.props.onChangeContactTypeHOC(
              true,
              "showNewEntityDialog",
            );
            this.setState({ newTypeId: "1" });
          }}
          classes={{ root: `at-btn__dropdown--list` }}>
          Create a New Contact
        </MenuItem>
      </CustomDropdown>
    );
  }
  
  render = () => {
    const { can_create, can_update, can_destroy } = permissionsChecker(
      "Subsales Claims",
      this.props.data,
    );
    const { owners } = this.props;

    const { 
      form_type_id,
      opposite_info_status, 
      opposite_info_link,
      representative_id,
      opposite_representative_party
    } = this.props.selected_claim
    const tmpOppositeClaimChecking = representative_id === 2 && this.state.info_provided_by_opposite_agent // For opposite claim only, disabled when info_provided_by_opposite_agent is true

    let tmpOppositeRepresentativeParty = opposite_representative_party?.toLowerCase() ?? ""
    const { colorName = "" } = _.find(oppositeInfoStatusBadges, item => item.value === opposite_info_status) || {};

    return (
      <>
        <AtlasTable
          columns={columnData}
          meta={[]}
          searchParams={[]}
          totalPages={["1"]}
          rowData={owners || []}
          headerClass={"at-form_mngmt__table-header"}
          actionColumnContent={[
            {
              name: "info",
              onShow: () => !can_update || tmpOppositeClaimChecking,
              onClick: (rowData) =>
                this.onClickEditProfile(rowData),
            },
            {
              name: "edit",
              onShow: () => can_update && !tmpOppositeClaimChecking,
              iconClass: "at-table-new-icon-button ",
              iconStyle: { width: 20, height: 20, color: "#007bff" },
              onClick: (rowData) => this.onClickEditProfile(rowData),
            },
            {
              name: "delete",
              onShow: () => can_destroy && !tmpOppositeClaimChecking,
              iconClass: "at-table-new-icon-button ",
              iconStyle: { width: 20, height: 20, fill: "#EB3134" },
              onClick: (rowData) => this.onClickRemoveData(rowData),
            },
          ]}
          renderExtraContent={
            can_create
              ? () => (
                <>
                  {
                    // Only show if it is opposite claim
                    (representative_id === 2 && form_type_id !== 4) && (
                      <div className={`at-table_heading flex-column align-items-start grid_gap-0 mb-lg-0 mb-3`}>
                        <CustomCheckbox
                          labelClassname={"at-party-checkbox"}
                          content={ <p className="at-form-input__title fw-500 mb-0">Let {tmpOppositeRepresentativeParty} or representative to add & update {tmpOppositeRepresentativeParty} details</p> }
                          checked={this.state.info_provided_by_opposite_agent}
                          onChangeCheckboxValue={() => {
                            if(!this.state.info_provided_by_opposite_agent) {
                              this.props.onChangeOppositePaticipantHOC(true, "showShareLink")
                            } else {
                              this.props.onChangeOppositePaticipantHOC(true, "showRemoveShareLinkConfirmation")
                            }
                          }}
                        />
                        {(this.state.info_provided_by_opposite_agent && (opposite_info_link && opposite_info_link !== null)) && (
                          <a 
                            className="at-form-input__title fw-500 ml-4 pl-1"
                            style={{color: "#2563EB"}}
                            onClick={() => {
                              this.props.onChangeOppositePaticipantHOC(true, "showShareLink")
                            }}>Share Link</a>
                        )}
                        {
                          opposite_info_status !== null && (
                            <div className="ml-4 pl-1">
                              <p className="at-form-input__title fw-500 mb-1">Representative Updated Status</p>
                              <div>
                                <span
                                  style={{ ...getColorBadge(colorName) }}
                                  className={`at-status_badge`}>
                                  {opposite_info_status}
                                </span>
                              </div>
                            </div>
                          )
                        }
                      </div>
                    )
                  }
                  <div className={`at-table_heading g-2`}>
                    {this.renderDropDown(this.props.selected_claim.sub_sale_type_id === 2 ? "Landlord" : "Vendor", tmpOppositeClaimChecking)}
                    <button
                      className="btn-new btn-new--primary"
                      disabled={tmpOppositeClaimChecking}
                      onClick={() => {
                        this.props.onChangeContactTypeHOC(
                          true,
                          "showNewEntityDialog",
                        );
                        this.setState({ newTypeId: "3" });
                      }}>
                      Add Law Firm
                    </button>
                  </div>
                </>
              )
              : ""
          }
        />
        {
          this.props.showShareLink && (
            <ShareLinkToRepresentative
              representative_title={this.props.selected_claim.opposite_representative_party}
              infoPassword={this.props.infoPassword}
              onChangeInfoPassword={val => this.props.onChangeOppositePaticipantHOC(val, "infoPassword")}
              disabledShareButtons={this.props.disabledShareButtons}
              selected_claim={this.props.selected_claim}
              opposite_info_status={opposite_info_status}
              opposite_info_link={opposite_info_link}
              info_provided_by_opposite_agent={this.state.info_provided_by_opposite_agent}
              updateOppositeInfoProvided={this.props.updateOppositeInfoProvided}
              sendOppositeInfoRequestEmail={this.props.sendOppositeInfoRequestEmail}
              onClickShareEmail={ () => this.props.onChangeOppositePaticipantHOC(true, "showSendEmailLink") }
              onToggleDialog={val => {
                if(opposite_info_link === null) {
                  this.setState({ info_provided_by_opposite_agent: false })
                }
                this.props.onChangeOppositePaticipantHOC(val, "showShareLink")
              }}
            />
          )
        }
        {this.renderNewOwnerDialog()}
        {this.renderEditOwnerDialog()}
        {(this.props.onLoadContactType || this.props.onLoadOppositeParticipant) && <LoadingModal />}
        {
          this.props.showSendEmailLink && (
            <ModalDialog
              title={`Send Link Via Email`}
              onLoad={this.props.onLoadOppositeParticipant}
              onClose={() => this.onCloseSendEmail()}
              children={
                <div>
                  <p className="fs-2">Please include a , between email addresses if multiple emails need to be included as recipients.</p>
                  <p className="fs-2">For example: test@gmail.com,john@gmail.com</p>
                  <div className="mt-3">
                    <h2 className={`at-form-input__title`} required>Recipient's Email</h2>
                    <AtlasFormInput
                      type="text"
                      required={true}
                      value={`${this.props.recipientEmail || ""}`}
                      onChangeValue={(val) => this.props.onChangeOppositePaticipantHOC(val, "recipientEmail")}
                    />
                  </div>
                </div>
              }
              footer={
                <>
                  <div className="d-flex grid_gap-1">
                    <button className="btn-new btn-new--primary" onClick={ () => {
                      this.props.sendOppositeInfoRequestEmail( this.props.selected_claim.id,{
                        recipients: this.props.recipientEmail,
                      })
                    }}>Send</button>
                    <button className="btn-new btn-new--outline-secondary" 
                      onClick={ () => this.onCloseSendEmail() }>
                      Cancel
                    </button>
                  </div>
                </>
              }/>
          )
        }
        <ConfirmationModal
          mode={"info"}
          open={this.props.showRemoveShareLinkConfirmation}
          loading={this.props.onLoadOppositeParticipant}
          message={"The representative will not be able to access or update the details of the case participants."}
          title={"Remove Share Link"}
          positiveAction={ () => this.props.updateOppositeInfoProvided(this.props.selected_claim.id, { info_provided_by_opposite_agent: false }) }
          positiveText={"Confirm"}
          negativeAction={ () => this.props.onChangeOppositePaticipantHOC(false, "showRemoveShareLinkConfirmation") }
          negativeText={"Cancel"}
        />
        <DeleteConfirmationModal
          open={this.state.showConfirmationModal}
          loading={this.props.onLoadContactType}
          positiveAction={this.deleteSelectedItem}
          negativeAction={() => this.setState({ showConfirmationModal: false })}
          message={`You will not able to recover the data`}
        />
      </>
    );
  };
}

export default compose(RegionHOC, OppositeParticipant)(OwnerLawyer);
