import React, { Component } from "react";
import { Dialog, MenuItem } from "@material-ui/core";
import { FaUserTie } from "react-icons/fa";

import AtlasIcon from "components/Icon/atlasIcon";
import LoadingModal from "components/LoadingModal";
import AtlasTable from "components/NewPaginatedTable";
import DeleteConfirmationModal from "components/Modal/delete";
import ContactFormDialog from "../components/formDialog";
import CustomDropdown from "components/Button/dropdown";

import permissionsChecker from "utils/permissionsChecker";

import "stylesheets/containers/subsales-claims/index.scss";

const columnData = [
  {
    header: "Full Name",
    renderColumn: (rowData) => (
      <div className={"d-flex align-items-center party-card__badge-title-icon at-subsales-contact_form_type-title-icon"}>
        {rowData.contact_type_id === "2" ? (
          <>
            <AtlasIcon svgHref={"atlas-building-company"}/>
            <div className={"ml-1"}>
              {rowData.full_name}
              <br />({rowData.nric})
            </div>
          </>
        ) : (
          <>
            {rowData.contact_type_id === "1" ? <AtlasIcon svgHref={"atlas-icon-user"}/> : <FaUserTie />}
            {rowData.full_name}
          </>
        )}
      </div>
    ),
  },
  { header: "Email", accessor: "email" },
  { header: "NRIC/Passport No.",
    renderColumn: ({contact_type_id, nric}) => (contact_type_id === "2" ? "N/A" : nric),
  },
  { header: "Mobile/Office Contact No.", accessor: "contact_number" },
];

class Referral extends Component {
  state = {
    showConfirmationModal: false,
    toRemoveItem: {},
  };

  componentDidMount = () => {
    this.props.getAssignedContactType(this.props.selected_claim.id, 4);
  };

  componentDidUpdate = (pp) => {
    if (this.props.internalAgentCount !== pp.internalAgentCount) {
      if (this.props.referrals.length > 0) {
        this.props.getAssignedContactType(this.props.selected_claim.id, 4);
      }
    }
  };

  onSubmitData = (newContact) => {
    let tmp = {
      referral: newContact,
    };
    this.props.createEntity(tmp, this.props.selected_claim.id, 4);
  };

  onSubmitUpdatedData = (data) =>
    this.props.submitUpdatedData(this.props.selected_claim.id, data);

  onClickEditProfile = (data) =>
    this.props.getSelectedEntity(this.props.selected_claim.id, 4, data.id);

  onClickSaveReferralCard = (data) =>
    this.props.updateSelectedEntity(
      data,
      this.props.selected_claim.id,
      4,
      data.referral.id,
    );

  onClickRemoveReferralCard = (data) =>
    this.setState({ showConfirmationModal: true, toRemoveItem: data });

  deleteSelectedItem = () =>
    this.setState({ showConfirmationModal: false }, () => {
      this.props.removeSelectedEntity(
        this.props.selected_claim.id,
        this.state.toRemoveItem.type_id,
        this.state.toRemoveItem.id,
      );
    });

  renderEditDialog = () => {
    if (this.props.showEditEntityDialog) {
      return (
        <Dialog open={true} maxWidth={"lg"} classes={{ paper: "m-0" }}>
          <ContactFormDialog
            title={"Edit"}
            type={"Referral"}
            formTypes={this.props.formTypes([
              {
                label: "Internal Agent",
                value: "3",
              },
            ])}
            nationalities={this.props.nationalities}
            banks={this.props.banks}
            personalForms={this.props.personalForms}
            companyForms={this.props.companyForms}
            loading={this.props.onLoadContactType}
            onToggleDialog={() =>
              this.props.onChangeContactTypeHOC(false, "showEditEntityDialog")
            }
            onChangeValue={this.props.onChangeEditContact}
            submitData={this.onSubmitUpdatedData}
            storedAddresses={this.props.data.addressReducer.address}
            selectedClaim={this.props.selected_claim}
            selectedContact={this.props.selectedContact}
          />
        </Dialog>
      );
    }
  };

  renderNewDialog = () => {
    if (this.props.showNewEntityDialog) {
      return (
        <Dialog open={true} maxWidth={"lg"} classes={{ paper: "m-0" }}>
          <ContactFormDialog
            title={"Add"}
            type={"Referral"}
            formTypes={this.props.formTypes([
              {
                label: "Internal Agent",
                value: "3",
              },
            ])}
            nationalities={this.props.nationalities}
            banks={this.props.banks}
            personalForms={this.props.personalForms}
            companyForms={this.props.companyForms}
            allFormItems={this.props.referrals}
            selectedAgent={this.props.selectedAgent}
            claim_status={this.props.claim_status}
            loading={this.props.onLoadContactType}
            onToggleDialog={() => this.props.onChangeContactTypeHOC(false, "showNewEntityDialog")}
            searchAgent={this.props.searchAgent}
            submitData={this.onSubmitData}
            selectedClaim={this.props.selected_claim}
            storedAddresses={this.props.data.addressReducer.address}
          />
        </Dialog>
      );
    }
  };

  onClickSelectFromContacts = () => {
    this.props.updateSelectedParticipantTypeId(4)
    this.props.updateSelectedTitle("Referral")
    this.props.onChangeSubsaleClaimsHOC(true, "showAssignContactModal")
  }

  renderDropDown = () => {
    return (
      <CustomDropdown label={"Add Referral"}>
        <MenuItem
          onClick={() => this.onClickSelectFromContacts()}
          classes={{ root: `at-btn__dropdown--list` }}>
          Select from My Contact
        </MenuItem>
        <MenuItem
          onClick={() => this.props.onChangeContactTypeHOC(true, "showNewEntityDialog")}
          classes={{ root: `at-btn__dropdown--list` }}>
          Create a New Contact
        </MenuItem>
      </CustomDropdown>
    );
  }

  render = () => {
    const { referrals } = this.props;
    const { can_create } = permissionsChecker(
      "Subsales Claims",
      this.props.data,
    );
    return (
      <>
        <AtlasTable
          columns={columnData}
          meta={[]}
          searchParams={[]}
          totalPages={["1"]}
          rowData={referrals || []}
          actionColumnContent={[
            {
              name: "edit",
              onClick: (rowData) => this.onClickEditProfile(rowData),
            },
            {
              name: "delete",
              color: "#F04438",
              onClick: (rowData) => this.onClickRemoveReferralCard(rowData),
            },
          ]}
          renderExtraContent={
            can_create
              ? () => (
                  <div className={`at-table_heading`}>
                    {this.renderDropDown("Referral")}
                  </div>
                )
              : ""
          }
          customEmptyState={{
            title: "No referrals available",
            description: (
              <>
                Click<b>Add Referral</b> to create referrals
              </>
            ),
            icon: "icon-user",
          }}
        />
        {this.renderNewDialog()}
        {this.renderEditDialog()}
        {this.props.onLoadContactType && <LoadingModal />}
        <DeleteConfirmationModal
          open={this.state.showConfirmationModal}
          loading={this.props.onLoadContactType}
          positiveAction={this.deleteSelectedItem}
          negativeAction={() => this.setState({ showConfirmationModal: false })}
          message={`You will not able to recover the data`}
        />
      </>
    );
  };
}

export default (Referral);
