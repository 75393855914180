import React, { Component } from "react";
import { compose } from "redux";
import _ from "lodash";
import Moment from "moment";

import UnderlineTab from "components/Tab/new";
import DuoTab from "components/Tab/duo";
import AtlasEmailModal from "components/Modal/email";
import AlertBox from "components/AlertBox";
import EditFormModalContent from "../../../components/editFormModalContent";
import SubsalesAppointmentLetter from "./TempSubsalesLetter";
import SubrentAppointmentLetter from "./TempSubrentLetter";
import PartyContent from "../../../components/PartyContent";
import AtlasIcon from "components/Icon/atlasIcon";
import SvgIcon from "components/Icon/new";
import EProcessContent from "./eProcessContent";
import FormChart from "components/Chart/form";
import ConfirmationModal from "components/Modal/confirmation";
import ModalDialog from "components/Modal/ModalDialog";
import CustomTypeahead from "components/Typeahead/new";
import AtlasRadioGroup from "components/RadioGroup";
import LoadingModal from "components/LoadingModal";
import PropertyContent from "../../../components/propertyTabContent";

import PartyHOC from "../actions/party";
import ContactHOC from "actions/contact";
import isEmptyValue from "utils/isEmpty";
import permissionsChecker from "utils/permissionsChecker";
import { checkIsNumber } from "utils/checkNull";

const SigningTabs = ["e-Signing", "Manual Signing"];

export const NavTabs = [
  { label: "Property", icon: <SvgIcon svgHref="icon-listing-centre" /> },
  {
    label: "Participants", icon: <SvgIcon svgHref="confirmation-form_participants" />,
  },
  { label: "T&C", icon: <SvgIcon svgHref="confirmation-form_document-text" /> },
  {
    label: "Contract Signing", icon: <SvgIcon svgHref="confirmation-form_esign" />,
  }
];

const partyDictionary = [
  {
    id: 3,
    subsalesTitle: "Vendor",
    subrentTitle: "Landlord",
  },
  {
    id: 4,
    subsalesTitle: "Vendor's Representative",
    subrentTitle: "Landlord's Representative",
  },
];

const RadioOptions = [
  { label: "Create New Confirmation Form", value: "1" },
  { label: "Select Existing Confirmation Form", value: "2" },
  { label: "Select Existing Physical Form", value: "3" },
];

class EditContent extends Component {
  state = {
    selectedSection: "Property",
    toSubmitData: {},
    autoCalculatedList: [],
    onAutoCalculateEarnestDepositPercentage: false,
    formBondType: "",
    selectedForm: {},
  };

  componentDidMount = () => {
    const { selected_appointment_letter } = this.props;

    // check if e_process is not null then, call api
    this.props.tempEProcess && this.props.getSigningStatus(selected_appointment_letter.id);
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.showEditApptLetter &&
      prevState.selectedSection !== this.state.selectedSection
    ) {
      this.props.getAppointmentLetter(
        this.props.selected_appointment_letter.id,
      );
    }
    if (prevState.formBondType !== this.state.formBondType) {
      this.setState({ selectedForm: {} });
    }

    if (prevState.selectedSection !== this.state.selectedSection) {
      const { selected_appointment_letter } = this.props;

      if([ "Participants", "Contract Signing" ].indexOf(this.state.selectedSection) > -1) {
        this.props.getParty(selected_appointment_letter.id);
        
        if(this.state.selectedSection === "Participants") {
          this.props.getCurrentUserContactList();
        }
      }
    }
  };

  onChangeEditAppointmentForm = (context, value) => {
    this.setState({
      [context]: value,
    });
  };

  onCloseSelectForm = () => {
    this.props.onChangeAppointmentHOC(false, "showSelectForm");
    this.setState({
      formBondType: "",
      selectedForm: {},
    });
  };

  onChangeEditSubsale = (context, value = "") => {
    const { selected_appointment_letter, onChangeAppointmentHOC } = this.props;

    const { onAutoCalculateEarnestDepositPercentage } = this.state;
    let tmpAutoCalculatedList = [];
    let tmp = _.cloneDeep(selected_appointment_letter);

    if (context === "TriggerIcon") {
      this.setState({ onAutoCalculateEarnestDepositPercentage: value });
    } else {
      tmp[context] = value;

      if (
        context === "earnest_deposit_percentage" &&
        checkIsNumber(tmp.earnest_deposit_percentage) &&
        tmp.earnest_deposit_percentage <= 10 &&
        onAutoCalculateEarnestDepositPercentage
      ) {
        tmp.balance_deposit_percentage = (
          10 - tmp.earnest_deposit_percentage
        ).toFixed(2);
        tmpAutoCalculatedList.push("balance_deposit_percentage");
      }
      if (
        context === "balance_deposit_percentage" &&
        checkIsNumber(tmp.balance_deposit_percentage) &&
        tmp.balance_deposit_percentage <= 10 &&
        onAutoCalculateEarnestDepositPercentage
      ) {
        tmp.earnest_deposit_percentage = (
          10 - tmp.balance_deposit_percentage
        ).toFixed(2);
        tmpAutoCalculatedList.push("earnest_deposit_percentage");
      }
      this.setState({ autoCalculatedList: tmpAutoCalculatedList });
      onChangeAppointmentHOC(tmp, "selected_appointment_letter");
    }
  };

  onChangeEditSubrent = (context, value = "") => {
    const { selected_appointment_letter, onChangeAppointmentHOC } = this.props;

    let tmpAutoCalculatedList = [];
    let tmp = _.cloneDeep(selected_appointment_letter);

    tmp[context] = value;

    const {
      rental_amount = 0,
      advanced_rental_months = 0,
      security_deposit_months = 0,
    } = tmp;

    if (context === "rental_amount" || context === "advanced_rental_months") {
      if (
        checkIsNumber(rental_amount) &&
        checkIsNumber(advanced_rental_months)
      ) {
        tmp.advanced_rental_amount = (
          rental_amount * advanced_rental_months
        ).toFixed(2);
        tmpAutoCalculatedList.push("advanced_rental_amount");
      }
    }
    if (context === "rental_amount" || context === "security_deposit_months") {
      if (
        checkIsNumber(rental_amount) &&
        checkIsNumber(security_deposit_months)
      ) {
        tmp.security_deposit_amount = (
          rental_amount * security_deposit_months
        ).toFixed(2);
        tmpAutoCalculatedList.push("security_deposit_amount");
      }
    }

    this.setState({ autoCalculatedList: tmpAutoCalculatedList });
    onChangeAppointmentHOC(tmp, "selected_appointment_letter");
  };

  submitEmail = (recipient) =>
    this.props.sendAppointmentLetter(
      this.props.selected_appointment_letter.id,
      { recipients: recipient },
    );

  renderTabContent = () => {
    return (
      <UnderlineTab
        sections={NavTabs}
        selectedSection={this.state.selectedSection}
        onSelectSection={(val) => this.setState({ selectedSection: val })}
      />
    );
  };

  renderContractParticipants = () => {
    return (
      <PartyContent
        {...this.props}
        form_type={"appointment"}
        partyDictionary={partyDictionary}
        selected_form={this.props.selected_appointment_letter}
        menuExpand={this.props.data.sideMenuReducer.sideMenuExpand}
        renderNavButton={() => this.renderNavButton(this.state.selectedSection)}
      />
    );
  };

  renderEsigningContent = () => (
    <>
      <div className="at-form__content at-new_container-20">
        <h5 className="at-card_content-title">{"Contract Signing"}</h5>
        <p className="fs-2">
          How do you want to sign this contract? Choose only one method.
        </p>
      </div>
      <DuoTab
        sections={SigningTabs}
        selectedSection={this.props.selectedSigningTab}
        onSelectSection={(val) =>
          this.props.onChangePartyHOC(val, "selectedSigningTab")
        }
      />
      {this.renderSigningTabContent()}
    </>
  );

  renderSigningTabContent = () => {
    const { selectedSigningTab } = this.props;

    const { id: appointmentId, transaction_number } =
      this.props.selected_appointment_letter;
    const { sideMenuExpand } = this.props.data.sideMenuReducer;

    return (
      <>
        {selectedSigningTab === "Manual Signing" && (
          <>
            <AlertBox
              containerClassName="mb-3"
              containerStyle={{
                backgroundColor: "#FFFAEA",
                borderColor: "#FCB122",
                marginBottom: "1rem",
              }}
              iconStyle={{ color: "#F79008" }}
              description={`Please make sure that you have saved the data by clicking the "Save Terms & Conditions" button in T&C before proceeding any actions below.`}
            />
            <div className="at-form__content">
              <h5 className="at-card_content-title mb-3">{"Manual Signing"}</h5>
              <p className="fs-2">
                You can offer your client two options when signing the
                confirmation form:
              </p>
              <p className="fs-2">
                1. You can print out the confirmation form and cross out any
                inapplicable phrases or mistakes before your client signs the
                printed form.
              </p>
              <p className="fs-2">
                2. Should your client wish to use a digital signature for the
                confirmation form, you will need to attach a screenshot of the
                client sending you the confirmation form (via E-mail, WhatsApp,
                WeChat or any other platform) as proof of submission for claims.
              </p>
              <br />
              <p className="fs-2">
                Download Adobe Fill & Sign mobile app to sign PDF documents
                digitally.
              </p>
              <div className="d-flex grid_gap-1 flex-wrap mt-2">
                <button
                  className="btn-new btn-new--secondary"
                  onClick={() =>
                    this.props.downloadAppointmentLetter(
                      appointmentId,
                      `${transaction_number}.pdf`,
                    )
                  }
                >
                  {`Download Appointment Letter`}
                </button>
                <button
                  className="btn-new btn-new--secondary"
                  onClick={() =>
                    this.props.onChangeAppointmentHOC(true, "showSendEmail")
                  }
                >
                  {`Send Appointment Letter`}
                </button>
              </div>
            </div>
            <div
              className={`at-floating_panel${sideMenuExpand ? " menu-expand" : ""}`}
            >
              {this.renderNavButton(this.state.selectedSection)}
            </div>
          </>
        )}
        {selectedSigningTab === "e-Signing" && (
          <EProcessContent
            {...this.props}
            menuExpand={this.props.data.sideMenuReducer.sideMenuExpand}
            renderNavButton={() =>
              this.renderNavButton(this.state.selectedSection)
            }
          />
        )}
      </>
    );
  };

  renderFormContent = (switchMode) => {
    const { type_id } = this.props.selected_appointment_letter;
    const { data, selected_appointment_letter, closedEditing } = this.props;

    return (
      <>
        {type_id === 1 && (
          <SubsalesAppointmentLetter
            switchMode={switchMode}
            closedEditing={closedEditing}
            autoCalculatedList={this.state.autoCalculatedList}
            onAutoCalculateEarnestDepositPercentage={
              this.state.onAutoCalculateEarnestDepositPercentage
            }
            onChange={(context, value) =>
              this.onChangeEditSubsale(context, value)
            }
            invoicing_branches={this.props.invoicing_branches}
            storedAddresses={data.addressReducer.address}
            data={selected_appointment_letter}
          />
        )}
        {type_id !== 1 && (
          <SubrentAppointmentLetter
            switchMode={switchMode}
            closedEditing={closedEditing}
            autoCalculatedList={this.state.autoCalculatedList}
            onChange={(context, value) =>
              this.onChangeEditSubrent(context, value)
            }
            invoicing_branches={this.props.invoicing_branches}
            storedAddresses={data.addressReducer.address}
            data={selected_appointment_letter}
          />
        )}
      </>
    );
  };

  renderPropertyContent = () => {
    const {
      data,
      closedEditing,
      townshipsList,
      onLoadApptLetter,
      onChangeAppointmentHOC,
      selected_form_property,
      autoSuggestOptions,
      original_form_property,
      updatePropertyLocation,
      edgeAutoSuggest,
      autoSuggestLoading,
      getSelectedTownShip,
    } = this.props;

    return (
      <PropertyContent
        formType={"AppointmentLetter"}
        data={data}
        townshipsList={townshipsList}
        closedEditing={closedEditing}
        loading={onLoadApptLetter}
        onChange={onChangeAppointmentHOC}
        formData={selected_form_property}
        originalPropertyData={original_form_property}
        autoSuggestOptions={autoSuggestOptions}
        updateForm={updatePropertyLocation}
        edgeAutoSuggest={edgeAutoSuggest}
        autoSuggestLoading={autoSuggestLoading}
        getSelectedTownShip={getSelectedTownShip}
        onClickCloseForm={() =>
          onChangeAppointmentHOC(false, "showEditApptLetter")
        }
        menuExpand={this.props.data.sideMenuReducer.sideMenuExpand}
        renderNavButton={() => this.renderNavButton(this.state.selectedSection)}
      />
    );
  };

  renderNavButton = (selectedTab) => {
    let tmpTabs = _.cloneDeep(NavTabs);
    let tmpIndex = tmpTabs.findIndex((item) => item.label === selectedTab);
    let lastIndex =
      tmpTabs.findIndex((item) => item.label === "Contract Signing") + 1;

    return (
      <>
        {tmpIndex - 1 >= 0 && (
          <button
            className="btn-new btn-new--secondary at-btn_prev"
            onClick={() =>
              this.setState({ selectedSection: tmpTabs[tmpIndex - 1].label })
            }
          >
            <p>{tmpTabs[tmpIndex - 1].label}</p>
          </button>
        )}
        {tmpIndex + 1 < lastIndex && (
          <button
            className="btn-new btn-new--secondary at-btn_next"
            onClick={() =>
              this.setState({ selectedSection: tmpTabs[tmpIndex + 1].label })
            }
          >
            <p>{tmpTabs[tmpIndex + 1].label}</p>
          </button>
        )}
        <button
          className="btn-new btn-new--outline-secondary"
          onClick={() =>
            this.props.onChangeAppointmentHOC(false, "showEditApptLetter")
          }
        >
          Close
        </button>
      </>
    );
  };

  renderFormsChart = () => {
    const {
      forms_chart,
      id,
    } = this.props.selected_appointment_letter;

    return (
      forms_chart !== null && (
        <FormChart
          history={this.props.history}
          infoData={{ id: id }}
          currentFormType={"Appointment Letter"}
          forms_chart={forms_chart}
          onClickButton={(data) => {
            this.props.onChangeAppointmentHOC(true, "showSelectForm");
            this.setState({ toSubmitData: data });
          }}
          onClickClosing={() => {}}
        />
      )
    );
  };

  renderClosingFormModal = () => {
    const { formBondType } = this.state;
    const { type_id } = this.props.selected_appointment_letter;

    if (this.props.showSelectForm) {
      return (
        <ModalDialog
          title={"Submit Appointment Letter"}
          fullHeight={false}
          onClose={() => this.onCloseSelectForm()}
          children={
            <div className="at-apt_letter-closing_form-cont">
              <p className="mb-3 fs-2">
                Please choose an option to submit appointment letter:
              </p>
              <AtlasRadioGroup
                mode="old"
                horizontal={false}
                checkedValue={formBondType}
                containerClassName={"w-100"}
                selectedRadioValue={(val) => {
                  if (val === "2") {
                    this.props.getUnattachedConfirmationForms("AppointmentLetter", type_id);
                  } else if (val === "3") {
                    this.props.getUnattachedSubsalesForms(type_id, "AppointmentLetter");
                  }
                  this.setState({ formBondType: val });
                }}
                options={RadioOptions}
              />
              {formBondType === "2" && (
                <div className="mt-3 mb-4" style={{ height: 250 }}>
                  <h2 className="at-form-input__title mr-10">Form Number</h2>
                  <CustomTypeahead
                    showClearButton
                    typeaheadId={"unclaimedConfirmationForm"}
                    options={this.props.unclaimedConfirmationForms || []}
                    selectedValue={
                      this.state.selectedForm &&
                      _.find(this.props.unclaimedConfirmationForms, {
                        id: this.state.selectedForm.id,
                      })
                        ? [
                            _.find(this.props.unclaimedConfirmationForms, {
                              id: this.state.selectedForm.id,
                            }),
                          ]
                        : []
                    }
                    labelKey={"label"}
                    filterBy={["label", "property_address"]}
                    onSelect={(val) => {
                      if (val && val.length > 0) {
                        return this.setState({ selectedForm: val[0] });
                      }
                      return this.setState({ selectedForm: "" });
                    }}
                    childrenHead={(rowItem) => <p>{rowItem.label}</p>}
                    highlighterData={[(option) => option.property_address]}
                  />
                </div>
              )}
              {formBondType === "3" && (
                <div className="mt-3 mb-4" style={{ height: 250 }}>
                  <h2 className="at-form-input__title mr-10">Form Number</h2>
                  <CustomTypeahead
                    showClearButton
                    typeaheadId={"unclaimedSubsalesForm"}
                    options={this.props.unclaimedSubsalesForms || []}
                    selectedValue={
                      this.state.selectedForm &&
                      _.find(this.props.unclaimedSubsalesForms, {
                        id: this.state.selectedForm.id,
                      })
                        ? [
                            _.find(this.props.unclaimedSubsalesForms, {
                              id: this.state.selectedForm.id,
                            }),
                          ]
                        : []
                    }
                    labelKey={"label"}
                    filterBy={["tracking_number"]}
                    onSelect={(val) => {
                      if (val && val.length > 0) {
                        return this.setState({ selectedForm: val[0] });
                      }
                      return this.setState({ selectedForm: {} });
                    }}
                    childrenHead={(rowItem) => <p>{rowItem.label}</p>}
                    highlighterData={[]}
                  />
                </div>
              )}
              <div className="at-modal_dialog-container-footer d-flex grid_gap-1">
                {formBondType && (
                  <button
                    disabled={
                      formBondType === "1"
                        ? false
                        : isEmptyValue(this.state.selectedForm)
                    }
                    className="btn-new btn-new--primary"
                    onClick={() => {
                      formBondType === "1"
                        ? this.props.onChangeAppointmentHOC(
                            true,
                            "showSubmitConfirmation",
                          )
                        : this.props.submitForm(
                            this.state.toSubmitData.form_id,
                            "AppointmentLetter",
                            {
                              submission_method_id: formBondType,
                              attached_form_id: this.state.selectedForm.id,
                            },
                          );
                    }}
                  >
                    Submit
                  </button>
                )}
                <button
                  className="btn-new btn-new--outline-secondary"
                  onClick={() => this.onCloseSelectForm()}
                >
                  Cancel
                </button>
              </div>
            </div>
          }
        />
      );
    }
  };

  render = () => {
    const { closedEditing } = this.props
    const { description, transaction_number, e_process } = this.props.selected_appointment_letter;
    const { selectedSection } = this.state;
    const tmpTransactionNumber = `${!isEmptyValue(transaction_number) ? `/ ${transaction_number}` : ""}`;
    const { status_id } = e_process ?? {}
    const tmpEProcessDisabledChecking = !( e_process === null || (e_process !== null && status_id === 0))  
    const { can_update } = permissionsChecker("Forms", this.props.data);

    return (
      <>
        <EditFormModalContent
          title={`Edit Letter ${tmpTransactionNumber}`}
          history={this.props.history}
          closeFormModal={() =>
            this.props.onChangeAppointmentHOC(false, "showEditApptLetter")
          }
          saveTnC={() => this.props.saveTnC()}
          can_update={can_update}
          closedEditing={closedEditing}
          eProcessDisabledChecking={tmpEProcessDisabledChecking}
          formType={"appointment letter"}
          description={description}
          selectedSection={this.state.selectedSection}
          menuExpand={this.props.data.sideMenuReducer.sideMenuExpand}
          tabContent={this.renderTabContent()}
          propertyContent={this.renderPropertyContent()}
          partyContent={this.renderContractParticipants()}
          signingContent={this.renderEsigningContent()}
          formContent={(switchMode) => this.renderFormContent(switchMode)}
          chartContent={this.renderFormsChart()}
          renderNavButton={() => this.renderNavButton(selectedSection)}
          toggleClaimed={this.props.claimedAppointmentLetter}
          onChangeEdit={this.onChangeEditAppointmentForm}
          onChangeField={this.props.onChangeApptLetterValue}
          loading={this.props.onLoadApptLetter}
        />
        <AtlasEmailModal
          title={"Send Appointment Letter"}
          loading={this.props.onLoadApptLetter}
          showSendEmail={this.props.showSendEmail}
          closeSendEmail={() =>
            this.props.onChangeAppointmentHOC(false, "showSendEmail")
          }
          submitEmail={(recipients) => this.submitEmail(recipients)}
        />
        <ConfirmationModal
          mode={"info"}
          open={this.props.showSubmitConfirmation}
          title={"Closing Deal"}
          message={
            "Are you sure you want to submit Appointment Letter to Confirmation Form?"
          }
          loading={this.props.onLoadApptLetter || this.props.onLoadForms}
          positiveText={"Submit"}
          icon={<AtlasIcon svgHref={"svg-document-user"} />}
          positiveAction={() =>
            this.props.submitForm(
              this.state.toSubmitData.form_id,
              "AppointmentLetter",
              { submission_method_id: this.state.formBondType },
            )
          }
          negativeText={"Cancel"}
          negativeAction={() =>
            this.props.onChangeAppointmentHOC(false, "showSubmitConfirmation")
          }
        />
        {this.renderClosingFormModal()}
        {(this.props.onLoadApptLetter || this.props.onLoadForms) && (
          <LoadingModal />
        )}
      </>
    );
  };
}

export default compose(ContactHOC, PartyHOC)(EditContent);
